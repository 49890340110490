import { Injectable } from "@angular/core";
import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineCourseService extends ApiService {

  constructor() {
    super('onlinecourse');
  }
}