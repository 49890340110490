import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { IBaseClass } from "./base.interface";
import { QueryStringService } from "../query-string/querystring.service";
import { Objectionable } from "./base.objectionable";
import { LazyList } from "./base.lazylist";

/**
 * @deprecated use {@link ApiService} instead
 */
export class BaseProvider<C> {
    private _apiUrl: string;
    private _origUrl: string;
    public queryStringProvider: QueryStringService;

    constructor(public http: HttpClient, public apiUrl: string) {
        this._apiUrl = apiUrl;
        this.queryStringProvider = new QueryStringService();
    }

    // call the provider - inside the callback - , but send the request to another url
    bent(apiUrl: string, callback: (provider: BaseProvider<C>) => void): void {
        if(this._origUrl) {
            throw "BaseProvider.bend may not be nested.";
        }
        this._origUrl = this._apiUrl;
        this._apiUrl = apiUrl;
        callback(this);
        this._apiUrl = this._origUrl;
        this._origUrl = undefined;
    }

    getErrorObservable(err: any): Observable<any> {
        return Observable.create((errObs) => errObs.error(err)).share();
    }

    defaults(c: C|null, queryStringObject?: any): Observable<C> {
        return this.http.post<C>(this._apiUrl + "new" + this.queryStringProvider.generateUrl(queryStringObject), c);
    }

    get(id: number, queryStringObject?: any): Observable<C> {
        return this.http.get<C>(this._apiUrl + id + this.queryStringProvider.generateUrl(queryStringObject));
    }

    getList(queryStringObject?: any): Observable<C[]> {
        return this.http.get<C[]>(this._apiUrl + "list" + this.queryStringProvider.generateListUrl(queryStringObject));
    }

    getLazyList(queryStringObject?: any): Observable<LazyList<C>> {
        if (!queryStringObject) {
            queryStringObject = {filter: {}};
        } else if (!queryStringObject.filter) {
            queryStringObject.filter = {};
        }
        queryStringObject.filter.lazylist = true;
        return this.http.get<LazyList<C>>(this._apiUrl + "list" + this.queryStringProvider.generateListUrl(queryStringObject));
    }

    create(c: C, queryStringObject?: any): Observable<C> {
        return this.http.post<C>(this._apiUrl + "add" + this.queryStringProvider.generateUrl(queryStringObject), c);
    }

    update<C extends IBaseClass>(c: C, queryStringObject?: any): Observable<C> {
        return this.http.post<C>(this._apiUrl + "update/" + c.id + this.queryStringProvider.generateUrl(queryStringObject), c);
    }

    remove(id: number, queryStringObject?: any): Observable<void> {
        return this.http.delete<void>(this._apiUrl + "delete/" + id + this.queryStringProvider.generateUrl(queryStringObject));
    }

    report(o: Objectionable, queryStringObject?: any): Observable<C> {
        return this.http.post<C>(this._apiUrl + "objectionable/" + o.id + this.queryStringProvider.generateUrl(queryStringObject), o);
    }
}
