import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/dist/types';
import { AuthService } from 'services/auth/auth.service';
import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { PlatformService } from 'services/platform/platform.service';
import { RoutingService } from 'services/routing/routing.service';
import { ConstantRoute } from 'src/app/constant-routes';
import { Pages } from 'src/app/pages';

@Component({
  selector: 'app-home-redirect',
  templateUrl: './home-redirect.page.html',
  styleUrls: ['./home-redirect.page.scss'],
  standalone: true,
  imports: []
})
export class HomeRedirectPage implements OnInit {

  subscription: Subscription;

  constructor(
    private router: Router,
    public platformService: PlatformService,
    public routingService: RoutingService,
    public configProvider: BranchConfigService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    console.log('HOME-REDIRECT: deciding where to redirect. userId is \'' + this.configProvider?.config?.userId + '\' and config is ', this.configProvider?.config);
    // check if user is ready
    if (this.configProvider?.config?.userId && this.configProvider?.config?.features) {
      this.redirect();
    }
    // else wait for user to be ready
    else {
      this.configProvider.isUserReady.then(config => this.redirect());
    }
  };

  private redirect(): void {
    if (this.configProvider?.config?.features?.onboarding) {
      // onboarding
      console.log('HOME-REDIRECT: redirecting to ' + '/onboarding');
      this.router.navigate(['/onboarding']);
    } else {
      if (this.platformService.isMobile()) {
        // Redirect to the full coaching route
        console.log('HOME-REDIRECT: redirecting to ' + '/' + ConstantRoute.FULL_COACHING);
        this.router.navigate(['/' + ConstantRoute.FULL_COACHING]);
      } else {
        // dashboard
        console.log('HOME-REDIRECT: redirecting to ' + '/dashboard');
        this.router.navigate(['/dashboard']);
      }
    }
  }
}
