import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service for handling alerts.
 */
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService
  ) { }

  /**
   * Displays a yes-no alert.
   * @param header header of the alert
   * @param yesHandler action that happens when the yes-button in pressed
   * @param noHandler action that happens when the no-button in pressed or the alert is dismissed (optional)
   * @param subHeader subheader of the alert (optional)
   * @param message message of the alert (optional)
   * @param yesText custom text for the yes button (optional)
   * @param noText custom text for the no button (optional)
   */
  public async presentAlert(header: string, yesHandler: () => void, noHandler?: () => void, subHeader?: string, message?: string, yesText?: string, noText?: string) {
    const yesButtonText: string = !!yesText ? yesText : this.translateService.instant('alert.yes-no-alert.button.yes');
    const noButtonText: string = !!noText ? noText : this.translateService.instant('alert.yes-no-alert.button.no');
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      cssClass: 'yes-no-alert',
      buttons: [
        {
          text: noButtonText,
          role: 'cancel',
          cssClass: 'yes-no-alert-button-no',
          handler: () => {
            noHandler();
          },
        },
        {
          text: yesButtonText,
          role: 'confirm',
          cssClass: 'yes-no-alert-button-yes',
          handler: () => {
            yesHandler();
          },
        },
      ]
    });
    await alert.present();
  }
}
