import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FileProvider } from 'services/file/file.provider';
import { DataFields, DataGroup, MetaField, MetaFile, MetaId, MetaString, MetaValue } from 'metagroup';
import { ModalService } from 'services/modal/modal.service';
import { LeistungViewMoreModalPage } from 'pages/leistung-view-more-modal/leistung-view-more-modal';
import { ImageSelectViewMoreModalPage } from 'pages/image-select-view-more-modal/image-select-view-more-modal';

@Component({
  selector: 'meta-field',
  templateUrl: 'meta-field.html',
  styleUrls: ['meta-field.scss']
})
export class MetaFieldComponent implements OnChanges {
  @Input() field: MetaField;
  @Input() dataGroup: DataGroup;
  @Input() dataGroupList: DataGroup[];
  @Input() itemClosed?: boolean;
  @Input() needsToBeOpened?: boolean;
  @Input() displayCheckBoxAsToggle?: boolean;
  @Output() change = new EventEmitter<any>();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isFileLoading: boolean;
  autocompleteLabel: string;
  autocompleteMatchingFieldValues: MetaValue[];
  multiselectOpened: boolean;
  fieldVisible: boolean;

  constructor(
    private modalService: ModalService,
    public element: ElementRef,
    public fileProvider: FileProvider,
  ) {
  }

  ngOnInit() {
    if (this.field && this.field.type === 'fileSelect' && this.field.values) {
      (<MetaValue[]>this.field.values).forEach(e => {
        e.native = this.isValueIdInValuesList(e.id);
      });
    }
  }

  fieldValue(fieldValues: MetaValue[], valueId: MetaId): string {
    if (!fieldValues) return;
    let fV = fieldValues.find(fV => {
      return fV.id == valueId;
    });
    if (fV) return fV.label;
  }

  onFileChange(event) {
    if (this.isFileLoading) return;
    this.isFileLoading = true;
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.dataGroup.fieldData[this.field.id] = {
          filename: file.name,
          filetype: file.type,
          value: (<String>reader.result).split(',')[1]
        };
        this.isFileLoading = false;
      };
      reader.onerror = () => {
        this.dataGroup.fieldData[this.field.id] = null;
        this.isFileLoading = false;
      }
    } else {
      this.dataGroup.fieldData[this.field.id] = null;
      this.isFileLoading = false;
    }
  }

  clickFileChange(event, file: MetaFile) {
    if (!file || !file.url) {
      this.fileInput.nativeElement.click(event);
    } else {
      this.download(file);
    }
  }

  imageUrl(): string {
    let file = (<MetaFile>(this.dataGroup.fieldData[this.field.id]));
    if (!file) return;
    if (file.url) {
      return file.url;
    } else if (file.value) {
      return 'data:' + file.filetype + ';base64,' + file.value;
    }
  }
  imageUrlKind(): string {
    let file = (<MetaFile>(this.dataGroup.fieldData[this.field.id]));
    if (!file) return 'none';
    return file.url ? 'url' : (file.value ? 'value' : 'none');
  }

  imageSelectFor(filename: string): MetaFile {
    if (this.field && this.field.values) {
      for (let i = 0, il = this.field.values.length; i < il; i++) {
        if ((<MetaFile>(this.field.values[i])).filename === filename) {
          return <MetaFile>(this.field.values[i]);
        }
      }
    }
  }
  imageSelectUrlFor(file: MetaFile | null, fileFilename: string): string {
    if (!file) {
      file = this.imageSelectFor(fileFilename);
    }
    if (file.url) {
      return file.url[0] === '.' ? file.url : ('/api' + file.url);
    } else if (file.value) {
      return 'data:' + file.filetype + ';base64,' + file.value;
    }
  }
  imageSelectUrlKindFor(file: MetaFile | null, fileFilename: string): string {
    if (!file) {
      file = this.imageSelectFor(fileFilename);
    }
    if (!file) return 'none';
    return file.url ? 'url' : (file.value ? 'value' : 'none');
  }
  imageSelectHasValuesNotPaid(): boolean {
    if (this.field && this.field.values && this.field.values.length > 0) {
      for (let i = 0, il = this.field.values.length; i < il; i++) {
        let file = <MetaFile>(this.field.values[i]);
        if (file.needstobepaid && !file.hasbeenpaid) {
          return true;
        }
      }
    }
    return false;
  }
  imageSelectViewMore() {
    let values: MetaFile[] = [];
    (<MetaFile[]>this.field.values).forEach(file => {
      if (file.needstobepaid && !file.hasbeenpaid) {
        values.push(file);
      }
    });
    this.modalService.showModal(
      {component: ImageSelectViewMoreModalPage, componentProps: { values: values, fieldid: this.field.id }, cssClass: 'modal-overlay'},
      (value: MetaFile) => {
      if (value) { //TODO: is this correct condition?
        this.dataGroup.fieldData[this.field.id] = value.filename;
        this.changed();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.hasOwnProperty('field') || changes.hasOwnProperty('dataGroup')) && this.field.type === 'textSelect') {
      let val = <MetaString>(this.dataGroup.fieldData[this.field.id]);
      if (val && val.id) {
        this.selectAutocompleteId(val.id);
      } else {
        this.autocompleteLabel = ((val && val.label) ? val.label : '');
        this.autocompleteLabelChanged();
      }
    }

    if (this.element && this.element.nativeElement && changes.hasOwnProperty('field') && this.field.type === 'hidden') {
      this.element.nativeElement.classList.add('hideme');
    } else {
      this.element.nativeElement.classList.remove('hideme');
    }
  }
  autocompleteLabelChanged() {
    let idx = (<MetaValue[]>(this.field.values)).findIndex(fv => {
      return fv.label === this.autocompleteLabel;
    });
    if (idx !== -1) { // there is a direct match
      this.dataGroup.fieldData[this.field.id] = (<MetaString>this.field.values[idx]);
    } else {
      if (this.autocompleteLabel) {
        let val = new MetaString();
        val.id = null;
        val.label = this.autocompleteLabel;
        this.dataGroup.fieldData[this.field.id] = val;
      } else {
        this.dataGroup.fieldData[this.field.id] = null;
      }
    }
    this.autocompleteMatchingFieldValuesUpdate();
  }
  selectAutocompleteId(id: MetaId) {
    let idx = (<MetaValue[]>(this.field.values)).findIndex(fv => {
      return fv.id === id;
    });
    if (idx !== -1) {
      let val: MetaString = (<MetaString>this.field.values[idx]);
      this.dataGroup.fieldData[this.field.id] = val;
      this.autocompleteLabel = val.label;
      this.autocompleteMatchingFieldValuesUpdate();
    }
  }
  autocompleteMatchingFieldValuesUpdate() {
    this.autocompleteMatchingFieldValues = [];
    (<MetaValue[]>(this.field.values)).forEach(fv => {
      if (fv.label.toLowerCase().includes(this.autocompleteLabel.toLowerCase())) {
        this.autocompleteMatchingFieldValues.push(fv);
      }
    });
  }

  concatLabels(): string {
    let ids = <MetaId[]>this.dataGroup.fieldData[this.field.id];
    if (!ids) return;
    let labels = [];
    (<MetaValue[]>(this.field.values)).forEach(value => {
      let idx = ids.findIndex(id => {
        return id === value.id;
      });
      if (idx !== -1) {
        labels.push(value.label);
      }
    });
    return labels.join(', ');
  }
  toggleValueIdInValuesList(tid: MetaId) {
    let ids = <MetaId[]>this.dataGroup.fieldData[this.field.id];
    if (!ids) {
      this.dataGroup.fieldData[this.field.id] = ids = [];
    }
    let idx = ids.findIndex(id => {
      return id === tid;
    });
    if (idx === -1) {
      ids.push(tid);
    } else {
      ids.splice(idx, 1);
    }
  }
  isValueIdInValuesList(tid: MetaId) {
    if (!this.dataGroup || !this.dataGroup.fieldData) return;
    let ids = <MetaId[]>this.dataGroup.fieldData[this.field.id];
    if (!ids) return;
    let idx = ids.findIndex(id => {
      return id === tid;
    });
    return idx !== -1;
  }

  toggleFieldVisibilityIfNeeded() {
    if (this.needsToBeOpened) {
      this.fieldVisible = !this.fieldVisible;
    }
  }
  isFieldVisible() {
    return !this.needsToBeOpened || this.fieldVisible;
  }

  changed() {
    this.change.emit(this.dataGroup.fieldData[this.field.id]);

  }

  starValues(): number[] {
    let ret = [];
    if (this.field && this.field.maxValues) {
      for (let i = 1; i <= this.field.maxValues; i++) {
        ret.push(i);
      }
    }
    return ret;
  }

  buy(dataGroup: any) {
    let leistungen: DataFields[] = [];
    if (dataGroup.fieldData.needstobepaid && !dataGroup.fieldData.hasbeenpaid) {
      if (this.dataGroup.fieldData) {

        this.dataGroupList.filter(a => a.fieldData.needstobepaid && !a.fieldData.hasbeenpaid).forEach(element => {
          leistungen.push(element.fieldData);
        });
      }
      this.modalService.showModal({component: LeistungViewMoreModalPage, componentProps: { leistungen: leistungen, leistung: dataGroup.fieldData }, cssClass: 'modal-overlay'});
    }
  }

  download(metaField: MetaFile) {
    if (!metaField) return;
    this.fileProvider.downloadMetaFile(metaField);
  }
}
