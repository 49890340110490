import { Component, Injector, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { OnboardingContractRemoveModalPage } from 'pages/onboarding-contract-remove/onboarding-contract-remove';
import { AuthService } from 'services/auth/auth.service';
import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { ContractAvailableDocumentService } from 'services/contract-available-document/contract-available-document.service';
import { ContractUploadedDocumentService } from 'services/contract-uploaded-document/contract-uploaded-document.service';
import { FileProvider } from 'services/file/file.provider';
import { ModalService } from 'services/modal/modal.service';
import { OnboardingContentService } from 'services/onboarding-content/onboarding-content.service';
import { PagesService } from 'services/pages/pages.service';
import { RoutingService } from 'services/routing/routing.service';
import { DataGroup, DataPage, MetaFile, MetaId, MetaPage } from 'src/app/metagroup';

@Component({
  selector: 'page-onboarding',
  templateUrl: 'onboarding.html',
  styleUrls: ['onboarding.scss']
})
export class OnboardingPage implements OnInit {
  public readonly pageName: string = "OnboardingPage";

  //@ViewChild(HTMLIFrameElement) downloadIFrame: HTMLIFrameElement;

  public pages: any[];

  contractAvailableDocumentMeta: MetaPage;
  contractAvailableDocuments: DataPage[] = null;
  contractUploadedDocumentMeta: MetaPage;
  contractUploadedDocuments: DataPage[] = null;

  constructor(
    public injector: Injector,
    public contractAvailableDocumentProvider: ContractAvailableDocumentService,
    public contractUploadedDocumentProvider: ContractUploadedDocumentService,
    public onboardingContent: OnboardingContentService,
    public authProvider: AuthService,
    public modalService: ModalService,
    public pagesProvider: PagesService,
    public fileProvider: FileProvider,
    private navController: NavController,
    private configProvider: BranchConfigService,
    private routingService: RoutingService
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit OnboardingPage');
    this.configProvider.isReady.then((config: BranchConfig) => {
      this.pages = this.pagesProvider.getPage('OnboardingPage').sub;
      if (!!this.authProvider.user) {
        this.loadContractAvailableDocumentMeta();
        this.loadContractUploadedDocumentMeta();
      }
    });

    this.routingService.setRootPage();
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter OnboardingPage');
    if (!!this.authProvider.user) {
      this.contractAvailableDocumentProvider.getList().then(data => {
        this.contractAvailableDocuments = data;
      }, error => {
        console.error('cannot load available document list');
      });
      this.contractUploadedDocumentProvider.getList().then(data => {
        this.contractUploadedDocuments = data;
      }, error => {
        console.error('cannot load uploaded document list');
      });
    }
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave OnboardingPage');
  }

  loadContractAvailableDocumentMeta(): void {
    this.contractAvailableDocumentProvider.getMeta().then(data => {
      this.contractAvailableDocumentMeta = data;
    }, error => {
      console.error('cannot load available document meta');
    });
  }

  loadContractUploadedDocumentMeta(): void {
    this.contractUploadedDocumentProvider.getMeta().then(data => {
      this.contractUploadedDocumentMeta = data;
    }, error => {
      console.error('cannot load uploaded document meta');
    });
  }

  edit(id: MetaId) {
    this.navController.navigateForward('onboarding-contract/' + id);
  }

  save(contractUploadedDocument: DataPage) {
    this.contractUploadedDocumentProvider.update(contractUploadedDocument.id, contractUploadedDocument).then(data => {
      //?
    }, error => {
      console.error('cannot save uploaded document');
    });
  }

  remove(contractUploadedDocument: DataPage) {
    this.modalService.showModal(
      { component: OnboardingContractRemoveModalPage, componentProps: { dataPage: contractUploadedDocument } },
      data => this.loadContractUploadedDocumentMeta()
    );
  }

  download(document: DataPage): string {
    if (!document) return;
    let file = (<MetaFile>((<DataGroup>document.file).fieldData.upload));
    if (!file) return;

    this.fileProvider.downloadMetaFile(file);
  }

  release() {
    this.contractUploadedDocumentProvider.release().then(data => {
      console.log('release responded:', data);
      this.contractUploadedDocuments = data;
      //TODO: ?
    }, error => {
      console.error('cannot release uploaded documents: ', error);
    })
  }

  accept(contractUploadedDocument: DataPage) {
    // find list of termin status options:
    (<DataGroup>contractUploadedDocument.file).fieldData.status = 3;
    this.save(contractUploadedDocument);
  }

  decline(contractUploadedDocument: DataPage) {
    (<DataGroup>contractUploadedDocument.file).fieldData.status = 4;
    this.save(contractUploadedDocument);
  }

  //TODO: this is not as runtime-efficient as it ought to be
  getLabel(meta: MetaPage, groupId: string, fieldId: string) {
    if (!meta) return;
    let gidx = meta.groups.findIndex(group => {
      return group.id === groupId;
    });
    if (gidx === -1) return;
    let fidx = meta.groups[gidx].fields.findIndex(field => {
      return field.id === fieldId;
    });
    if (fidx === -1) return;
    return meta.groups[gidx].fields[fidx].label;
  }

  //TODO: this is not as runtime-efficient as it ought to be
  getFlex(meta: MetaPage, groupId: string, fieldId: string) {
    if (!meta) return;
    let gidx = meta.groups.findIndex(group => {
      return group.id === groupId;
    });
    if (gidx === -1) return;
    let fidx = meta.groups[gidx].fields.findIndex(field => {
      return field.id === fieldId;
    });
    if (fidx === -1) return;
    return meta.groups[gidx].fields[fidx].flex;
  }

  isEveryDocumentReleased(): boolean {
    return !!this.contractUploadedDocuments && null == this.contractUploadedDocuments.find(doc => false == (<DataGroup>doc.file).fieldData.released);
  }
}
