import { Injectable } from "@angular/core";
import { HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Mocker {
    /*
        Implementation Requirements:

        The MockProvider is optional.
        1) To use the MockProvider in a TAF2 App, you need to import it in the app.module.ts as with any other provider.
        2) Then, ApiInterceptor, which is used by all TAF2 Apps, will automatically resort to any Mocker you register() with the MockProvider.

        A Mocker is usually an @Injectable which can register itself with the MockProvider in its own constructor.

        IMPORTANT:
        It is *not* supported to use HttpClient in a Mocker, since that would introduce a cyclic dependency.
        In order to supply a JSON file as a response, use the following:
        1) add a typings.d.ts file to the root folder of your App with following contents:
            declare module "*.json" {
              const value: any;
              export default value;
            }
        2) in your Mocker ts file import the json as so:
            import * as anyNameYouWish from 'anyPathYouWish/anyFileYouWish.json';

        Handle Method:
        1) handle method *must* return an Observable if it is handling the given request.
        2) handle method *must* return null if it is not handling the given request.
     */
    handle(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>>;
}

@Injectable()
export class MockService {
    registered: Mocker[] = [];

    constructor() {
    }

    register(mocker: Mocker) {
        this.registered.push(mocker);
    }

    // tries to handle request using registered Mockers, returns Observable if handled
    // if not handled returns null
    handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
        let handled: Observable<any>;
        for(let i=0; i<this.registered.length; i++) {
            handled = this.registered[i].handle(request.url, request.method, request);
            if(handled) return handled;
        }
        return null;
    }
}