/// <reference path="./xmpp__client.d.ts" />

// see https://github.com/xmppjs/xmpp.js/tree/master/packages/client

import {Injectable} from "@angular/core";

import {ConstantVariable} from "constant-variable";
import {Observable, Subject} from "rxjs";
import {XmppLoaderProvider} from "./xmpp-loader";
import {jid, xml, XmppClient, XmppStatus} from "./xmpp__client";

@Injectable({
  providedIn: 'root'
})
export class XmppLowLevelProvider {
  xmpp: XmppClient;

  protected errorSubject: Subject<any>;
  get error(): Observable<any> {
    return this.errorSubject.asObservable();
  }

  protected offlineSubject: Subject<void>;
  get offline(): Observable<void> {
    return this.offlineSubject.asObservable();
  }

  protected onlineSubject: Subject<jid.JID>;
  get online(): Observable<jid.JID> {
    return this.onlineSubject.asObservable();
  }

  protected stanzaSubject: Subject<xml.Element>;
  get stanza(): Observable<xml.Element> {
    return this.stanzaSubject.asObservable();
  }

  protected statusSubject: Subject<XmppStatus>;
  get status(): Observable<XmppStatus> {
    return this.statusSubject.asObservable();
  }

  constructor(
    public xmppLoaderProvider: XmppLoaderProvider,
  ) {
  }

  init(username: string, password: string) {
    this.xmpp = this.xmppLoaderProvider.client({...ConstantVariable.xmppOptions, username, password});

    this.errorSubject = new Subject();
    this.xmpp.on("error", (err) => {
      console.error('Error xmpp', err);
      this.errorSubject.next(err);
    });

    this.offlineSubject = new Subject();
    this.xmpp.on("offline", () => {
      console.error('Offline xmpp');
      this.offlineSubject.next();
    });

    this.onlineSubject = new Subject();
    this.xmpp.on("online", address => {
      console.error('Online xmpp as', address);
      this.onlineSubject.next(address); // TODO: react
    });

    this.stanzaSubject = new Subject();
    this.xmpp.on("stanza", stanza => {
      console.error('Stanza xmpp', stanza);
      this.stanzaSubject.next(stanza); // TODO: react
    });

    this.statusSubject = new Subject();
    this.xmpp.on("status", status => {
      console.error('Status xmpp', status);
      this.statusSubject.next(status);
    });
  }

  start(): Promise<any> {
    return this.xmpp.start().catch(err => {
      console.error('Error xmpp start', err);
      this.errorSubject.next(err); // TODO: or does this duplicate with on('error' ?
    });
  }

  stop(): Promise<any> {
    return this.xmpp.stop().catch(err => {
      console.error('Error xmpp stop', err);
      this.errorSubject.next(err); // TODO: or does this duplicate with on('error' ?
    });
  }

  send(stanza: xml.Element): Promise<any> {
    return this.xmpp.send(stanza).catch(err => {
      console.error('Error xmpp send', err);
      this.errorSubject.next(err); // TODO: or does this duplicate with on('error' ?
    });
  }

  sendMultiple(stanzas: xml.Element[]): Promise<any> {
    return this.xmpp.sendMultiple(stanzas).catch(err => {
      console.error('Error xmpp sendMultiple', err);
      this.errorSubject.next(err); // TODO: or does this duplicate with on('error' ?
    });
  }
}
