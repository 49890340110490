import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnboardingContentService, PlainPage, PlainPages } from 'services/onboarding-content/onboarding-content.service';
import { PagesService } from 'services/pages/pages.service';

@Component({
  selector: 'page-onboarding-content',
  templateUrl: 'onboarding-content.html',
  styleUrls: ['onboarding-content.scss']
})
export class OnboardingContentPage implements OnInit {
  public readonly pageName: string = "OnboardingContentPage";

  public pages: any[];
  public plainpage: PlainPage;

  constructor(
    public injector: Injector,
    public onboardingContent: OnboardingContentService,
    public pagesProvider: PagesService,
    private route: ActivatedRoute
  ) {
    //this.pages = Pages.find(page => { return page.page == 'OnboardingPage'; }).sub;
    this.pages = pagesProvider.getPage('OnboardingPage').sub;
    this.setPlainpage(this.onboardingContent.plainPages);
  }

  ngOnInit() {
    console.log('ngOnInit OnboardingContentPage');
    this.onboardingContent.plainPagesSubject.subscribe(plainpages => {
      this.setPlainpage(plainpages);
    });
  }

  setPlainpage(pages: PlainPages): void {
    if(pages) {
      this.route.paramMap.subscribe(paramMap => {
        let idx = pages.contents.findIndex(plainpage => {
          return String(plainpage.id) == paramMap.get('plainpage');
        });
        if(idx !== -1) {
          this.plainpage = pages.contents[idx];
        }
      });
    }
  }

}
