import { Injectable } from "@angular/core";
import { ApiService } from "services/api/api.service";
import { Chapter } from "./chapter";

@Injectable({
  providedIn: 'root'
})
export class ChapterService extends ApiService<Chapter> {

  constructor() {
    super('onlinecourse/chapter');
  }
}