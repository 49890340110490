// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .large-container {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/bewerbungsservice-jitsi-modal/bewerbungsservice-jitsi-modal.scss"],"names":[],"mappings":"AAGE;EACE,UAAA;AAFJ","sourcesContent":["@use 'globals' as g;\n\n:host {\n  .large-container {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
