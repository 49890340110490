import { Injectable } from '@angular/core';
import { Mocker, MockService } from 'services/mock/mock.service';
import { HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseList } from '../baselist/baselist';

export enum MockFlags {
  NONE = 0,
  META = 1,
  GET = 2,
  LIST = 4,
  ADD = 8,
  UPDATE = 16,
  ALL = 0xFFFF,
}

@Injectable({
  providedIn: 'root'
})
export class MetabaseMocker implements Mocker {

  constructor(
    public mockProvider: MockService,
    public kind: string,
    public jsonMeta: any,
    public mockFlags: MockFlags = MockFlags.ALL,
    public jsonFilterMeta?: any,
  ) {
    this.mockProvider.register(this);
  }

  handle(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let observable: Observable<HttpEvent<any>> = null;

    if (url === '/api/' + this.kind + '/meta' && method === 'GET') {
      if (this.mockFlags & MockFlags.META) {
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: this.jsonMeta
          }));
          resp.complete();
        });
        console.log('Mocker: mocked GET ' + this.kind + '/meta', request);
      }

    } else if (url === '/api/' + this.kind + '/filtermeta' && method === 'GET') {
      if (this.mockFlags & MockFlags.META) {
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: this.jsonFilterMeta
          }));
          resp.complete();
        });
        console.log('Mocker: mocked GET ' + this.kind + '/filtermeta', request);
      }

    } else if (url.startsWith('/api/' + this.kind + '/list') && method === 'GET') {
      if (this.mockFlags & MockFlags.LIST) {
        let arr = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');

        let filter = this.extractfilter(url);

        // paging by filter parameters
        let list = new BaseList<any>();
        list.count = arr.length;
        list.paging = { i: parseInt(filter.i) || 0, s: parseInt(filter.s) || (arr.length - (parseInt(filter.i) || 0)) };
        list.end = list.paging.i + list.paging.s >= list.count;
        let start: number = list.paging.i;
        let size: number = list.paging.s;
        list.list = arr.slice(start, start + size);

        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: list
          }));
          resp.complete();
        });
        console.log('Mocker: mocked GET ' + this.kind + '/list', request, list);
      }

    } else if (url.startsWith('/api/' + this.kind + 'data/'/*id*/) && method === 'GET') {
      if (this.mockFlags & MockFlags.GET) {
        let id = url.substr(url.lastIndexOf('/') + 1, url.length);
        let kinddata = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');
        let idx = kinddata.findIndex(data => {
          return data.id == id;
        });
        let kdata = kinddata[idx];
        console.log(JSON.stringify(kdata));
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: kdata ? 200 : 404,
            body: kdata
          }));
          resp.complete();
        });
        console.log('Mocker: mocked GET ' + url, request);
      }

    } else if (url.startsWith('/api/' + this.kind) && method === 'GET') { // singleton
      if (this.mockFlags & MockFlags.GET) {
        let kinddata = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');
        let kdata = (kinddata && kinddata.length > 0) ? kinddata[0] : kinddata;
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: kdata ? 200 : 404,
            body: kdata
          }));
          resp.complete();
        });
        console.log('Mocker: mocked GET ' + this.kind, request);
      }

    } else if (url.startsWith('/api/' + this.kind + '/add') && method === 'POST') {
      if (this.mockFlags & MockFlags.ADD) {
        let id = url.substr(url.lastIndexOf('/') + 1, url.length);
        let kinddata = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');
        let idx = kinddata.findIndex(data => {
          return data.id == request.body.id;
        });
        let kd = request.body;
        if (idx === -1) {
          kd.id = Date.now(); // just be unique for the mockup ...
          kinddata.unshift(kd);
          window.localStorage.setItem('tcdz_mock_' + this.kind, JSON.stringify(kinddata));
        }
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: idx === -1 ? 200 : 409,
            body: kd
          }));
          resp.complete();
        });
        console.log('Mocker: mocked POST ' + this.kind + '/add', request);
      }

    } else if (url.startsWith('/api/' + this.kind + '/update/') && method === 'POST') {
      if (this.mockFlags & MockFlags.UPDATE) {
        let id = url.substr(url.lastIndexOf('/') + 1, url.length);
        let kinddata = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');
        let idx = kinddata.findIndex(data => {
          return data.id == id;
        });
        if (idx !== -1) {
          kinddata[idx] = request.body;
          window.localStorage.setItem('tcdz_mock_' + this.kind, JSON.stringify(kinddata));
        }
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: idx !== -1 ? 200 : 404,
            body: request.body
          }));
          resp.complete();
        });
        console.log('Mocker: mocked POST ' + this.kind + '/update/' + id, request);
      }

    } else if (url.startsWith('/api/' + this.kind) && method === 'PUT') { //singleton
      if (this.mockFlags & MockFlags.UPDATE) {
        let kinddata = JSON.parse(window.localStorage.getItem('tcdz_mock_' + this.kind) || '[]');
        if (!kinddata) kinddata = [];
        kinddata[0] = request.body;
        window.localStorage.setItem('tcdz_mock_' + this.kind, JSON.stringify(kinddata));
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: kinddata.length > 0 ? 200 : 404,
            body: request.body
          }));
          resp.complete();
        });
        console.log('Mocker: mocked PUT ' + this.kind, request);
      }
    }

    return observable;
  }

  extractfilter(url: string): any {
    let filter: any = {};
    let ques = url.indexOf('?');
    if (ques !== -1) {
      let parmstr = url.substring(ques + 1, url.length);
      let parmps = parmstr.split('&');
      parmps.forEach(parmp => {
        let tmp = parmp.split('=');
        filter[decodeURIComponent(tmp[0])] = decodeURIComponent(tmp[1]);
      });
    }
    return filter;
  }
}
