import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DashboardProfilePageRoutingModule } from './dashboard-profile-routing.module';

import { DashboardProfilePage } from './dashboard-profile.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    IonicModule,
    DashboardProfilePageRoutingModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [DashboardProfilePage]
})
export class DashboardProfilePageModule {}
