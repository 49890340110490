// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.replyCountStyle {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  text-transform: lowercase;
  padding: 0 10px;
  cursor: pointer;
  color: #39f;
}
.replyCountStyle:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/CometChat-threaded-message-reply-count/cometchat-threaded-message-reply-count/cometchat-threaded-message-reply-count.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,WAAW;AACb;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".replyCountStyle {\n  display: inline-block;\n  font-size: 11px;\n  font-weight: 500;\n  line-height: 12px;\n  text-transform: lowercase;\n  padding: 0 10px;\n  cursor: pointer;\n  color: #39f;\n}\n.replyCountStyle:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
