import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { UserdataService } from 'services/userdata/userdata.service';
import { DataGroup, DataPage, MetaFile, MetaGroup, MetaId, MetaPage, MetaString, MetaValue } from 'src/app/metagroup';
import { BaseList } from 'services/baselist/baselist';
import { CoachService } from 'services/coach/coach.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'page-bewerbungsservice',
  templateUrl: 'bewerbungsservice.html',
  styleUrls: ['bewerbungsservice.scss']
})
export class BewerbungsservicePage implements OnInit {
  public readonly pageName: string = "BewerbungsservicePage";

  coachesMeta: MetaPage;
  coachesFilterMeta: MetaGroup;
  coachesFilter: DataGroup;
  coachesFilterClone: DataGroup;
  coaches: BaseList<DataPage>;
  anredeById: Map<MetaId, MetaValue>;
  searchrunning: boolean = false;
  filterchanged: boolean = false;

  constructor(
    public injector: Injector,
    public coachesProvider: CoachService,
    public userdataProvider: UserdataService,
    private route: ActivatedRoute,
    private navController: NavController
  ) {
    this.coachesFilter = new DataGroup();
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungsservicePage');

    this.coachesProvider.getMeta().then(data => {
      this.coachesMeta = data;

      // find list of anrede options
      let idxField, idxGroup;
      idxGroup = this.coachesMeta.groups.findIndex(group => {
        idxField = group.fields.findIndex(field => {
          return field.id === 'anrede';
        });
        return idxField !== -1;
      });
      this.anredeById = new Map<MetaId, MetaValue>();
      if (idxGroup !== -1) {
        let anreden = (<MetaValue[]>(this.coachesMeta.groups[idxGroup].fields[idxField].values));
        anreden.forEach(anrede => {
          this.anredeById.set(anrede.id, anrede);
        });
      }
    });
    this.coachesProvider.getFilterMeta().then(data => {
      console.log('getFilterMeta', data);
      this.coachesFilterMeta = data;
    });
  }

  ionViewWillEnter() {
    this.route.paramMap.subscribe( paramMap => {
      const redirectId = paramMap.get('redirectId');
      if (redirectId !== undefined) {
        //this.view(redirectId);
      }
      console.log('ionViewWillEnter BewerbungsservicePage');
      //this.search();
    })
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungsservicePage');
  }

  view(id: MetaId) {
    // Master- und Detail-Seite sind hier vertauscht, weil Benutzer erstmal seinen eigenen Coach sehen soll
    this.navController.navigateForward('/bewerbungsservice/' + id);
  }

  changed() {
    this.filterchanged = true;
  }

  search(more: boolean = false, event?: any) {
    if (event) {
      event.preventDefault();
    }
    if (this.searchrunning) return;
    this.searchrunning = true;
    let start = 0, size = 6;
    if (more) {
      start = this.coaches.list.length;
    } else {
      this.coachesFilterClone = cloneDeep(this.coachesFilter);
    }
    //console.log('this.coachesFilter',this.coachesFilter);
    this.coachesProvider.getList({
      ort: this.coachesFilterClone.fieldData.ort && (<MetaString>this.coachesFilterClone.fieldData.ort).label,
      i: start,
      s: size,
    }).then(data => {
      //console.log('DATA',data);
      if (more) {
        if (data.end) {
          this.coaches.list.splice(data.count);
        }
        for (let i = start; i < start + data.list.length; i++) {
          this.coaches.list[i] = data.list[i - start];
        }
        this.coaches.end = data.end;
        this.coaches.count = data.count;
        this.coaches.paging.s += data.paging.s;
      } else {
        this.coaches = data; //.list;
      }
      this.searchrunning = false;
      this.filterchanged = false;
    }, error => {
      this.searchrunning = false;
      this.filterchanged = false;
    });
  }

  fotoImageUrl(coach: DataPage): string {
    if (coach.person && (<DataGroup>coach.person).fieldData && (<DataGroup>coach.person).fieldData.foto) {
      let file: MetaFile = <MetaFile>((<DataGroup>coach.person).fieldData.foto);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

}
