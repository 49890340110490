import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { ToastService } from 'services/toast/toast.service';
import { ConstantVariable } from 'src/app/constant-variable';
import { AuthService } from 'services/auth/auth.service';
import { PasswordResetCredentials } from 'services/auth/auth-credentials';
import { RoutingService } from 'services/routing/routing.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'page-forgot',
  templateUrl: 'forgot.html',
  styleUrls: ['forgot.scss']
})
export class ForgotPage implements OnInit {
  public readonly pageName: string = "ForgotPage";

  showAllFeatures: boolean;
  public passwordResetData: PasswordResetCredentials = { /*email: null,*/ username: null, redirecturl: '/password-change/userkey/pwchangekey' };

  constructor(
    public auth: AuthService,
    public injector: Injector,
    public branch: BranchConfigService,
    public toastService: ToastService,
    private navController: NavController,
    private routingService: RoutingService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  ngOnInit() {
    console.log('ngOnInit ForgotPage');
  }

  submit() {
    this.auth.passwordReset(this.passwordResetData).then(res => {
      console.log('APPCOM: login Success, res=', res);
      this.toastService.handleError('password_reset_success');
    }, err => {
      console.log('APPCOM: login Failure, err=', err);
      this.toastService.handleError(err);
    });
  }
}
