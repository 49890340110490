import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Checklist, ChecklistService } from 'services/checklist/checklist.service';


@Component({
  selector: 'page-checklist',
  templateUrl: 'checklist.html',
  styleUrls: ['checklist.scss']
})
export class ChecklistPage implements OnInit {
  public readonly pageName: string = "ChecklistPage";

  checklist: Checklist;

  constructor(
    public injector: Injector,
    public checklistsProvider: ChecklistService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit ChecklistPage');
    this.route.paramMap.subscribe( paramMap => {
      let id = paramMap.get('id');
      this.checklistsProvider.get(+id).then((checklist: Checklist) => {
        this.checklist = checklist;
        console.log('Tipp loaded: ',this.checklist);
      }, error => {
        console.error('failed to load checklist',error);
      });
    });
  }

}
