/*
  Putting into type what is observed from the samples given, especially:

  Meta format samples found:
  http://gitlab/js/car/blob/master/app/test/data/applicationMasterData_1.json - and similar files
  http://gitlab/js/ags/blob/master/app/test/data/jobEditDetailMasterData-1.json - and similar files

  Data format samples found:
  http://gitlab/js/ags/blob/master/app/test/data/jobEditDetail1015.json
 */

//NOTE: mostly a number is used as an id e.g. 1,
//      but from the samples there are also strings used,
//      like e.g. "privacy", or even numbers in form of a string, e.g. "1"
export type MetaId = number|string;

// this is the set of types that combined give the possible types a data property of DataFields can have as a value
export type DataValue = string|number|boolean|Date|MetaId|MetaId[]|MetaDateSelect|MetaFile|MetaString;

// this is the object type for fields of type upload
export class MetaFile {
  id?: number; // file id
  filename: string;
  filetype: string;
  value?: string; // base64 value if MetaFile is to be uploaded to the backend
  url?: string; // relative url if MetaFile got given by the backend
  price?: number; // price to pay (currently only used for values in "imageSelect")
  currency?: string; // currency code (currently only used for values in "imageSelect")
  productcode?: string; // product code for shop system (currently only used for values in "imageSelect")
  needstobepaid?: boolean; // does this file need to be paid? (currently only used for values in "imageSelect")
  hasbeenpaid?: boolean; // has this file been paid for? (currently only used for values in "imageSelect")
}

// this is the object type for fields of type textSelect
export class MetaString {
  id?: MetaId; // id !== null if a "value" member has been chosen by the user (if the user just entered freetext, the id is empty)
  label: string; // either the label from the MetaValue chosen, or a freetext entered by the user
}

// ?
export class MetaRelation {
  id: MetaId; // only number observed, we'll also accept string
  invert: boolean; // ?
  values: number[]; // ?
}

// ?
export class MetaRelations {
  VISIBLE: MetaRelation; // ?
  OBLIGATORY: MetaRelation; // ?
  // are there more properties, i.e. is there a semantic meaning in unusual all-caps naming of these properties?
}

// this describes a form field value to choose from
export class MetaValue {
  id: MetaId; // value id, number and string observed, e.g. "1" or 1.
  label: string; // value label
  native?:any; // helper for native value in some inputs
}

// this describes the value of a field of type "dateSelect"
export class MetaDateSelect {
  id: MetaId; // value id, the user did choose
  date: string; // of format: 'YYYY-mm-dd', the user did enter - probably?
}

// this describes a form field
export class MetaField {
  id: MetaId; // field id
  vkey?: null; // whatever this is, the only samples use null, so fixing this as null type, optional.
  label: string; // field label

  // type of field, observed values are ["text","singleSelect","multiSelect","date","upload","checkbox","textarea","dateSelect"]
  //NOTE: since different types of uploads will surely be displayed completeley different (a PDF upload of a document different from an portrait image, etc.),
  //      we need some more fine-grained control; however for now I'll just introduce another type: "image" representing an image upload with a display of the image
  //NOTE: i'll introduce new types:
  //      "image": an image (user image in bewerbungsmappe)
  //      "imageReadonly": an image (user image in bewerbungsmappe) - non editable / readonly comparable to "text" and "label"
  //      "imageSelect": like singleSelect but user selects a MetaFile (from MetaFiles in values Array), filename property is used as label for the options and selected value for the control, and images are displayed to the user in a view
  //      "time": a time value
  //      "label": like text, but not editable
  //      "labelkey": like label, but will be used as translation key
  //      "link": an url, not editable (editable urls have type text)
  //      "number": like text, but a floating point value
  //      "textSelect": a text field, but that uses the values:MetaValue[] property of the "singleSelect" type to offer autocompletion results; resulting DataValue is a MetaString
  //      "buttonSelect": same as singleSelect, but displayed as a list of buttons
  //      "rating": an integer value displayed as a star rating control (1 to maxValues stars)
  //      "fileSelect": multiSelect with file download
  //      "hidden": something of any type that is of internal purpose only, e.g. id's, internal boolean flags, ...
  type: string;

  required?: string|boolean; // observed values are ["required","",true], we'll also accept [false,null] or omission.
  values?: MetaValue[]|MetaFile[]; // values to choose from, value-less types have either of [[],null], but we'll also accept omission.
  relations?: MetaRelations; //?
  maxValues?: number; // used for type=upload, probably maximum number of uploads (note: is it also used for type=multiSelect?), used for type=rating (number of stars maximum)

  placeholder?: string; //NOTE: this property is new, not observed in the sample data, but need sth. for some places

  // CSS flex applied to the field wrapper, default is "1 0 250px"
  flex?: string;
}

// this describes a group of form fields
export class MetaGroup {
  id: MetaId; // not found in the samples, but we'd like to have a generic identifier for the dataset
  //NOTE: it appears as if the next three properties will be used interchangeably (the groups observed either have a "title" or a "name" or a "label"), value of group title properties is evaluated with precedence title || name || label
  // group title
  title?: string;
  name?: string;
  label?: string;
  required?: string|boolean; //this is new here; meaning: at least "one thing" has to be specified in this group to be valid
  // the following describes the fields in the group
  fields: MetaField[];
  listtype?: string; // E(null,'array','set') null=>fields defines fields of the group itself; array/set=>fields defines fields of list elements of this group (list is either open 'array' or closed 'set' list of values)
  setFieldId?: MetaId; // if listtype==='set' => this id MUST identify a field in the group of TYPE "singleSelect" which identifies the field which value is unique for the set (its value can only be chosen when adding the listitem and is of fixed value in editing mode, thus to preserve uniqueness in the set)
}

// this describes a page, i.e. a group of groups of form fields
// i.e. one of the elements of the whole bewerbermappemeta json array delivered by the backend
//NOTE: this is an object not seen in the samples, but needed for grouping the groups on pages
export class MetaPage {
  id: MetaId; // not found in the samples, but we'd like to have a generic identifier for the dataset
  title: string; // in contrast to the MetaGroup let's assume this property is called title and not name nor label
  required?: string|boolean; //this is new here; meaning: at least "one thing" has to be specified on this page to be valid
  groupDeactivatedItemsPagewide?: boolean; // only meaningful for metapages that contain metagroups of listtype 'array' or 'set': if truthy, active/inactive datagroups are *not* separated within their metagroup, but over all groups of the metapage (and within that separation by their metagroup)
  groups: MetaGroup[]; // a MetaGroup object for each sub-page under Bewerbungsmappe
}

// this describes the dataset of values input/selected/gathered for a MetaGroup
export class DataFields {
  //
  // Property Identifier (propName):
  // the property identifier equals the value of the id property of a MetaField converted to string representation.
  //
  // Property Value:
  // in case of a simple field, this contains just the text value
  // in case of a singleSelect this contains the MetaId of the selected value from values or null
  // in case of a multiSelect this contains an array of selected MetaId from values or []
  // in case of a checkbox this contains boolean
  // in case of a date this contains Date
  // in case of a dateSelect it contains a MetaDateSelect
  // since maybe later we might get numeric input types, I'll also include number
  // new: in case of an image this contains the image url (eiter a base64 url, or an url to an image on a server, whatever)
  //
  [propName: string]: DataValue;
}

// this describes the dataset of values input/selected/gathered for an identified MetaGroup
export class DataGroup {
  id: MetaId; // not found in the samples, but we'd like to have a generic identifier for the dataset
  fieldData: DataFields;
  isDeactivated?: boolean; // property only available if listtype of corresponding is 'set' or 'array': if truthy this group in the list is deactivated (i.e. defaults to active)
  constructor(id?: MetaId) {
    this.id = id;
    this.fieldData = new DataFields();
  }
}

// this describes a set of DataGroup's on a MetaPage
export class DataPage {
  id: MetaId; // not found in the samples, there only found e.g. "jobId" but we'd like to have a generic identifier for the dataset
  // propName equals the title||name||label property of the MetaGroup
  [propName: string]: DataGroup|DataGroup[]|MetaId|boolean|Array<any>; //IMPORTANT: read following note:
  // I only added "|MetaId" here due to a nasty restriction typescript language.
  // From the point of application logic the type of the indexed type shall be considered as "DataGroup|DataGroup[]" *only*.
  // Failure to comply results in application crash.
}
