import { Injectable } from '@angular/core';
import { mockUrlMapping } from 'constant-variable';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  /**
   * Return true, if the current environment is "prod".
   */
  public static isProd(): boolean {
    return environment && environment.production;
  }

  /**
   * Return true, if the current environment is "dev".
   */
  public static isMock(): boolean {
    return environment && environment.mock;
  }

  /**
   * Return true, if any mock url is set up for the cur rent environment.
   */
  public static isAnyMockUrlPresent(): boolean {
    return mockUrlMapping.size > 0;
  }

  /**
   * Return the mock url for given original url.
   */
  public static getMockUrl(original: string): string {
    // Strip URL parameters
    const strippedUrl = original.split('?')[0];

    // First pass: Check for exact matches
    for (let [key, value] of mockUrlMapping.entries()) {
      if (key === strippedUrl) {
        return value;
      }
    }

    // Second pass: Check for wildcard matches
    for (let [key, value] of mockUrlMapping.entries()) {
      // Replace wildcard '*' with a regex pattern to match any path segment
      const regex = new RegExp('^' + key.replace(/\*/g, '[^/]+') + '$');
      if (regex.test(strippedUrl)) {
        return value;
      }
    }

    // Return the original URL if no match is found
    return original;
  }
}
