import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { LoginCredentials, PasswordResetChangeCredentials } from 'services/auth/auth-credentials';
import { AuthService } from 'services/auth/auth.service';
import { BranchConfigService } from "services/branch-config/branch-config";
import { RoutingService } from 'services/routing/routing.service';
import { ToastService } from 'services/toast/toast.service';
import { ConstantVariable } from 'src/app/constant-variable';

@Component({
  selector: 'page-password-change',
  templateUrl: 'password-change.html',
  styleUrls: ['password-change.scss']
})
export class PasswordChangePage implements OnInit {
  public readonly pageName: string = "PasswordChangePage";

  public passwordResetChangeData: PasswordResetChangeCredentials = { password: null, password2: null };
  public loginData: LoginCredentials = { username: null, password: null };

  constructor(
    public auth: AuthService,
    public injector: Injector,
    public branch: BranchConfigService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private navController: NavController,
    private routingService: RoutingService
  ) {
    console.log('constructor PasswordChangePage');
  }

  ngOnInit() {
    console.log('ngOnInit PasswordChangePage');
  }

  submit() {
    this.route.paramMap.subscribe( paramMap => {
      this.passwordResetChangeData.userkey = paramMap.get('userkey');
      this.passwordResetChangeData.pwchangekey = paramMap.get('pwchangekey');
      this.auth.passwordResetChange(this.passwordResetChangeData).then(res => {
        console.log('APPCOM: login Success, res=', res);
  
        this.toastService.handleError('password_reset_2_success');
  
        this.loginData.username = res.username;
        this.loginData.password = this.passwordResetChangeData.password;
        this.auth.login({ ...ConstantVariable.authParameters, ...this.loginData }).then(res => {
  
          //...
  
        }, err => {
          console.log('APPCOM: login Failure, err=', err);
          this.toastService.handleError(err);
        });
  
      }, err => {
        console.log('APPCOM: passwordResetChange Failure, err=', err);
        this.toastService.handleError(err);
      });
    });
  }

  openPage(page) {
    this.navController.navigateRoot(this.routingService.getPath(page));
  }

}
