// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h1 {
  text-align: center;
  border-color: transparent;
  margin-bottom: 10px;
  margin-left: 10%;
  margin-right: 10%;
}
:host .desc {
  margin-bottom: 20px;
}
:host .info {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/register/register.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ","sourcesContent":[":host {\n  h1 {\n    text-align: center;\n    border-color: transparent;\n    margin-bottom: 10px;\n    margin-left: 10%;\n    margin-right: 10%;\n  }\n\n  .desc {\n    margin-bottom: 20px;\n  }\n\n  .info {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
