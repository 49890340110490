import { Injectable } from '@angular/core';

import { DataPage } from 'metagroup';
import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserCoachService extends ApiService {

  constructor() {
    super('user/coach');
  }

  getByTippGoupId(tippgroupId: string): Promise<DataPage> {
    return this.httpGet('group', tippgroupId);
  }

  setCoach(id: number) {
    return this.httpPost('set', id);
  }

}
