import { Injectable } from '@angular/core';

import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountdataService extends ApiService {

  constructor() {
    super('user/accountdata');
  }
}
