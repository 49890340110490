import { Privacy } from './../../services/privacy/privacyUrls';
import { Component, Input, OnInit } from '@angular/core';
import { PrivacyService } from 'services/privacy/privacy.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {

  @Input() showPrivacy: boolean = true;
  @Input() showImprint: boolean = true;

  private urls: Privacy;

  constructor(
    private privacyProvider: PrivacyService
  ) { }

  ngOnInit() {
    this.privacyProvider.getUrls()
      .then(data => {
        this.urls = data;
      })
      .catch(error => {
        console.error('ERROR loading data: ', error);
      });
  }

  public showAny(): boolean {
    return this.showPrivacy || this.showImprint;
  }
}
