import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterPage } from './register';
import { ComponentsModule } from 'components/components.module';
import { DirectivesModule } from 'directives/directives.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    RegisterPage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    RouterModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class RegisterPageModule {}
