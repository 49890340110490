import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: "[iframeLoad]",

})
export class IframeLoadDirective implements OnInit {
    @Input('iframeLoad') loader = () => { };

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.onload = this.loader;
    }
}
