
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';



import { MessageService } from 'services/message/message.service';

/**
 * Purpose: enable AuthInterceptor to intercept image requests that should get the X-Auth-Token ...
 *
 * usage e.g.:
 * <img [src]="(image.url | image) | async" />
 * <div [style.background-image]="'url('+( (image.url | image) | async )+')'"></div>
 */

export class ImageCache {
    [propName: string]: string | boolean;
}
@Injectable()
export class ImageCacheProvider {
    cache: ImageCache = {};
    constructor() {
    }
}

/**
 * Hinweis: deprecation wurde vorerst aufgehoben
 */
@Injectable()
@Pipe({
    name: 'image',
})
export class ImagePipe implements PipeTransform {

    constructor(
        private http: HttpClient,
        private message: MessageService,
        @Optional() private cache: ImageCacheProvider,
    ) { }

    transform(url: string, cache: boolean = false) { // use cache parameter with caution, note, for this to work, ImageCacheProvider MUST be injected to the app.component!!!
        let thisA = this;
        //if(!url) return '';
        if (cache && this.cache && this.cache.cache.hasOwnProperty(url)) {
            return Observable.create(observer => {
                observer.next(this.cache.cache[url]);
            });
        } else {
            return this.http.get(url, {
                headers: new HttpHeaders({ 'Accept': 'image/*, */*' }),
                responseType: 'blob'
            }).pipe(switchMap((blob: Blob) => {
                // return new observable which emits a base64 string when blob is converted to base64
                return Observable.create(observer => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); // convert blob to base64
                    reader.onloadend = function () {
                        observer.next(reader.result); // emit the base64 string result (to the actual displaying DOM or async pipe)
                        if (cache && thisA.cache) {
                            thisA.cache.cache[url] = "" + reader.result;
                        }
                        thisA.message.subject.next({ // also tell potential listeners in the application that it is now loaded (ready to be shown by the user agent)
                            imagePipe: {
                                url: url,
                                success: true,
                                data: reader.result
                            }
                        });
                    };
                    reader.onerror = function (error) {
                        console.error('FileReader failed to read blob: onerror raised', error);
                        observer.error(error);
                    };
                    reader.onabort = function (error) {
                        console.error('FileReader failed to read blob: onabort raised', error);
                        observer.error(error);
                    };
                });
            }), catchError((error, caught) => {
                thisA.message.subject.next({ // also tell potential listeners in the application that it failed to load
                    imagePipe: {
                        url: url,
                        success: false,
                        error: error,
                        caught: caught
                    }
                });
                return Promise.resolve(url); // just return the original url (will not work, but breaks the deadloop)
            }));
        }
    }
}