import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tipp } from "services/bewerbungstipps/bewerbungstipps";
import { GoodType, PaidGoodsService } from "services/paid-goods/paid-goods.service";

@Component({
  selector: 'page-bewerbungstipp-view-more-modal',
  templateUrl: 'bewerbungstipp-view-more-modal.html',
  styleUrls: ['bewerbungstipp-view-more-modal.scss']
})
export class BewerbungstippViewMoreModalPage implements OnInit {
  public readonly pageName: string = "BewerbungstippViewMoreModalPage";

  @Input() bewerbungstipps: Tipp[];
  @Input() bewerbungstipp: Tipp;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
    ) {
    }
    
    ngOnInit() {
      console.log('ngOnInit BewerbungstippViewMoreModalPage');
      this.viewIndex = this.bewerbungstipps.findIndex(tipp => tipp.id === this.bewerbungstipp.id);
      if(this.viewIndex === -1) {
        console.error('BewerbungstippViewMoreModalPage: bewerbungstipp not in bewerbungstipps.');
        this.viewIndex = 0;
      }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if(this.viewIndex < 0) {
      this.viewIndex = this.bewerbungstipps.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if(this.viewIndex >= this.bewerbungstipps.length) {
      this.viewIndex = 0;
    }
  }

  buynow() {
    return this.paidGoodsProvider.buildPaymentUrl(GoodType.BEWERBUNGSTIPP, Number(this.bewerbungstipps[this.viewIndex].id));
  }

  cancel() {
    this.dismiss();
  }

  dismiss(bewerbungstipp?: Tipp) {
    this.modalController.dismiss(bewerbungstipp);
  }

}
