// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableRowStyle {
  border: 1px solid #eaeaea;
  display: table;
  width: 100%;
  table-layout: fixed;
  font-size: 14px;
}
.tableRowStyle > td {
  padding: 0.625em;
}
.tableRowStyle > img {
  width: 36px;
  height: 36px;
  float: left;
}

.avatarStyle {
  float: left;
  height: 35px;
  width: 35px;
}
.avatarStyle > span {
  top: 26px;
  left: -8px;
}

.nameStyle {
  margin: 10px 0 0 0;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
}

.roleStyle {
  width: 150px;
  font-size: 12px;
}

.actionStyle {
  width: 70px;
  cursor: pointer;
}
.actionStyle > img {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 767px) {
  .roleStyle {
    width: 115px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Groups/CometChat-ban-group-member-list/cometchat-ban-group-member-list/cometchat-ban-group-member-list.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;AACb;AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".tableRowStyle {\n  border: 1px solid #eaeaea;\n  display: table;\n  width: 100%;\n  table-layout: fixed;\n  font-size: 14px;\n}\n.tableRowStyle > td {\n  padding: 0.625em;\n}\n.tableRowStyle > img {\n  width: 36px;\n  height: 36px;\n  float: left;\n}\n\n.avatarStyle {\n  float: left;\n  height: 35px;\n  width: 35px;\n}\n.avatarStyle > span {\n  top: 26px;\n  left: -8px;\n}\n\n.nameStyle {\n  margin: 10px 0 0 0;\n  width: calc(100% - 50px);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-left: 4px;\n}\n\n.roleStyle {\n  width: 150px;\n  font-size: 12px;\n}\n\n.actionStyle {\n  width: 70px;\n  cursor: pointer;\n}\n.actionStyle > img {\n  width: 20px !important;\n  height: 20px !important;\n  cursor: pointer;\n}\n@media (min-width: 320px) and (max-width: 767px) {\n  .roleStyle {\n    width: 115px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
