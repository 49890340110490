import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from 'components/components.module';
import { DirectivesModule } from 'directives/directives.module';
import { BewerbungsmappePage } from './bewerbungsmappe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BewerbungsmappePage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class BewerbungsmappePageModule {}
