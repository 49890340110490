// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .meta-validity {
  background-color: var(--cdz-menu-color);
  border: 1px solid var(--cdz-litecolor);
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
:host .meta-validity .meta-invalid span::after {
  content: "-";
  font-weight: normal;
  padding-left: 0.2em;
}
:host .meta-validity .meta-invalid span:last-child::after {
  display: none;
}
:host .meta-validity .meta-invalid span:nth-last-child(2) {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/meta-validity/meta-validity.scss"],"names":[],"mappings":"AACE;EACE,uCAAA;EACA,sCAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGQ;EACE,YAAA;EACA,mBAAA;EACA,mBAAA;AADV;AAIU;EACE,aAAA;AAFZ;AAKQ;EACE,iBAAA;AAHV","sourcesContent":[":host {\n  .meta-validity {\n    background-color: var(--cdz-menu-color);\n    border: 1px solid var(--cdz-litecolor);\n    padding: 10px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    .meta-invalid {\n      span {\n        &::after {\n          content: '-';\n          font-weight: normal;\n          padding-left: .2em;\n        }\n        &:last-child {\n          &::after {\n            display: none;\n          }\n        }\n        &:nth-last-child(2) {\n          font-weight: bold;\n        }\n      }\n      .meta-invalid-page {\n\n      }\n      .meta-invalid-group {\n\n      }\n      .meta-invalid-field {\n      }\n      .meta-invalid-validator {\n\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
