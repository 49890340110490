// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .videos {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-bottom: -30px;
}
:host .videos cdzvideo {
  flex: 1 1;
  flex-basis: calc(33% - 30px);
  min-width: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
}
:host .right-links {
  padding-top: 20px;
  margin-bottom: -30px;
  text-align: right;
}
:host .right-links a {
  color: var(--cdz-primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/video-list/video-list.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,oBAAA;AAAR;AACQ;EACE,SAAA;EACA,4BAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACV;AAGM;EACE,iBAAA;EACA,oBAAA;EACA,iBAAA;AADR;AAEQ;EACE,0CAAA;AAAV","sourcesContent":[":host {\n    .videos {\n        display: flex;\n        flex-wrap: wrap;\n        margin-right: -30px;\n        margin-bottom: -30px;\n        cdzvideo {\n          flex: 1 1;\n          flex-basis: calc(33% - 30px);\n          min-width: 300px;\n          margin-right: 30px;\n          margin-bottom: 30px;\n        }\n      }\n    \n      .right-links {\n        padding-top: 20px;\n        margin-bottom: -30px;\n        text-align: right;\n        a {\n          color: var(--cdz-primary-color) !important;\n        }\n      }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
