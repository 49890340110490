import { Injectable } from '@angular/core';

import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContractAvailableDocumentService extends ApiService {

  constructor() {
    super('onboarding/files/internal');
  }
}
