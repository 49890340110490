import { Injectable } from '@angular/core';
import { DataPage } from 'metagroup';
import { ApiService } from 'services/api/api.service';

let _ = (key) => key;

/***reserve some keys so they dont get removed from i18n files***/
_('forms.candooze.onboarding.state.private');
_('forms.candooze.onboarding.state.default');
//... TODO: add all that may be sent from BMS in place ...
/***end***/

@Injectable({
  providedIn: 'root'
})
export class ContractUploadedDocumentService extends ApiService<any> {

  constructor() {
    super('onboarding/files/candidate');
  }

  release(): Promise<DataPage[]> {
    return this.httpPost('release', '');
  }
}
