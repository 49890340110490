import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'services/auth/auth.service';
import { BewerbungsmappeService, BewerbungsmappeStatus } from 'services/bewerbungsmappe/bewerbungsmappe.service';
import { BranchConfigService } from "services/branch-config/branch-config";
import { MessageService } from 'services/message/message.service';
import { MetaValidityService } from 'services/meta-validity/meta-validity.service';
import { PagesService } from 'services/pages/pages.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { ConstantVariable } from 'src/app/constant-variable';
import { DataGroup, DataPage } from 'src/app/metagroup';

@Component({
  selector: 'page-bewerbungsmappe',
  templateUrl: 'bewerbungsmappe.html',
  styleUrls: ['bewerbungsmappe.scss']
})
export class BewerbungsmappePage implements OnInit {
  public readonly pageName: string = "BewerbungsmappePage";

  public pages: any[];
  public metapage: any;
  public groupData: DataPage; // = new DataPage();
  public needsToBeOpened: boolean;
  public fieldsNeedToBeOpened: boolean;
  public cannotAddIfInvalid: boolean;
  public showExpandButton: boolean;
  public status: BewerbungsmappeStatus;
  public subscription: Subscription;

  overflowPage() {
    let overpname = ((this.branch.config && this.branch.config.features && this.branch.config.features.jobboerse) || ConstantVariable.showAllFeatures) ? 'JobboersePage' :
      (((this.branch.config && this.branch.config.features && this.branch.config.features.dashboard) || ConstantVariable.showAllFeatures) ? 'DashboardPage' : null);
    //return Pages.find(page => {return page.page === overpname});
    return this.pagesProvider.getPage(overpname);
  }

  constructor(
    public injector: Injector,
    public bewerbermappe: BewerbungsmappeService,
    public metaValidity: MetaValidityService,
    public message: MessageService,
    public userdataProvider: UserdataService,
    public branch: BranchConfigService,
    public pagesProvider: PagesService,
    public auth: AuthService,
    private route: ActivatedRoute
  ) {
    //this.pages = Pages.find(page => { return page.page == 'BewerbungsmappePage'; }).sub;
    this.pages = pagesProvider.getPage(this.pageName).sub;
  }

  /**
    * Ionic Page Life Cycle
    */
  ionViewWillEnter() {
    // will be called BEFORE ionViewWillLeave !
    this.subscription = this.message.messages().subscribe(msg => {
      if (msg && msg.bewerbermappeStored) { // receive a message from the previous tab
        this.reloadDataAfterSave();
        this.subscription.unsubscribe();
      }
    });
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter BewerbungsmappePage');
    this.bewerbermappe.getMeta().then(pages => {
      this.route.paramMap.subscribe( paramMap => {
        let navpageid = paramMap.get('metapage');
        this.metapage = pages.find(page => { return page.id == navpageid; });
        if (this.metapage) {
          console.log('subpage:', this.metapage);
          this.needsToBeOpened = (this.metapage.id === 'lebenslauf' || this.metapage.id === 'attachments'); // IMPORTANT: the page id "lebenslauf" and "attachments" are thus set in stone.
          this.showExpandButton = (this.metapage.id === 'lebenslauf'); // IMPORTANT: the page id "lebenslauf" is thus set in stone.
          this.cannotAddIfInvalid = (this.metapage.id === 'lebenslauf' || this.metapage.id === 'attachments'); // IMPORTANT: the page id "lebenslauf" and "attachments" are thus set in stone.
          this.fieldsNeedToBeOpened = (this.metapage.id === 'profilfragen'); // IMPORTANT: the page id "profilfragen" is thus set in stone.
          this.loaddata();
        } else {
          console.error('BewerbungsmappePage: a MetaPage.id of "' + navpageid + '" is unknown.');
        }
      })
    });
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungsmappePage');
    //this.userdataProvider.reset('progressneeded').catch(error => console.error('failed to reset counter for progressneeded',error));
    //this.userdataProvider.reset('progress').catch(error => console.error('failed to reset counter for progress',error));
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungsmappePage');
    console.log(this.groupData);
    if (this.groupData && !!this.auth.user) {
      this.bewerbermappe.postData(this.groupData.id, this.groupData).then(data => {
        this.message.subject.next({ bewerbermappeStored: true }); // send a message to the next "tab"
        this.subscription.unsubscribe(); // no longer needed
      });
    }
  }

  /**
    * other
    */

  reloadDataAfterSave() {
    this.loadStatus();
  }
  loaddata() {
    this.bewerbermappe.getData(this.metapage.id).then(data => {
      this.groupData = data;

      // add missing groups
      this.metapage.groups.forEach(group => {
        if (!this.groupData[group.id]) {
          if (group.listtype === 'array' || group.listtype === 'set') {
            this.groupData[group.id] = [];
          } else {
            this.groupData[group.id] = new DataGroup();
          }
        }
      });
    });
    this.loadStatus();
  }

  loadStatus() {
    this.bewerbermappe.getStatus().then(data => {
      this.status = data;
    });
  }
}
