import { Injectable } from '@angular/core';

import { DataPage, MetaId } from 'metagroup';
import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends ApiService {

  constructor() {
    super('application');
  }

  public approve(id: MetaId): Promise<DataPage> {
    return this.httpPost('approve', id);
  }

  public decline(id: MetaId): Promise<DataPage> {
    return this.httpPost('decline', id);
  }
}
