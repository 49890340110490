import { Directive, forwardRef, Attribute, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

/**
 * numberValidator
 * ValidatorFn for testing against min and max value of a numeric form element
 *
 * Can be used in a Reactive Form Validation Context as follows:
 * ... new FormControl(..., [ ..., Validators.required, ..., numberValidator.max(<maxvalue>), numberValidator.min(<minvalue>) ... ]) ...
 */
export class NumberValidator {
    static max(maxValue: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
            // self value
            let v = c.value;

            if(typeof c.value === 'number') {
                if(c.value > maxValue) {
                    return {max: true};
                }
            } else if(typeof c.value === 'string') {
                if(parseFloat(c.value) > maxValue) {
                    return {max: true};
                }
            } else {
                // indeterminate
                return null;
            }
        };
    }
    static min(minValue: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
            // self value
            let v = c.value;

            if(typeof c.value === 'number') {
                if(c.value < minValue) {
                    return {max: true};
                }
            } else if(typeof c.value === 'string') {
                if(parseFloat(c.value) < minValue) {
                    return {max: true};
                }
            } else {
                // indeterminate
                return null;
            }
        };
    }
    static integer(isInteger: boolean = true): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
            if(isInteger === false) {
                // indeterminate (do not test)
                return null;
            } else if(typeof c.value === 'number') {
                if(!Number.isInteger(c.value)) {
                    return {integer: true};
                }
            } else if(typeof c.value === 'string') {
                let parsed = parseFloat(c.value);
                if(isNaN(parsed)) {
                    // indeterminate
                    return null;
                } else if(!Number.isInteger(parsed)) {
                    return {integer: true};
                }
            } else {
                // indeterminate
                return null;
            }
        };
    }
}

/**
 * maxDirective
 * Validator for testing agains max value of a numeric control
 *
 * Can be used in a Template Driven Form Validation Context as follows:
 * <input ... required ... max="<maxvalue>" ... [(ngModel)]="..." ...>
 *
 */
@Directive({
    selector: '[max][formControlName],[max][formControl],[max][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxDirective), multi: true }
    ]
})
export class MaxDirective implements Validator, OnChanges {
    @Input() max: number;
    //constructor( @Attribute('max') public max: number) {}
    constructor(public el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.hasOwnProperty('max')) {
            if(typeof this.max !== 'undefined' && this.max !== null) {
                this.el.nativeElement.setAttribute('max', this.max);
            } else {
                this.el.nativeElement.removeAttribute('max');
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return NumberValidator.max(this.max)(c);
    }
}

/**
 * minDirective
 * Validator for testing agains max value of a numeric control
 *
 * Can be used in a Template Driven Form Validation Context as follows:
 * <input ... required ... min="<maxvalue>" ... [(ngModel)]="..." ...>
 *
 */
@Directive({
    selector: '[min][formControlName],[min][formControl],[min][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MinDirective), multi: true }
    ]
})
export class MinDirective implements Validator, OnChanges {
    @Input() min: number;
    //constructor( @Attribute('min') public min: number) {}
    constructor(public el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.hasOwnProperty('min')) {
            if(typeof this.min !== 'undefined' && this.min !== null) {
                this.el.nativeElement.setAttribute('min', this.min);
            } else {
                this.el.nativeElement.removeAttribute('min');
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return NumberValidator.min(this.min)(c);
    }
}

/**
 * integerDirective
 * Validator for testing a value of a numeric control to be integer
 *
 * Can be used in a Template Driven Form Validation Context as follows:
 * <input ... required ... integer ... [(ngModel)]="..." ...>
 * <input ... required ... [integer]="true" ... [(ngModel)]="..." ...>
 *
 */
@Directive({
    selector: '[integer][formControlName],[integer][formControl],[integer][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => IntegerDirective), multi: true }
    ]
})
export class IntegerDirective implements Validator {
    @Input() integer?: boolean;

    constructor(public el: ElementRef) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return NumberValidator.integer(this.integer)(c);
    }
}