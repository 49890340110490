import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { XmppLoaderProvider } from "./xmpp-loader";
import { XmppLowLevelProvider } from "./xmpp-low-level";
import { jid } from "./xmpp__client";

export class XmppMessage {
  to: jid.JID;
  from: jid.JID;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class XmppHighLevelProvider {
  address: jid.JID;
  subscribedAddresses: Set<jid.JID>;
  availableAddresses: Map<jid.JID, boolean>;
  receiveSubject: Subject<XmppMessage>;
  onlineSubscription: Subscription;
  offlineSubscription: Subscription;
  stanzaSubscription: Subscription;

  protected _present: boolean = false;
  get present(): boolean {
    return this._present;
  }
  set present(present: boolean) {
    if (present !== this._present) {
      if (present) {
        this.xmppLow.send(this.xmppLoaderProvider.xml("presence")).then(() => {
          this._present = true;
        }, err => {
          console.error('Presence fail', err);
          this._present = false;
        }); // signal presence
      } else {
        this.xmppLow.send(this.xmppLoaderProvider.xml("presence", { type: "unavailable" })).then(() => {
          this._present = false;
        }, err => {
          console.error('Presence unavailable fail', err);
          this._present = false;
        });
      }
    }
  }

  sendPresence(): Promise<any> {
    if (this.present) {
      return this.xmppLow.send(this.xmppLoaderProvider.xml("presence"));
    } else {
      return this.xmppLow.send(this.xmppLoaderProvider.xml("presence", { type: "unavailable" }));
    }
  }

  constructor(
    public xmppLoaderProvider: XmppLoaderProvider,
    public xmppLow: XmppLowLevelProvider,
  ) {
  }

  init(username: string, password: string) {
    this.subscribedAddresses = new Set();
    this.availableAddresses = new Map();
    this.xmppLow.init(username, password);
    this.onlineSubscription = this.xmppLow.online.subscribe((address: jid.JID) => {
      console.log('Xmpp online, address:', address);
      this.address = address;
      this.present = true;
    });
    this.offlineSubscription = this.xmppLow.offline.subscribe(() => {
      this._present = false; // correct?
      // ?
    });
    this.stanzaSubscription = this.xmppLow.stanza.subscribe((stanza: any /*xml.Element*/) => {
      console.log('Xmpp received stanza TODO: is type really xml.Element // does it have attrs and is() ?', stanza);
      if (stanza.is('message')) {
        this.receiveSubject.next({
          to: stanza.attrs.to,
          from: stanza.attrs.from,
          text: 'TODO: implement / how to get text node?' // TODO
        });
      } else if (stanza.is('presence')) {
        if (stanza.attrs.type === 'subscribed') {
          // subscription accepted
          this.subscribedAddresses.add(stanza.attrs.from);
        } else if (stanza.attrs.type === 'unsubscribed') {
          // subscription refused / revoked
          this.subscribedAddresses.delete(stanza.attrs.from);
        } else if (stanza.attrs.type === 'unavailable') {
          // user is unavailable
          this.availableAddresses.set(stanza.attrs.from, false);
        } else if (stanza.attrs.type === 'error') {
          console.error('Xmpp presence error stanza:', stanza);
          if (stanza.attrs.from) {
            this.subscribedAddresses.delete(stanza.attrs.from);
          }
        } else if (stanza.attrs.type === 'probe') {
          this.sendPresence();
        } else if (typeof stanza.attrs.type === 'undefined') {
          // user is available
          this.availableAddresses.set(stanza.attrs.from, true);
          //TODO: there is an optional subelement <show>VALUE</show>,
          // with value being: away|chat|dnd|xa,
          // (with meanings: away|chat-willing|do-not-disturb|extended-away)
          // describing the availableness more detailed
          //TODO: also a freetext <status>VALUE</status> can be provided
        }
      } else {
        console.log('Xmpp TODO not implemented stanza:', stanza);
      }
    });
  }

  uninit() {
    this.address = undefined;
    this.subscribedAddresses = new Set();
    this.availableAddresses = new Map();
    this.onlineSubscription && this.onlineSubscription.unsubscribe();
    this.offlineSubscription && this.offlineSubscription.unsubscribe();
    this.stanzaSubscription && this.stanzaSubscription.unsubscribe();
  }

  send(message: XmppMessage): Promise<any> {
    const stanza = this.xmppLoaderProvider.xml(
      "message",
      { type: "chat", to: message.to },
      this.xmppLoaderProvider.xml("body", {}, message.text),
    );
    return this.xmppLow.send(stanza).then(res => {
      this.subscribePresence(message.to);
      return res;
    });
  }

  subscribePresence(address: jid.JID) {
    if (!this.subscribedAddresses.has(address)) {
      const stanza = this.xmppLoaderProvider.xml(
        "presence",
        { type: "subscribe", to: address }
      );
      return this.xmppLow.send(stanza);
    }
  }

  unsubscribePresence(address: jid.JID) {
    const stanza = this.xmppLoaderProvider.xml(
      "presence",
      { type: "unsubscribe", to: address }
    );
    return this.xmppLow.send(stanza);
  }

  /** should be called on application init, if messaging feature is enabled */
  start(): Promise<any> {
    return this.xmppLow.start();
  }

  /** should be called on application destroy, if messaging feature is enabled */
  stop(): Promise<any> {
    return this.xmppLow.stop();
  }
}
