import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { JobboerseDetailPage } from './jobboerse-detail';
import { ComponentsModule } from 'components/components.module';
import { DirectivesModule } from 'directives/directives.module';
import { PipesModule } from 'pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    JobboerseDetailPage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    TranslateModule,
    PipesModule,
    CommonModule,
    FormsModule,
  ],
})
export class JobboerseDetailPageModule { }
