import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseList } from '../baselist/baselist';
import { Payable } from '../payable/payable.service';

export class Checklist extends Payable {
  id: number;
  tippgroupId: string;
  title: string;
  html: string;
}
@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(public http: HttpClient) {
  }

  getList(tippgroupId?: string): Promise<Array<Checklist>> {
    return new Promise<Array<Checklist>>((resolve, reject) => {
      this.http.get<Array<Checklist>>('/api/checklist').subscribe(data => {
        if(tippgroupId) {
          let res = new Array<Checklist>();
          data.forEach(p => {
            if(p.tippgroupId === tippgroupId) {
              res.push(p);
            }
          });
          resolve(res);
        } else {
          resolve(data);
        }
      }, err => {
        reject(err);
      });
    });
  }

  get(id: number): Promise<Checklist> {
    return new Promise<Checklist>((resolve, reject) => {
      this.http.get<Checklist>('/api/checklist/'+id).subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

}
