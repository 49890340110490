import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'page-preview',
  templateUrl: 'page-preview.html',
  styleUrls: ['page-preview.scss']
})
export class PagePreviewComponent implements OnChanges {

  @Input() html: string;
  obfuscated: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty('html')) {
      this.obfuscated = this.obfuscate(this.html);
    }
  }

  obfuscate(html: string): string {

    let similarchar = (a: string): string => {
      if(a.match(/[a-z]/)) {
        return "abcdefghijklmnopqrstuvwxyz"[Math.floor(Math.random()*100000) % 26];
      } else if(a.match(/[A-Z]/)) {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[Math.floor(Math.random()*100000) % 26];
      } else if(a.match(/\w/)) {
        return "abcdefghijklmnopqrstuvwxyz"[Math.floor(Math.random()*100000) % 26];
      } else {
        return a;
      }
    };

    if(!html) return;
    let out: string = '';
    let charnum: number = 0;
    let maxcharnum: number = 500;
    let ischar: boolean = false;
    for(let i=0,il=html.length;i<il;i++) {
      if(ischar) {
        if(html[i] === '<') {
          ischar = false;
          out += html[i];
        } else {
          charnum++;
          if(charnum > maxcharnum) {
            out += similarchar(html[i]);
          } else {
            out += html[i];
          }
        }
      } else {
        if(html[i] === '>') {
          ischar = true;
        }
        out += html[i];
      }
    }
    return out;
  }

}
