//Main Menu
//import { _ } from '@biesbjerg/ngx-translate-extract';
let _ = (key) => key;

export const PSEUDO_ID_ASSIGNED = 'assigned';

export const Pages = [
  { icon: 'select-item', title: _('dashboard.menu'), page: 'DashboardPage', feature: 'dashboard', params: null, active: false, hideSub: false, sub: null, },
  {
    icon: 'open-book', title: _('onboarding.menu'), page: 'OnboardingPage', feature: 'onboarding', params: null, active: false, hideSub: false, sub: [
      {
        title: _('contracts.menu'), page: 'OnboardingPage', feature: 'onboarding', params: {}, active: false, hideSub: true, sub: [
          { title: _('contract.menu'), page: 'OnboardingContractPage', feature: 'onboarding', params: undefined, active: false, hideSub: false, sub: null, }
        ],
      },
      /* the remaining OnboardingContentPage sub pages here be filled in according to the json data from backend */
    ]
  },
  { icon: 'mail', title: _('nachrichten.menu'), page: 'ChatStandalonePage', feature: 'nachrichten', params: null, active: false, hideSub: false, sub: null, },
  {
    icon: 'male-avatar', title: _('bewerbungsmappe.menu'), page: 'BewerbungsmappePage', feature: 'bewerbungsmappe', params: { metapage: '1' }, active: false, hideSub: false, sub: [ // IMPORTANT: the page id "person" is thus set in stone.
      { title: _('message.wait'), page: 'BewerbungsmappePage', feature: 'bewerbungsmappe', params: { metapage: '1' }, active: false, hideSub: false, sub: null, } // IMPORTANT: the page id "person" is thus set in stone.
      /* the sub pages here be filled in according to the json data from backend */
    ]
  },
  {
    icon: 'archive-manager', title: _('bewerbungstipps.menu'), page: 'BewerbungstippsPage', feature: 'bewerbungstipps', params: { id: 'overview' }, active: false, hideSub: false, sub: [
      { title: _('message.wait'), page: 'BewerbungstippsPage', feature: 'bewerbungstipps', params: { id: 'overview' }, active: false, hideSub: false, sub: null, }
    ],
  },
  {
    icon: 'search-1', title: _('jobboerse.menu'), page: 'JobboersePage', feature: 'jobboerse', params: null, active: false, hideSub: true, sub: [
      {
        title: _('jobboersedetail.menu'), page: 'JobboerseDetailPage', feature: 'jobboerse', params: undefined, active: false, hideSub: true, sub: [
          { title: _('jobboersebewerbermappe.menu'), page: 'JobboerseBewerbermappePage', feature: 'bewerbungsmappe', params: undefined, active: false, hideSub: false, sub: null },
        ],
      },
    ],
  },
  {
    icon: 'address', title: _('applications.menu'), page: 'ApplicationsPage', feature: 'applications', params: null, active: false, hideSub: true, sub: [
      { title: _('application.menu'), page: 'ApplicationPage', feature: 'application', params: undefined, active: false, hideSub: false, sub: null, }
    ],
  },
  { icon: 'eye', title: _('recherche.menu'), page: 'RecherchePage', feature: 'recherche', params: null, active: false, hideSub: false, sub: null, },
  // Master- und Detail-Seite sind hier vertauscht, weil Benutzer erstmal seinen eigenen Coach sehen soll
  {
    icon: 'video-conversation', title: _('bewerbungsservicedetail.menu'), page: 'BewerbungsserviceDetailPage', feature: 'bewerbungsservice', params: { id: PSEUDO_ID_ASSIGNED }, active: false, hideSub: true, sub: [
      { title: _('bewerbungsservice.menu'), page: 'BewerbungsservicePage', feature: 'bewerbungsservice', params: undefined, active: false, hideSub: true, sub: null }
    ],
  },
  {
    icon: 'boy', title: _('account.menu'), page: 'AccountPage', feature: 'account', params: null, active: false, hideSub: false, sub: [
      { title: _('accessdata.menu'), page: 'AccountPage', feature: 'account', params: null, active: false, hideSub: false, sub: null, },
      { title: _('password.menu'), page: 'PasswordPage', feature: 'account', params: null, active: false, hideSub: false, sub: null, },
      { title: _('bewerbungsservicesettings.menu'), page: 'BewerbungsserviceSettingsPage', feature: 'bewerbungsservice', params: null, active: false, hideSub: false, sub: null, },
      { title: _('paidgoods.menu'), page: 'PaidGoodsPage', feature: 'paidgoods', params: null, active: false, hideSub: false, sub: null, },
    ]
  },
  { icon: 'boy', title: _('impressum.menu'), page: 'ImpressumPage', feature: 'impressum', params: null, active: false, hideSub: false, sub: null, },
  { icon: 'boy', title: _('terms.menu'), page: 'TermsPage', feature: 'terms', params: null, active: false, hideSub: false, sub: null, },
  { icon: 'technical-support', title: _('contact.menu'), page: 'ContactPage', feature: 'contact', params: null, active: false, hideSub: false, sub: null, },
  // ...
];
