import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { ConstantVariable } from 'constant-variable';
import { BewerbungsmappeService, BewerbungsmappeStatus } from 'services/bewerbungsmappe/bewerbungsmappe.service';
import { MetaPage, MetaGroup, MetaField, MetaValue, MetaDateSelect, MetaRelations, MetaRelation, MetaId, DataValue, DataGroup, DataFields, DataPage } from 'metagroup';
import { NavController } from '@ionic/angular';
import { RoutingService } from 'services/routing/routing.service';


@Component({
  selector: 'profilfragen',
  templateUrl: 'profilfragen.html',
  styleUrls: ['profilfragen.scss']
})
export class ProfilfragenComponent {
  @Input('coach') coach: DataPage;
  showAllFeatures: boolean;
  public metapage: any;
  public groupData: DataPage;
  public field: MetaField;
  public dataGroup: DataGroup;

  constructor(
    public branch: BranchConfigService,
    public bewerbermappe: BewerbungsmappeService,
    private navController: NavController,
    private routingService: RoutingService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
    this.load();
  }

  load() {
    this.bewerbermappe.getMeta().then(pages => {
      let navpageid = 'profilfragen';
      this.metapage = pages.find(page => { return page.id == navpageid; });
      if(this.metapage) {


        console.log('subpage:', this.metapage);
        this.bewerbermappe.getData(this.metapage.id).then(data => {
          this.groupData = data;

          // add missing groups
          this.metapage.groups.forEach(group => {
            if (!this.groupData[group.id]) {
              if(group.listtype === 'array' || group.listtype === 'set') {
                this.groupData[group.id] = [];
              } else {
                this.groupData[group.id] = new DataGroup();
              }
            }
          });

          // find the field and dataGroup
          if(this.metapage.groups && this.metapage.groups.length > 0) {
            let idx = this.metapage.groups.findIndex(group => {
              return group.id === 'newquestions'; // IMPORTANT: the group id "newquestions" is thus set in stone.
            });
            if(idx !== -1) {
              let group = this.metapage.groups[idx];
              if(group && group.fields && group.fields.length > 0) {
                this.field = group.fields[0];
                this.dataGroup = <DataGroup>this.groupData[group.id];
              }
            }
          }

        });
      } else {
        console.error('BewerbungsmappePage: a MetaPage.id of "'+navpageid+'" is unknown.');
      }
    });
  }

  save() {
    this.bewerbermappe.postData(this.groupData.id,this.groupData).then(data=>{
      this.load(); // load again to get next question in group' newquestions from backend //TODO: won't work until implemented by the real backend, as the DEV metadata are static
    });
  }

  openPage(page) {
    this.navController.navigateForward(this.routingService.getPath(page));
  }

}
