import { Injectable } from '@angular/core';
import { ApiService } from 'services/api/api.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService extends ApiService<any> {

  constructor() {
    super('contact');
  }
}
