import { Component, Injector, OnInit } from '@angular/core';
import { PasswordChangeCredentials } from 'services/auth/auth-credentials';
import { AuthService } from 'services/auth/auth.service';
import { PagesService } from 'services/pages/pages.service';
import { ToastService } from 'services/toast/toast.service';

@Component({
  selector: 'page-password',
  templateUrl: 'password.html',
  styleUrls: ['password.scss']
})
export class PasswordPage implements OnInit {
  public readonly pageName: string = "PasswordPage";
  public passwordData: PasswordChangeCredentials = { oldPassword: null, password: null, password2: null };

  public pages: any[];

  constructor(
    public injector: Injector,
    public auth: AuthService,
    public toastService: ToastService,
    public pagesProvider: PagesService,
  ) {
    /*
    this.pages = Pages.find(page => {
      return page.page == 'AccountPage';
    }).sub;
    */
    this.pages = pagesProvider.getPage('AccountPage').sub;
  }

  save() {
    this.auth.passwordChange(this.passwordData).then(res => {
      console.log('APPCOM: login Success, res=', res);
    }, err => {
      console.log('APPCOM: login Failure, err=', err);
      this.toastService.handleError(err);
    });
  }

  cancel() {
    this.passwordData = { oldPassword: null, password: null, password2: null };
  }

  ngOnInit() {
    console.log('ngOnInit PasswordPage');
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter PasswordPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave PasswordPage');
  }

}
