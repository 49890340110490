import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SignupCredentials } from 'services/auth/auth-credentials';
import { AuthService } from 'services/auth/auth.service';
import { ModalService } from 'services/modal/modal.service';
import { RegisterService } from 'services/register/register.service';
import { ToastService } from 'services/toast/toast.service';
import { DataPage } from 'src/app/metagroup';

@Component({
  selector: 'page-register',
  templateUrl: 'register.html',
  styleUrls: ['register.scss']
})
export class RegisterPage implements OnInit {
  public readonly pageName: string = "RegisterPage";
  public readonly importType = 'appimport';

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  public registerData: SignupCredentials = {
    id: null,
    email: null,
    password: "",
    password2: "",
    recommendationCode: null,
    cvUpload: null,
    terms: null,
    type: null,
  };

  public isCvLoading: boolean;
  public isPasswordStep: boolean;
  public mdata: DataPage;
  public showLinksAfterImport: boolean;
  public loading: boolean;
  public msgInfo: string;

  constructor(
    public auth: AuthService,
    public toastService: ToastService,
    public injector: Injector,
    public modalService: ModalService,
    public registerProvider: RegisterService,
    private route: ActivatedRoute,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    if (!!this.auth.user) {
      this.auth.logout()
        .then(res => {
          console.log('APPCOM: logged out', res);
          this.route.paramMap.subscribe( paramMap => {
            const type = paramMap.get('type');
            const data = paramMap.get('data');
            this.navController.navigateRoot('register/' + type + '/' + data);
          });
        }, err => {
          console.log('APPCOM: failed to log out', err);
          this.toastService.handleError(err);
        });
    }
  }

  ionViewWillEnter() {
    this.route.paramMap.subscribe( paramMap => {
      const type = paramMap.get('type');
      const data = paramMap.get('data');
      const params = { type: type, data: data };

      this.registerData.type = params.type || 'email';
  
      this.registerProvider.getMdata(params).then(data => {
        this.mdata = data;
  
        this.registerData.id = this.mdata.id;
        if (this.mdata.email) {
          this.registerData.email = "" + this.mdata.email;
        }
      }, err => {
        console.error('cannot load registerdata', err);
      });
    });
  }

  submit() {
    this.showLinksAfterImport = false;
    this.loading = true;

    this.registerProvider.register(this.registerData).then(data => {
      this.msgInfo = data.message;
      this.toastService.handleSuccess(this.msgInfo, -1);
      console.log('APPCOM: register Success, res=', data);
    }, err => {
      if (err.importsuccess) {
        this.showLinksAfterImport = true;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });

  }

  openModal(page, event, params?) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.modalService.showModal(
      {component: page, componentProps: params},
      data => {
        //console.log(data);
      }
    );
  }

  onFileChange(event) {
    if (this.isCvLoading) return;
    this.isCvLoading = true;
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.registerData.cvUpload = {
          filename: file.name,
          filetype: file.type,
          value: (<String>reader.result).split(',')[1]
        };
        this.isCvLoading = false;
      };
      reader.onerror = () => {
        this.registerData.cvUpload = null;
        this.isCvLoading = false;
      }
    } else {
      this.registerData.cvUpload = null;
      this.isCvLoading = false;
    }
  }

  clickFileChange(event) {
    this.fileInput.nativeElement.click(event);
  }

}
