// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
.login-content .form-item,
.login-content ion-button {
  width: 100%;
  margin-bottom: 15px;
}
.login-content app-privacy {
  position: absolute;
  bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/login.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AACJ;AACI;;EAEI,WAAA;EACA,mBAAA;AACR;AAEI;EACI,kBAAA;EACA,YAAA;AAAR","sourcesContent":[".login-content {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-items: center;\n    width: 100%;\n\n    .form-item,\n    ion-button {\n        width: 100%;\n        margin-bottom: 15px;\n    }\n\n    app-privacy {\n        position: absolute;\n        bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
