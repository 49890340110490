import { NgModule } from '@angular/core';
import { IframeLoadDirective } from './iframe-load/iframe-load.directive';
import { EqualValidatorDirective } from './equal-validator/equal-validator.directive';
import { IntegerDirective, MaxDirective, MinDirective } from './number-validator/number-validator.directive';

@NgModule({
    declarations: [
        IframeLoadDirective,
        EqualValidatorDirective,
        MaxDirective,
        MinDirective,
        IntegerDirective,
    ],
    imports: [],
    exports: [
        IframeLoadDirective,
        EqualValidatorDirective,
        MaxDirective,
        MinDirective,
        IntegerDirective,
    ]
})
export class DirectivesModule { }
