import { NgModule } from '@angular/core';
import { AccountPageModule } from 'pages/account/account.module';
import { ActivatePageModule } from 'pages/activate/activate.module';
import { ApplicationPageModule } from 'pages/application/application.module';
import { ApplicationsPageModule } from 'pages/applications/applications.module';
import { BewerbungsmappePageModule } from 'pages/bewerbungsmappe/bewerbungsmappe.module';
import { BewerbungsserviceDetailPageModule } from 'pages/bewerbungsservice-detail/bewerbungsservice-detail.module';
import { BewerbungsservicePageModule } from 'pages/bewerbungsservice/bewerbungsservice.module';
import { BewerbungstippPageModule } from 'pages/bewerbungstipp/bewerbungstipp.module';
import { BewerbungstippsPageModule } from 'pages/bewerbungstipps/bewerbungstipps.module';
import { ChecklistPageModule } from 'pages/checklist/checklist.module';
import { DashboardPageModule } from 'pages/dashboard/dashboard.module';
import { ForgotPageModule } from 'pages/forgot/forgot.module';
import { JobboerseBewerbermappePageModule } from 'pages/jobboerse-bewerbermappe/jobboerse-bewerbermappe.module';
import { JobboerseDetailPageModule } from 'pages/jobboerse-detail/jobboerse-detail.module';
import { JobboersePageModule } from 'pages/jobboerse/jobboerse.module';
import { OnboardingContractPageModule } from 'pages/onboarding-contract/onboarding-contract.module';
import { PaidGoodsPageModule } from 'pages/paid-goods/paid-goods.module';
import { PasswordChangePageModule } from 'pages/password-change/password-change.module';
import { RegisterPageModule } from 'pages/register/register.module';
import { AccountPage } from './account/account';
import { ActivatePage } from './activate/activate';
import { ApplicationPage } from './application/application';
import { ApplicationsPage } from './applications/applications';
import { BewerbungsmappePage } from './bewerbungsmappe/bewerbungsmappe';
import { BewerbungsserviceDetailPage } from './bewerbungsservice-detail/bewerbungsservice-detail';
import { BewerbungsserviceJitsiModalPage } from './bewerbungsservice-jitsi-modal/bewerbungsservice-jitsi-modal';
import { BewerbungsserviceJitsiModalPageModule } from './bewerbungsservice-jitsi-modal/bewerbungsservice-jitsi-modal.module';
import { BewerbungsserviceSettingsPage } from './bewerbungsservice-settings/bewerbungsservice-settings';
import { BewerbungsserviceSettingsPageModule } from './bewerbungsservice-settings/bewerbungsservice-settings.module';
import { BewerbungsservicePage } from './bewerbungsservice/bewerbungsservice';
import { BewerbungstippViewMoreModalPage } from './bewerbungstipp-view-more-modal/bewerbungstipp-view-more-modal';
import { BewerbungstippViewMoreModalPageModule } from './bewerbungstipp-view-more-modal/bewerbungstipp-view-more-modal.module';
import { BewerbungstippPage } from './bewerbungstipp/bewerbungstipp';
import { BewerbungstippsPage } from './bewerbungstipps/bewerbungstipps';
import { ChatStandalonePageModule } from './chat-standalone/chat-standalone.module';
import { ChatStandalonePage } from './chat-standalone/chat-standalone.page';
import { ChecklistViewMoreModalPage } from './checklist-view-more-modal/checklist-view-more-modal';
import { ChecklistViewMoreModalPageModule } from './checklist-view-more-modal/checklist-view-more-modal.module';
import { ChecklistPage } from './checklist/checklist';
import { DashboardPage } from './dashboard/dashboard';
import { ForgotPage } from './forgot/forgot';
import { ImageSelectViewMoreModalPage } from './image-select-view-more-modal/image-select-view-more-modal';
import { ImageSelectViewMoreModalPageModule } from './image-select-view-more-modal/image-select-view-more-modal.module';
import { ImpressumPage } from './impressum/impressum';
import { ImpressumPageModule } from './impressum/impressum.module';
import { JobboerseBewerbermappePage } from './jobboerse-bewerbermappe/jobboerse-bewerbermappe';
import { JobboerseDetailPage } from './jobboerse-detail/jobboerse-detail';
import { JobboersePage } from './jobboerse/jobboerse';
import { LeistungViewMoreModalPage } from './leistung-view-more-modal/leistung-view-more-modal';
import { LeistungViewMoreModalPageModule } from './leistung-view-more-modal/leistung-view-more-modal.module';
import { LoginPage } from './login/login.page';
import { LoginPageModule } from './login/login.page.module';
import { OnboardingContentPage } from './onboarding-content/onboarding-content';
import { OnboardingContentPageModule } from './onboarding-content/onboarding-content.module';
import { OnboardingContractRemoveModalPage } from './onboarding-contract-remove/onboarding-contract-remove';
import { OnboardingContractRemoveModalPageModule } from './onboarding-contract-remove/onboarding-contract-remove.module';
import { OnboardingContractPage } from './onboarding-contract/onboarding-contract';
import { OnboardingPage } from './onboarding/onboarding';
import { OnlineCoursePage } from './online-course/online-course';
import { OnlineCoursePageModule } from './online-course/online-course.module';
import { OnlinecourseViewMoreModalPage } from './onlinecourse-view-more-modal/onlinecourse-view-more-modal';
import { OnlinecourseViewMoreModalPageModule } from './onlinecourse-view-more-modal/onlinecourse-view-more-modal.module';
import { PaidGoodsPage } from './paid-goods/paid-goods';
import { PasswordChangePage } from './password-change/password-change';
import { PasswordPage } from './password/password';
import { PasswordPageModule } from './password/password.module';
import { PodcastViewMoreModalPage } from './podcast-view-more-modal/podcast-view-more-modal';
import { PodcastViewMoreModalPageModule } from './podcast-view-more-modal/podcast-view-more-modal.module';
import { RecherchePage } from './recherche/recherche';
import { RecherchePageModule } from './recherche/recherche.module';
import { RegisterPage } from './register/register';
import { TermsPage } from './terms/terms';
import { TermsPageModule } from './terms/terms.module';
import { VideoViewMoreModalPage } from './video-view-more-modal/video-view-more-modal';
import { VideoViewMoreModalPageModule } from './video-view-more-modal/video-view-more-modal.module';
import { WebinarViewMoreModalPage } from './webinar-view-more-modal/webinar-view-more-modal';
import { WebinarViewMoreModalPageModule } from './webinar-view-more-modal/webinar-view-more-modal.module';

@NgModule({
  declarations: [],
  imports: [
    LoginPageModule,
    DashboardPageModule,
    BewerbungstippsPageModule,
    BewerbungsserviceDetailPageModule,
    BewerbungsmappePageModule,
    JobboersePageModule,
    JobboerseDetailPageModule,
    JobboerseBewerbermappePageModule,
    ApplicationsPageModule,
    AccountPageModule,
    ActivatePageModule,
    ApplicationPageModule,
    BewerbungstippPageModule,
    BewerbungsservicePageModule,
    ChecklistPageModule,
    ForgotPageModule,
    OnboardingContractPageModule,
    PaidGoodsPageModule,
    PasswordChangePageModule,
    RegisterPageModule,
    BewerbungsserviceSettingsPageModule,
    ImpressumPageModule,
    OnboardingContentPageModule,
    PasswordPageModule,
    RecherchePageModule,
    TermsPageModule,
    VideoViewMoreModalPageModule,
    WebinarViewMoreModalPageModule,
    PodcastViewMoreModalPageModule,
    LeistungViewMoreModalPageModule,
    ChecklistViewMoreModalPageModule,
    ImageSelectViewMoreModalPageModule,
    BewerbungstippViewMoreModalPageModule,
    OnboardingContractRemoveModalPageModule,
    BewerbungsserviceJitsiModalPageModule,
    ChatStandalonePageModule,
    OnlineCoursePageModule,
    OnlinecourseViewMoreModalPageModule,
  ],
  exports: [
    LoginPage,
    DashboardPage,
    BewerbungstippsPage,
    BewerbungsserviceDetailPage,
    BewerbungsmappePage,
    JobboersePage,
    JobboerseDetailPage,
    JobboerseBewerbermappePage,
    ApplicationsPage,
    AccountPage,
    ActivatePage,
    ApplicationPage,
    BewerbungstippPage,
    BewerbungsservicePage,
    ChecklistPage,
    ForgotPage,
    OnboardingContractPage,
    OnboardingPage,
    PaidGoodsPage,
    PasswordChangePage,
    RegisterPage,
    BewerbungsserviceSettingsPage,
    ImpressumPage,
    OnboardingContentPage,
    PasswordPage,
    RecherchePage,
    TermsPage,
    VideoViewMoreModalPage,
    WebinarViewMoreModalPage,
    PodcastViewMoreModalPage,
    LeistungViewMoreModalPage,
    ChecklistViewMoreModalPage,
    ImageSelectViewMoreModalPage,
    BewerbungstippViewMoreModalPage,
    OnboardingContractRemoveModalPage,
    BewerbungsserviceJitsiModalPage,
    ChatStandalonePage,
    OnlineCoursePage,
    OnlinecourseViewMoreModalPage,
  ]
})
export class PagesModule { }
