import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { RoutingService } from 'services/routing/routing.service';
// import { SwUpdate } from '@angular/service-worker';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {
  AlertController, Config, MenuController, NavController, Platform
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ImageCacheProvider } from 'pipes/image/image.pipe';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'services/auth/auth.service';
import { BewerbungsmappeService } from 'services/bewerbungsmappe/bewerbungsmappe.service';
import { BewerbungstippsProvider } from 'services/bewerbungstipps/bewerbungstipps';
import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { CoachService } from "services/coach/coach.service";
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { JitsiCallInfo, JitsiService } from 'services/jitsi/jitsi.service';
import { LanguageService } from 'services/language/language.service';
import { MessageService } from 'services/message/message.service';
import { CometchatProvider } from "services/nachrichten/cometchat";
import { NachrichtenProvider } from "services/nachrichten/nachrichten";
import { NachrichtenMocker } from 'services/nachrichten/nachrichten.mocker';
import { OnboardingContentService } from 'services/onboarding-content/onboarding-content.service';
import { PlatformService } from 'services/platform/platform.service';
import { PushNotificationService } from 'services/push-notification/push-notification.service';
import { RegisterMocker } from 'services/register/register.mocker';
import { RuntimeConfigService } from 'services/runtime-config/runtime-config.service';
import { ToastService } from 'services/toast/toast.service';
import { ConstantVariable } from './constant-variable';
import { DataGroup, DataPage } from "./metagroup";
import { Pages } from './pages';
import { register } from 'swiper/element/bundle';
import { ConstantRoute } from './constant-routes';

// register swiper globally
// https://swiperjs.com/element
register();

@Component({
  templateUrl: 'app.html',
  styleUrls: ['./app.scss']
})
export class MyApp implements OnDestroy, OnInit {
  // @ViewChild(Nav, { static: true }) nav: Nav;

  //rootPage:any = 'DashboardPage';
  rootPage: string;
  pages: any[];
  activePage = new Subject();
  showAllFeatures: boolean;
  allUpdatePagesRun: Promise<any>;
  allUpdatePagesRunResolve: any;

  isLogin: boolean;
  guardedView: any;
  isOnboarding: boolean;

  errorSubscription: Subscription;
  userSubscription: Subscription;
  stateSubscription: Subscription;
  pageSubscription: Subscription;
  navSubscription: Subscription;
  messageSubscription: Subscription;
  jitsiCallSubscription: Subscription;

  ngOnDestroy() {
    this.externalLogouts().catch(e => { });
    this.errorSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.stateSubscription.unsubscribe();
    this.pageSubscription.unsubscribe();
    if (this.navSubscription) this.navSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.jitsiCallSubscription.unsubscribe();
  }
  ngOnInit() { }

  lastSelectedPage = null;

  constructor(
    public platform: Platform,
    public router: Router,
    public zone: NgZone,
    public alertCtrl: AlertController,
    public menu: MenuController,
    public auth: AuthService,
    public message: MessageService,
    public translate: TranslateService,
    public config: Config,
    public adapter: DateAdapter<any>,
    public runtimeConfigProvider: RuntimeConfigService,
    public bewerbermappe: BewerbungsmappeService,
    public configProvider: BranchConfigService,
    public onboardingContentProvider: OnboardingContentService,
    public registerMocker: RegisterMocker,
    public toastService: ToastService,
    public imageCache: ImageCacheProvider,
    public pushNotificationService: PushNotificationService,
    public bewerbungstippsProvider: BewerbungstippsProvider, //IMPORTANT: for production comment out this Injection (injecting this mocks backend for bewerbungstipps data)
    public nachrichtenMocker: NachrichtenMocker,
    public languageService: LanguageService,
    public events: EventsService,
    public nachrichtenProvider: NachrichtenProvider,
    public coachesProvider: CoachService,
    public cometchatProvider: CometchatProvider,
    public navController: NavController,
    public routingService: RoutingService,
    public jitsiService: JitsiService,
    public platformService: PlatformService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;

    //for iOS: re-activate viewport "user-scalable=no" setting (which has been removed by Apple from iOS 10 for dumb reason) ...
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
    this.menu.swipeGesture(false, 'content');

    this.resetAllUpdatePagesRun();

    this.initTranslate();
    this.initializeApp();

    //Main Menu
    this.pages = Pages;
    // this.pageSubscription = this.activePage.subscribe(this.updatePages);

    this.updateLoginState(!!this.auth.user);

    this.errorSubscription = this.auth.error().subscribe(err => {
      console.log('APPCOM: errorSubscription got error:', err);
      this.toastService.handleError(err.response, () => {
        //?
      });
    });

    this.userSubscription = this.auth.userChanged().subscribe(user => {
      console.log('APPCOM: userSubscription got user:', user);
      if (user) {
        this.externalLogins().catch(e => { });
      } else {
        this.externalLogouts().catch(e => { });
      }
      this.resetAllUpdatePagesRun();
      // this.runAllUpdatePages();
    });

    this.stateSubscription = this.auth.stateChanged().subscribe(isLoggedIn => {
      console.log('APPCOM: stateSubscription got changed state:', isLoggedIn);
      this.updateLoginState(isLoggedIn);
      if (isLoggedIn) {
        if (this.guardedView) {
          let view = this.guardedView;
          console.log('go to guarded view', view);
          this.guardedView = null;
          this.navController.navigateRoot(this.routingService.getPath(view.page), view.params);
        } else {
          this.navController.navigateRoot(this.routingService.getPath(this.rootPage));
        }
      } else {
        this.navController.navigateRoot(this.routingService.getPath('LoginPage'));
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: eu.heroes.candooze:///activate/email/123
        // path = /activate/email/123
        const path = event.url.slice('eu.heroes.candooze://'.length, event.url.length);
        if (!!path) {
          console.debug('DEEPLINK routing to ', path);
          this.router.navigateByUrl(path);
        }
        // If no match, do nothing - let regular routing logic take over
      });
    });

    // push notifications
    this.pushNotificationService.initPushNotifications();
    this.jitsiCallSubscription = this.events.subscribe(EventKey.JITSI_CALL, (...args: any[]) => {
      this.jitsiCall(args[0][0], args[0][1]);
    });
    // start cometchat on reload or on login
    this.cometchatProvider.startCometchat();
    this.auth.userChanged().subscribe(user => {
      if (user) {
        this.cometchatProvider.startCometchat();
      } else {
        this.cometchatProvider.logout();
      }
    });

    // wait for platform to be ready
    this.platform.ready()
      .then(() => {
        this.initBranch();
        // jedes mal erneut aufrufen, wenn der user gewechselt wird
        this.auth.userChanged().subscribe(event => this.initBranch());
      });

    this.platform.resume.subscribe(() => {
      this.initBranch();
    });
  }

  externalLogins(): Promise<void[]> {
    return this.configProvider.configuration.then(config => {
      let proms: Promise<any>[] = [];
      return Promise.all(proms);
    });
  }

  externalLogouts(): Promise<void[]> {
    return this.configProvider.configuration.then(config => {
      let proms: Promise<any>[] = [];
      return Promise.all(proms);
    });
  }

  getLang() {
    return this.translate.getBrowserLang() || 'de';
  }

  initTranslate() {
    const _init = () => this.languageService.init('de');
    this.configProvider.isReady.then((config: BranchConfig) => {
      _init();
    }, e => {
      _init();
    });
  }

  resetAllUpdatePagesRun() {
    this.allUpdatePagesRun = new Promise<any>((resolve, reject) => {
      this.allUpdatePagesRunResolve = resolve;
    });
  }

  /**
   * Determines, if the side menu is shown or not.
   * The side menu will be shown, if a user is logged in and the plattform is not mobile.
   * An exception is if the system is running in onboarding mode. In this case, the side menu will always be shown.
   * @returns true, if the condition is met
   */
  showSideMenu(): boolean {
    return !!this.auth.user && (this.isOnboarding || !this.platformService.isMobile());
  }

  /**
   * Determines, if the tabs menu is shown or not.
   * The side tabs will be shown, if a user is logged in and the plattform is mobile.
   * An exception is if the system is running in onboarding mode. In this case, the tabs menu will never be shown.
   * @returns true, if the condition is met
   */
  showTabMenu(): boolean {
    return !!this.auth.user && !this.isOnboarding && this.platformService.isMobile();
  }
  
  /**
   * Determines, if the candooze mobile app is used or not.
   * @returns true, if the mobile app is currently used
   */
  isCdzMobile(): boolean {
    return !this.isOnboarding && this.platformService.isMobile();
  }

  // Branch initialization
  private initBranch(): void {
    this.configProvider.init().then((config: BranchConfig) => {
      if (config.apiBaseUrl) {
        this.runtimeConfigProvider.set('apiBaseUrl', (config.apiBaseUrl + '/'));
        let regexp = new RegExp('(^\\/api\\/)|(^' + (config.apiBaseUrl + '/').replace('/', '\\/') + ')', 'i');
        this.runtimeConfigProvider.set('apiAuthRegexp', regexp);
      } else {
        console.error('ERROR: apiBaseUrl is missing from branch config object!');
      }

      // set colors from backend
      if (!!config.colorMap) {
        for (const key in config.colorMap) {
          if (config.colorMap.hasOwnProperty(key)) {
            const value = config.colorMap[key];
            if (!!value && value.trim().length > 0) {
              document.documentElement.style.setProperty('--' + key, value.trim());
            }
          }
        }
      }

      // custom start page for candooze mobile
      if (this.showTabMenu() && !this.routingService.isAnyActive()) {
        this.navController.navigateRoot('/' + ConstantRoute.FULL_COACHING);
      } else {
        //set first activated page found in this.pages to be this.rootPage
        let found = false;
        if (!!config.features) {
          for (let pi = 0; pi < this.pages.length; pi++) {
            if (this.pages[pi].feature && config.features.hasOwnProperty(this.pages[pi].feature) && config.features[this.pages[pi].feature]) {
              this.rootPage = this.pages[pi].page;
              found = true;
              break;
            }
          }
        }
        if (!found) {
          this.rootPage = 'LoginPage';
        }

        // Override, set first visible page as 'dashboard'-Page as enabled by feature!
        this.auth.authOptions.dashboardPage = this.rootPage;
        if (!this.routingService.isAnyActive()) { // only if we are not already displaying a page
          this.navController.navigateRoot(this.routingService.getPath(this.rootPage));
        }
      }


      // this.runAllUpdatePages();
      this.languageService.init('de');

      if (this.auth.user) {
        this.externalLogins().catch(e => { });
      }
    }, error => {
      console.error('BranchConfigProvider error: ', error);
      this.languageService.init('de');
      this.toastService.handleError(error, () => {
        window.location.reload();
      });
    }).then(() => {
      console.log('BranchConfigProvider, checking user config');
      this.configProvider.reloadForUser().then((config: BranchConfig) => {
        console.log('BranchConfigProvider, got user config', config);
        // this.runAllUpdatePages(config);
        // check if onboarding is used (this will determine some visual differences)
        if (!!config.features) {
          this.isOnboarding = config.features.onboarding;
        }
      });
    });
  }

  initializeApp() {
    this.messageSubscription = this.message.messages().subscribe(message => {
      console.log('message received', message);
      if (message.hasOwnProperty('guardedView')) {
        this.guardedView = message.guardedView;
        console.log('remember guarded view', message.guardedView);
      }
    });
    this.platform.ready().then(() => {
      //this.initPushNotification();   // <- for push notifications see implementation in app.components.ts in Ionium/TAF application
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }

  openPage(page, root = true) {
    console.log('openPage', page);
    // close the menu when clicking a link from the menu
    this.menu.close();
    // navigate to the new page if it is not the current page
    if (root) {
      this.navController.navigateRoot(this.routingService.getPathReplaceAny(page.page, page.params));
    } else {
      this.navController.navigateForward(this.routingService.getPathReplaceAny(page.page, page.params));
    }
  }

  static pageParamsEqual(a, b) {
    //console.trace('pageParamsEqual:', a, b); // commented out, pollutes the log
    console.log('pageParamsEqual:', a, b);
    //function checks if the page parameter objects equal each other
    //additional to the usual page class name check this enables identity checking for pages in navigation that are dynamic
    //if params specification in Pages is undefined (exactly undefined) then ignore the parameters (new: single parameters can also be specified as undefined to indicate genericness)
    let compare = function (a, b) {
      if (typeof a === 'undefined') {
        return true;
      } else if (typeof a === 'object' && typeof b === 'object' && a !== null && b !== null) {
        let pa = Object.getOwnPropertyNames(a), pb = Object.getOwnPropertyNames(b);
        // check if props are ident in both
        for (let i = 0; i < pb.length; i++) {
          if (!compare(a[pb[i]], b[pb[i]])) return false; // short out
        }
        for (let i = 0; i < pa.length; i++) {
          if (!compare(a[pa[i]], b[pa[i]])) return false; // short out
        }
        return true; // passed all tests
      } else {
        return a == b; //NOTE: was '===' however, Ionic sometimes passes scalar value as string, sometimes as a number => @Ionic team - just why???
      }
    };
    // if params to be ignored
    if (typeof a === 'undefined') return true;
    // empty object, null, undefined and all falsy values are treated as being equal:
    if ((!a || Object.getOwnPropertyNames(a).length < 1) && (!b || Object.getOwnPropertyNames(b).length < 1)) return true;
    else if (!a || !b) return false; // if only one of the param objects is falsy but the other not, then treat as unequal
    else if (typeof a !== 'object' || typeof b !== 'object') return false; // block out invalid values (object or falsy required)
    else return compare(a, b); // compare recursively
  }

  doLogout() {
    this.auth.logout()
      .then(res => {
        console.log('APPCOM: logged out', res);
      }, err => {
        console.log('APPCOM: failed to log out', err);
        this.toastService.handleError(err);
      });
  }

  updateLoginState(state: boolean) {
    this.menu.enable(state);
    this.isLogin = state;
  }

  jitsiCall(callInfo: JitsiCallInfo, userId: number) {
    this.coachesProvider.get(userId).then(coach => {
      this.showJitsiAlert(coach, callInfo);
    }, error => {
      console.error('cannot load coach with id ' + userId);
      this.showJitsiAlert(null, callInfo);
    });
  }

  async showJitsiAlert(caller: DataPage, callInfo: JitsiCallInfo) {
    const anonymousCaller = this.translate.instant('jitsi.call.anonymous');
    const callerName = (caller && caller.person && (caller.person as DataGroup).fieldData && (caller.person as DataGroup).fieldData.name) || anonymousCaller;
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('jitsi.call.title'),
      subHeader: this.translate.instant('jitsi.call.subtitle', { callerName: callerName }),
      buttons: [
        {
          text: this.translate.instant('jitsi.call.decline'),
          role: 'cancel',
          handler: () => {
            // TODO?
          }
        },
        {
          text: this.translate.instant('jitsi.call.accept'),
          handler: () => {
            this.jitsiService.startVideoChat(caller, callInfo);
          }
        }
      ]
    });
    return await alert.present();
  }
}
