// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callMessageStyle {
  padding: 8px 12px;
  margin-bottom: 16px;
  text-align: center;
}

.callMessageTxtStyle {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/CometChat-action-message-bubble/cometchat-action-message-bubble/cometchat-action-message-bubble.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".callMessageStyle {\n  padding: 8px 12px;\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.callMessageTxtStyle {\n  font-size: 13px;\n  font-weight: 500;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
