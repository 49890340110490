// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div[content] > h1.bewerbungstipps {
  font-weight: bold;
  padding-left: 50px;
  position: relative;
}
:host div[content] > h1.bewerbungstipps::before {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
:host div[content] > h1.bewerbungstipps::before {
  font-family: "fontello";
  content: "\\e820";
}
:host div[content] > h2.blogtitle {
  padding-left: 52px;
  font-weight: 600;
  color: var(--cdz-midcolor);
}
:host .blogpost {
  margin-top: 30px;
}
:host .right-back {
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: 0;
  width: 150px;
}
:host .right-back .button {
  width: 150px;
}
:host .right-back .button .button-inner {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/bewerbungstipp/bewerbungstipp.scss"],"names":[],"mappings":"AAEI;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;AADN;AAEM;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,4BAAA;EACA,wBAAA;EACA,kCAAA;AAAR;AAEM;EAEE,uBAAA;EACA,gBAAA;AADR;AAME;EACE,kBAAA;EACA,gBAAA;EACA,0BAAA;AAJJ;AAOE;EACE,gBAAA;AALJ;AASE;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AAPJ;AAQI;EACE,YAAA;AANN;AAOM;EACE,mBAAA;AALR","sourcesContent":[":host {\n  div[content] > h1 {\n    &.bewerbungstipps {\n      font-weight: bold;\n      padding-left: 50px;\n      position: relative;\n      &::before {\n        content: '';\n        display: block;\n        position: absolute;\n        width: 40px;\n        top: 0;\n        left: 0;\n        bottom: 0;\n        background-repeat: no-repeat;\n        background-size: contain;\n        background-position: center center;\n      }\n      &::before {\n        // background-image: url(../../../assets/imgs/bewerbungstipps.png);\n        font-family: 'fontello';\n        content: \"\\e820\"; //stars // \"\\e829\"; //filing\n      }\n    }\n  }\n\n  div[content] > h2.blogtitle {\n    padding-left: 52px;\n    font-weight: 600;\n    color: var(--cdz-midcolor);\n  }\n\n  .blogpost {\n    margin-top: 30px;\n    //...\n  }\n\n  .right-back {\n    display: block;\n    margin-top: 30px;\n    margin-left: auto;\n    margin-right: 0;\n    width: 150px;\n    .button {\n      width: 150px;\n      .button-inner {\n        white-space: normal;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
