import { Injectable, Optional, Inject } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

export class Message {
    id: number;
    messagecategory: any;
    user: any;
    touser: any;
    text: string;
    delivered: any;
    readTimestamp: any;
    created: any;
    random?: number;
}

/**
 * Usage: just a provider that can be globally injected to create a global messaging ...
 */

@Injectable()
export class MessageService {
    subject = new Subject<any>();

    constructor() {
    }

    messages() : Observable<any> {
        return this.subject.asObservable();
    }
}
