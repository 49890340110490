import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { MockService } from 'services/mock/mock.service';
import { MetabaseMocker, MockFlags } from '../metabase/metabase.mocker';
import * as registerdata from './registerdata.json';

@Injectable({
    providedIn: 'root'
  })
export class RegisterMocker extends MetabaseMocker {

    constructor(public mockProvider: MockService) {
        super(mockProvider, 'register', null, MockFlags.NONE);
    }

    handle(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        let observable: Observable<HttpEvent<any>> = null;
        if (url.startsWith('/api/' + this.kind + '/mdata')) {
            if (this.mockFlags & MockFlags.GET) {
                let a = clone(registerdata);
                a.id = url.substr(url.lastIndexOf('/') + 1, url.length)
                let d = [a];

                window.localStorage.setItem('tcdz_mock_' + this.kind, JSON.stringify(d));

                observable = new Observable(resp => {
                    resp.next(new HttpResponse({
                        status: 200,
                        body: registerdata
                    }));
                    resp.complete();
                });
                console.log('Mocker: mocked GET ' + this.kind + '/meta', request);
            }
        }
        return observable;
    }
}
