import { Component, Input, Optional, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { MetaGroup, MetaField, DataGroup, MetaId, MetaValue } from 'metagroup';

@Component({
  selector: 'meta-group',
  templateUrl: 'meta-group.html',
  styleUrls: ['meta-group.scss']
})
export class MetaGroupComponent implements OnChanges {
  @Input() group: MetaGroup;
  @Input() dataGroup: DataGroup|DataGroup[];
  @Input() itemClosed?: boolean; // whether the whole group (or group-list) is opened or closed - actively from the parent context (possibly in combination with navigation)
  @Input() needsToBeOpened?: boolean; // whether the group (or each item of a group-list) needs to be opened for being edited - passively from within the meta group component itself
  @Input() fieldsNeedToBeOpened?: boolean; // whether all fields in this group need to be opened by clicking on the label before displaying the respective input (and its value)
  @Input() cannotAdd?: boolean; // only for listtype == 'array' or 'set': if this input is truthy, no new array elements / set elements can be added
  @Input() activationUI?: boolean; // only for listtype == "array' or 'set': if truthy, UI for activating/deactivating elements is shown and items are listed grouped by this criterion
  @Input() activationHideActivated?: boolean; // only if activationUI is truthy: hide all activated datagroups
  @Input() activationHideDeactivated?: boolean; // only if activationUI is truthy: hide all deactivated datagroups
  @Input() showExpandButton?: boolean; // show a button that adds/removes an expanded class
  @Input() displayCheckBoxAsToggle?: boolean;
  @Output() change = new EventEmitter<any>();
  @Output() gotoeditpageevent = new EventEmitter<any>();
  setField?: MetaField;
  setFieldUnusedValues: MetaValue[];
  setFieldValueId?: MetaId = null;
  itemOpenedIndex: number;
  static itemOpenedGroupId: MetaId;
  expands: boolean[] = [];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty('group')) {
      if(this.group && this.group.listtype === 'set') {
        if(this.group.setFieldId) {
          let idx = this.group.fields.findIndex(field => {
            return field.id === this.group.setFieldId;
          });
          if(idx !== -1) {
            if(this.group.fields[idx].type === 'singleSelect') {
              this.setField = this.group.fields[idx];
              this.updateSetFieldValueList();
            } else {
              console.error('setFieldId \''+this.group.setFieldId+'\', field in group \''+this.group.id+'\' is not of type \'singleSelect\'; a group with listtype \'set\' must reference a field of type \'singleSelect\' in the group by \'setFieldId\'.');
            }
          } else {
            console.error('setFieldId \''+this.group.setFieldId+'\', field not found in group \''+this.group.id+'\'; a group with listtype \'set\' must reference a field of type \'singleSelect\' in the group by \'setFieldId\'.');
          }
        } else {
          console.error('setFieldId missing in group \''+this.group.id+'\'; a group with listtype \'set\' must reference a field of type \'singleSelect\' in the group by \'setFieldId\'.');
        }
      }
    }
    if(this.group && this.group.listtype === 'set' && this.setField && changes.hasOwnProperty('dataGroup')) {
      this.updateSetFieldValueList();
    }
  }

  //NOTE: this approach is quite hacky, but I don't find another approach getting the layout in question
  goImage(field: MetaField): boolean {
    return field.type === 'image' || field.type === 'imageReadonly';
  }
  hasImage() {
    return this.group && (this.group.fields.findIndex(field => {
      return field.type === 'image' || field.type === 'imageReadonly';
    }) !== -1);
  }

  fieldClass(field: MetaField): string {
    return 'field-' + field.id;
  }

  groupSelectFieldsValueClasses(dataGroup: DataGroup): string {
    if(!this.group || !dataGroup) return;
    let cls = [];
    this.group.fields.forEach(metaGroup => {
      if(metaGroup.type === 'singleSelect' && dataGroup.fieldData[metaGroup.id]) {
        cls.push('group-singleselect-' + metaGroup.id + '-value-' + <MetaId>dataGroup.fieldData[metaGroup.id]);
      }
    });
    if(this.hasImage()) {
      cls.push('has-image');
    }
    return cls.join(' ');
  }

  isList(group: MetaGroup) {
    return group.listtype === 'array' || group.listtype === 'set';
  }

  add(event?: any) {
    if(this.group.listtype === 'set') {
      let dg = new DataGroup();
      dg.fieldData[this.setField.id] = this.setFieldValueId;
      (<DataGroup[]>this.dataGroup).push(dg);
      this.updateSetFieldValueList();
      setTimeout(()=>{
        this.setFieldValueId = null; //reset it
      });
    } else {
      (<DataGroup[]>this.dataGroup).push(new DataGroup());
    }
    this.itemOpenIfNeeded((<DataGroup[]>this.dataGroup).length - 1, true);
    this.changed();
  }

  remove(idx: number, event?: any) {
    (<DataGroup[]>this.dataGroup).splice(idx,1); //TODO: Rückfrage?
    if(this.group.listtype === 'set') {
      this.updateSetFieldValueList();
    }
    this.itemCloseAll();
    this.changed();
  }

  setdeactivated(deactivated: boolean, dataGroup: DataGroup, event?: any) {
    dataGroup.isDeactivated = deactivated;
    this.itemCloseAll();
    this.changed();
  }

  updateSetFieldValueList() {
    if(this.group.listtype !== 'set' || !this.setField) return;
    this.setFieldUnusedValues = [];
    (<MetaValue[]>(this.setField.values)).forEach(value => {
      let idx = (<DataGroup[]>this.dataGroup).findIndex(group => {
        return group.fieldData[this.setField.id] === value.id;
      });
      if(idx === -1) {
        this.setFieldUnusedValues.push(value);
      }
    });
  }

  itemOpened(index: number): boolean {
    return (!this.needsToBeOpened) || (MetaGroupComponent.itemOpenedGroupId === this.group.id && this.itemOpenedIndex === index);
  }
  itemOpenIfNeeded(index: number, expand: boolean = false): void {
    if(this.needsToBeOpened) {
      MetaGroupComponent.itemOpenedGroupId = this.group.id;
      this.itemOpenedIndex = index;
      if(expand) {
        //this.expands = []; // collapse all others
        this.expands[index] = true;
      }
    }
  }
  itemCloseAll(): void {
    if(this.needsToBeOpened) {
      MetaGroupComponent.itemOpenedGroupId = undefined;
      this.itemOpenedIndex = undefined;
    }
    // this.expands = []; // collapse all
  }
  itemOpenedClasses(index: number): string {
    return (this.itemClosed ? 'group-closed' : 'group-opened') + ' ' + (this.itemOpened(index) ? 'item-opened' : 'item-closed') + ' ' + (this.expands[index] ? 'group-expanded' : 'group-collapsed');
  }

  itemActivatedClasses(dataGroup: DataGroup): string {
    if(this.activationUI) {
      if(dataGroup.isDeactivated) return 'group-deactivated';
      else return 'group-activated';
    } else {
      return '';
    }
  }
  activationShowSeparated(): boolean {
    return this.activationUI && !this.activationHideDeactivated && !this.activationHideActivated;
  }
  activationShowTogetherFiltered(dataGroup: DataGroup): boolean {
    return !this.activationUI || !((this.activationHideDeactivated && dataGroup.isDeactivated) || (this.activationHideActivated && !dataGroup.isDeactivated));
  }
  gotoeditpage(event:any) {
    this.gotoeditpageevent.emit(event);
  }

  changed() {
    this.change.emit(this.dataGroup);
  }

  expand(index: number, event: any) {
    if(event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    this.expands[index] = !this.expands[index];
  }

}
