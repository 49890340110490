import { NavController } from '@ionic/angular';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Tipp, TippGroup } from 'services/bewerbungstipps/bewerbungstipps';
import { ModalService } from 'services/modal/modal.service';
import { BewerbungstippViewMoreModalPage } from 'pages/bewerbungstipp-view-more-modal/bewerbungstipp-view-more-modal';

/**
 * Generated class for the BewerbungstippListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'bewerbungstipp-list',
  templateUrl: 'bewerbungstipp-list.html',
  styleUrls: ['bewerbungstipp-list.scss']
})
export class BewerbungstippListComponent {
  @Input() bewerbungstippGroup?: TippGroup;
  @Input() latest?: boolean;
  @Input() dashboardLayout?: boolean;
  @Input() showRightLinks?: boolean;

  @Output() moreClick: EventEmitter<Object> = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private navController: NavController
  ) {
  }

  openTippPage(tipp: Tipp) {
    let _open = (tipp: Tipp) => {
      this.navController.navigateForward('bewerbungstipp/' + this.bewerbungstippGroup.id + '/' + tipp.id);
    };
    if (tipp.needstobepaid && !tipp.hasbeenpaid) {
      let tipps: Tipp[] = [];
      if (this.bewerbungstippGroup && this.bewerbungstippGroup.list) {
        this.bewerbungstippGroup.list.forEach(tipp => {
          if (tipp.needstobepaid && !tipp.hasbeenpaid) {
            tipps.push(tipp);
          }
        });
      }
      this.modalService.showModal(
        {component: BewerbungstippViewMoreModalPage, componentProps: { bewerbungstipps: tipps, bewerbungstipp: tipp }, cssClass: 'modal-overlay'},
        (answer) => {
        if (answer && answer.data) { // could be another one of the tipps
          _open(answer);
        }
      });
    } else {
      _open(tipp);
    }
  }

  doMore(event) {
    if (event) {
      event.preventDefault();
    }
    this.moreClick.emit('');
  }
}
