/**
 * the object to use for f.qs parameters
 */
export class QSObject {
    _?: string; // of format "id,prop1,prop2,...", empty string means all props
    [propName:string]: QSObject|any; //name of prop is property path name, value is nested qs object; 
    //IMPORTANT: the type declaration of this property shall be considered QSObject (only), not QSObject|any - which is only set to work around an annoying Typescript language restriction
    static toString(qso: QSObject): string { //note: unfortunately, non-static method seems to get lost from instances somewhere in translation
        return JSON.stringify(qso);
    }
}