import { mockUrlMapping } from 'constant-variable';

export const environment = {
    name: 'prod',
    production: true,
    mock: false,
    apiUrl: ".",
    firebase: {
        apiKey: "AIzaSyCXT3b2AHoUe9NtAOI0YBJs6dP581ksclE",
        authDomain: "candooze-app.firebaseapp.com",
        databaseURL: "https://candooze-app.firebaseio.com",
        projectId: "candooze-app",
        storageBucket: "candooze-app.appspot.com",
        messagingSenderId: "915706844251",
        appId: "1:915706844251:web:0b159179d47fda6e695b7e",
        measurementId: "G-GXMG9J1ML5",
        vapidKey: "BJWnnoRmm8CyTIa5k74sQcSklhl_myDwwTToY6Pgkbjg2DgClPo3C3xEjABJrN50PuyqXP2YMVatLMrd1DBwvVo"
    }
};

mockUrlMapping
    .set('/api/coaches/meta', '/assets/mock/coaches/coaches-meta.json')
    .set('/api/coaches/filtermeta', '/assets/mock/coaches/coaches-filtermeta.json')
    .set('/api/checklist', '/assets/mock/checklist/checklist.json')
    .set('/api/user/accountdata/meta', '/assets/mock/user/user-accountdata-meta.json')
    .set('/api/contact/meta', '/assets/mock/contact/contact-meta.json')
    .set('/api/onboarding/files/internal/meta', '/assets/mock/contract-available-documentmeta.json')
    .set('/api/onboarding/files/candidate/meta', '/assets/mock/contract-uploaded-documentmeta.json')
