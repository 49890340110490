import { Component, OnInit } from "@angular/core";
import { File } from "services/file/file";
import { FileService } from "services/file/file.service";
import { ChapterElement } from "services/onlinecourse/chapter-element";
import { ChapterElementService } from "services/onlinecourse/chapter-element.service";
import { OnlineCourse } from "services/onlinecourse/onlinecourse";
import { OnlineCourseService } from "services/onlinecourse/onlinecourse.service";

@Component({
  selector: "page-online-course",
  templateUrl: "./online-course.html",
  styleUrls: ["./online-course.scss"],
})
export class OnlineCoursePage implements OnInit {
  descShowMore: boolean = false;
  course: OnlineCourse;

  constructor(
    public onlineCourseProvider: OnlineCourseService,
    public chapterElementProvider: ChapterElementService,
    public fileService: FileService,
  ) { }

  // TODO: id = 11 ist ein Dummy Wert, wird in einem späteren CR richtig implementiert
  ngOnInit(): void {
    console.log("ngOnInit OnlineCoursePage");
    this.onlineCourseProvider.get(11).then(
      (data) => {
        this.course = <OnlineCourse>data;
        this.fileService.getAsUrlForCss(this.course.foto.value).then(url => this.course.foto.url = url);
      },
      (error) => {
        console.error("failed to load coursedata", error);
      }
    );
  }

  setPlayed(chapterElement: ChapterElement): void {
    // flip played value
    chapterElement.played = !chapterElement.played;
    this.chapterElementProvider.update(chapterElement.id, chapterElement);
  }

  async openChapterElementFile(file: File) {
    if (!file.url) {
      file.url = await this.fileService.getAsUrl(file.value);
    }
    window.open(file.url);
  }
}
