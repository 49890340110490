import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ActivateService } from 'services/activate/activate.service';
import { SignupCredentials } from 'services/auth/auth-credentials';
import { DataPage } from 'src/app/metagroup';

/**
 * Generated class for the ActivatePage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-activate',
  templateUrl: 'activate.html',
  styleUrls: ['activate.scss']
})
export class ActivatePage implements OnInit {

  public registerData: SignupCredentials = {
    id: null,
    email: null,
    prename: "",
    name: "",
    password: "",
    password2: ""
  };
  
   public msgInfo: String;
   public mdata: DataPage;

  constructor( 
    public modalCtrl: ModalController,
    public activateService: ActivateService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.paramMap.subscribe( paramMap => {
      const params = { type: paramMap.get('type'), data: paramMap.get('data') }
      this.registerData.type = params.type || 'email';
  
      this.activateService.getActivateMdata(params).then(data => {
        this.mdata = data;
        this.registerData.id = this.mdata.id;
        if (this.mdata.email) {
          this.registerData.email = "" + this.mdata.email;
        }
        if (this.mdata.name) {
          this.registerData.name = "" + this.mdata.name;
        }
        if (this.mdata.prename) {
          this.registerData.prename = "" + this.mdata.prename;
        }
  
      }, err => {
        console.error('cannot load registerdata', err);
      });  
    })
  }

  submit() {
      this.activateService.signup(this.registerData).then(res => {
        console.log('APPCOM: login Success, res=', res);
      });
  }
}
