import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoachService } from 'services/coach/coach.service';
import { UserCoachService } from 'services/coach/user-coach.service';
import { MessageService } from 'services/message/message.service';
import { PagesService } from 'services/pages/pages.service';
import { DataPage } from 'src/app/metagroup';

@Component({
  selector: 'page-bewerbungsservice-settings',
  templateUrl: 'bewerbungsservice-settings.html',
  styleUrls: ['bewerbungsservice-settings.scss']
})
export class BewerbungsserviceSettingsPage implements OnDestroy, OnInit {
  public readonly pageName: string = "BewerbungsserviceSettingsPage";

  myCoach: DataPage;
  public subscription: Subscription;

  public pages: any[];

  constructor(
    private router: Router,
    public injector: Injector,
    public coachesSettingsProvider: UserCoachService,
    public coachesProvider: CoachService,
    public pagesProvider: PagesService,
    public message: MessageService,
  ) {
    this.pages = pagesProvider.getPage('AccountPage').sub;
    this.subscription = this.message.messages().subscribe(msg => {
      if (msg && msg.changedCoach) {
        this.myCoach = msg.changedCoach;
      }
    });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungsserviceSettingsPage');
    this.coachesSettingsProvider.getSingleton().then(data => {
      console.log('COACH', data);
      if (data && data.id) {
        this.myCoach = data;
      } else {
        console.error('got no coach');
      }
    }, error => {
      console.error('cannot get coach', error);
    });
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter BewerbungsserviceSettingsPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungsserviceSettingsPage');
  }

}
