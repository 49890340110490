import { Injectable } from "@angular/core";
import { CometChat } from "@cometchat-pro/chat";
import { ApiService } from 'services/api/api.service';
import { AuthResponse } from 'services/auth/auth-response.interface';
import { AuthService } from 'services/auth/auth.service';
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { FCMToken, PushNotificationService } from 'services/push-notification/push-notification.service';
import { BranchConfigService } from '../branch-config/branch-config';
import { UserdataService } from "../userdata/userdata.service";

// comatchat widget, this will be replaced by the actual object from "cometchatwidget_v3.js" on compile
declare const CometChatWidget: any;

export class CometChatData {
  appId: string;
  region: string;
  authToken: string;
  widgetId: string;
}

@Injectable({
  providedIn: 'root'
})
export class CometchatService extends ApiService<any> {

  public isReady: Promise<any>;

  private listenerCounter: number = 10000;
  private cometChatData: CometChatData;
  private launched: boolean = false;
  private widgetLaunched: boolean = false;
  private resolveIsReady: any;

  constructor(
    private userdataProvider: UserdataService,
    private branchConfigProvider: BranchConfigService,
    private authService: AuthService,
    private pushNotificationService: PushNotificationService,
    private events: EventsService,
  ) {
    super('chat/cometchat');
    this.isReady = new Promise((resolve, reject) => {
      this.resolveIsReady = resolve;
    });

    // Register with AuthService for login and logout events
    this.authService.registerLoginCallback((user: AuthResponse) => this.startCometchat(user));
    this.authService.registerLogoutCallback((user: AuthResponse) => this.logout(user));
  }

  /**
   * Checks branch if cometchat is enabled.
   * @returns a promise containint true if cometchat is enabled
   */
  public async isEnabled(): Promise<boolean> {
    let config = await this.branchConfigProvider.isReady;
    return !!config && !!config.features && config.features.nachrichten;
  }

  /**
   * Initializes cometchat and loggs the current user in. This does nothing if there is no logged in user.
   * This is the preferred method to call if you want to initialize cometchat.
   * If you only want to check if cometchat is already initialized but don't want to initialize it again, just call the isReady-promise.
   * 
   * @param authData Optional authentication data, if not provided will use authService.user
   */
  public async startCometchat(authData: AuthResponse): Promise<any> {
    const enabled = await this.isEnabled();

    if (!!authData && false == this.launched && enabled) {
      console.debug('COMETCHAT-TS: Starting cometchat...');
      this.launched = true;
      await this.getCometchatData();
      await this.init();
      await this.login(authData);
      this.listenForNewMessages();
      this.resolveIsReady();
    }
  }

  /**
   * Gets cometchat data for init and login.
   * This method waits for branch.io to return the api config, so the cometChatData can be requested.
   * @returns cometchat data 
   */
  private getCometchatData(): Promise<any> {
    if (!this.cometChatData || !this.cometChatData.appId || !this.cometChatData.authToken || !this.cometChatData.region) {
      return this.httpGet('data', '')
        .then(data => {
          this.cometChatData = data;
          console.debug('COMETCHAT-TS: get cometchat data success', data);
        })
        .catch(error => {
          console.debug('COMETCHAT-TS: get cometchat data error', error);
        });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Init cometchat
   * see https://www.cometchat.com/docs/angular-chat-ui-kit/overview#configure-cometchat-inside-your-app
   */
  private init(): Promise<any> {
    const appID = this.cometChatData.appId;
    const region = this.cometChatData.region;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .autoEstablishSocketConnection(true)
      .build();
    return CometChat.init(appID, appSetting).then(
      () => {
        console.debug('COMETCHAT-TS: Initialization completed successfully');
      },
      (error) => {
        console.debug('COMETCHAT-TS: Initialization failed with error:', error);
      }
    );
  }

  private login(authData: AuthResponse): Promise<CometChat.User> {
    const uid = authData.userid;

    if (!this.cometChatData.authToken || !uid) {
      console.debug("COMETCHAT-TS: login stopped, because either authToken or uid was not set", this.cometChatData.authToken, uid);
      return Promise.resolve(<CometChat.User>{});
    }

    return CometChat.login(this.cometChatData.authToken).then(
      user => {
        console.debug('COMETCHAT-TS: login success', { user });
        this.pushNotificationService.tokenReady.then((token: FCMToken) => {
          console.debug('COMETCHAT-TS: registering firebase token for cometchat: ', token.token);
          CometChat.registerTokenForPushNotification(token.token);
        });
        return user;
      },
      error => {
        console.error('COMETCHAT-TS: login error', { error });
        throw error;
      }
    );
  }

  private listenForNewMessages() {
    let newMessageListenerID: string = "cometchat-new-message-listener";
    CometChat.addMessageListener(
      newMessageListenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          this.events.publish(EventKey.COMETCHAT_MESSAGECOUNT);
        },
        onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
          this.events.publish(EventKey.COMETCHAT_MESSAGECOUNT);
        },
        onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
          this.events.publish(EventKey.COMETCHAT_MESSAGECOUNT);
        }
      })
    );
  }

  public async getUnreadMessagesCount(): Promise<any> {
    const enabled = await this.isEnabled();
    if (false == enabled) {
      return;
    }

    await this.isReady;
    let count = 0;
    // cometchat returns an object with two arrays
    const messages = await CometChat.getUnreadMessageCount();
    if (!!messages) {
      // the groups array contains key (group id) value (number of unread messages in this group) pairs
      const groups = messages['groups'];
      if (!!groups) {
        Object.keys(groups).forEach((key, index) => count += groups[key]);
      }
      // the users array contains key (conversation partner user id) value (number of unread messages in this conversation) pairs  
      const users = messages['users'];
      if (!!users) {
        Object.keys(users).forEach((key, index) => count += users[key]);
      }
    }
    return count;
  }

  /**
   * Launch the cometchat widget (overlay)
   */
  public launchWidget(): void {
    if (false == this.widgetLaunched) {
      const appID = this.cometChatData.appId;
      const region = this.cometChatData.region;
      const authToken = this.cometChatData.authToken;
      const widgetId = this.cometChatData.widgetId;

      CometChatWidget.init({
        "appID": appID,
        "appRegion": region,
        "authToken": authToken,
      }).then(response => {
        CometChatWidget.launch({
          "widgetID": widgetId,
          "target": "#cometchat",
          "docked": "true",
          "alignment": "right",
          "roundedCorners": "true",
          "height": "450px",
          "width": "400px"
        });
        this.widgetLaunched = true;
      }, error => {
        console.error("CometChat Widget Initialization failed with error:", error);
      });
    }
  }

  private createUser(authData: AuthResponse): Promise<CometChat.User> {
    const uid = authData.userid;

    if (!this.cometChatData.authToken || !uid) {
      console.debug("COMETCHAT-TS: createUser stopped, because either authToken or uid was not set", this.cometChatData.authToken, uid);
      return Promise.resolve(<CometChat.User>{});
    }

    var user = new CometChat.User(uid);
    user.setName(this.userdataProvider.userdata.name);

    return new Promise<CometChat.User>((resolve, reject) => {
      return this.branchConfigProvider.isReady.then(() => {
        this.httpPost('user/create', '')
          .then(user => {
            resolve(user);
            console.debug("COMETCHAT-TS: create user success", { user });
            return user;
          })
          .catch(error => {
            reject(error);
            console.error("COMETCHAT-TS: create user error ", { error });
            throw error;
          });
      });
    });
  }

  /**
   * Logs out from cometchat
   * 
   * @param authData Optional authentication data, if not provided it's just used for callback compatibility
   */
  public async logout(authData?: AuthResponse): Promise<any> {
    const enabled = await this.isEnabled();
    if (false == enabled) {
      return;
    }

    // Only attempt logout if we're currently logged in
    if (this.launched) {
      CometChat.logout()
        .then(result => {
          console.debug("COMETCHAT-TS: logout success", { result });
          this.launched = false;
          this.cometChatData = null;
        })
        .catch(error => {
          console.error("COMETCHAT-TS: logout error", { error });
          this.launched = false;
          this.cometChatData = null;
        });
    }

    // Also ensure the widget is logged out if it was launched
    if (this.widgetLaunched) {
      this.logoutWidget();
    }
  }

  public logoutWidget(): void {
    CometChatWidget.logout()
      .then(result => {
        console.debug("COMETCHAT-TS: widget logout success", { result });
        this.widgetLaunched = false;
        this.cometChatData = null;
      })
      .catch(error => {
        console.error("COMETCHAT-TS: widget logout error", { error });
        this.widgetLaunched = false;
        this.cometChatData = null;
      });
  }

  //NOTE: currently only supports messages of type TextMessage sending to an individual.
  private privatesendMessage(receiverID: string, messageText: string): Promise<CometChat.BaseMessage> {
    var receiverType = CometChat.RECEIVER_TYPE.USER;
    var textMessage = new CometChat.TextMessage(receiverID, messageText, receiverType);
    return CometChat.sendMessage(textMessage).then(
      message => {
        console.debug("COMETCHAT-TS: messageSend success", message);
        return message;
      },
      error => {
        console.error("COMETCHAT-TS: messageSend error", error);
        throw error;
      }
    );
  }
  //NOTE: currently only supports messages of type TextMessage
  private addMessageListener(listener: (message: CometChat.TextMessage) => void): string {
    var listenerID = "TCDZCCLISTENER_" + (this.listenerCounter++);
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: message => {
          console.log("COMETCHAT-TS: message received", message);
          listener(message);
        }
      })
    );
    return listenerID;
  }
  private removeMessageListener(listenerID: string): void {
    CometChat.removeMessageListener(listenerID);
  }
  private getContacts(): Promise<CometChat.User[]> {
    var usersRequest = new CometChat.UsersRequestBuilder()
      .setLimit(30) // TODO <- limit for paging
      .build();
    return usersRequest.fetchNext().then(
      userList => {
        console.debug("COMETCHAT-TS: getContacts success", userList);
        return userList;
      },
      error => {
        console.error("COMETCHAT-TS: getContacts error", error);
        throw error;
      }
    );
  }
  private getUser(userId: string): Promise<CometChat.User> {
    return CometChat.getUser(userId).then(
      user => {
        console.debug("COMETCHAT-TS: getUser success", user);
        return user;
      },
      error => {
        console.error("COMETCHAT-TS: getUser error", error);
        console.error("Userid " + userId);
        throw error;
      }
    );
  }
  private getConversations(): Promise<CometChat.Conversation[]> {
    var conversationsRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(30) // TODO <- limit for paging
      .build();
    return conversationsRequest.fetchNext().then(
      conversationList => {
        console.debug("COMETCHAT-TS: getConversations success", conversationList);
        return conversationList;
      },
      error => {
        console.error("COMETCHAT-TS: getConversations error", error);
        throw error;
      }
    );
  }
  // NOTE: only supports conversations with individual users
  private getConversation(userId: string): Promise<CometChat.Conversation> {
    return CometChat.getConversation(userId, 'user').then(
      conversation => {
        console.debug('COMETCHAT-TS: getConversation success', conversation);
        return conversation;
      }, error => {
        console.error('COMETCHAT-TS: getConversation error', error);
        throw error;
      }
    );
  }
  // NOTE: only loads text messages
  private getConversationMessages(userId: string): Promise<CometChat.BaseMessage[]> {
    var messagesRequest = new CometChat.MessagesRequestBuilder()
      .setLimit(30) // TODO <- limit for paging
      .setTypes([CometChat.MESSAGE_TYPE.TEXT])
      .setUID(userId)
      .build();
    return messagesRequest.fetchPrevious().then(
      messages => {
        console.debug("COMETCHAT-TS: getConversationMessages success", messages);
        return messages;
      },
      error => {
        console.error("COMETCHAT-TS: getConversationMessages error", error);
        throw error;
      }
    );
  }
  // NOTE: only considers chats with individuals
  private getUnreadMessageCounts(): Promise<Object> {
    return CometChat.getUnreadMessageCountForAllUsers().then(
      array => {
        console.debug("COMETCHAT-TS: getUnreadMessageCount success", array);
        return array;
      },
      error => {
        console.error("COMETCHAT-TS: getUnreadMessageCount error", error);
        throw error;
      }
    );
  }
  // TODO: this seems to be important, I do not understand the use yet, so I put the method here as a note
  private getConversationFromMessage(message: CometChat.BaseMessage): Promise<CometChat.Conversation> {
    return CometChat.CometChatHelper.getConversationFromMessage(message).then(
      conversation => {
        console.debug("COMETCHAT-TS: getConversationFromMessage success", conversation);
        return conversation;
      },
      error => {
        console.error("COMETCHAT-TS: getConversationFromMessage error", error);
        throw error;
      }
    );
  }
}
