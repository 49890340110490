import { AuthOptions } from "services/auth/auth-config";

const client_id = "candooze";
const client_secret = "yb7vt88tv7fj1hv9ph5i4t2xlm87eu0l";

export const ConstantVariable: any = {

  appId: "de.heroes.candooze",

  // xmppOptions: {
  //   // TODO: protocol can be ws:, wss:, xmpp:, xmpps: - what does the Jitsi Prosody talk, and on which port? 5280 5347 5222
  //   //service: "wss://meet.candooze.com:5280/xmpp-websocket",
  //   //service: "ws://94.16.108.53:5280/xmpp-websocket",
  //   //service: "xmpp://meet.candooze.com:5347/xmpp",
  //   service: "wss://jabber.hot-chilli.net:5281/xmpp-websocket",
  //   //domain: "localhost",
  //   //domain: "jabber.hot-chilli.net",
  //   resource: "example", // example: "laptop" ... ?
  //   //username: "username",
  //   //password: "password",
  // },

  firebaseConfig: {
    apiKey: "AIzaSyCXT3b2AHoUe9NtAOI0YBJs6dP581ksclE",
    authDomain: "candooze-app.firebaseapp.com",
    databaseURL: "https://candooze-app.firebaseio.com",
    projectId: "candooze-app",
    storageBucket: "candooze-app.appspot.com",
    messagingSenderId: "915706844251"
  },

  //TAF base api url
  //NOTE: all HttpClient calls that go to an url starting with '/api/' go to this url instead:
  /* THIS IS MY DUMMY till the candooze server works:
  apiBaseUrl: "http://localhost:3000/v1/",   // <----------------- still needed for loading the menu pages
  apiAuthRegexp: /(^\/api\/)|(^http:\/\/localhost:3000\/v1\/)/i,   // <----------------- still needed for loading the menu pages
  */
  /* THIS WILL BE (DO NOT DELETE THIS COMMENT!!!!!): */
  //PROD???:
  //apiBaseUrl: "http://h1c.stage.heroes.eu/rest/",
  //apiAuthRegexp: /(^\/api\/)|(^http:\/\/h1c\.stage\.heroes\.eu\/rest\/)/i,
  //DEV:
  //apiBaseUrl: "http://localhost:8080/bms/rest/",
  //apiAuthRegexp: /(^\/api\/)|(^http:\/\/localhost:8080\/bms\/rest\/)/i,
  apiBaseUrl: null, //   "http://localhost:8080/bms/rest/api/candooze/mapping/v1/",
  apiOptions: {
    postOnlyModifications: false
  },
  apiAuthRegexp: null, //   /(^\/api\/)|(^http:\/\/localhost:8080\/bms\/rest\/api\/candooze\/mapping\/v1\/)/i,
  apiAuthOptions: { // optional parameters to override paths the AuthProvider users
    preventCookie: true,
    encodeAs: 'application/x-www-form-urlencoded',
    tokenProperty: 'access_token',
    tokenRefreshProperty: 'refresh_token',
    tokenRefreshParameters: {
      grant_type: "refresh_token",
      client_id: client_id,
      client_secret: client_secret,
    },
    tokenHeader: 'Authorization',
    tokenPrefix: 'Bearer ',
    urls: {
      sessionUser: 'user/session', // ... TODO
      login: 'oauth/token', // got that from Sebastian, other urls guessed for now ...
      logout: 'user/logout', // seen that from 'ags'
      signup: 'registration/register',
      passwordReset: 'user/cdz/reset',
      passwordResetChange: 'user/cdz/reset/update',
      passwordChange: 'user/cdz/change',
    },
    dashboardPage: "", // by default empty, but is declared to override in app.component.ts
  } as AuthOptions,
  authParameters: {
    grant_type: "password",
    client_id: client_id,
    client_secret: client_secret,
  },
  /* ... */
  remoteI18nPrefix: '/api/i18n/generate?prefix=candooze&lang=', //TODO
  remoteI18nSuffix: '', //TODO,

 //showAllFeatures: true,
 //TODO: true ONLY FOR DEBUGGING - COMMENT THIS LINE OUT
};

export const mockUrlMapping: Map<string, string> = new Map();
// values see: environment.mock.ts