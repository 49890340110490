import { Component, Injector, OnInit } from '@angular/core';

@Component({
  selector: 'page-recherche',
  templateUrl: 'recherche.html',
  styleUrls: ['recherche.scss']
})
export class RecherchePage implements OnInit {
  public readonly pageName: string = "RecherchePage";

  constructor(
    public injector: Injector,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit RecherchePage');
  }

}
