import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'services/routing/routing.service';

@Component({
  selector: 'app-dashboard-profile',
  templateUrl: './dashboard-profile.page.html',
  styleUrls: ['./dashboard-profile.page.scss'],
})
export class DashboardProfilePage implements OnInit {

  constructor(
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.routingService.setRootPage();
  }

}
