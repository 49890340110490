import { Location } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiFilter } from 'services/api/api-filter';
import { ApiListWrapper } from 'services/api/api-list-wrapper';
import { ApiService } from 'services/api/api.service';
import { FileService } from 'services/file/file.service';
import { OrderProduct } from 'services/order/order';
import { ConstantRoute } from 'src/app/constant-routes';
import { PaymentMethod } from './payment-method';
import { Product } from './product';
import { ProductType } from './product-type';
import { PlatformService } from 'services/platform/platform.service';
import { AuthService } from 'services/auth/auth.service';
import { ConstantVariable } from 'constant-variable';

/**
 * This service can and should be used to load and send product information for any product.
 */
@Injectable({
  providedIn: 'root'
})
export class ProductService extends ApiService<Product> {

  constructor(
    private fileService: FileService,
    private decimalPipe: DecimalPipe,
    private router: Router,
    private location: Location,
    private platformService: PlatformService,
    private authService: AuthService,
  ) {
    super('product');
  }

  /**
   * Retrieves a product by its key and optionally applies additional options.
   * If the product has an image, it converts the image value to a URL.
   *
   * @param {string | number} key - The key of the product to retrieve.
   * @param {any} [options] - Optional parameters to customize the retrieval.
   * @returns {Promise<any>} A promise that resolves to the retrieved product data.
   */
  public get(key: string | number, options?: any): Promise<Product> {
    return super.get(key, options).then((product: Product) => {
      return this.prepareForDisplay(product);
    });
  }

  /**
   * Retrieves product recommendations based on the specified types and product ID.
   *
   * @param {Array<ProductType>} [types=[]] - An array of product types to filter recommendations.
   * @param {number} [id=null] - The ID of the product for which recommendations are sought.
   * @param {number} [maxResults=3] - The maximum number of recommendations to return.
   * @returns {Promise<ApiListWrapper<Product>>} A promise that resolves to a list of recommended products.
   */
  public getRecommendations(types: Array<ProductType> = [], id: number = null, maxResults: number = 3): Promise<ApiListWrapper<Product>> {
    let filter: ApiFilter = new ApiFilter({ types: types, id: id }, 0, maxResults)
    let params: HttpParams = this.buildApiFilterParams(filter);
    return this.httpGet('recommendations', '', { params }).then((data: ApiListWrapper<Product>) => {
      return this.prepareListForDisplay(data);
    });
  }

  /**
   * Retrieves a list of products with optional filtering.
   * 
   * @param filter - An optional filter object to apply to the product list.
   * @returns A promise that resolves to an `ApiListWrapper` containing the list of products.
   * 
   * The method performs the following operations:
   * - Calls the `super.getList` method with the provided filter.
   * - Processes the returned data to extract the list of products.
   * - For each product in the list:
   *   - If the product has an image, resolves the image URL using `fileService.getAsUrl`.
   *   - Strips HTML tags from the `description` property.
   */
  public getList(filter?: ApiFilter | any): Promise<ApiListWrapper<Product>> {
    return super.getList(filter).then((data: ApiListWrapper<Product>) => {
      return this.prepareListForDisplay(data);
    });
  }

  /**
   * Initiates the booking process for a given product by opening a payment URL in the same window.
   *
   * @param product - The product to be booked. It should contain the type and id properties.
   */
  public book(product: Product): void {
    const paymentUrl = new URL(this.configProvider.config.apiBaseUrl + '/paid-good/add/' + product.type + '/' + product.id);
    const successPath = this.location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree([ConstantRoute.FULL_ORDER.replace(ConstantRoute.PLACEHOLDER_ID, '')])));
    const cancelPath = this.location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree([ConstantRoute.FULL_PRODUCT.replace(ConstantRoute.PLACEHOLDER_ID, product.id.toString())])))
    if (this.platformService.isNative()) {
      paymentUrl.searchParams.append('success_url', ConstantVariable.appId + ':///' + successPath);
      paymentUrl.searchParams.append('cancel_url', ConstantVariable.appId + ':///' + cancelPath);
    } else {
      paymentUrl.searchParams.append('success_url', window.location.origin + '/' + successPath);
      paymentUrl.searchParams.append('cancel_url', window.location.origin + '/' + cancelPath);
    }
    paymentUrl.searchParams.append('access_token', this.authService.user.access_token);

    window.open(paymentUrl, "_self");
  }

  /**
   * Navigates dynamically based on the accessibility of the product.
   * 
   * If the product is accessible, it navigates to the product page.
   * Otherwise, it navigates to the product detail page.
   * 
   * @param {Product} product - The product to navigate to.
   */
  public navigateDynamically(product: Product): void {
    if (product?.accessible) {
      console.debug('ROUTING: Product is accessible, routing to detail page');
      this.navigateToProduct(product);
    } else {
      console.debug('ROUTING: Product is not accessible, routing to product page');
      this.navigateToDetail(product);
    }
  }

  /**
   * Navigates to the product detail page for the given product.
   * Logs a debug message indicating that the product is accessible and routing to the detail page.
   * 
   * @param {Product} product - The product object containing the ID to navigate to. 
   * @returns {void}
   */
  public navigateToProduct(product: Product | OrderProduct): void {
    switch (product?.type) {
      case ProductType.PODCAST:
        this.router.navigate(['/' + ConstantRoute.FULL_PODCAST.replace(ConstantRoute.PLACEHOLDER_ID, product?.id?.toString())]);
        break;
      case ProductType.VIDEO:
        this.router.navigate(['/' + ConstantRoute.FULL_VIDEO.replace(ConstantRoute.PLACEHOLDER_ID, product?.id?.toString())]);
        break;
      case ProductType.WEBINAR:
        console.warn('ROUTING: Webinar product type is not supported');
        break;
      case ProductType.ADVICE:
        this.router.navigate(['/' + ConstantRoute.FULL_ADVICE.replace(ConstantRoute.PLACEHOLDER_ID, product?.id?.toString())]);
        break;
      case ProductType.COURSE:
        console.warn('ROUTING: Course product type is not supported');
        break;
    }
  }

  /**
   * Navigates to the product detail page for the given product.
   * Logs a debug message indicating that the product is not accessible and routing to the product page.
   *
   * @param {Product} product - The product object containing the ID to navigate to.
   * @returns {void}
   */
  public navigateToDetail(product: Product): void {
    this.router.navigate(['/' + ConstantRoute.FULL_PRODUCT.replace(ConstantRoute.PLACEHOLDER_ID, product?.id?.toString())]);
  }

  /**
   * Get the payment method textkey for a product.
   * @param paymentMethod the paymentMethod to convert
   * @returns a string that is a textkey
   */
  public convertToPaymentMethodTextkey(paymentMethod: PaymentMethod): string {
    switch (paymentMethod) {
      case PaymentMethod.PAYPAL: {
        return 'service.product.payment.paypal'
      }
      case PaymentMethod.FREE: {
        return 'service.product.payment.free'
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Get the product type textkey for a product.
   * @param productType the productType to convert
   * @returns a string that is a textkey
   */
  public convertToProductTypeTextkey(productType: ProductType): string {
    return productType ? 'service.product.type.' + productType?.toLowerCase() : '';
  }

  /**
   * Converts a product object to a price label text key based on its properties.
   *
   * @param {Product} product - The product object to convert.
   * @returns {string} The price label text key.
   */
  public convertToPriceLabelTextkey(product: Product): string {
    // products that can be booked multiple times
    if (this.canBeBookedMultipleTimes(product)) {
      if (product?.available) {
        if (product?.payment?.hasToBeBooked) {
          if (!product?.payment?.price) {
            return 'service.product.price.hasToBeBooked.free'; // available && has to be booked && free
          } else {
            return 'service.product.price.hasToBeBooked.unpaid'; // available && has to be booked && has price
          }
        }
      } else {
        return 'service.product.price.notAvailable'; // not available
      }
    }
    // products that can only be booked once
    else {
      if (product?.available) {
        if (product?.payment?.hasToBeBooked) {
          if (Array.isArray(product?.orders) && product?.orders?.length) {
            return 'service.product.price.hasToBeBooked.paid'; // available && has to be booked && paid
          } else {
            if (!product?.payment?.price) {
              return 'service.product.price.hasToBeBooked.free'; // available && has to be booked && unpaid && free
            } else {
              return 'service.product.price.hasToBeBooked.unpaid'; // available && has to be booked && unpaid && has price
            }

          }
        } else {
          return this.getPriceCanOpenTextkey(product); // available && does not have to be booked
        }
      } else {
        if (Array.isArray(product?.orders) && product?.orders?.length) {
          return this.getPriceCanOpenTextkey(product); // not available && paid
        } else {
          return 'service.product.price.notAvailable'; // not available && not paid
        }
      }
    }
    return '';
  }

  /**
   * Get the price text for a product.
   * @param product the product to convert
   * @returns a string that is the actual price
   */
  public convertToPriceText(product: Product): string {
    // available && has to be booked && has price
    if (product?.available && product?.payment?.hasToBeBooked && product?.payment?.price) {
      // products that can be booked multiple times
      if (this.canBeBookedMultipleTimes(product)) {
        return product?.payment?.currency + ' ' + this.decimalPipe.transform(product?.payment?.price, '1.2-2') // return currency and price 
      }
      // products that can only be booked once
      else {
        if (false == Array.isArray(product?.orders) || product?.orders?.length == 0) {
          return product?.payment?.currency + ' ' + this.decimalPipe.transform(product?.payment?.price, '1.2-2') // return currency and price 
        }
      }
    }
    // fallback
    return '';
  }

  /**
   * Removes HTML tags from the description of a given product.
   *
   * @param {Product} product - The product object whose description needs to be sanitized.
   * @returns {Product} - The product object with HTML tags removed from its description.
   */
  public removeHtmlTags(product: Product): Product {
    if (product?.description) {
      product.description = product.description && product.description.replace(/<[^>]*>/g, '');
    }
    return product;
  }

  /**
   * Retrieves the text key for the "can open" price message based on the product type.
   *
   * @param {Product} product - The product for which the text key is to be retrieved.
   * @returns {string} The text key corresponding to the product type.
   *
   * @remarks
   * The text key is used to fetch localized messages for different product types.
   * If the product type is not recognized, the default text key for podcasts is returned.
   */
  private getPriceCanOpenTextkey(product: Product): string {
    switch (product?.type) {
      case ProductType.ADVICE:
        return 'service.product.price.canOpen.article';
      case ProductType.PODCAST:
        return 'service.product.price.canOpen.podcast';
      case ProductType.VIDEO:
        return 'service.product.price.canOpen.video';
      case ProductType.WEBINAR:
        return 'service.product.price.canOpen.webinar';
      case ProductType.COURSE:
        return 'service.product.price.canOpen.course';
      default:
        return 'service.product.price.canOpen.podcast';
    }
  }

  /**
   * Determines if a product can be booked multiple times.
   *
   * @param product - The product to check.
   * @returns `true` if the product type is either `COACHING` or `SERVICE`, otherwise `false`.
   */
  private canBeBookedMultipleTimes(product: Product): boolean {
    return product?.type === ProductType.COACHING || product?.type === ProductType.SERVICE;
  }

  /**
   * Prepares a product for display by handling its image, description, and URL.
   *
   * @private
   * @param {Product} product - The product to prepare for display.
   * @returns {Product} - The prepared product.
   */
  private prepareForDisplay(product: Product): Product {
    this.handleImage(product);
    return product;
  }

  /**
   * Prepares a list of products for display by handling their images, descriptions, and URLs.
   *
   * @private
   * @param {ApiListWrapper<Product>} data - The data containing the list of products to prepare for display.
   * @returns {ApiListWrapper<Product>} - The prepared list of products.
   */
  private prepareListForDisplay(data: ApiListWrapper<Product>): ApiListWrapper<Product> {
    let list = (data && data.list) ? data.list : [];
    if (list && list.length > 0) {
      list.forEach(product => {
        this.prepareForDisplay(product);
      });
    }
    return data;
  }

  /**
   * Handles the image of a product by converting its image value to a URL.
   *
   * @private
   * @param {Product} product - The product whose image is to be handled.
   */
  private handleImage(product: Product): void {
    if (product?.image?.value) {
      this.fileService.getAsUrl(product.image.value).then(url => {
        product.image.url = url;
        return product;
      });
    }
  }
}
