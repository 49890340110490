import { InjectionToken } from '@angular/core';

// Configuration object to use for optionally configuring AuthProvider and AuthInterceptor for servers not conforming to the TAF standard
export class AuthOptions {
    public preventCookie?: boolean; // if true, only the auth token header will be set, not the cookie.
    public authGuardPage?: string;
    public dashboardPage?: string;
    public encodeAs?: string; // optional MIME type affecting the encoding of data sent to server (for currently supported types see the provider)
    public tokenProperty?: string; // optional property name of the token
    public tokenRefreshProperty?: string|false; // optional property name of refresh token (for OAuth flow)
    public tokenRefreshParameters?: any; // an object containing parameter properties for the refresh call
    public tokenHeader?: string;
    public tokenPrefix?: string;
    public urls?: { // optional non-TAF-standard
        login?: string,
        logout?: string,
        sessionUser?: string,
        signup?: string,
        passwordReset?: string,
        passwordChange?: string,
        passwordResetChange?: string,
    };
    public logoutOnError?: { // these optional parameters affect logout behavior after authentication related errors; NOTE: they do NOT affect OAuth implementation as that is standardized
        invalidToken?: boolean,
        invalidPermission?: boolean,
        revalidateSessionFirst?: boolean,
    };
    public appleSignIn?: {
        clientId?: string;
        redirectUri?: string;
    }

    static mergeAuthOptions (authOptions: AuthOptions): AuthOptions {
        let defaultAuthOptions: AuthOptions = {
            preventCookie: false,
            authGuardPage: 'LoginPage',
            dashboardPage: 'DashboardPage',
            encodeAs: 'application/json',
            tokenProperty: 'authtoken',
            tokenRefreshProperty: false,
            tokenRefreshParameters: null,
            tokenHeader: 'X-Auth-Token',
            tokenPrefix: '',
            urls: {
                login: 'login',
                logout: 'logout',
                sessionUser: 'session/user',
                signup: 'signup',
                passwordReset: 'password/reset',
                //passwordChange: 'password/change',
                passwordChange: 'password/update',
                passwordResetChange: 'password/update/token',
            },
            logoutOnError: {
                invalidToken: true,
                invalidPermission: true,
                revalidateSessionFirst: true,
            },
            appleSignIn: {
                clientId: undefined,
                redirectUri: undefined
            }
        };
        let options = authOptions || {};
        let urls = options.urls || {};
        options.urls = { ...defaultAuthOptions.urls, ...urls };
        let logoutOnError = options.logoutOnError || {};
        options.logoutOnError = { ...defaultAuthOptions.logoutOnError, ...logoutOnError };
        let appleSignIn = options.appleSignIn || {};
        options.appleSignIn = { ...defaultAuthOptions.appleSignIn, ...appleSignIn };
        return { ...defaultAuthOptions, ...options };
    }
}

export const ApiAuthRegexp = new InjectionToken<RegExp>('api-auth-regexp');
/*

App *has* to use:

    in the App add to @NgModule ... providers: [ ... { provide: ApiAuthRegexp, useValue: ... the regular expression ... } ... ]

 */

export const ApiAuthOptions = new InjectionToken<AuthOptions>('api-auth-options');
/*

App *can* use to optionally configure the AuthProvider for Servers with non-standard behavior (Used for Candooze)

    and in the App add to @NgModule ... providers: [ ... {provide: ApiAuthOptions, useValue: ConstantVariable.apiAuthOptions } ... ]

 */
