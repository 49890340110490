import { Component, Injector, OnInit } from '@angular/core';

@Component({
  selector: 'page-agb',
  templateUrl: 'terms.html',
  styleUrls: ['terms.scss']
})
export class TermsPage implements OnInit {
  public readonly pageName: string = "TermsPage";

  constructor(
    public injector: Injector,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit TermsPage');
  }

}
