
export class ConstantRoute {
  /* Prefixes */
  public static PREFIX_TABS: string = 't';

  /* IDs */
  public static ID_ID: string = 'id';
  public static ID_MYCOACH: string = 'my';
  
  /* Placeholders */
  public static PLACEHOLDER_ID: string = ':' + ConstantRoute.ID_ID;
  public static PLACEHOLDER_MYCOACH: string = ':' + ConstantRoute.ID_MYCOACH;

  /* Paths */
  public static PATH_COACHING: string = 'coaching';
  public static PATH_JOBS: string = 'jobs';
  public static PATH_EXPLORE: string = 'explore';
  public static PATH_PROFILE: string = 'profile';
  public static PATH_USERSETTINGS: string = 'settings';
  public static PATH_CHANGEPASSWORD: string = 'change-password';
  public static PATH_ORDERS: string = 'orders';
  public static PATH_CONTACT: string = 'contact';
  public static PATH_COACH: string = 'coach';
  public static PATH_COACH_ID: string = ConstantRoute.PATH_COACH + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static PATH_MYCOACH: string = 'my-coach';
  public static PATH_COACHES: string = 'coaches';
  public static PATH_PRODUCT: string = 'product';
  public static PATH_PRODUCT_ID: string = ConstantRoute.PATH_PRODUCT + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static PATH_PRODUCTS: string = 'products';
  public static PATH_ORDER: string = 'order';
  public static PATH_ORDER_ID: string = ConstantRoute.PATH_ORDER + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static PATH_PODCAST: string = 'podcast';
  public static PATH_PODCAST_ID: string = ConstantRoute.PATH_PODCAST + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static PATH_ADVICE: string = 'advice';
  public static PATH_ADVICE_ID: string = ConstantRoute.PATH_ADVICE + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static PATH_VIDEO: string = 'video';
  public static PATH_VIDEO_ID: string = ConstantRoute.PATH_VIDEO + '/' + ConstantRoute.PLACEHOLDER_ID;

  /* Params */
  public static PARAM_DELIMITER: string = ',';
  public static PARAM_TYPE: string = 'type';

  /* Full paths */
  public static FULL_COACHING: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACHING;
  public static FULL_JOBS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_JOBS;
  public static FULL_EXPLORE: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_EXPLORE;
  public static FULL_PROFILE: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PROFILE;
  public static FULL_USERSETTINGS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_USERSETTINGS;
  public static FULL_CHANGEPASSWORD: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_CHANGEPASSWORD;
  public static FULL_ORDERS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_ORDERS;
  public static FULL_CONTACT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_CONTACT;
  public static FULL_COACH: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACH + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static FULL_MYCOACH: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACH + '/' + ConstantRoute.PLACEHOLDER_MYCOACH;
  public static FULL_MYCOACH_REDIRECT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_MYCOACH;
  public static FULL_COACHES: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_COACHES;
  public static FULL_PRODUCTS: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PRODUCTS;
  public static FULL_PRODUCT: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PRODUCT + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static FULL_ORDER: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_ORDER + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static FULL_PODCAST: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_PODCAST + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static FULL_ADVICE: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_ADVICE + '/' + ConstantRoute.PLACEHOLDER_ID;
  public static FULL_VIDEO: string = ConstantRoute.PREFIX_TABS + (!!ConstantRoute.PREFIX_TABS ? '/' : '') + ConstantRoute.PATH_VIDEO + '/' + ConstantRoute.PLACEHOLDER_ID;
}
