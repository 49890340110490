import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { ConstantVariable } from 'constant-variable';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'news',
  templateUrl: 'news.html',
  styleUrls: ['news.scss']
})
export class NewsComponent {
  showAllFeatures: boolean;

  constructor(
    public branch: BranchConfigService,
    private navController: NavController
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  goTo() {
    if(this.branch.config.features.bewerbungstipps || this.showAllFeatures) {
      this.navController.navigateRoot('bewerbungsservice');
    } else {
      console.log('not allowed to go to BewerbungsservicePage');
    }
  }

}
