// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Inter, sans-serif;
}

.chatWrapperStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.chatWrapperStyle * {
  box-sizing: border-box;
}
.chatWrapperStyle *::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

.chatWrapperStyle *::-webkit-scrollbar-track {
  background: #ffffff00;
}

.chatWrapperStyle *::-webkit-scrollbar-thumb {
  background: #ccc;
}
.chatWrapperStyle *::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.messageWindowStyle {
  padding: 20px;
  height: 101vh;
  overflow: hidden;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/CometChat-messages/cometchat-messages/cometchat-messages.component.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["* {\n  font-family: Inter, sans-serif;\n}\n\n.chatWrapperStyle {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  box-sizing: border-box;\n  position: relative;\n}\n.chatWrapperStyle * {\n  box-sizing: border-box;\n}\n.chatWrapperStyle *::-webkit-scrollbar {\n  width: 8px;\n  height: 4px;\n}\n\n.chatWrapperStyle *::-webkit-scrollbar-track {\n  background: #ffffff00;\n}\n\n.chatWrapperStyle *::-webkit-scrollbar-thumb {\n  background: #ccc;\n}\n.chatWrapperStyle *::-webkit-scrollbar-thumb:hover {\n  background: #aaa;\n}\n\n.messageWindowStyle {\n  padding: 20px;\n  height: 101vh;\n  overflow: hidden;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
