// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  max-width: 100%;
  display: flex;
  height: 25px;
  line-height: 25px;
  color: var(--cdz-primary-color);
  background-color: var(--cdz-darecolor);
}
:host .premium-logo {
  flex-shrink: 2;
  flex-grow: 0;
  flex-basis: 50%;
  max-width: 60%;
  min-width: 30%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url('logo-invers.svg');
  background-repeat: no-repeat;
  background-size: auto 130%;
  background-position: 5px center;
  padding-left: 10px;
}
:host .premium-logo.no-logo {
  background-image: none;
}
:host .premium-label {
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: 50%;
  max-width: 70%;
  min-width: 40%;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/components/premium-bar/premium-bar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,+BAAA;EACA,sCAAA;AACF;AAAE;EACE,cAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,wCAAA;EACA,4BAAA;EACA,0BAAA;EACA,+BAAA;EACA,kBAAA;AAEJ;AADI;EACE,sBAAA;AAGN;AAAE;EACE,cAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ","sourcesContent":[":host {\n  width: 100%;\n  max-width: 100%;\n  display: flex;\n  height: 25px;\n  line-height: 25px;\n  color: var(--cdz-primary-color);\n  background-color: var(--cdz-darecolor);\n  .premium-logo {\n    flex-shrink: 2;\n    flex-grow: 0;\n    flex-basis: 50%;\n    max-width: 60%;\n    min-width: 30%;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    background-image: url(../../../assets/imgs/logo-invers.svg);\n    background-repeat: no-repeat;\n    background-size: auto 130%;\n    background-position: 5px center;\n    padding-left: 10px;\n    &.no-logo {\n      background-image: none;\n    }\n  }\n  .premium-label {\n    flex-shrink: 1;\n    flex-grow: 0;\n    flex-basis: 50%;\n    max-width: 70%;\n    min-width: 40%;\n    text-align: right;\n    padding-right: 10px;\n    font-weight: bold;\n    white-space: nowrap;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
