import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountPage } from 'pages/account/account';
import { ActivatePage } from 'pages/activate/activate';
import { ApplicationPage } from 'pages/application/application';
import { ApplicationsPage } from 'pages/applications/applications';
import { BewerbungsmappePage } from 'pages/bewerbungsmappe/bewerbungsmappe';
import { BewerbungsserviceDetailPage } from 'pages/bewerbungsservice-detail/bewerbungsservice-detail';
import { BewerbungsserviceSettingsPage } from 'pages/bewerbungsservice-settings/bewerbungsservice-settings';
import { BewerbungsservicePage } from 'pages/bewerbungsservice/bewerbungsservice';
import { BewerbungstippPage } from 'pages/bewerbungstipp/bewerbungstipp';
import { BewerbungstippsPage } from 'pages/bewerbungstipps/bewerbungstipps';
import { ChatStandalonePage } from 'pages/chat-standalone/chat-standalone.page';
import { ChecklistPage } from 'pages/checklist/checklist';
import { ContactPage } from 'pages/contact/contact';
import { DashboardPage } from 'pages/dashboard/dashboard';
import { ForgotPage } from 'pages/forgot/forgot';
import { ImpressumPage } from 'pages/impressum/impressum';
import { JobboerseBewerbermappePage } from 'pages/jobboerse-bewerbermappe/jobboerse-bewerbermappe';
import { JobboerseDetailPage } from 'pages/jobboerse-detail/jobboerse-detail';
import { JobboersePage } from 'pages/jobboerse/jobboerse';
import { LoginPage } from 'pages/login/login.page';
import { OnboardingContentPage } from 'pages/onboarding-content/onboarding-content';
import { OnboardingContractPage } from 'pages/onboarding-contract/onboarding-contract';
import { OnboardingPage } from 'pages/onboarding/onboarding';
import { OnlineCoursePage } from 'pages/online-course/online-course';
import { PaidGoodsPage } from 'pages/paid-goods/paid-goods';
import { PasswordChangePage } from 'pages/password-change/password-change';
import { PasswordPage } from 'pages/password/password';
import { RecherchePage } from 'pages/recherche/recherche';
import { RegisterPage } from 'pages/register/register';
import { TermsPage } from 'pages/terms/terms';
import { AuthGuard } from 'services/auth/auth-guard.decorator';
import { PublicGuard } from 'services/auth/public-guard.decorator';
import { ConstantRoute } from './constant-routes';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', canActivate: [PublicGuard], component: LoginPage },
  { path: 'dashboard', canActivate: [AuthGuard], component: DashboardPage },
  { path: 'bewerbermappe/:metapage', canActivate: [AuthGuard], component: BewerbungsmappePage },
  { path: 'bewerbungstipps/:id', canActivate: [AuthGuard], component: BewerbungstippsPage },
  { path: 'bewerbungsservice/:id', canActivate: [AuthGuard], component: BewerbungsserviceDetailPage },
  { path: 'jobboerse', canActivate: [AuthGuard], component: JobboersePage },
  { path: 'jobboerse/:id', canActivate: [AuthGuard], component: JobboerseDetailPage },
  { path: 'jobboerse-bewerbermappe/:id', canActivate: [AuthGuard], component: JobboerseBewerbermappePage },
  { path: 'applications', canActivate: [AuthGuard], component: ApplicationsPage },
  { path: 'account', canActivate: [AuthGuard], component: AccountPage },
  { path: 'contact', canActivate: [AuthGuard], component: ContactPage },
  { path: 'activate/:type/:data', canActivate: [PublicGuard], component: ActivatePage },
  { path: 'application/:id', canActivate: [AuthGuard], component: ApplicationPage },
  { path: 'bewerbungstipp/:groupid/:id', canActivate: [AuthGuard], component: BewerbungstippPage },
  { path: 'bewerbungsservice', canActivate: [AuthGuard], component: BewerbungsservicePage },
  { path: 'checklist/:groupid/:id', canActivate: [AuthGuard], component: ChecklistPage },
  { path: 'forgot', canActivate: [PublicGuard], component: ForgotPage },
  { path: 'onboarding-contract/:id', canActivate: [AuthGuard], component: OnboardingContractPage },
  { path: 'onboarding', canActivate: [AuthGuard], component: OnboardingPage },
  { path: 'paidgoods', canActivate: [AuthGuard], component: PaidGoodsPage },
  { path: 'password-change/:userkey/:pwchangekey', canActivate: [PublicGuard], component: PasswordChangePage },
  { path: 'register', canActivate: [PublicGuard], component: RegisterPage },
  { path: 'register/:type/:data', canActivate: [PublicGuard], component: RegisterPage },
  { path: 'bewerbungsservicesettings', canActivate: [AuthGuard], component: BewerbungsserviceSettingsPage },
  { path: 'impressum', canActivate: [AuthGuard], component: ImpressumPage },
  { path: 'onboarding-content/:plainpage', canActivate: [AuthGuard], component: OnboardingContentPage },
  { path: 'password', canActivate: [AuthGuard], component: PasswordPage },
  { path: 'recherche', canActivate: [AuthGuard], component: RecherchePage },
  { path: 'terms', canActivate: [AuthGuard], component: TermsPage },
  { path: 'chat', canActivate: [AuthGuard], component: ChatStandalonePage },
  { path: 'onlinecourse', canActivate: [AuthGuard], component: OnlineCoursePage },
  // tabs dashboard
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard-tabs/dashboard-tabs-routing.module').then((m) => m.routes),
  },
  { path: ConstantRoute.FULL_MYCOACH_REDIRECT, redirectTo: ConstantRoute.FULL_MYCOACH, pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
