import { Component, Injector, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ApplicationService } from 'services/application/application.service';
import { RoutingService } from 'services/routing/routing.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { DataGroup, DataPage, MetaFile, MetaId, MetaPage, MetaValue } from 'src/app/metagroup';
const moment = _rollupMoment || _moment;

class BewerbungFilter {
  status: MetaId = null;
  begriff: string;
}

@Component({
  selector: 'page-applications',
  templateUrl: 'applications.html',
  styleUrls: ['applications.scss']
})
export class ApplicationsPage implements OnInit {
  public readonly pageName: string = "ApplicationsPage";

  bewerbungMeta: MetaPage;
  bewerbungen: DataPage[];
  bewerbungenFiltered: DataPage[];
  bewerbungStatus: MetaValue[];
  bewerbungStatusById: Map<MetaId, MetaValue>;
  filter: BewerbungFilter = new BewerbungFilter();
  showingmore: any = {};

  constructor(
    public injector: Injector,
    public bewerbungenProvider: ApplicationService,
    public userdataProvider: UserdataService,
    private navController: NavController,
    private routingService: RoutingService
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungenPage');
    //this.userdataProvider.reset('openapplications').catch(error => console.error('failed to reset counter for openapplications',error));

    this.bewerbungenProvider.getMeta().then(data => {
      this.bewerbungMeta = data;
      let idxField;
      let idxGroup = this.bewerbungMeta.groups.findIndex(group => {
        idxField = group.fields.findIndex(field => {
          return field.id === 'status';
        });
        return idxField !== -1;
      });
      this.bewerbungStatusById = new Map<MetaId, MetaValue>();
      if (idxGroup !== -1) {
        this.bewerbungStatus = (<MetaValue[]>(this.bewerbungMeta.groups[idxGroup].fields[idxField].values));
        this.bewerbungStatus.forEach(status => {
          this.bewerbungStatusById.set(status.id, status);
        });
      } else {
        this.bewerbungStatus = [];
      }
    });
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter BewerbungenPage');
    this.bewerbungenProvider.getList().then(data => {
      this.bewerbungen = cloneDeep(data.list);
      this.sort();
      //this.search();
    });
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungenPage');
  }

  edit(id: MetaId) {
    this.navController.navigateForward('/bewerbung/' + id);
  }

  sort() {
    // sort this.bewerbungen according to specified criteria
    this.bewerbungen.sort((a: DataPage, b: DataPage): number => {
      let aTermin = (<DataGroup>a.status).fieldData.status === 'eingeladen';
      let bTermin = (<DataGroup>b.status).fieldData.status === 'eingeladen';
      if (aTermin !== bTermin) {
        if (aTermin) return -1;
        else return 1;
      } else {
        let aTime = moment((<DataGroup>a.status).fieldData.datum + ' ' + (<DataGroup>a.status).fieldData.uhrzeit, 'YYYY-MM-DD HH:mm').valueOf();
        let bTime = moment((<DataGroup>b.status).fieldData.datum + ' ' + (<DataGroup>b.status).fieldData.uhrzeit, 'YYYY-MM-DD HH:mm').valueOf();
        if (aTime > bTime) return -1;
        else if (aTime < bTime) return 1;
        else return 0;
      }
    });
  }

  /*
  search() {
    let matches = (bewerbung: DataPage) => {
      if(this.filter.status) {
        let status: DataValue;
        let groups = Object.getOwnPropertyNames(bewerbung);
        for (let g = 0; g < groups.length; g++) {
          if (groups[g] === 'id') continue;
          if ((<DataGroup>bewerbung[groups[g]]).fieldData.status) {
            status = (<DataGroup>bewerbung[groups[g]]).fieldData.status;
            break;
          }
        }
        if (this.filter.status !== status) return;
      }
      if(this.filter.begriff) {
        let groups = Object.getOwnPropertyNames(bewerbung);
        for (let g = 0; g < groups.length; g++) {
          if (groups[g] === 'id') continue;
          let keys = Object.getOwnPropertyNames((<DataGroup>bewerbung[groups[g]]).fieldData);
          for(let k=0;k<keys.length;k++) {
            if (keys[k] === 'id') continue;
            if(typeof (<DataGroup>bewerbung[groups[g]]).fieldData[keys[k]] === 'string') {
              if((<string>(<DataGroup>bewerbung[groups[g]]).fieldData[keys[k]]).toLowerCase().includes(this.filter.begriff.toLowerCase())) {
                return true;
              }
            }
          }

        }
        return false;
      }
      return true;
    };
    this.bewerbungenFiltered = [];
    if(this.bewerbungen) {
      this.bewerbungen.forEach(bewerbung => {
        if (matches(bewerbung)) {
          this.bewerbungenFiltered.push(bewerbung);
        }
      });
    }
  }
  */

  logoImageUrl(bewerbung: DataPage): string {
    if (bewerbung.firma && (<DataGroup>bewerbung.firma).fieldData && (<DataGroup>bewerbung.firma).fieldData.firmaLogo) {
      let file: MetaFile = <MetaFile>((<DataGroup>bewerbung.firma).fieldData.firmaLogo);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  seitText(bewerbung: DataPage): string {
    let dstr;
    if (!bewerbung) return;
    if (bewerbung.status) {
      dstr = (<DataGroup>bewerbung.status).fieldData.datum + ' ' + (<DataGroup>bewerbung.status).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

  abText(bewerbung: DataPage): string {
    let dstr;
    if (!bewerbung) return;
    if (bewerbung.status) {
      dstr = (<DataGroup>bewerbung.stelle).fieldData.datum + ' ' + (<DataGroup>bewerbung.stelle).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

  hasNewMessage(bewerbung: DataPage): boolean {
    let nachrichten: DataGroup[] = (<DataGroup[]>bewerbung.nachrichten) || [];
    for (let n = 0, nl = nachrichten.length; n < nl; n++) {
      if (nachrichten[n].fieldData.new) { //NOTE: this shall bear a non-empty value whenever backend considers nachricht to be new
        return true;
      }
    }
    return false;
  }

  showmore(bewerbung: DataPage): void {
    this.bewerbungen.forEach(b => {
      if (b.id === bewerbung.id) {
        this.showingmore[b.id] = true;
      } else {
        this.showingmore[b.id] = false;
      }
    });
  }
  showsmore(bewerbung: DataPage): boolean {
    const isAlwaysMore = !['tipps', 'checklists', 'jobboerse', 'coaches', 'absagegruende', 'unterlagenoptimieren', 'termin'].some(action => this.showaction(action, bewerbung)); //NOTE: thus these values are set in stone
    return isAlwaysMore || this.showingmore[bewerbung.id];
  }

  doaction(action: string, bewerbung: DataPage) {
    let page = 'DashboardPage';
    let parms: any = {};
    if (action === 'tipps') {
      page = 'BewerbungstippsPage';
      parms.id = 'overview';
    } else if (action === 'checklists') {
      page = 'BewerbungstippsPage';
      parms.id = 'checklisten';
    } else if (action === 'jobboerse') {
      page = 'JobboersePage';
    } else if (action === 'coaches') {
      page = 'BewerbungsservicePage';
    } else if (action === 'absagegruende') {
      page = 'BewerbungstippsPage';
      parms.id = 'absage';
    } else if (action === 'unterlagenoptimieren') {
      page = 'BewerbungstippsPage';
      parms.id = 'unterlagen';
    } else if (action === 'termin') {
      page = 'TerminPage';
      parms.id = 'add'; // <- TODO
      //TODO: create the termin before going there,
      //TODO: or if the termin already exists cause it's been created by the backend,
      //TODO: we need to know the id from somewhere in the 'bewerbung'
    }
    this.navController.navigateForward(this.routingService.getPathReplaceId(page, parms.id));
  }

  showaction(action: string, bewerbung: DataPage) {
    let status = (<DataGroup>bewerbung.status).fieldData.status;
    if (status === 'abgeschickt') {
      return action === 'jobboerse';

    } else if (status === 'bearbeitet') {
      return false;

    } else if (status === 'abgelehnt') {
      return false;

    } else if (status === 'eingeladen') {
      return false; // action === 'termin'; // TODO removed since not yet impl.

    } else if (status === 'zugesagt') {
      return false; // no actions available here?
      
    } else {
      return false;
    }
  }

}
