import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { OnlineCoursePage } from "./online-course";
import { ComponentsModule } from "components/components.module";
import { DirectivesModule } from "directives/directives.module";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    OnlineCoursePage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class OnlineCoursePageModule {}
