import { Component, Input } from '@angular/core';

import { MetaInvalid } from 'services/meta-validity/meta-validity.service';

@Component({
  selector: 'meta-validity',
  templateUrl: 'meta-validity.html',
  styleUrls: ['meta-validity.scss']
})
export class MetaValidityComponent {
  @Input() invalid: MetaInvalid[];
  @Input() showPage?: boolean = false;

  constructor() {
  }

}
