import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ContractUploadedDocumentService } from 'services/contract-uploaded-document/contract-uploaded-document.service';
import { DataGroup, DataPage, MetaPage } from 'src/app/metagroup';



@Component({
  selector: 'page-onboarding-contract',
  templateUrl: 'onboarding-contract.html',
  styleUrls: ['onboarding-contract.scss']
})
export class OnboardingContractPage implements OnInit {
  public readonly pageName: string = "OnboardingContractPage";

  contractUploadedDocumentMeta: MetaPage;
  contractUploadedDocument: DataPage;

  //itemOpened: boolean = false;
  itemOpened: boolean = true;

  constructor(
    public injector: Injector,
    public contractUploadedDocumentProvider: ContractUploadedDocumentService,
    private navController: NavController,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit OnboardingContractPage');
    let addMissingDataGroups = () => {
      this.contractUploadedDocumentMeta.groups.forEach(group => {
        if(!this.contractUploadedDocument[group.id]) {
          this.contractUploadedDocument[group.id] = new DataGroup();
        }
      });
    };
    this.contractUploadedDocumentProvider.getMeta().then(data => {
      this.contractUploadedDocumentMeta = data;
      this.route.paramMap.subscribe( paramMap => {
        let id = paramMap.get('id');
        if(id === 'add') {
          this.contractUploadedDocument = new DataPage();
          addMissingDataGroups();
        } else {
          this.contractUploadedDocumentProvider.get(id).then(data => {
            this.contractUploadedDocument = data;
            addMissingDataGroups();
          }, error => {
            console.error('cannot load uploaded document');
          });
        }
      })
    }, error => {
      console.error('cannot load uploaded document meta');
    });
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave OnboardingContractPage');
  }

  save() {
    if(this.contractUploadedDocument.id) {
      this.contractUploadedDocumentProvider.update(this.contractUploadedDocument.id, this.contractUploadedDocument).then(data => {
        //
      }, error => {
        console.error('cannot update uploaded document');
      });
    } else {
      this.contractUploadedDocumentProvider.create(this.contractUploadedDocument).then(data => {
        this.navController.navigateForward('onboarding-contract/' + data.id);
      }, error => {
        console.error('cannot create uploaded document');
      });
    }
  }

  cancel() {
    if(this.contractUploadedDocument.id) {
      //this.itemOpened = false;
      this.ngOnInit();
    } else {
      this.navController.navigateRoot('onboarding');
    }
  }

  open() {
    if(!this.itemOpened) {
      this.itemOpened = true;
    }
  }

  close() {
    this.navController.navigateRoot('onboarding');
  }

}
