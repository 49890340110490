import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Pages } from 'src/app/pages';
import { PagesService } from '../pages/pages.service';

//TODO: refactor as necessary / this might be used in other parts of the app ...
export class PlainPage {
  id: number;
  html: string;
  label: string;
  language: string;
}
export class PlainPages {
  contents: PlainPage[];
  text?: string;
  title?: string;
  contracts?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingContentService {

  plainPages: PlainPages;
  pages: any[];
  plainPagesSubject: Subject<PlainPages>;

  constructor(
    public http: HttpClient,
    public pagesProvider: PagesService,
  ) {
    this.pages = Pages;
    this.plainPagesSubject = new Subject();
  }

  updateSubpages(): Promise<PlainPages> {
    return new Promise<PlainPages>((resolve, reject) => {
      this.http.get<PlainPages>('/api/candooze/onboarding/content').subscribe(data => {
        this.plainPages = data;
        resolve(data);
        this.plainPagesSubject.next(data);
      }, err => {
        this.plainPages = this.plainPages || { contents: [] };
        reject(err);
      });
    }).then(() => {
      let page = this.pagesProvider.getPage('OnboardingPage');
      if (page) {
        // replace local title text with remote text
        page.sub[0].title = this.plainPages.contracts;
        //page.sub = [page.sub[0]]; // keep first page (Contract)
        page.sub.splice(1, page.sub.length - 1); // keep first page (Contract) // reference safe
        this.plainPages.contents.forEach((plainpage, index) => {
          let subpage = {
            title: plainpage.label,
            page: 'OnboardingContentPage',
            feature: 'onboarding',
            params: { plainpage: plainpage.id },
            active: false,
            sub: null,
          };
          page.sub.push(subpage);
        });
      }
      //console.log('pages after onboardingcontent: ' + JSON.stringify(Pages,null,2));
      return this.plainPages;
    });
  }

}
