import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { BranchConfigService } from 'services/branch-config/branch-config';
let _ = (key) => key;

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private unDismissedMessages: string[] = [];
  private doNotDismissMessages: boolean = false;
  private errorToast: any;

  private errorsSubscription: Subscription;
  private errors: any = {};

  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private configProvider: BranchConfigService
  ) { }

  /**
   * This method handles and displays success messages.
   * @param message the message to show
   * @param duration duration for the message (optional)
   */
  public handleSuccess(message: string, duration?: number): void {
    this.showSuccessToast(message, duration);
  }

  /**
   * This method handles and displays all error messages. You can provide fallback code to execute on toast dismiss.
   * @param error the error object or string
   * @param onDismiss fallback code to execute on toast dismiss
   */
  public handleError(error: HttpErrorResponse | string, onDismiss?: () => void): void {

    // build error messages for every provided error code
    let buildErrors = (codes: string[]) => {
      let messages = [];

      // close button label
      let close = (this.errors.close_button || {}).label || 'X';

      // transform error codes into usable objects
      codes.forEach(code => {
        let value = this.errors[code];
        // if value is not an object, create an empty object
        if (!value || typeof value === 'string') {
          value = {};
        }
        // if no close button is defined, create an empty object
        if (!value.close_button) {
          value.close_button = {};
        }
        // if no label for the close button is defined, set a default
        if (!value.close_button.label) {
          value.close_button.label = 'X';
        }
        // if not message is set, set the error code as the message
        if (!value.message) {
          value.message = code;
        }
        // if the message is set now, add it to the messages array for later usage
        if (value.message) {
          messages.push(value.message);
        }
        // define the global close button
        if (value.close_button.label !== 'X' && value.close_button.label !== this.errors.close_button.label) {
          close = value.close_button.label;
        }
      });

      // build the full error message
      let fullMessage = messages.join('<br/>');

      // only display one error toast at a time
      let messageAlreadyShown = false;
      for (let i = 0; i < this.unDismissedMessages.length; i++) {
        if (this.unDismissedMessages[i] === fullMessage) {
          messageAlreadyShown = true;
          break;
        }
      }
      if (!messageAlreadyShown) {
        if (this.errorToast) {
          this.doNotDismissMessages = true;
          this.errorToast.dismiss().then(() => {
            this.doNotDismissMessages = false;
          });
        }
        this.unDismissedMessages.push(fullMessage);

        // show the actual toast
        this.showErrorToast(onDismiss, close);
      }
    };

    // pull error message out of the provided error and call buildErrors
    let code = 'unknown';
    if (typeof error === 'string') {
      code = error;
      buildErrors([code]);
    } else if (error && error.error && error.error.error) {
      code = error.error.error;
      buildErrors([code]);
    } else if (error && error.error && error.error.vdata) {
      let errorCodes = [];
      if (error.error.vdata.messageTitle) {
        errorCodes.push(error.error.vdata.messageTitle);
      }
      if (error.error.vdata.messages) {
        error.error.vdata.messages.forEach(msg => {
          // sometimes the backend sends duplicate error messages
          // this is a workaround until the backend is fixed
          if (false == errorCodes.includes(msg)) {
            errorCodes.push(msg);
          }
        });
      }
      buildErrors(errorCodes);
    } else {
      buildErrors([code]);
    }
  }
  
  private async showErrorToast(onDismiss: any, close: any) {
    this.errorToast = await this.toastCtrl.create({
      icon: 'warning-outline',
      message: this.unDismissedMessages.join('<br/><br/>'),
      // duration: 10000,
      buttons: [{
        text: close,
        role: 'cancel',
        handler: () => {
          if (!this.doNotDismissMessages) {
            this.unDismissedMessages.splice(0);
          }
          this.errorToast = null;
          if (onDismiss) {
            onDismiss();
          }
        }
      }],
      position: 'middle',
      cssClass: 'toast-error'
    });
    return await this.errorToast.present();
  }

  private async showSuccessToast(message: string, duration?: number) {
    const toast = await this.toastCtrl.create({
      icon: 'checkmark-outline',
      message: message,
      duration: !!duration ? duration : 5000,
      position: 'top',
      cssClass: 'toast-success'
    });
    return await toast.present();
  }
}