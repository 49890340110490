import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';

/**
 * Service for handling modals.
 */
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalCtrl: ModalController) { }

  /**
   * Displays a popup.
   * @param options modal options, will be given to the modal on creation
   * @param onDismiss callback, will be called on closing the modal
   * @returns a promise that resolves once the modal is visible
   */
  public async showModal(options: any, onDismiss: any = (any) => {}): Promise<any> {
    let modal = await this.modalCtrl.create(options);
    modal.onDidDismiss().then(onDismiss);
    return await modal.present();
  }

  /**
   * Creates a popup, but DOES NOT display it. The caller has to call modal.present() for himself.
   * @param options modal options, will be given to the modal on creation
   * @param onDismiss callback, will be called on closing the modal
   * @returns a promise that resolves once the modal has been created
   */
  public async createModal(options: any, onDismiss: any = (any) => {}): Promise<HTMLIonModalElement> {
    let modal = await this.modalCtrl.create(options);
    modal.onDidDismiss().then(onDismiss);
    return modal;
  }
}
