// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgStyle {
  overflow: hidden;
  display: inherit;
  width: 100%;
  height: 100%;
}

.imgBorderStyle {
  border-width: 1px;
  border-color: #aaa;
  border-radius: 50%;
  border-style: solid;
}

.figure {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(234, 234, 234);
  border-radius: 50%;
}

.Offline {
  width: 9px;
  height: 9px;
  top: -12px;
  float: right;
  position: relative;
  background-color: rgb(85, 85, 85);
}

.Online {
  width: 9px;
  height: 9px;
  top: -12px;
  float: right;
  position: relative;
  background-color: rgb(0, 255, 0);
}`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Shared/CometChat-avatar/cometchat-avatar/cometchat-avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,gCAAA;EACA,kBAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,iCAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,gCAAA;AACF","sourcesContent":[".imgStyle {\n  overflow: hidden;\n  display: inherit;\n  width: 100%;\n  height: 100%;\n}\n\n.imgBorderStyle {\n  border-width: 1px;\n  border-color: #aaa;\n  border-radius: 50%;\n  border-style: solid;\n}\n\n.figure {\n  border-width: 1px;\n  border-style: solid;\n  border-color: rgb(234, 234, 234);\n  border-radius: 50%;\n}\n\n.Offline {\n  width: 9px;\n  height: 9px;\n  top: -12px;\n  float: right;\n  position: relative;\n  background-color: rgb(85, 85, 85);\n}\n\n.Online {\n  width: 9px;\n  height: 9px;\n  top: -12px;\n  float: right;\n  position: relative;\n  background-color: rgb(0, 255, 0);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
