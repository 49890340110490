import { Injectable } from '@angular/core';

import { MetaFile } from 'metagroup';
import { DownloadService } from 'services/download/download.service';
import { ToastService } from 'services/toast/toast.service';

/**
 * @deprecated use {@link FileService} to load files
 */
@Injectable({
  providedIn: 'root'
})
export class FileProvider {
    constructor(
        public toastService: ToastService,
        public downloadService: DownloadService,
    ) {
    }

    downloadMetaFile(file: MetaFile) {
        let _download = (blob: Blob) => {
            //present this blob for download

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(blob);
            let link = window.document.createElement('a');
            link.href = data;
            link.download = file.filename;
            window.document.body.appendChild(link); // For Firefox it is now necessary to add the link to the document to be clickable
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                window.document.body.removeChild(link);
            }, 100);

        };

        if (file.url) {
            this.downloadService.downloadBlob('/api' + file.url, file.filetype).subscribe(blob => {
                _download(blob);
            }, error => {
                console.error('cannot download file from ' + file.url);
                this.toastService.handleError(error, () => {
                    //?
                });
            });
        } else if (file.value) {

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            let blob = new Blob([window.atob(file.value.substr(file.value.indexOf(',') + 1))], { type: file.filetype });

            _download(blob);
        }
    }
}
