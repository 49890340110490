import { Injectable } from '@angular/core';
import { ApiFilter } from 'services/api/api-filter';
import { ApiService } from 'services/api/api.service';
import { FileService } from 'services/file/file.service';
import { UserCoachService } from './user-coach.service';
import { AlertService } from 'services/alert/alert.service';
import { ToastService } from 'services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * This service can and should be used to load and send coach information.
 */
@Injectable({
  providedIn: 'root'
})
export class CoachService extends ApiService {

  constructor(
    private fileService: FileService,
    private userCoachService: UserCoachService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    super('coach');
  }

  /**
   * Convert a list of services to one string, that is seperated by commas except the last two services, which are joined with given conjunction.
   * @param services list to join
   * @param conjunction conjunction for the last two services
   * @returns a single string
   */
  public servicesAsString(services: string[], conjunction: string): string {
    if (!services || services.length === 0) {
      return '';
    }
    if (services.length === 1) {
      return services[0];
    }

    // Join all elements except the last one with a comma
    const allButLast = services.slice(0, -1).join(', ');

    // Append the conjunction and the last element
    return `${allButLast} ${conjunction} ${services[services.length - 1]}.`;
  }

  /**
   * Assign the given coach to current user and return the newly assigned coach. The coach will only be assigned, after a security alert has been confirmed.
   * This function also intercepts the promise and converts the image to an url.
   * @param coach is the coach that shall be assigned
   * @returns a promise that may contain the newly assigned coach
   */
  public assignCoachToSelf(coach: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.alertService.presentAlert(
        this.translateService.instant('service.coach.changeTo.confirmation'),
        () => {
          this.userCoachService.setCoach(coach.id)
            .then(updatedCoach => {
              if (updatedCoach.image && updatedCoach.image.value) {
                this.fileService.getAsUrl(updatedCoach.image.value).then(url => {
                  updatedCoach.image = url;
                  return updatedCoach;
                });
              }
              this.toastService.handleSuccess(this.translateService.instant('service.coach.changeTo.success') + updatedCoach.firstname + ' ' + updatedCoach.lastname);
              resolve(updatedCoach); // Resolve with updatedCoach
            })
            .catch(error => {
              reject(error); // Reject the promise on error
            });
        },
        () => {
          // Handle case where alert is dismissed or declined
          reject(new Error('User canceled the coach change.'));
        }
      );
    });
  }

  /**
   * Load the greeting information for the coach, that is assigned to the current user.
   * This function also intercepts the promise and converts the image to an url.
   * @param options are standard HTTP options
   * @returns a promise for the greeting information
   */
  public getGreeting(options?: any): Promise<any> {
    return this.get('greeting', options).then(data => {
      if (data.coachImage && data.coachImage.value) {
        return this.fileService.getAsUrl(data.coachImage.value).then(url => {
          data.coachImage = url;
          return data;
        });
      }
      return data;
    });
  }

  public getList(filter?: ApiFilter | any): Promise<any> {
    return super.getList(filter).then(list => {
      if (list && list.length > 0) {
        list.forEach(coach => {
          if (coach.image && coach.image.value) {
            return this.fileService.getAsUrl(coach.image.value).then(url => {
              coach.image = url;
              return coach;
            });
          }
        });
      }
      return list;
    });
  }

}
