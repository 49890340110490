import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { MetaFile } from 'metagroup';
import { Webinar } from 'services/webinar/webinar.service';
import { ModalService } from 'services/modal/modal.service';
import { WebinarViewMoreModalPage } from 'pages/webinar-view-more-modal/webinar-view-more-modal';

@Component({
  selector: 'webinar',
  templateUrl: 'webinar.html',
  styleUrls: ['webinar.scss']
})
export class WebinarComponent {
  @Input() webinar: Webinar;
  @Input() otherWebinars?: Webinar[];
  @Input() hideBuying?: boolean = false;

  constructor(
    public branch: BranchConfigService,
    private router: Router,
    private modalService: ModalService
  ) {
  }

  fotoUrl(): string {
    if (this.webinar && this.webinar.image) {
      let file: MetaFile = <MetaFile>(this.webinar.image);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  buy() {
    let webinars: Webinar[] = [];
    if (this.otherWebinars) {
      this.otherWebinars.forEach(webinar => {
        if (webinar.needstobepaid && !webinar.hasbeenpaid) {
          webinars.push(webinar);
        }
      });
    }
    this.modalService.showModal(
      {component: WebinarViewMoreModalPage, componentProps: { webinars: webinars, webinar: this.webinar }, cssClass: 'modal-overlay'},
      (webinar: Webinar) => {
        if (webinar) { // could be another one of the webinars
          //?
        }
      });
  }
}
