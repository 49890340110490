import { Pipe, PipeTransform } from '@angular/core';

// takes a duration in seconds and displays it friendly

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: string|number, ...args) {
    let output: string|number = value;
    if(typeof output === 'string') {
      output = parseInt(output);
    }
    if(typeof output === 'number') {
      let seconds = output;
      let minutes = Math.floor(output / 60);
      let hours = Math.floor(output / (60*60));
      let minix = (minutes - (hours * 60));
      if(minutes < 1) return seconds + ' sec';
      else if(hours < 1) return minutes + ' min';
      else return hours + ':' + (minix > 9 ? minix : '0' + minix) + ' h'
    }
    return output;
  }
}
