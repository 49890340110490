import { Component, Injector, OnInit } from '@angular/core';
import { LoginCredentials } from 'services/auth/auth-credentials';
import { AuthService } from 'services/auth/auth.service';
import { BranchConfig, BranchConfigService } from "services/branch-config/branch-config";
import { ToastService } from 'services/toast/toast.service';
import { ConstantVariable } from 'src/app/constant-variable';


@Component({
  selector: 'page-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage implements OnInit {
  public readonly pageName: string = "LoginPage";

  showAllFeatures: boolean;
  public loginData: LoginCredentials = { /*email: null,*/ username: null, password: null, };

  constructor(
    public auth: AuthService,
    public injector: Injector,
    public branch: BranchConfigService,
    public toastService: ToastService,
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  ngOnInit() {
    console.log('ngOnInit LoginPage');
  }

  submit() {
    this.auth.login({ ...ConstantVariable.authParameters, ...this.loginData }).then(res => {
      //this.auth.login({...ConstantVariable.authParameters, ...this.loginData}).then(res => {
      console.log('APPCOM: login Success, res=', res);
    }, err => {
      console.log('APPCOM: login Failure, err=', err);
      this.toastService.handleError(err);
    });
  }
}
