import {Injectable} from "@angular/core";
import {jid, xml, client} from "./xmpp__client";

/**
 * As it is impossible to 'npm i' version 0.12.0 of the library (in fact any version newer than 0.3.0),
 * because our webpack version is too old (cannot upgrade, Ionic is too old),
 * and 0.3.0 is a very rudimentary version without documentation, the attempt is
 * to load the xmpp library at runtime.
 */
 @Injectable({
  providedIn: 'root'
})
export class XmppLoaderProvider {
  loading: Promise<void>;
  loaded: boolean = false;
  client: typeof client;
  xml: typeof xml;
  jid: typeof jid;

  constructor() {

  }
  load(): Promise<void> {
    console.error('XmppLoaderProvider::load');
    this.loading = this.loading || new Promise((resolve, reject) => {
      if(typeof window !== 'undefined') {
        // if(document.getElementById('xmppclient')) {
        if(this.loaded) {
          resolve();
        } else {
          const s = document.createElement('script');
          s.setAttribute('id','xmppclient');
          s.setAttribute('src', "https://unpkg.com/@xmpp/client@0.12.0/dist/xmpp.min.js");
          s.setAttribute('crossorigin','crossorigin');
          s.onload = () => {
            const { client, xml, jid } = (window as any).XMPP;
            this.client = client;
            this.xml = xml;
            this.jid = jid;
            this.loaded = true;
            resolve();
          };
          s.onerror = (error) => {
            console.error('XmppLoaderProvider: Failed to load Xmpp library');
            reject();
          };
          document.head.appendChild(s);
        }
      } else {
        reject();
      }
    });
    return this.loading;
  }
}
