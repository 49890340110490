import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { JitsiCallInfo, JitsiService } from 'services/jitsi/jitsi.service';
import { Userdata } from "services/userdata/userdata.service";
import { DataGroup, DataPage } from "src/app/metagroup";


@Component({
  selector: 'page-bewerbungsservice-jitsi-modal',
  templateUrl: 'bewerbungsservice-jitsi-modal.html',
  styleUrls: ['bewerbungsservice-jitsi-modal.scss']
})
export class BewerbungsserviceJitsiModalPage implements OnInit {
  public readonly pageName: string = "BewerbungsserviceJitsiModalPage";

  @ViewChild('jitsiContainer', { static: true }) jitsiContainer: any;

  @Input() callInfo: JitsiCallInfo;
  @Input() caller: DataPage;
  @Input() userdata: Userdata;

  callerName: string;

  constructor(
    private modalController: ModalController,
    public jitsiService: JitsiService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    console.log('ngOnInit BewerbungsserviceJitsiModalPage');
    const anonymousCaller = this.translate.instant('jitsi.call.anonymous');
    this.callerName = (this.caller && this.caller.person && (this.caller.person as DataGroup).fieldData && (this.caller.person as DataGroup).fieldData.name) || anonymousCaller;
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter BewerbungsserviceJitsiModalPage');
    this.callInfo.options.parentNode = this.jitsiContainer ? this.jitsiContainer.nativeElement : undefined;
    this.callInfo.options.width = this.callInfo.options.width || '100%';
    this.callInfo.options.height = this.callInfo.options.height || (Math.max(window.innerHeight - 500, 300) + 'px');
    this.callInfo.options.userInfo = { displayName: this.userdata.name };
    this.jitsiService.initBrowserApi(this.callInfo);
  }

  close() {
    this.modalController.dismiss();
  }
}
