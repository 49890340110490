import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OnlineCourseProduct } from 'services/onlinecourse/onlinecourse-product';
import { GoodType, PaidGoodsService } from "services/paid-goods/paid-goods.service";

@Component({
  selector: 'page-onlinecourse-view-more-modal',
  templateUrl: 'onlinecourse-view-more-modal.html',
  styleUrls: ['onlinecourse-view-more-modal.scss']
})
export class OnlinecourseViewMoreModalPage implements OnInit {
  public readonly pageName: string = "OnlinecourseViewMoreModalPage";

  @Input() onlinecourses: OnlineCourseProduct[];
  @Input() onlinecourse: OnlineCourseProduct;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit OnlinecourseViewMoreModalPage');
    this.viewIndex = this.onlinecourses.findIndex(onlinecourse => onlinecourse.id === this.onlinecourse.id);
    if (this.viewIndex === -1) {
      console.error('OnlinecourseViewMoreModalPage: onlinecourse not in onlinecourses.');
      this.viewIndex = 0;
    }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.onlinecourses.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.onlinecourses.length) {
      this.viewIndex = 0;
    }
  }

  buynow() {
    return this.paidGoodsProvider.buildPaymentUrl(GoodType.ONLINECOURSE, this.onlinecourses[this.viewIndex].id);
  }

  cancel() {
    this.dismiss();
  }

  dismiss(onlinecourse?: OnlineCourseProduct) {
    this.modalController.dismiss(onlinecourse);
  }

}
