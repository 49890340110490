import { Injectable } from "@angular/core";
import { CameraOptions, Camera, DestinationType, Direction, EncodingType, MediaType, PictureSourceType } from "@awesome-cordova-plugins/camera/ngx";

const defaultCameraOptions: CameraOptions = {
    quality: 50,
    destinationType: DestinationType.DATA_URL,
    sourceType: PictureSourceType.CAMERA,
    allowEdit: false,
    encodingType: EncodingType.JPEG,
    // targetWidth: number // Width in pixels to scale image.
    // targetHeight: number // Height in pixels to scale image.
    mediaType: MediaType.PICTURE,
    correctOrientation: false,
    saveToPhotoAlbum: false,
    // popoverOptions: CameraPopoverOptions // IOS-only
    cameraDirection: Direction.BACK
};

@Injectable({
    providedIn: 'root'
})
export class CameraService {
    private sourceType: PictureSourceType;

    constructor(public camera: Camera) { }

    setSourceType(sourceType: PictureSourceType): void {
        this.sourceType = sourceType;
    }

    getCameraOptions(): CameraOptions {
        let options: CameraOptions = defaultCameraOptions;
        options.quality = 100;
        if (this.sourceType) {
            options.sourceType = this.sourceType;
        }

        return options;
    }

    getPicture(options?: CameraOptions): Promise<any> {
        return this.camera.getPicture(options || this.getCameraOptions());
    }

}
