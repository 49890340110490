import { Injectable } from '@angular/core';

import { Pages } from 'src/app/pages';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor() {
  }

  getPage(pagename: string, pages?: any[]): any {
    if(!pages) pages = Pages;

    let idx = pages.findIndex(page => {
      return page.page === pagename;
    });
    if(idx !== -1) {
      return pages[idx];
    } else {
      for(let i=0,il=pages.length;i<il;i++) {
        if(pages[i].sub && pages[i].sub.length > 0) {
          let page = this.getPage(pagename, pages[i].sub);
          if(page) return page;
        }
      }
      return null;
    }
  }

}
