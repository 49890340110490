import { Component, Injector, OnInit } from '@angular/core';
import { DataGroup, DataPage, MetaPage } from 'metagroup';
import { ContactService } from 'services/contact/contact.service';

@Component({
  selector: 'page-contact',
  templateUrl: 'contact.html',
  styleUrls: ['contact.scss']
})
export class ContactPage implements OnInit {
  public readonly pageName: string = "ContactPage";

  contactMeta: MetaPage;
  contact: DataPage;

  itemOpened: boolean = true;
  sending: boolean = false;
  sent: boolean = false;

  constructor(
    public injector: Injector,
    public contactProvider: ContactService,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit KontaktPage');
    let addMissingDataGroups = () => {
      this.contactMeta.groups.forEach(group => {
        if (!this.contact[group.id]) {
          this.contact[group.id] = new DataGroup();
        }
      });
    };
    this.contactProvider.getMeta().then(data => {
      this.contactMeta = data;
      this.contact = new DataPage();
      addMissingDataGroups();
    }, error => {
      console.error('cannot load contact meta', error);
    });
  }

  send() {
    this.sending = true;
    this.contactProvider.create(this.contact).then(data => {
      this.sent = true;
      this.itemOpened = false;
    }, error => {
      console.error('cannot send contact', error);
      this.sending = false;
    });
  }

  open() {
    if (!this.itemOpened) {
      this.itemOpened = true;
    }
  }

}
