import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApplicationService } from 'services/application/application.service';
import { DataFields, DataGroup, DataPage, MetaPage } from 'src/app/metagroup';

// import { Stats } from 'fs';

@Component({
  selector: 'page-application',
  templateUrl: 'application.html',
  styleUrls: ['application.scss']
})
export class ApplicationPage implements OnInit {
  public readonly pageName: string = "ApplicationPage";

  bewerbungMeta: MetaPage;
  bewerbung: DataPage;

  itemOpened: boolean = false;

  constructor(
    public injector: Injector,
    public applicationService: ApplicationService,
    private route: ActivatedRoute,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungPage');
    let addMissingDataGroups = () => {
      this.bewerbungMeta.groups.forEach(group => {
        if (!this.bewerbung[group.id]) {
          if (!group.listtype) {
            this.bewerbung[group.id] = new DataGroup();
          } else {
            this.bewerbung[group.id] = [];
          }
        }
      });
    };
    this.applicationService.getMeta().then(data => {
      this.bewerbungMeta = data;
      this.route.paramMap.subscribe(paramMap => {
        let id = paramMap.get('id');
        if (id === 'add') {
          this.bewerbung = new DataPage();
          addMissingDataGroups();
        } else {
          this.applicationService.get(id).then(data => {
            this.bewerbung = data;
            addMissingDataGroups();
          });
        }
      })
    });
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave BewerbungPage');
  }

  save() {
    if (this.bewerbung.id) {
      this.applicationService.update(this.bewerbung.id, this.bewerbung).then(data => {
        this.itemOpened = false;
      });
    } else {
      this.applicationService.create(this.bewerbung).then(data => {
        this.navController.pop();
        this.navController.navigateForward('bewerbung/' + data.id);
      });
    }
  }

  cancel() {
    if (this.bewerbung.id) {
      this.itemOpened = false;
      this.ngOnInit();
    } else {
      this.close();
    }
  }

  isHRUnionRequest() {
    // die Casts sind notwendig dank den Union-Types
    const status = this.bewerbung.status as any as DataPage;
    const fieldData = status.fieldData as any as DataFields;
    return fieldData.status === 'angefragt';
  }

  approve() {
    this.applicationService.approve(this.bewerbung.id).then(data => {
      this.bewerbung = data;
    })
  }

  decline() {
    this.applicationService.decline(this.bewerbung.id).then(data => {
      this.bewerbung = data;
    })
  }

  open() {
    if (!this.itemOpened) {
      this.itemOpened = true;
    }
  }

  close() {
    this.navController.navigateRoot('bewerbungen');
  }

}
