import { Injectable } from "@angular/core";
import { isArray, isObject } from "lodash";
import { QSObject } from "./qsobject";

@Injectable()
export class QueryStringService {
    public defaultPagerSize: number = 100;
    public customParams: any;

    private setPagerParam(pager?: any): string {
        let index: number = 0;
        let size: number = this.defaultPagerSize;

        try {
            if(isArray(pager)) {
                index = pager[0];
                size = pager[1];
            } else if(isObject(pager)) {
                let pagerKeys: string[] = Object.keys(pager);
                index = pager[pagerKeys[0]];
                size = pager[pagerKeys[1]];
            }
        } catch(error) {
            console.error("ErrorPagerParam: ", error);
        }

        return "p.i="+index+"&p.s="+size;
    }
    private setParams(queryStringObject?: any, paramName?: string): string {
        let params: string = "";
        if(!queryStringObject) {
            return params;
        }
        try {
            if(isObject(queryStringObject)) {
                Object.getOwnPropertyNames(queryStringObject).forEach(p => {
                    if(typeof queryStringObject[p] !== 'undefined') {
                        let pn = (paramName ? paramName + "." : "") + p;
                        let pv = queryStringObject[p];
                        if(pn === 'f.qs' && typeof pv === 'object') {
                            pv = QSObject.toString(pv); // serialize f.qs QSObject
                        }
                        params += "&" + pn + "=" + pv;
                    }
                });
            }
        } catch(error) {
            console.error("ErrorQSParam: ", error);
        }
        return params;
    }

    public setDefaultPager(): string {
        return this.setPagerParam();
    }

    public generateListUrl(queryStringObject?: any): string {
        if(!queryStringObject) {
            queryStringObject = { pager: true };
        } else if(queryStringObject && !queryStringObject.pager) {
            queryStringObject.pager = true;
        }

        return this.generateUrl(queryStringObject);
    }

    public generateUrl(queryStringObject?: any): string {
        if(!queryStringObject) {
            return "";
        }

        let queryString: string = "";
        if(queryStringObject.pager) {
            queryString += this.setPagerParam(queryStringObject.pager);
        }
        if(queryStringObject.filter) {
            queryString += this.setParams(queryStringObject.filter, "f");
        }
        if(queryStringObject.other) {
            queryString += this.setParams(queryStringObject.other);
        }
        if(isObject(this.customParams)) {
            queryString += this.setParams(this.customParams);
        }

        if(queryString.length > 0) {
            queryString = "?" + queryString.substr(queryString.charAt(0) === "&" ? 1 : 0);
        }

        // encodeURIComponent() ?
	    return queryString;
    }
}
