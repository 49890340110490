import { AuthService } from './auth.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class PublicGuard  {

    constructor(
        public authProvider: AuthService,
        public router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authProvider.user) {
            console.log('PUBLIC-GUARD: not logged in');
            // not authorised so return true
            return true;
        }
        
        // logged in so redirect to dashboard page with the return url
        console.log('PUBLIC-GUARD: logged in, redirecting to dashboard page');
        this.router.navigate(['/dashboard']);
        return false;
    }
}
