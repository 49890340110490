import { Component, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MetaFile } from 'metagroup';
import { OnlineCoursePage } from 'pages/online-course/online-course';
import { BranchConfigService } from "services/branch-config/branch-config";
import { ModalService } from 'services/modal/modal.service';
import { OnlineCourse } from 'services/onlinecourse/onlinecourse';

@Component({
  selector: 'onlinecourse',
  templateUrl: 'onlinecourse.html',
  styleUrls: ['onlinecourse.scss']
})
export class OnlincourseComponent {

  @Input() onlineCourse: OnlineCourse;
  @Input() otherOnlinecourse?: OnlineCourse[];
  @Input() hideBuying?: boolean = false;
  @ViewChild('videoElem', { static: true }) videoElement;
  playing: boolean;
  protected cycleSrc: boolean = false;

  constructor(
    public branch: BranchConfigService,
    private router: Router,
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {
  }

  openOnlinecourse() {
    this.modalService.showModal({ component: OnlineCoursePage, componentProps: { videos: [this.onlineCourse], video: this.onlineCourse }, cssClass: 'modal-overlay' });
  }

  fotoUrl(): string {
    if (!!this.onlineCourse && !!this.onlineCourse.foto) {
      let file: MetaFile = <MetaFile>this.onlineCourse.foto;
      return "url(data:" + file.filetype + ";base64," + file.value + ")";
    } else {
      return null;
    }
  }

}