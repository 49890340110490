import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from "@capacitor/core";
import { Buffer } from 'buffer';
import { initializeApp } from 'firebase/app';
import { ApiService } from "services/api/api.service";
import { AuthResponse } from "services/auth/auth-response.interface";
import { AuthService } from "services/auth/auth.service";
import { BranchConfigService } from "services/branch-config/branch-config";
import { EventKey } from "services/events/events.keys";
import { EventsService } from "services/events/events.service";
import { environment } from "src/environments/environment";

export class FCMToken {
    token: string;
}

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService extends ApiService<FCMToken> {

    public tokenReady: Promise<FCMToken>;

    private fcmToken: FCMToken;
    private optionalFireEvents: (payload) => void;
    private resolveTokenReady: any;

    constructor(
        private branchConfigProvider: BranchConfigService,
        private authService: AuthService,
        private events: EventsService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super('pushdevice');
        this.fcmToken = new FCMToken();
        this.tokenReady = new Promise((resolve, reject) => {
            this.resolveTokenReady = resolve;
        });

        // Register with AuthService for login and logout events
        this.authService.registerLoginCallback((user: AuthResponse) => this.createTokenForUser(user));
        this.authService.registerLogoutCallback((user: AuthResponse) => this.deleteTokenForUser(user));
    }

    /**
     * Checks branch if push notifications are enabled.
     * @returns a promise containint true if push notifications are enabled
     */
    public async isEnabled(): Promise<boolean> {
        let config = await this.branchConfigProvider.isReady;
        return !!config && !!config.features && config.features.pushNotifications;
    }

    public async initPushNotifications(optionalFireEvents?: (payload) => void) {
        const pushNotificationsEnabled = await this.isEnabled();
        if (pushNotificationsEnabled) {
            if (optionalFireEvents) {
                this.optionalFireEvents = optionalFireEvents;
            }

            if (Capacitor.isNativePlatform()) {
                this.addMobileListener();
            } else {
                initializeApp(environment.firebase);
                this.addWebListener();
            }

            FirebaseMessaging.requestPermissions().then(permisson => {
                if (permisson.receive === 'granted') {
                    console.debug('FIREBASE: Permission granted');
                    FirebaseMessaging.getToken({
                        vapidKey: environment.firebase.vapidKey,
                    }).then(event => {
                        console.debug('FIREBASE: Got token: ', event?.token);
                        this.fcmToken.token = event.token;
                        this.resolveTokenReady(this.fcmToken);
                        // create token for user if user is already logged in
                        if (this.authService.user) {
                            this.createTokenForUser(this.authService.user);
                        }
                    });
                } else {
                    console.error('Error while registering firebase token');
                }
            });
        }
    }

    private async createTokenForUser(user: AuthResponse): Promise<any> {
        const pushNotificationsEnabled = await this.isEnabled();
        if (pushNotificationsEnabled && this.fcmToken.token && user) {
            this.create(this.fcmToken).then((result) => {
                console.debug('FIREBASE: Successfully saved firebase token');
            })
        }
    }

    private async deleteTokenForUser(user: AuthResponse): Promise<any> {
        const pushNotificationsEnabled = await this.isEnabled();
        if (pushNotificationsEnabled && this.fcmToken.token && user) {
            this.delete(this.fcmToken.token).then((result) => {
                console.debug('FIREBASE: Successfully deleted firebase token');
            })
        }
    }

    private addMobileListener() {
        FirebaseMessaging.removeAllListeners().then(() => {
            console.debug('FIREBASE: Now listening for messages');

            // Show us the notification payload if the app is open on our device
            FirebaseMessaging.addListener('notificationReceived', (event) => {
                console.debug('FIREBASE: Message received: ', event);
                this.fireEvents(event?.notification?.data, true);
            });

            // Method called when tapping on a notification
            FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
                console.debug('FIREBASE: Push action performed: ', event);
                this.fireEvents(event?.notification?.data);
            });
        });
    }

    private addWebListener() {
        console.debug('FIREBASE: Now listening for messages');
        // listen for notifications while the app is open
        // message comes from firebase-messaging-sw-js
        navigator.serviceWorker.addEventListener('message', (event) => {
            // foreground notification: event.data.data
            // background notification: event.data
            const appWasInForeground: boolean = !!event.data && !!event.data.data;
            const data = appWasInForeground ? event.data.data : event.data;
            console.debug('FIREBASE: Message received from service worker: ', data);
            this.fireEvents(data, appWasInForeground);
        });
        // listen for notifications while the app is closed
        // message comes from firebase-messaging-sw-js
        this.route.queryParams.subscribe((params) => {
            let notification = params['notification'];
            if (!!notification) {
                // parse query param
                let data = JSON.parse(Buffer.from(notification, 'base64').toString('binary'));
                // remove query param so it is not present for reloads
                this.router.navigate([], { queryParams: { 'notification': null }, queryParamsHandling: 'merge' });
                console.debug('FIREBASE: Notification read from url: ', data);
                this.fireEvents(data);
            }
        });
    }

    private fireEvents(payload: any, appWasInForeground: boolean = false) {
        if (this.optionalFireEvents) {
            console.log("executed optional fireEvents");
            this.optionalFireEvents(payload);
        }

        if (payload.type) {
            // event for incoming jitsi call
            if (payload.type === "jitsi:call") {
                this.events.publish(EventKey.JITSI_CALL, JSON.parse(payload.callInfo), payload.userId);
            }
        }
        // else case is for cometchat push notifications for now since cometchat does not provide any attribute that we could use to identify the push notification
        else {
            if (false == appWasInForeground) {
                this.router.navigate(['/chat']);
            }
        }
    }
}