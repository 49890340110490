import { PipeTransform, Pipe } from '@angular/core';

// USAGE: [innerHtml]="text | highlight:search"

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
    transform(text: string, search): string {
        if(search) {
            let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            pattern = pattern.split(' ').filter((t) => {
                return t.length > 0;
            }).join('|');
            let regex = new RegExp(pattern, 'gi');

            return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
        } else {
            return text;
        }
    }
}