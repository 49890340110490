import { Injectable } from "@angular/core";
import { ApiService } from "services/api/api.service";
import { ChapterElement } from "./chapter-element";

@Injectable({
  providedIn: 'root'
})
export class ChapterElementService extends ApiService<ChapterElement> {

  constructor() {
    super('onlinecourse/chapter/element');
  }
}