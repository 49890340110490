import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Jitsi } from 'capacitor-jitsi-meet';
import { DataPage } from 'metagroup';
import { BewerbungsserviceJitsiModalPage } from 'pages/bewerbungsservice-jitsi-modal/bewerbungsservice-jitsi-modal';
import { AuthService } from 'services/auth/auth.service';
import { ModalService } from 'services/modal/modal.service';
import { Userdata, UserdataService } from 'services/userdata/userdata.service';

export class JitsiCallInfo {
    domain: string;
    options: {
        roomName: string;
        parentNode?: HTMLElement;
        width?: string; // '100%'
        height?: string;
        [propName: string]: any; // TODO: usw.
    };
}

@Injectable()
export class JitsiService {
    webapiUrl: string;
    inited: Promise<void>;
    modal: HTMLIonModalElement;

    constructor(
        private authProvider: AuthService,
        public userdataProvider: UserdataService,
        public modalService: ModalService,
        public platform: Platform
    ) { }

    /**
     * Start a video chat. This method opens the video chat by calling the browser or native API, depending on the current environment.
     * @param caller is the caller
     * @param callInfo contains info about the call
     */
    public startVideoChat(caller: DataPage, callInfo: JitsiCallInfo) {
        console.debug('JITSI: Active platforms: ', this.platform.platforms());
        if (this.platform.is("android") || this.platform.is("ios")) {
            // use mobile custom logic
            this.openMobile(callInfo);
        } else {
            // create and show modal
            this.loadUserdata((userdata: Userdata) => {
                this.modalService.createModal({ component: BewerbungsserviceJitsiModalPage, componentProps: { callInfo: callInfo, caller: caller, userdata: userdata }, cssClass: 'modal-large', backdropDismiss: false }).then(modal => {
                    this.modal = modal;
                    this.modal.present();
                });
            });
        }
    }

    /**
     * Initialize the browser API for jitsi. This should only be called after the modal has been created!
     * @param callInfo contains the call info
     */
    public initBrowserApi(callInfo: JitsiCallInfo): void {
        if (!this.modal) {
            throw new Error("The jitsi modal must have been created before the API can be initialized!");
        }
        console.debug('JITSI: Opening video call for browser.', callInfo);
        if (!this.inited) {
            // add the jitsi script to HTML, then done, resolve this promise
            this.inited = new Promise((resolve, reject) => {
                const scriptElement = document.createElement('script');
                this.webapiUrl = callInfo.domain + '/external_api.js';
                scriptElement.setAttribute('src', this.webapiUrl);
                scriptElement.onload = (event) => {
                    resolve();
                };
                scriptElement.onerror = (error) => {
                    reject(error);
                };
                document.head.appendChild(scriptElement);
            });
        }
        // when the jitsi script has been added successfully, call the jitsi api
        this.inited.then(() => {
            // cut https:// or http:// from domain
            const domain = callInfo.domain.startsWith('https://') ? callInfo.domain.slice('https://'.length) : (callInfo.domain.startsWith('http://') ? callInfo.domain.slice('http://'.length) : callInfo.domain);
            // @ts-ignore this class exists after dynamically loading the jitsi api script
            const api = new JitsiMeetExternalAPI(domain, callInfo.options);
            // when the user leaves the video conference in any way, the modal should close 
            api.addListener('videoConferenceLeft', () => {
                this.modal.dismiss();
            });
            // on modal close, close this api connection as well
            this.modal.onDidDismiss().then(() => {
                api.dispose()
            });
        }, error => {
            console.error('JitsiProvider.open: Init failure:', error);
        });
    }

    private openMobile(callInfo: JitsiCallInfo): void {
        this.loadUserdata((userdata: Userdata) => {
            console.debug('JITSI: Opening video call for mobile.', callInfo);
            Jitsi.joinConference({
                // required parameters
                roomName: callInfo.options.roomName, // room identifier for the conference
                url: callInfo.domain, // endpoint of the Jitsi Meet video bridge

                // recommended settings for production build. see full list of featureFlags in the official Jitsi Meet SDK documentation
                featureFlags: {
                    'prejoinpage.enabled': false, // go straight to the meeting and do not show the pre-join page
                    'recording.enabled': false, // disable as it requires Dropbox integration
                    'live-streaming.enabled': false, // 'sign in on Google' button not yet functional
                    'android.screensharing.enabled': false, // experimental feature, not fully production ready
                },

                // optional parameters
                displayName: userdata.name, // user's display name
                // email: 'email', // user's email
                avatarURL: userdata.imgUrl, // user's avatar url
                startWithAudioMuted: true, // start with audio muted, default: false
                startWithVideoMuted: false, // start with video muted, default: false
                chatEnabled: false, // enable Chat feature, default: true
                inviteEnabled: false, // enable Invitation feature, default: true

                // advanced parameters (optional)
                // token: "String", // jwt authentication token
                // configOverrides: { 'p2p.enabled': false }, // see list of config overrides in the official Jitsi Meet SDK documentation
            }).then(answer => {
                console.debug('JITSI MOBILE: joinConference done');
                console.debug(answer) // { success: true }

                window.addEventListener('onConferenceJoined', () => {
                    console.debug('JITSI MOBILE: onConferenceJoined');
                    // do things here
                });
                window.addEventListener('onConferenceTerminated', () => {
                    console.debug('JITSI MOBILE: onConferenceTerminated');
                    // do things here
                });
                window.addEventListener('onConferenceLeft', () => {
                    console.debug('JITSI MOBILE: onConferenceLeft');
                    // do things here
                });

                Jitsi.leaveConference().then(answer => {
                    console.log() // { success: true }
                });
            });
        });
    }

    /**
     * Check if the user is logged in and load userdata of the current user. Execute the onSuccess function on success.
     * @param onSuccess is called on success
     */
    private loadUserdata(onSuccess) {
        if (!!this.authProvider.user) {
            this.userdataProvider.get().then((userdata: Userdata) => {
                onSuccess(userdata);
            }, error => {
                console.error('JITSI: Loading userdata failed.', error);
            });
        }
    }
}