import { Component, Injector, OnInit } from '@angular/core';

@Component({
  selector: 'page-impressum',
  templateUrl: 'impressum.html',
  styleUrls: ['impressum.scss']
})
export class ImpressumPage implements OnInit {
  public readonly pageName: string = "ImpressumPage";

  constructor(
    public injector: Injector,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit ImpressumPage');
  }

}
