import { Injectable } from "@angular/core";

/**
 * NOTE: please prefer the static (AOT-compiled) configuration already existing over this configuration method,
 *       unless it is necessary to change the application configuration during the runtime (i.e. e.g. apiBaseUrl provided by branch.io etc.)
 */

export class RuntimeConfigProperties {
    [propName: string]: any;
}

@Injectable()
export class RuntimeConfigService {
    protected properties: RuntimeConfigProperties = {};

    get(name: string) {
        return this.properties.hasOwnProperty(name) ? this.properties[name] : null;
    }
    set(name: string, value: any) {
        this.properties[name] = value;
    }

    constructor(
    ) {
    }
}