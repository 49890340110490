import { Injectable } from "@angular/core";
import { ApiService } from "services/api/api.service";
import { Privacy } from "./privacyUrls";

@Injectable({
    providedIn: 'root'
})
export class PrivacyService extends ApiService {

    constructor() {
        super('privacy');
    }

    getUrls(): Promise<Privacy> {
        return this.httpGet('', '');
    }
}