// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .podcasts {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-bottom: -30px;
}
:host .podcasts podcast {
  flex: 1 1;
  flex-basis: calc(33% - 30px);
  min-width: 200px;
  margin-right: 30px;
  margin-bottom: 30px;
}
:host .right-links {
  padding-top: 20px;
  margin-bottom: -30px;
  text-align: right;
}
:host .right-links a {
  color: var(--cdz-primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/podcast-list/podcast-list.scss"],"names":[],"mappings":"AACE;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,oBAAA;AAAN;AACM;EACE,SAAA;EACA,4BAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACR;AAGI;EACE,iBAAA;EACA,oBAAA;EACA,iBAAA;AADN;AAEM;EACE,0CAAA;AAAR","sourcesContent":[":host {\n  .podcasts {\n      display: flex;\n      flex-wrap: wrap;\n      margin-right: -30px;\n      margin-bottom: -30px;\n      podcast {\n        flex: 1 1;\n        flex-basis: calc(33% - 30px);\n        min-width: 200px;\n        margin-right: 30px;\n        margin-bottom: 30px;\n      }\n    }\n  \n    .right-links {\n      padding-top: 20px;\n      margin-bottom: -30px;\n      text-align: right;\n      a {\n        color: var(--cdz-primary-color) !important;\n      }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
