import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { ConstantVariable } from 'constant-variable';
import { Podcast } from "services/podcasts/podcasts.service";
import { ModalService } from 'services/modal/modal.service';
import { NavController } from '@ionic/angular';
import { RoutingService } from 'services/routing/routing.service';
import { PodcastViewMoreModalPage } from 'pages/podcast-view-more-modal/podcast-view-more-modal';

@Component({
  selector: 'podcast',
  templateUrl: 'podcast.html',
  styleUrls: ['podcast.scss']
})
export class PodcastComponent implements OnDestroy {
  @Input() podcast: Podcast;
  @Input() otherPodcasts?: Podcast[];
  @Input() hideBuying?: boolean = false;
  @Input() isInList?: boolean = false;
  @ViewChild('audio', { static: true }) audio;
  showAllFeatures: boolean;
  beep = '../../../assets/audio/beep.mp3';
  playing: boolean;

  constructor(
    public branch: BranchConfigService,
    private modalService: ModalService,
    private navController: NavController,
    private routingService: RoutingService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
    this._onended = this.onended.bind(this);
    this.playing = false;
  }

  ngOnDestroy() {
    this.unbindevents();
  }

  play() {
    if (this.isInList) {
      this.modalService.showModal({component: PodcastViewMoreModalPage, componentProps: { podcasts: [this.podcast], podcast: this.podcast }, cssClass: 'modal-overlay'});
    }
    else {
      this.playing = true;
      this.audio.nativeElement.play();
      this.bindevents();
    }
    //this.onupdatecontrols(null);
  }

  stop() {
    this.playing = false;
    this.audio.nativeElement.pause();
    this.unbindevents();
  }

  bindevents() {
    let audio: HTMLAudioElement = (<HTMLAudioElement>this.audio.nativeElement);
    audio.addEventListener('ended', this._onended);
    audio.addEventListener('abort', this._onended);
    audio.addEventListener('error', this._onended);
    audio.addEventListener('emptied', this._onended);
  }
  unbindevents() {
    let audio: HTMLAudioElement = (<HTMLAudioElement>this.audio.nativeElement);
    audio.removeEventListener('ended', this._onended);
    audio.removeEventListener('abort', this._onended);
    audio.removeEventListener('error', this._onended);
    audio.removeEventListener('emptied', this._onended);
  }

  _onended: (event: any) => any;
  onended(event: any) {
    this.stop();
  }

  buy() {
    let podcasts: Podcast[] = [this.podcast];
    if (this.otherPodcasts) {
      this.otherPodcasts.forEach(podcast => {
        if (podcast.needstobepaid && !podcast.hasbeenpaid) {
          podcasts.push(podcast);
        }
      });
    }
    console.log('PODCAST: start', this.otherPodcasts);
    this.modalService.showModal(
      {component: PodcastViewMoreModalPage, componentProps: { podcasts: podcasts, podcast: this.podcast }, cssClass: 'modal-overlay'},
      (podcast: Podcast) => {
        if (podcast) { // could be another one of the podcasts
          //?
        }
      });
  }
}
