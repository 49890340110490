import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaFile } from 'src/app/metagroup';
import { BranchConfigService } from '../branch-config/branch-config';
import { Payable, PayableService } from '../payable/payable.service';
import { ProductService } from 'services/product/product.service';

export class Webinar extends Payable {
  id: number;
  tippgroupId: string;
  category: string;
  title: string;
  date: string;
  duration: number;
  image: MetaFile;
  url: string;
  previewurl: string;
  html: string;
  eventDate: Date;
}

/**
 * @deprecated use {@link ProductService} instead
 */
@Injectable({
  providedIn: 'root'
})
export class WebinarService extends PayableService<Webinar> {

  constructor(
    public http: HttpClient,
    public configProvider: BranchConfigService) {
    super('webinar', http, configProvider);
  }
}
