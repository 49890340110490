import { Injectable } from '@angular/core';
import { DataFields, DataPage, MetaPage } from 'metagroup';
import { ApiService } from 'services/api/api.service';

export class JobboerseApplyMdata {
  templates: DataFields[];
  meta: MetaPage;
}

@Injectable({
  providedIn: 'root'
})
export class JobboerseService extends ApiService<any> {

  constructor() {
    super('jobboerse');
  }

  importJobFromLink(link: string): Promise<DataPage> {
    return this.httpPost('import', '', { link: link });
  }

  /**
   * application meta data for apply
   * 
   * @returns 
   */
  getApplyMeta(id: number): Promise<JobboerseApplyMdata> {
    return this.httpGet('apply/mdata', id);
  }

  /**
   * application data for apply
   * 
   * @param id job id
   * @returns 
   */
  getApplyData(id: number): Promise<DataPage> {
    return this.httpGet('apply/data', id);
  }

  postApplyData(data: DataPage): Promise<DataFields> {
    return this.httpPost('apply/data', '', data);
  }
}
