import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataPage, MetaGroup, MetaId, MetaPage } from 'metagroup';
import { Pages } from 'src/app/pages';
import { BranchConfigService } from '../branch-config/branch-config';
import { PagesService } from '../pages/pages.service';
import { UserdataService } from '../userdata/userdata.service';

export class BewerbungsmappeStatus {
  progress: number;
}

@Injectable({
  providedIn: 'root'
})
export class BewerbungsmappeService {

  metapages: MetaPage[] = null;
  pages: any[];

  constructor(
    public http: HttpClient,
    public pagesProvider: PagesService,
    public userdataProvider: UserdataService,
    public branchConfigProvider: BranchConfigService,
  ) {
    this.pages = Pages;
  }

  getMeta(): Promise<MetaPage[]> {
    if (this.metapages) {
      return new Promise<MetaPage[]>((resolve) => {
        resolve(this.metapages);
      });
    } else {
      return new Promise<MetaPage[]>((resolve, reject) => {
        return this.branchConfigProvider.isReady.then(() => {
          this.http.get<MetaPage[]>('/api/bewerbermappemeta').subscribe(data => {
            this.metapages = data;
            resolve(data);
          }, err => {
            reject(err);
          });
        })
      });
    }
  }

  getStatus(): Promise<BewerbungsmappeStatus> {
    return new Promise<BewerbungsmappeStatus>((resolve, reject) => {
      this.branchConfigProvider.isReady.then(() => {
        this.http.get<BewerbungsmappeStatus>('/api/bewerbermappestatus').subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    });
  }

  //getData(metaGroupTitle: string): Promise<DataGroup> {
  //getData(metaGroupId: MetaId): Promise<DataGroup> {
  getData(metaPageId: MetaId): Promise<DataPage> {
    return new Promise<DataPage>((resolve, reject) => {
      this.branchConfigProvider.isReady.then(() => {
        this.http.get<DataPage>('/api/bewerbermappedata/' + metaPageId).subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    });
  }

  //postData(metaGroupId: MetaId, dataGroup: DataGroup): Promise<void> { //TODO: or is there a response of some kind? (server validation?)
  postData(metaPageId: MetaId, dataPage: DataPage): Promise<void> { //TODO: or is there a response of some kind? (server validation?)
    return new Promise<void>((resolve, reject) => {
      this.branchConfigProvider.isReady.then(() => {
        this.http.post<void>('/api/bewerbermappedata/' + metaPageId, dataPage).subscribe(data => {
          this.userdataProvider.refresh();
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    });
  }

  updateSubpages(): Promise<MetaGroup[]> {
    return new Promise<MetaGroup[]>((resolve, reject) =>
      this.branchConfigProvider.isReady.then(() => {
        this.http.get<MetaGroup[]>('/api/bewerbermappemeta/groups').subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      })
    ).then((metapages) => {
      let updateIn = (pagename: string, excludemetapageids: MetaId[]) => {
        let page = this.pagesProvider.getPage(pagename);
        if (page) {
          if (page.sub) {
            if (page.sub.length > 0) {
              page.sub.splice(0, page.sub.length); // reference safe
            }
          } else {
            page.sub = [];
          }
          metapages.forEach((metapage, index) => {
            let subpage = {
              title: metapage.title,
              page: pagename,
              feature: 'bewerbungsmappe',
              params: { metapage: metapage.id },
              active: false,
              sub: null,
            };
            let idx = excludemetapageids.indexOf(metapage.id);
            if (idx === -1) {
              page.sub.push(subpage);
            }
          });
        }
      };
      updateIn('BewerbungsmappePage', ['zusammenfassung']);  // IMPORTANT: the page id "zusammenfassung" is thus set in stone.
      updateIn('JobboerseBewerbermappePage', ['person']);  // IMPORTANT: the page id "person" is thus set in stone. // this page is deeper down
      //console.log('pages after bewerbermappe: ' + JSON.stringify(Pages,null,2));
      return metapages;
    });
  }

}
