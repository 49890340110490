import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: 'app-pager',
  templateUrl: 'pager.html',
  styleUrls: ['pager.scss']
})
export class PagerComponent implements OnInit {
  @Input() totalpages: number;
  @Input() currentpage: number;
  @Output() currentpageChange = new EventEmitter<number>();

  constructor() {

  }

  ngOnInit() {

  }

  go(step: number) {
    this.currentpage += step;
    if(this.currentpage >= this.totalpages) {
      this.currentpage = this.totalpages - 1;
    }
    if(this.currentpage < 1) {
      this.currentpage = 0;
    }
    this.currentpageChange.next(this.currentpage);
  }
}
