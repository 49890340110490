import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'services/auth/auth.service';
import { MessageService } from 'services/message/message.service';
import { ProductType } from 'services/product/product-type';
import { ConstantRoute } from 'src/app/constant-routes';
import { BaseList } from '../baselist/baselist';
import { BranchConfigService } from '../branch-config/branch-config';
import { Payable } from '../payable/payable.service';

export class PaidGood<Good extends Payable> {
  id: number;
  paymenttoken: string; // paymenttoken to identify the paidgood during the stages of the payment process
  created: string; // formatted timestamp of start of the payment process
  paymenttime?: string; // formatted timestamp of payment fulfillment, is null or missing, if not yet paid
  price: number; // original asking price when the user decided to buy - the amount the user paid/has to pay (asking price contained in good.price may have changed in the meantime)
  currency: string; // original asking currency
  name: string; //PayResource title
  description: string; // PayResource description

  //when creating, only the following properties need to be specified, the others are set by the backend
  type: string; // the type of Good contained
  good: Good; // the actual bought good (something that inherits Payable)

  constructor(good?: Good, type?: GoodType) {
    this.good = good || null;
    this.type = type || null;
  }
}


export enum GoodType {
  PODCAST = ProductType.PODCAST,
  VIDEO = ProductType.VIDEO,
  BEWERBUNGSTIPP = ProductType.ADVICE,
  COACHING = ProductType.COACHING,
  SERVICE = ProductType.SERVICE,
  WEBINAR = ProductType.WEBINAR,
  ONLINECOURSE = ProductType.COURSE,

  CHECKLIST = "not-used-anymore",
  IMAGE = "not-used-anymore"
}

/**
 * @deprecated Use ProductService instead
 */
@Injectable({
  providedIn: 'root'
})
export class PaidGoodsService {

  constructor(
    public http: HttpClient,
    public configProvider: BranchConfigService,
    public message: MessageService,
    public alertCtrl: AlertController,
    public translate: TranslateService,
    public authProvider: AuthService,
    private router: Router,
    private location: Location,
  ) {
  }

  buildPaymentUrl(type: ProductType, id: number): URL {
    const url = new URL(this.configProvider.config.apiBaseUrl + '/paid-good/add/' + type + '/' + id);
    url.searchParams.append('success_url', window.location.origin + this.location.prepareExternalUrl(this.router.serializeUrl(this.router.createUrlTree([ConstantRoute.FULL_ORDER.replace(ConstantRoute.PLACEHOLDER_ID, '')]))));
    url.searchParams.append('cancel_url', window.location.href);
    url.searchParams.append('access_token', this.authProvider.user.access_token);
    return url;
  }

  getList(): Promise<BaseList<PaidGood<Payable>>> {
    return this.configProvider.isReady.then(() => {
      return new Promise<BaseList<PaidGood<Payable>>>((resolve, reject) => {
        this.http.get<BaseList<PaidGood<Payable>>>('/api/paid-good/list').subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }, error => {
      return Promise.reject(error);
    });
  }

  get(id: number): Promise<PaidGood<Payable>> {
    return this.configProvider.isReady.then(() => {
      return new Promise<PaidGood<Payable>>((resolve, reject) => {
        this.http.get<PaidGood<Payable>>('/api/paid-good/' + id).subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }, error => {
      return Promise.reject(error);
    });
  }

  // create(paidGood: PaidGood<Payable>): Promise<PaidGood<Payable>> {
  //   return this.configProvider.isReady.then(() => {
  //     return new Promise<PaidGood<Payable>>((resolve, reject) => {
  //       this.http.post<PaidGood<Payable>>('/api/paid-good/add', paidGood).subscribe(paidGood => {
  //         this.message.subject.next({ paidGood: paidGood });
  //         resolve(paidGood);
  //       }, err => {
  //         reject(err);
  //       });
  //     });
  //   }, error => {
  //     return Promise.reject(error);
  //   });
  // }

  // makePayment(paidGood: PaidGood<Payable>): Promise<PaidGood<Payable>> {
  //   return this.configProvider.isReady.then((config: BranchConfig) => {
  //     return new Promise<PaidGood<Payable>>((resolve, reject) => {

  //       // now bring up Paypalplus payment process... (won't work as of now, as there is no backend)
  //       window.open(config.apiBaseUrl + '/paypalplus/checkout?paymenttoken=' + encodeURIComponent(paidGood.paymenttoken), '_system');

  //       //NOTE: as there is no defined way of knowing when the payment process is completed, let's just ask the user for now
  //       //TODO: change that (server push, polling, whatever)!
  //       let alert = this.alertCtrl.create({
  //         title: this.translate.instant('paidgood.didyoupay.title'),
  //         subTitle: this.translate.instant('paidgood.didyoupay.subtitle'),
  //         buttons: [
  //           {
  //             text: this.translate.instant('paidgood.didyoupay.cancel'),
  //             role: 'cancel',
  //             handler: () => {
  //               resolve(paidGood);
  //             }
  //           },
  //           {
  //             text: this.translate.instant('paidgood.didyoupay.continue'),
  //             handler: () => {

  //               //
  //               //... when payment is SUCCESSFULLY COMPLETED (TODO: define how frontend will be informed by backend about that), THEN do the following
  //               //

  //               this.get(paidGood.id).then(paidGood => {
  //                 this.message.subject.next({ paidGood: paidGood });
  //                 resolve(paidGood);
  //               }, err => {
  //                 reject(err);
  //               });

  //             }
  //           }
  //         ]
  //       });
  //       alert.present();

  //     });
  //   }, error => {
  //     return Promise.reject(error);
  //   });
  // }

}
