import { NavController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from 'services/routing/routing.service';

@Component({
  selector: 'page-step',
  templateUrl: 'page-step.html',
  styleUrls: ['page-step.scss']
})
export class PageStepComponent {
  @Input() pages: any[];
  @Input() step?: number = 1;
  @Input() disabled?: boolean;
  @Input() overflow?: any;

  constructor(
    private router: Router,
    private navController: NavController,
    private routingService: RoutingService
  ) {}

  canStep(): boolean {
    let idx = this.pages.findIndex(page => {
      return page.active;
    });
    idx += this.step;
    if(idx >= 0 && idx < this.pages.length) {
      return true;
    } else {
      return !!this.overflow;
    }
  }

  doStep() {
    if(this.disabled) return;
    // find active page (tab)
    let idx = this.pages.findIndex(page => {
      return page.active;
    });

    // find next tab
    idx += this.step;
    let page;
    if(idx >= 0 && idx < this.pages.length) {
      page = this.pages[idx];
    } else {
      if(this.overflow) {
        page = this.overflow;
      }
    }

    // set new page (tab) active
    this.routingService.resetActive(page);

    // navigate to new page (tab)
    this.navController.navigateRoot(this.routingService.getPathReplaceAny(page.page, page.params));
  }
}
