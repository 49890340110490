import { CommonModule, DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NgModule, enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonItemSliding, IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ApiInterceptor } from 'interceptors/api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { MyApp } from './app.component';
import { DirectivesModule } from './directives/directives.module';
import { StorageService } from './services/storage/storage.service';
/**********/
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// NOTE : imported using the 'path' specified in tsconfig...
import { ComponentsModule } from 'components/components.module';
import { PipesModule } from 'pipes/pipes.module';
import { AccountdataService } from 'services/accountdata/accountdata.service';
import { BewerbungsmappeService } from 'services/bewerbungsmappe/bewerbungsmappe.service';
import { ApplicationService } from 'services/application/application.service';
import { BewerbungstippsProvider } from 'services/bewerbungstipps/bewerbungstipps';
import { BranchConfigService } from 'services/branch-config/branch-config';
import { ChecklistService } from 'services/checklist/checklist.service';
import { CoachService } from 'services/coach/coach.service';
import { UserCoachService } from 'services/coach/user-coach.service';
import { ContractAvailableDocumentService } from 'services/contract-available-document/contract-available-document.service';
import { ContractUploadedDocumentService } from 'services/contract-uploaded-document/contract-uploaded-document.service';
import { FileProvider } from 'services/file/file.provider';
import { JobboerseService } from 'services/jobboerse/jobboerse.service';
import { MetaValidityService } from 'services/meta-validity/meta-validity.service';
import { ModalService } from 'services/modal/modal.service';
import { CometchatProvider } from "services/nachrichten/cometchat";
import { NachrichtenProvider } from 'services/nachrichten/nachrichten';
import { NachrichtenMocker } from 'services/nachrichten/nachrichten.mocker';
import { OnboardingContentService } from 'services/onboarding-content/onboarding-content.service';
import { PagesService } from 'services/pages/pages.service';
import { PaidGoodsService } from 'services/paid-goods/paid-goods.service';
import { PodcastsService } from 'services/podcasts/podcasts.service';
import { RegisterService } from 'services/register/register.service';
import { RegisterMocker } from 'services/register/register.mocker';
import { ToastService } from 'services/toast/toast.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { VideoService } from 'services/video/video.service';
import { WebinarService } from 'services/webinar/webinar.service';
/**********/
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PagesModule } from 'pages/pages.module';
import { ImageCacheProvider } from 'pipes/image/image.pipe';
import { ApiAuthOptions, ApiAuthRegexp } from 'services/auth/auth-config';
import { AuthInterceptor } from 'services/auth/auth.interceptor';
import { AuthService } from 'services/auth/auth.service';
import { CameraService } from 'services/camera/camera.service';
import { ContactService } from 'services/contact/contact.service';
import { DownloadService } from 'services/download/download.service';
import { EventsService } from 'services/events/events.service';
import { JitsiService } from 'services/jitsi/jitsi.service';
import { LanguageService } from 'services/language/language.service';
import { MessageService } from 'services/message/message.service';
import { AuthMocker } from 'services/mock/auth.mocker';
import { MockService } from 'services/mock/mock.service';
import { ConversejsLoaderProvider } from 'services/nachrichten/conversejs-loader';
import { XmppHighLevelProvider } from 'services/nachrichten/xmpp-high-level';
import { XmppLoaderProvider } from 'services/nachrichten/xmpp-loader';
import { XmppLowLevelProvider } from 'services/nachrichten/xmpp-low-level';
import { ChapterElementService } from 'services/onlinecourse/chapter-element.service';
import { ChapterService } from 'services/onlinecourse/chapter.service';
import { OnlineCourseProductService } from 'services/onlinecourse/onlinecourse-product.service';
import { OnlineCourseService } from 'services/onlinecourse/onlinecourse.service';
import { PlatformService } from 'services/platform/platform.service';
import { PushNotificationService } from 'services/push-notification/push-notification.service';
import { RoutingService } from 'services/routing/routing.service';
import { RuntimeConfigService } from 'services/runtime-config/runtime-config.service';
import { FailsafeTranslateLoaderService } from 'services/translate/failsafe-translate-loader.service';
import { ConstantVariable } from './constant-variable';
import { ActivateService } from 'services/activate/activate.service';
import { AlertService } from 'services/alert/alert.service';
import { YoutubeEmbedService } from 'services/youtube-embed/youtube-embed.service';

registerLocaleData(localeDe, localeDeExtra);

export const FIXED_DE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};

// this is the magic wand
enableProdMode();

export function createTranslateLoader(http: HttpClient) {
  return new FailsafeTranslateLoaderService(http, ConstantVariable.remoteI18nPrefix, ConstantVariable.remoteI18nSuffix, './assets/i18n/', '.json');
}

export let appInjector: Injector;

@NgModule({
  declarations: [MyApp],
  bootstrap: [MyApp],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // IonicStorageModule.forRoot(),
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    PagesModule,
    // standalone components:
  ], providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpClient,
    StatusBar,
    SplashScreen,
    MockService,
    RuntimeConfigService,
    { provide: ApiAuthOptions, useValue: ConstantVariable.apiAuthOptions },
    { provide: ApiAuthRegexp, useValue: ConstantVariable.apiAuthRegexp },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: FIXED_DE_FORMATS },
    AuthService,
    ApplicationService,
    BewerbungsmappeService,
    MessageService,
    AuthMocker,
    BranchConfigService,
    BewerbungstippsProvider,
    OnboardingContentService,
    ContractAvailableDocumentService,
    ContractUploadedDocumentService,
    PushNotificationService,
    AccountdataService,
    ToastService,
    UserdataService,
    DownloadService,
    ImageCacheProvider,
    MetaValidityService,
    PodcastsService,
    ChecklistService,
    ContactService,
    JobboerseService,
    PagesService,
    OnlineCourseProductService,
    OnlineCourseService,
    ChapterService,
    ChapterElementService,
    CoachService,
    UserCoachService,
    VideoService,
    WebinarService,
    NachrichtenProvider,
    NachrichtenMocker,
    PaidGoodsService,
    LanguageService,
    RegisterService,
    ActivateService,
    RegisterMocker,
    JitsiService,
    XmppLoaderProvider,
    XmppLowLevelProvider,
    XmppHighLevelProvider,
    ConversejsLoaderProvider,
    FileProvider,
    CometchatProvider,
    ModalService,
    AlertService,
    EventsService,
    CameraService,
    RoutingService,
    StorageService,
    PlatformService,
    YoutubeEmbedService,
    provideHttpClient(withInterceptorsFromDi()),
    DecimalPipe
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    appInjector = this.injector;
  }
}
