import { Component, Input } from '@angular/core';

import { BranchConfigService } from "services/branch-config/branch-config";

@Component({
  selector: 'wrap-modal',
  templateUrl: 'wrap-modal.html',
  styleUrls: ['wrap-modal.scss']
})
export class WrapModalComponent {
  @Input() type: string;

  constructor(
    public branch: BranchConfigService,
  ) {}

}
