// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div[content] > h1.onboarding {
  font-weight: bold;
  padding-left: 50px;
  position: relative;
}
:host div[content] > h1.onboarding::before {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
:host div[content] > h1.onboarding::before {
  font-family: "fontello";
  content: "\\e812";
}`, "",{"version":3,"sources":["webpack://./src/app/pages/onboarding-content/onboarding-content.scss"],"names":[],"mappings":"AAEI;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;AADN;AAEM;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,4BAAA;EACA,wBAAA;EACA,kCAAA;AAAR;AAEM;EACE,uBAAA;EACA,gBAAA;AAAR","sourcesContent":[":host {\n  div[content] > h1 {\n    &.onboarding {\n      font-weight: bold;\n      padding-left: 50px;\n      position: relative;\n      &::before {\n        content: '';\n        display: block;\n        position: absolute;\n        width: 40px;\n        top: 0;\n        left: 0;\n        bottom: 0;\n        background-repeat: no-repeat;\n        background-size: contain;\n        background-position: center center;\n      }\n      &::before {\n        font-family: 'fontello';\n        content: \"\\e812\";\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
