import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Podcast } from 'services/podcasts/podcasts.service';

/**
 * Generated class for the PodcastListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'podcast-list',
  templateUrl: 'podcast-list.html',
  styleUrls: ['podcast-list.scss']
})
export class PodcastListComponent {

  @Input() podcasts?: Podcast[];
  @Input() showRightLinks?: boolean;
  @Input() latest?: boolean;
  @Input() dashboardLayout?: boolean;

  @Output() moreClick: EventEmitter<Object> = new EventEmitter();

  constructor(
    private router: Router
  ) {

  }

  doMore(event) {
    if (event) {
      event.preventDefault();
    }
    this.moreClick.emit('');
  }
}
