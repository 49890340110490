import { Component, Input } from '@angular/core';
import { MetaGroup, MetaField, DataGroup, DataFields } from 'metagroup';
import { LeistungViewMoreModalPage } from 'pages/leistung-view-more-modal/leistung-view-more-modal';
import { ModalService } from 'services/modal/modal.service';

@Component({
  selector: 'leistung',
  templateUrl: 'leistung.html',
  styleUrls: ['leistung.scss']
})

export class LeistungComponent {
  @Input() leistung: DataGroup;
  @Input() group: MetaGroup;
  @Input() leistungen: DataGroup[];

  constructor(private modalService: ModalService) {
  }

  buy(dataGroup: any) {
    let leistungen: DataFields[] = [];
    if (dataGroup.fieldData.needstobepaid && !dataGroup.fieldData.hasbeenpaid) {
      if (this.leistung.fieldData) {

        this.leistungen.filter(a => a.fieldData.needstobepaid && !a.fieldData.hasbeenpaid).forEach(element => {
          leistungen.push(element.fieldData);
        });
      }
      this.modalService.showModal({component: LeistungViewMoreModalPage, componentProps: { leistungen: leistungen, leistung: dataGroup.fieldData }, cssClass: 'modal-overlay'});
    }
  }

  openLeistung() {
    this.modalService.showModal({component: LeistungViewMoreModalPage, componentProps: { leistungen: [this.leistung.fieldData], leistung: this.leistung.fieldData }, cssClass: 'modal-overlay'});
  }

  getFieldSettings(id: String): MetaField {
    return this.group.fields.find(metaField => metaField.id === id);
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

}
