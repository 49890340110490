import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BewerbungsserviceDetailPage } from './bewerbungsservice-detail';
import { ComponentsModule } from 'components/components.module';
import { DirectivesModule } from 'directives/directives.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BewerbungsserviceDetailPage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class BewerbungsserviceDetailPageModule {}
