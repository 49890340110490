// representing a result list slice for paging
export class BaseList<T> {
  list: T[];
  count: number; // total count
  end: boolean; // if true, there is no more after this
  paging: {
    i: number; // start index requested
    s: number; // size of result set requested
  };

  constructor() {
    this.list = [];
    this.count = 0;
    this.end = false;
    this.paging = {
      i: 0,
      s: null,
    };
  }
}
