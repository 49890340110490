/*
 <link rel="stylesheet" type="text/css" media="screen" href="https://cdn.conversejs.org/7.0.5/dist/converse.min.css">
 <script src="https://cdn.conversejs.org/7.0.5/dist/converse.min.js" charset="utf-8"></script>
 */
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ConversejsLoaderProvider {
  loaded: boolean = false;
  constructor() {
  }
  load(): Promise<void> {
    const link = new Promise<void>((resolve, reject) => {
      if(typeof window !== 'undefined') {
        if(this.loaded) {
          resolve();
        } else {
          const l = document.createElement('link');
          l.setAttribute('href', "https://cdn.conversejs.org/5.0.5/dist/converse.min.css");
          l.setAttribute('rel','stylesheet');
          l.setAttribute('type','text/css');
          l.setAttribute('media','screen');
          l.onload = () => {
            resolve();
          };
          l.onerror = (error) => {
            console.error('ConversejsLoaderProvider: Failed to load conversejs / css');
            reject();
          };
          document.head.appendChild(l);
        }
      } else {
        reject();
      }
    });
    const scr = new Promise<void>((resolve, reject) => {
      if(typeof window !== 'undefined') {
        if(this.loaded) {
          resolve();
        } else {
          const s = document.createElement('script');
          s.setAttribute('src', "https://cdn.conversejs.org/5.0.5/dist/converse.min.js");
          s.setAttribute('charset','utf-8');
          s.onload = () => {
            resolve();
          };
          s.onerror = (error) => {
            console.error('ConversejsLoaderProvider: Failed to load conversejs / js');
            reject();
          };
          document.head.appendChild(s);
        }
      } else {
        reject();
      }
    });
    const all = Promise.all([link,scr]);
    return all.then(() => {
      this.loaded = true;
      return;
    });
  }
}
