// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .video {
  background-color: #fff;
  background-image: linear-gradient(180deg, rgba(var(--cdz-darkcolor), 0), rgba(var(--cdz-darkcolor), 0) 80%, rgba(0, 0, 0, 0.08));
  border: 1px solid var(--cdz-litecolor);
  position: relative;
}
:host .video video {
  min-height: 200px;
}
:host .video .video-title {
  width: calc(65% - 5px);
  display: inline-block;
  padding: 10px;
  vertical-align: top;
}
:host .video .video-date {
  width: 35%;
  display: inline-block;
  padding: 10px;
  text-align: right;
  vertical-align: top;
}
:host .video premium-bar {
  margin-top: -7px;
}
:host .video .premium-barred {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
:host .video .premium-barred:hover {
  background-color: rgba(var(--cdz-primary-color), 0.1);
}
:host .youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
:host .youtube-wrapper .youtube-video {
  position: absolute;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/video/video.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,gIAAA;EACA,sCAAA;EACA,kBAAA;AAAJ;AAEI;EACE,iBAAA;AAAN;AAGI;EACE,sBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;AADN;AAII;EACE,UAAA;EACA,qBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AAFN;AAKI;EACE,gBAAA;AAHN;AAKI;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,eAAA;AAHN;AAIM;EACE,qDAAA;AAFR;AAQE;EACE,kBAAA;EACA,sBAAA,EAAA,SAAA;EACA,SAAA;AANJ;AAQI;EACE,kBAAA;EACA,MAAA;EACA,OAAA;AANN","sourcesContent":[":host {\n  .video {\n    background-color: #fff;\n    background-image: linear-gradient(180deg, rgba(var(--cdz-darkcolor),0), rgba(var(--cdz-darkcolor),0) 80%, rgba(0,0,0,0.08));\n    border: 1px solid var(--cdz-litecolor);\n    position: relative;\n\n    video {\n      min-height: 200px;\n    }\n\n    .video-title {\n      width: calc(65% - 5px);\n      display: inline-block;\n      padding: 10px;\n      vertical-align: top;\n    }\n\n    .video-date {\n      width: 35%;\n      display: inline-block;\n      padding: 10px;\n      text-align: right;\n      vertical-align: top;\n    }\n\n    premium-bar {\n      margin-top: -7px;\n    }\n    .premium-barred {\n      position: absolute;\n      z-index: 10;\n      left: 0;\n      right: 0;\n      top: 0;\n      bottom: 0;\n      cursor: pointer;\n      &:hover {\n        background-color: rgba(var(--cdz-primary-color),0.1);\n      }\n    }\n  }\n\n  // dynamische Höhe von Youtube Videos: https://stackoverflow.com/questions/35814653/automatic-height-when-embedding-a-youtube-video\n  .youtube-wrapper{\n    position: relative;\n    padding-bottom: 56.25%; /* 16:9 */\n    height: 0;\n\n    .youtube-video {\n      position: absolute;\n      top: 0;\n      left: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
