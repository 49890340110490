import { Component, Input } from '@angular/core';

import { BranchConfigService } from "services/branch-config/branch-config";

@Component({
  selector: 'premium-bar',
  templateUrl: 'premium-bar.html',
  styleUrls: ['premium-bar.scss']
})
export class PremiumBarComponent {
  @Input() hasbeenpaid?: boolean;

  constructor(
    public branch: BranchConfigService,
  ) {
  }
}
