import { Component, OnInit } from '@angular/core';
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { CometchatProvider } from 'services/nachrichten/cometchat';

@Component({
  selector: 'app-chat-standalone',
  templateUrl: './chat-standalone.page.html',
  styleUrls: ['./chat-standalone.page.scss'],
})
export class ChatStandalonePage implements OnInit {

  cometChatReady: boolean = false;

  constructor(
    private cometchatProvider: CometchatProvider,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.cometchatProvider.isReady.then(() => this.cometChatReady = true);
  }

  userClicked() {
    // this timeout is necessary for the cometchat api to receive the updated message count first
    setTimeout(() => {
      this.events.publish(EventKey.COMETCHAT_MESSAGECOUNT);
    }, 500);
  }
}
