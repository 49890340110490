import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Video } from 'services/video/video.service';

/**
 * Generated class for the VideoListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'video-list',
  templateUrl: 'video-list.html',
  styleUrls: ['video-list.scss']
})
export class VideoListComponent {

  @Input() videos?: Video[];
  @Input() showRightLinks?: boolean;
  @Input() latest?: boolean;
  @Input() dashboardLayout?: boolean;

  @Output() moreClick: EventEmitter<Object> = new EventEmitter();

  constructor(
  ) {
  }

  doMore(event) {
    if (event) {
      event.preventDefault();
    }
    this.moreClick.emit('');
  }
}
