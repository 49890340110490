// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  margin-top: 50px;
}
:host .leistung-list-inner {
  display: block;
}
:host .leistung-list-inner leistung {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/leistung-list/leistung-list.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,cAAA;AACJ;AACI;EACI,cAAA;EACA,kBAAA;EACA,mBAAA;AACR","sourcesContent":[":host {\n  display: block; \n  position: relative;\n  margin-top: 50px;\n\n  .leistung-list-inner {\n    display: block; \n\n    leistung {\n        display: block; \n        margin-left: -15px;\n        margin-right: -15px;\n      }\n \n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
