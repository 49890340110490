// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageReactionsStyle {
  font-size: 11px;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  border-radius: 12px;
  margin: 4px 4px 0 0;
  cursor: pointer;
  background-color: #f6f6f6;
  border: 1px solid transparent;
  float: right;
  height: 30px;
}
.messageReactionsHoverStyle {
  cursor: pointer;
}
.messageReactionsHoverStyle:hover {
  border: 1px solid #eaeaea;
}

.messageReactionsSecondaryStyle {
  border: 1px solid #39f;
}

.emojiButtonStyle {
  outline: 0;
  border: 0;
  border-radius: 10px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  height: 30px;
  margin: 5px;
  padding: 5px 10px;
  background-color: #f6f6f6;
  cursor: pointer;
}

.reactionCountStyle {
  padding: 0 1px 0 3px;
}
.regularReactionIcon {
  height: 24px;
  width: 24px;
  padding: 2px;
}

.regularReaction {
  display: flex;
  flex-flow: wrap;
}

.regularReactionSecondaryStyle {
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/Extensions/CometChat-message-reactions/cometchat-message-reactions/cometchat-message-reactions.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,YAAY;EACZ,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".messageReactionsStyle {\n  font-size: 11px;\n  padding: 2px 6px;\n  display: inline-flex;\n  align-items: center;\n  vertical-align: top;\n  border-radius: 12px;\n  margin: 4px 4px 0 0;\n  cursor: pointer;\n  background-color: #f6f6f6;\n  border: 1px solid transparent;\n  float: right;\n  height: 30px;\n}\n.messageReactionsHoverStyle {\n  cursor: pointer;\n}\n.messageReactionsHoverStyle:hover {\n  border: 1px solid #eaeaea;\n}\n\n.messageReactionsSecondaryStyle {\n  border: 1px solid #39f;\n}\n\n.emojiButtonStyle {\n  outline: 0;\n  border: 0;\n  border-radius: 10px;\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n  position: relative;\n  height: 30px;\n  margin: 5px;\n  padding: 5px 10px;\n  background-color: #f6f6f6;\n  cursor: pointer;\n}\n\n.reactionCountStyle {\n  padding: 0 1px 0 3px;\n}\n.regularReactionIcon {\n  height: 24px;\n  width: 24px;\n  padding: 2px;\n}\n\n.regularReaction {\n  display: flex;\n  flex-flow: wrap;\n}\n\n.regularReactionSecondaryStyle {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
