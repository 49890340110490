// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div[content] > h1:first-child {
  font-weight: bold;
  padding-left: 50px;
  position: relative;
}
:host div[content] > h1:first-child::before {
  display: block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  font-family: "fontello";
  content: "\\e802";
}
:host .content-content > div[content] > h2 {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/bewerbungsservice-settings/bewerbungsservice-settings.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AACI;EAEE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,4BAAA;EACA,wBAAA;EACA,kCAAA;EACA,uBAAA;EACA,gBAAA;AAAN;AAGE;EACE,gBAAA;EACA,mBAAA;EACA,eAAA;AADJ","sourcesContent":[":host {\n  div[content] > h1:first-child {\n    font-weight: bold;\n    padding-left: 50px;\n    position: relative;\n    &::before {\n      // content: '';\n      display: block;\n      position: absolute;\n      width: 40px;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      background-repeat: no-repeat;\n      background-size: contain;\n      background-position: center center;\n      font-family: 'fontello';\n      content: \"\\e802\"; //boy\n    }\n  }\n  .content-content>div[content]>h2 {\n    margin-top: 45px;\n    margin-bottom: 10px;\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
