import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BranchConfigService } from '../branch-config/branch-config';
import { Payable, PayableService } from '../payable/payable.service';
import { ProductService } from 'services/product/product.service';

export class Video extends Payable {
  id: number;
  tippgroupId: string;
  category: string;
  title: string;
  date: string;
  duration: number;
  url: string;
  previewurl: string;
}

/**
 * @deprecated use {@link ProductService} instead
 */
@Injectable({
  providedIn: 'root'
})
export class VideoService extends PayableService<Video> {
  constructor(
    public http: HttpClient,
    public configProvider: BranchConfigService) {
    super('video', http, configProvider);
  }
}
