import { Injectable } from '@angular/core';
import { Mocker, MockService } from 'services/mock/mock.service';
import { HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseList } from '../baselist/baselist';
import { Chat, Nachricht, ChatPerson } from './nachrichten';
import nachrichtendata from './nachrichtendata.json';

@Injectable({
  providedIn: 'root'
})
export class NachrichtenMocker implements Mocker {

  chats: Chat[];
  nachrichten: Nachricht[];
  chatter: ChatPerson;

  constructor(
    public mockProvider: MockService,
  ) {
    this.mockProvider.register(this);

    // read in mockdata json and *patch* it over localstorage to empower simulation of some kind of chatting ability (at least the user part, of course not the other side)
    // in the handle() access localstorage data (mockdata json)
    this.chatter = <ChatPerson>((<any>nachrichtendata).chatter);
    this.chats = <Chat[]>JSON.parse((window.localStorage.getItem('tcdz_nachrichten_chats') || '[]'));
    let chats = <Chat[]>((<any>nachrichtendata).chats);
    chats.forEach(chat => {
      let idx = this.chats.findIndex(ch => {return ch.id === chat.id});
      if(idx === -1) {
        this.chats.push(chat);
      }
    });
    this.nachrichten = <Nachricht[]>JSON.parse((window.localStorage.getItem('tcdz_nachrichten_nachrichten') || '[]'));
    let nachrichten = <Nachricht[]>((<any>nachrichtendata).nachrichten);
    nachrichten.forEach(nachricht => {
      let idx = this.nachrichten.findIndex(na => {return na.id === nachricht.id});
      if(idx === -1) {
        this.nachrichten.push(nachricht);
      }
      // update last nachricht of chat
      let cidx = this.chats.findIndex(ch => {return ch.id === nachricht.chatId});
      if(cidx !== -1) {
        this.chats[cidx].last = nachricht;
      }
    });
    this.updateLocalStorage();
  }

  updateLocalStorage() {
    window.localStorage.setItem('tcdz_nachrichten_chats', JSON.stringify(this.chats));
    window.localStorage.setItem('tcdz_nachrichten_nachrichten', JSON.stringify(this.nachrichten));
  }

  handle(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    let observable: Observable<HttpEvent<any>> = null;

    if(url === '/api/chat/list' && method === 'GET') {
      // returns list of all chats of the current user
      console.log('Mocker: mocked GET chat/list');
      console.log(JSON.stringify(request));
      observable = new Observable(resp => {
        let n = new BaseList<any>();
        n.list = this.chats;
        n.count = n.list.length;
        n.end = true;
        resp.next(new HttpResponse({
          status: 200,
          body: n
        }));
        resp.complete();
      });
    }

    if(url.startsWith('/api/chatdata/') && method === 'GET') {
      // returns chat specified by given chatId
      let id = url.substr(url.lastIndexOf('/')+1,url.length);
      console.log('Mocker: mocked GET chatdata/'+id);
      console.log(JSON.stringify(request));
      let idx = this.chats.findIndex((chat: Chat) => {
        return chat.id === parseInt(id);
      });
      observable = new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: this.chats[idx]
        }));
        resp.complete();
      });
    }

    if(url.startsWith('/api/coach/') && url.endsWith('/chatdata') && method === 'GET') {
      // returns chat of current user to a given coach
      // errors if the current user has no chat to that coach
      let id = url.substring(url.lastIndexOf('coach/')+6,url.indexOf('/chatdata'));
      console.log('Mocker: mocked GET coach/'+id+'/chatdata');
      console.log(JSON.stringify(request));
      let idx = this.chats.findIndex((chat: Chat) => {
        return chat.to.coachId === parseInt(id);
      });
      if(idx === -1) {
        observable = new Observable(resp => {
          resp.error(new HttpErrorResponse({
            status: 404,
            error: null
          }));
          resp.complete();
        });
      } else {
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: this.chats[idx]
          }));
          resp.complete();
        });
      }
    }

    if(url.startsWith('/api/coach/') && url.endsWith('/chat/add') && method === 'POST') {
      // creates and returns a chat of current user to a given coach
      // errors in case the current user already has a chat to the given coach
      let id = url.substring(url.lastIndexOf('coach/')+6,url.indexOf('/chat'));
      console.log('Mocker: mocked GET coach/'+id+'/chat/add');
      console.log(JSON.stringify(request));
      let idx = this.chats.findIndex((chat: Chat) => {
        return chat.to.coachId === parseInt(id);
      });
      if(idx === -1) {
        idx = this.chats.length;
        let chat = (<Chat>request.body) || new Chat();
        chat.id = Math.ceil(Math.random() * 1000000);
        chat.to = chat.to || new ChatPerson();
        chat.to.id = Math.ceil(Math.random() * 1000000);
        chat.to.coachId = parseInt(id);
        //FIXME: chat.to.name = has to be already in body / later will be added from backend
        //FIXME: chat.to.image = has to be already in body / later will be added from backend
        chat.last = null;
        this.chats.push(chat);
        this.updateLocalStorage();
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: this.chats[idx]
          }));
          resp.complete();
        });
      } else {
        observable = new Observable(resp => {
          resp.error(new HttpErrorResponse({
            status: 400,
            error: null
          }));
          resp.complete();
        });
      }
    }

    if(url.startsWith('/api/chat/') && url.endsWith('/nachricht/list') && method === 'GET') {
      // returns list of all messages in a chat specified by chatId
      let id = url.substring(url.lastIndexOf('chat/')+5,url.indexOf('/nachricht'));
      console.log('Mocker: mocked GET chat/'+id+'/nachricht/list');
      console.log(JSON.stringify(request));
      let nachrichten: Nachricht[] = [];
      this.nachrichten.forEach((nachricht: Nachricht) => {
        if(nachricht.chatId === parseInt(id)) {
          nachrichten.push(nachricht);
        }
      });
      let n = new BaseList<any>();
      n.list = nachrichten;
      n.count = n.list.length;
      n.end = true;
      observable = new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: n
        }));
        resp.complete();
      });
    }

    if(url.startsWith('/api/nachrichtdata/') && method === 'GET') {
      // returns nachricht specified by given id
      let id = url.substr(url.lastIndexOf('/')+1,url.length);
      console.log('Mocker: mocked GET nachrichtdata/'+id);
      console.log(JSON.stringify(request));
      let idx = this.nachrichten.findIndex((nachricht: Nachricht) => {
        return nachricht.id === parseInt(id);
      });
      observable = new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: this.nachrichten[idx]
        }));
        resp.complete();
      });
    }

    if(url === '/api/nachricht/add' && method === 'POST') {
      // creates and returns a new message in chat specified by the chatId in the message
      console.log('Mocker: mocked GET nachricht/add');
      console.log(JSON.stringify(request));
      let nachricht: Nachricht = request.body;
      let id = nachricht.chatId;
      let idx = this.chats.findIndex((chat: Chat) => {
        return chat.id === id;
      });
      if(idx === -1) {
        observable = new Observable(resp => {
          resp.error(new HttpErrorResponse({
            status: 400,
            error: null //TODO: error message? see also other errors mocked ...
          }));
          resp.complete();
        });
      } else {
        let chat: Chat = this.chats[idx];
        nachricht.id = Math.ceil(Math.random() * 1000000);
        nachricht.from = this.chatter;
        this.nachrichten.push(nachricht);
        chat.last = nachricht; // update last nachricht of chat
        this.updateLocalStorage();
        observable = new Observable(resp => {
          resp.next(new HttpResponse({
            status: 200,
            body: nachricht
          }));
          resp.complete();
        });
      }
    }

    return observable;
  }
}
