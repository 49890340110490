import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/*
 This acts just like the standard HttpTranslateLoader from @ngx-translate/http-loader,
 except that:
    - it is able to merge to merge local translation with remote translation files (remote overriding local),
    - it is able to use a the local translation only as a fallback if loading from the remote location fails.
*/

@Injectable()
export class FailsafeTranslateLoaderService implements TranslateLoader  {

    constructor(private http: HttpClient, private remotePrefix: string, private remoteSuffix, private localPrefix: string, private localSuffix) {}

    getTranslation(lang: string): Observable<any>{
        return Observable.create(observer => {
            this.http.get<any>(this.remotePrefix + lang + this.remoteSuffix).subscribe(remoteData => {
                    this.http.get(this.localPrefix + lang + this.localSuffix).subscribe(localData => {
                        this.recursiveMergeJsonIn(localData,remoteData);
                        observer.next(localData);  // got local and remote data
                        observer.complete();
                    }, error => {
                        observer.next(remoteData); // got only remote data, no local translation for this language available
                        observer.complete();
                    });
                }, error => {
                    //  failed to retrieve from api, switch to local
                    this.http.get(this.localPrefix + lang + this.localSuffix).subscribe(localData => {
                        observer.next(localData); // got only local data, no remote translation for this language available (currently)
                        observer.complete();
                    }); // if this fails, no translation ais available at all ... :-(
                }
            );
        });
    }

    recursiveMergeJsonIn(a,b,path:string[] = []) {
        for(let b1 in b) {
            if(!a.hasOwnProperty(b1) || typeof a[b1] === 'undefined' || a[b1] === null) {
                a[b1] = b[b1]; // override whole tree
            } else if(typeof a[b1] === 'object' && typeof b[b1] === 'object') {
                this.recursiveMergeJsonIn(a[b1],b[b1],path.concat([b1]));
            } else if(typeof a[b1] !== 'object' && typeof b[b1] !== 'object') {
                a[b1] = b[b1]; // override a value
            } else {
                // ignore override as it is obviously in mismatch with the required type
                console.warn('FailsafeTranslateLoader: type mismatch in translation merging local and remote for property - '+
                    'the following i18n value from the server is ignored in favor of the compatible local value: '+
                    (path.concat([b1]).join('.'))+' local value is:'+JSON.stringify(a[b1])+' server value is:'+JSON.stringify(b[b1]));
            }
        }
    }
}
