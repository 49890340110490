// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  border: 1px solid var(--cdz-litecolor);
  width: 100%;
}
:host .coach {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
}
:host .coach .coach-fotoblock {
  flex: 1 1 150px;
  min-width: 150px;
  max-width: 150px;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
:host .coach .coach-inner {
  flex: 1 1 300px;
}
:host .coach .coach-inner .coach-info {
  white-space: normal;
}
:host .coach .coach-inner .button {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/coach/coach.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sCAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACD,eAAA;EACC,mBAAA;EACA,aAAA;EACA,SAAA;AACJ;AAAI;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,4BAAA;EACA,sBAAA;EACA,kCAAA;AAEN;AAAI;EACE,eAAA;AAEN;AAAM;EACE,mBAAA;AAER;AACM;EACE,YAAA;AACR","sourcesContent":[":host {\n  display: block;\n  border: 1px solid var(--cdz-litecolor);\n  width: 100%;\n\n  .coach {\n    display: flex;\n\t  flex-wrap: wrap;\n    flex-direction: row;\n    padding: 10px;\n    gap: 10px;\n    .coach-fotoblock {\n      flex: 1 1 150px;\n      min-width: 150px;\n      max-width: 150px;\n      width: 150px;\n      height: 150px;\n      background-repeat: no-repeat;\n      background-size: cover;\n      background-position: center center;\n    }\n    .coach-inner {\n      flex: 1 1 300px;\n\n      .coach-info {\n        white-space: normal;\n      }\n\n      .button {\n        float: right;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
