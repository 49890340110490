// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageContainerStyle {
  align-self: flex-end;
  margin-bottom: 16px;
  padding-left: 16px;

  max-width: 100%;
  clear: both;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  float: right;
}
.messageContainerStyle:hover > .tool {
  display: flex;
}
.tool {
  display: none;
}
.messageWrapperStyle {
  width: auto;
  flex: 1 1;
  align-self: flex-end;
  display: flex;
}

.messageVideoWrapperStyle {
  display: inline-block;
  align-self: "flex-end";
}
.messageVideoWrapperStyle > video {
  max-width: 250px;
  border-radius: 12px;
  display: inherit;
  outline: 0 !important;
}

.messageInfoWrapperStyle {
  align-self: flex-end;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/CometChat-sender-video-message-bubble/cometchat-sender-video-message-bubble/cometchat-sender-video-message-bubble.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;;EAElB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,SAAS;EACT,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,aAAa;AACf","sourcesContent":[".messageContainerStyle {\n  align-self: flex-end;\n  margin-bottom: 16px;\n  padding-left: 16px;\n\n  max-width: 100%;\n  clear: both;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  float: right;\n}\n.messageContainerStyle:hover > .tool {\n  display: flex;\n}\n.tool {\n  display: none;\n}\n.messageWrapperStyle {\n  width: auto;\n  flex: 1 1;\n  align-self: flex-end;\n  display: flex;\n}\n\n.messageVideoWrapperStyle {\n  display: inline-block;\n  align-self: \"flex-end\";\n}\n.messageVideoWrapperStyle > video {\n  max-width: 250px;\n  border-radius: 12px;\n  display: inherit;\n  outline: 0 !important;\n}\n\n.messageInfoWrapperStyle {\n  align-self: flex-end;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
