import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(public platform: Platform) { }

  public isNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  public isMobile(): boolean {
    // TODO: this needs to get implmented properly
    return this.platform.is('mobileweb') || this.platform.is('mobile') || this.isNative();
  }
  
  public isBrowser(): boolean {
    // TODO: this needs to get implmented properly
    return this.platform.is('mobileweb') || this.platform.is('desktop');
  }
}
