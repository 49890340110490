import { File } from '@awesome-cordova-plugins/file/ngx';
import { from as observableFrom, Observable } from 'rxjs';

import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//THRS compile failed with new Node/npm without this declaration, so always declare usage of RxJS statics, omitting may cause unexpected problems at any point in the future

//THRS compile failed with new Node/npm without this declaration, so always declare usage of RxJS operators, omitting may cause unexpected problems at any point in the future

declare const cordova;

/**
 * Purpose: enable AuthInterceptor to intercept downloal requests from the user by url that should get the X-Auth-Token ...
 */

/**
 * @deprecated use {@link FileService} to load files
 */
@Injectable()
export class DownloadService {
    private file: File;
    constructor(
        private http: HttpClient
    ) {
        // funktionieren files noch? :D musste das ändern um einen bug zu fixen, hat das geklappt?
        this.file = new File();
    }

    downloadBase64(url: string, mimetype: string = 'application/octet-stream'): Observable<any> {
        return this.http.get(url, {
            headers: new HttpHeaders({ 'Content-Type': mimetype, 'Accept': mimetype + ', */*' }),
            responseType: 'blob'
        }).pipe(
            switchMap((blob: Blob) => {
                // return new observable which emits a base64 string when blob is converted to base64
                return new Observable(observer => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); // convert blob to base64
                    reader.onloadend = () => {
                        observer.next(reader.result); // emit the base64 string result (to the actual displaying DOM or async pipe)
                    };
                });
            }),
            catchError((error, caught) => {
                return Promise.reject(error);
            })
        );
    }

    downloadBlob(url: string, mimetype: string = 'application/octet-stream'): Observable<any> {
        mimetype = !!mimetype ? mimetype : 'application/octet-stream';
        return this.http.get(url, {
            headers: new HttpHeaders({ 'Content-Type': mimetype, 'Accept': mimetype + ', */*' }),
            responseType: 'blob'
        }).pipe(catchError((error, caught) => {
            return Promise.reject(error);
        }));
    }

    downloadFileAs(url: string, mimetype: string = 'application/octet-stream'): Observable<any> {
        let i1 = url.lastIndexOf("?filename=");
        let i2 = url.lastIndexOf("/");
        const fileName: string = url.substring((i1 !== -1) ? (i1 + 10) : (i2 + 1));
        return this.http.get(url, {
            headers: new HttpHeaders({ 'Content-Type': mimetype, 'Accept': mimetype + ', */*' }),
            responseType: 'blob'
        }).pipe(mergeMap((data: Blob) => { // "flatMap is an alias for mergeMap"; changed to mergeMap because THRS compile failed using the alias when run with new Node/npm/etc.
            //if(window['cordova'] && window['cordova'].file) {  // <- was original, and works
            //if(cordova && cordova.file) {    // <- was changed to this with commit comment "fix build errors", but breaks functionality
            //note: added check for cordova.platformId so that platform browser builds do not attempt to do this via cordova but via browser
            if (typeof cordova !== 'undefined' && cordova && cordova.file && cordova.platformId !== 'browser') {   // <- does this work for you?
                //console.error('DEBUG downloading file using Cordova',this.file);
                return observableFrom(this.file.writeFile(this.file.dataDirectory, fileName, data, { replace: true }));
            } else {
                //console.error('DEBUG downloading file using Anchor');
                let anchor: HTMLAnchorElement = document.createElement('a');
                anchor.href = window.URL.createObjectURL(data);
                anchor.download = fileName;
                anchor.click();
                return observableFrom(Promise.resolve(data));
            }
        }), catchError((error) => {
            return Promise.reject(error);
        }));
    }
}