import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseProvider } from "../base/base.provider";
import { Language } from "../language/language.service";

const apiUrl: any = {
    user: "/api/user/",
    password: "/api/password/"
};

export class User {
    id: any;
    email: string;
    firstname: string;
    lastname: string;
    authtoken: string;
    birthday?: string; //YYYYMMDD
    password?: string;
    password2?: string;
    language: Language;
    // companygroupUserList: CompanygroupUserList;
}

export class Password {
    password: string;
    newpassword: string;
    newpassword2: string;
}

export class PasswordReset {
    newpassword: string;
    newpassword2: string;
    token: string;
}

@Injectable()
export class UserService extends BaseProvider<User> {

    constructor(public http: HttpClient) {
        super(http, apiUrl.user);
    }

    updatePassword(password: Password): Observable<Password> {
        return this.http.post<Password>(apiUrl.password + "update", password);
    }

    resetPassword(passwordReset: PasswordReset): Observable<PasswordReset> {
        return this.http.post<PasswordReset>(apiUrl.password + "update/token", passwordReset);
    }

}
