import { Component, Injector, OnInit } from '@angular/core';
import { AuthService } from 'services/auth/auth.service';
import { BewerbungstippsProvider, TippGroup } from 'services/bewerbungstipps/bewerbungstipps';
import { BranchConfigService } from "services/branch-config/branch-config";
import { CoachService } from 'services/coach/coach.service';
import { UserCoachService } from 'services/coach/user-coach.service';
import { EnvironmentService } from 'services/environment/environment.service';
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { JitsiCallInfo } from 'services/jitsi/jitsi.service';
import { OnlineCourseProduct } from 'services/onlinecourse/onlinecourse-product';
import { OnlineCourseProductService } from 'services/onlinecourse/onlinecourse-product.service';
import { Podcast, PodcastsService } from 'services/podcasts/podcasts.service';
import { Userdata, UserdataService } from 'services/userdata/userdata.service';
import { Video, VideoService } from 'services/video/video.service';
import { Webinar, WebinarService } from 'services/webinar/webinar.service';
import { ConstantVariable } from 'src/app/constant-variable';
import { DataGroup, DataPage, MetaFile, MetaGroup } from 'src/app/metagroup';
//import { _ } from '@biesbjerg/ngx-translate-extract';
let _ = (key) => key;


/* secure some dynamic keys */
_('dashboard.address');
/*                          */

@Component({
  selector: 'page-dashboard',
  templateUrl: 'dashboard.html',
  styleUrls: ['dashboard.scss']
})
export class DashboardPage implements OnInit {
  public readonly pageName: string = "DashboardPage";

  showAllFeatures: boolean;
  showTestJitsi: boolean;
  //TODO: the userdata may change due to user actions - implement some kind of messaging to tell the component to re-load the userdata ... - when exactly?
  //TODO: userdata is also used in HeadingUserComponent ... maybe harmonize
  userdata: Userdata;
  coach: DataPage;
  leistungenMetaGroup: MetaGroup;
  video: Video;
  bewerbungstippGroup: TippGroup;
  podcasts: Podcast[];
  videos: Video[];
  webinars: Webinar[];
  onlinecourses: OnlineCourseProduct[];

  private MAX_PRODUCT_AGE = 31; //days

  constructor(
    public injector: Injector,
    public userdataProvider: UserdataService,
    public authProvider: AuthService,
    public branch: BranchConfigService,
    public coachesSettingsProvider: UserCoachService,
    public coachesProvider: CoachService,
    public bewerbungstippsProvider: BewerbungstippsProvider,
    public podcastsProvider: PodcastsService,
    public videosProvider: VideoService,
    public webinarsProvider: WebinarService,
    public onlinecoursesProvider: OnlineCourseProductService,
    public events: EventsService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
    this.showTestJitsi = false == EnvironmentService.isProd();
  }

  ngOnInit() {
    this.video = new Video();

    if (!!this.authProvider.user) {
      this.userdataProvider.get().then(data => {
        // for testing, till the backend implements these:
        //data.isFirstLogin = true; //FIXME
        //data.openinvitations = 2; //FIXME
        //data.openapplications = 5; //FIXME
        //data.progressneeded = 1; //FIXME

        this.userdata = data;
        console.log('got userdata', data);
      }, error => {
        console.error('failed to load userdata', error);
      });

      this.branch.isReady.then(() => {
        this.coachesProvider.getMeta().then(data => {
  
          this.leistungenMetaGroup = data.groups.find(group => {
            return group.id === 'leistungen';
          });
  
          this.coachesSettingsProvider.getSingleton().then(data => {
            console.log('COACH', data);
            if (data) {
              this.coach = data;
              this.updateCoachData();
            } else {
              console.error('got no coach');
            }
          }, error => {
            console.error('cannot get coach ', error);
          });
        });
  
        this.bewerbungstippsProvider.getLatest(this.MAX_PRODUCT_AGE).then(data => {
          console.log('bewerbungstippsProvider bewerbungstippGroup', data);
          if (data) {
            this.bewerbungstippGroup = data;
          } else {
            console.error('got no bewerbungstippGroup');
          }
        }, error => {
          console.error('cannot get bewerbungstippGroup', error);
        });
        
        this.podcastsProvider.getLatest(this.MAX_PRODUCT_AGE).then(data => {
          if (data && data.list) {
            this.podcasts = data.list;
          } else {
            console.error('got no podcasts');
          }
        }, error => {
          console.error('cannot get podcasts', error);
        });
  
        this.videosProvider.getLatest(this.MAX_PRODUCT_AGE).then(data => {
          if (data && data.list) {
            this.videos = data.list;
          } else {
            console.error('got no videos');
          }
        }, error => {
          console.error('cannot get videos', error);
        });
  
        this.webinarsProvider.getLatest(this.MAX_PRODUCT_AGE).then(data => {
          if (data && data.list) {
            this.webinars = data.list;
          } else {
            console.error('got no webinars');
          }
        }, error => {
          console.error('cannot get webinars', error);
        });

        this.onlinecoursesProvider.getLatest(this.MAX_PRODUCT_AGE).then(data => {
          if (data && data.list) {
            this.onlinecourses = data.list;
          } else {
            console.error('got no onlinecourses');
          }
        }, error => {
          console.error('cannot get onlinecourses', error);
        });
      });
    }
  }

  fotoImageUrl(coach: DataPage): string {
    if (coach && coach.person && (<DataGroup>coach.person).fieldData && (<DataGroup>coach.person).fieldData.foto) {
      let file: MetaFile = <MetaFile>((<DataGroup>coach.person).fieldData.foto);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  updateCoachData() {
    this.video = new Video();
    let coach = this.coach;
    if (coach && coach.person && (<DataGroup>coach.person).fieldData && (<DataGroup>coach.person).fieldData.introvideo) {
      let url: string = <string>((<DataGroup>coach.person).fieldData.introvideo);
      this.video.url = url;
    } else {
      this.branch.configuration.then(config => {
        this.video.url = config.welcomeVideoUri;
      });
    }
  }

  testJitsi() {
    if (this.showTestJitsi) {
      let callInfo: JitsiCallInfo = {
        domain: 'https://meet.jit.si',
        options: {
          roomName: 'heroes-dev-test-HggnaL3ox1H5m9mrztrJbeMJl1an2rMS3sdMzh31'
        }
      };
      let userId: number = 1005;
      this.events.publish(EventKey.JITSI_CALL, callInfo, userId);
    }
  }

}