// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-links {
  margin-top: 10px;
}
.privacy-links a {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/privacy/privacy.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,kBAAA;AAER","sourcesContent":[".privacy-links {\n    margin-top: 10px;\n    a {\n        margin-right: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
