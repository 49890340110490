import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatStandalonePage } from './chat-standalone.page';
import { CometChatUI } from 'src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/public-api';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    IonicModule,
    CometChatUI,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [ChatStandalonePage]
})
export class ChatStandalonePageModule {}
