/**
 * Represents a filter for API requests.
 */
export class ApiFilter {
    /** The filter criteria. */
    filter: any;
    /** The page number for pagination. */
    page: number;
    /** The number of items per page for pagination. */
    size: number;

    /**
     * Creates an instance of ApiFilter.
     * @param filter - The filter criteria.
     * @param page - The page number for pagination.
     * @param size - The number of items per page for pagination.
     */
    constructor(filter?: any, page?: number, size?: number) {
        this.filter = filter;
        this.page = page;
        this.size = size;
    }
}