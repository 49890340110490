import { Inject, Injectable, Optional } from '@angular/core';
import { ApiService } from 'services/api/api.service';
import { ApiAuthOptions, AuthOptions } from 'services/auth/auth-config';
import { SignupCredentials } from 'services/auth/auth-credentials';
import { AuthResponse } from 'services/auth/auth-response.interface';
import { AuthService } from 'services/auth/auth.service';
import { DataPage } from 'src/app/metagroup';

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends ApiService<any> {

  constructor(
    public auth: AuthService,
    @Optional() @Inject(ApiAuthOptions) public authOptions: AuthOptions
  ) {
    super('registration');
  }

  public getMdata(postdata: { type: string, data: string }): Promise<DataPage> {
    return this.httpPost('mdata', '', postdata);
  }

  public register(signup: SignupCredentials): Promise<AuthResponse> {
    signup.username = !!signup && !!signup.username ? signup.username.trim() : null;
    return this.httpPost('register', '', signup);
  }
}
