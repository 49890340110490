import { Component, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

/*
  this is needed as a replacement for ion-icon, since ion-icon does not play well with fontello icons :-(
 */

@Component({
  selector: 'f-icon',
  templateUrl: 'f-icon.html',
  styleUrls: ['f-icon.scss'],
  standalone: true,
  imports: []
})
export class FIconComponent implements OnChanges {
  @Input() name: string;

  constructor(
    private elementRef: ElementRef,
  ) {
    elementRef.nativeElement.setAttribute('role', 'img');
    this.updateIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('name')) {
      this.updateIcon();
    }
  }

  updateIcon() {
    this.elementRef.nativeElement.setAttribute('class', (this.name ? 'icon icon-' + this.name : 'icon icon-none'));
    this.elementRef.nativeElement.setAttribute('aria-label', (this.name ? this.name : ''));
  }
}
