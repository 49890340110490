import { Component, Injector, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BaseList } from 'services/baselist/baselist';
import { BranchConfigService } from "services/branch-config/branch-config";
import { CoachService } from "services/coach/coach.service";
import { UserCoachService } from 'services/coach/user-coach.service';
import { JobboerseService } from 'services/jobboerse/jobboerse.service';
import { DataGroup, DataPage, MetaFile, MetaGroup, MetaId } from 'src/app/metagroup';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'page-jobboerse',
  templateUrl: 'jobboerse.html',
  styleUrls: ['jobboerse.scss']
})
export class JobboersePage implements OnInit {
  public readonly pageName: string = "JobboersePage";

  jobboerseFilterMeta: MetaGroup;
  jobboerseFilter: DataGroup;
  // jobboerseFilterClone: DataGroup;
  //jobs: DataPage[];
  jobs: BaseList<DataPage>;
  // searchrunning: boolean = false;
  loadrunning: boolean = false;
  // filterchanged: boolean = false;
  leistungenMetaGroup: MetaGroup;
  myCoach: DataPage;
  content: String;
  importEnabled: boolean;

  readonly pagesize = 5;
  protected pCurrentpage = 0;
  get currentpage(): number {
    return this.pCurrentpage;
  }
  set currentpage(index: number) {
    this.load(index).then(() => {
      this.pCurrentpage = Math.min(index, Math.floor(this.jobs.count / this.pagesize));
    });
  }
  get totalpages(): number {
    return this.jobs ? Math.ceil(this.jobs.count / this.pagesize) : 0;
  }

  constructor(
    public injector: Injector,
    public jobboerseProvider: JobboerseService,
    public coachesSettingsProvider: UserCoachService,
    public coachesProvider: CoachService,
    public branch: BranchConfigService,
    private navController: NavController
  ) {
    this.jobboerseFilter = new DataGroup();
  }

  ngOnInit() {
    console.log('ngOnInit JobboersePage');

    this.branch.isReady.then(config => {
      this.coachesProvider.getMeta().then(data => {
        this.leistungenMetaGroup = data.groups.find(group => {
          return group.id === 'leistungen';
        });
      });

      this.jobboerseProvider.getFilterMeta().then(data => {
        console.log('getFilterMeta', data);
        this.jobboerseFilterMeta = data;
      });

      this.jobboerseProvider.getSingleton().then(d => {
        this.content = !!d.content ? "" + d.content : null;
        const coachGroupId = !!d.coachGroupId ? "" + d.coachGroupId : null;
        this.importEnabled = "" + d.importEnabled === "true";

        this.coachesSettingsProvider.getByTippGoupId(coachGroupId || "0").then(data => {
          console.log('COACH', data);
          if (data && data.id) {
            this.myCoach = data;
          } else {
            console.error('got no coach');
          }
        }, error => {
          console.error('cannot get coach', error);
        });
      });

      this.jobboerseProvider.getSingleton().then();
    });// end branch ready
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter JobboersePage');
    this.load();
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave JobboersePage');
  }

  view(id: MetaId) {
    this.navController.navigateForward('jobboerse/' + id);
  }

  load(pagewanted = null): Promise<void> {
    if (pagewanted === null) {
      pagewanted = this.currentpage;
    }
    if (this.loadrunning) return Promise.resolve();

    this.loadrunning = true;
    return this.jobboerseProvider.getList({
      i: pagewanted * this.pagesize,
      s: this.pagesize,
    }).then(data => {
      this.jobs = data;
      this.loadrunning = false;
    }, error => {
      this.loadrunning = false;
    });
  }

  import() {
    this.jobboerseProvider.importJobFromLink(this.jobboerseFilter.fieldData.link as string).then(() => {
      this.load();
    });
  }

  logoImageUrl(job: DataPage): string {
    if (job.arbeitgeber && (<DataGroup>job.arbeitgeber).fieldData && (<DataGroup>job.arbeitgeber).fieldData.firmaLogo) {
      let file: MetaFile = <MetaFile>((<DataGroup>job.arbeitgeber).fieldData.firmaLogo);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  seitText(job: DataPage) {
    let dstr;
    if (job.status) {
      dstr = (<DataGroup>job.status).fieldData.datum + ' ' + (<DataGroup>job.status).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

}
