import { Component, Input } from '@angular/core';

/**
 * Generated class for the BewerbungstippTitleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'bewerbungstipp-title',
  templateUrl: 'bewerbungstipp-title.html',
  styleUrls: ['bewerbungstipp-title.scss']
})
export class BewerbungstippTitleComponent {

  @Input() group: string;
  @Input() latest?: boolean;
  @Input() icon: string;
  @Input() bold?: boolean;

  constructor() {
  }

}
