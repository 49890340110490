// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2.no-icon {
  padding-left: 0;
}
:host h2 .icon {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  top: 0;
  left: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/bewerbungstipp-title/bewerbungstipp-title.scss"],"names":[],"mappings":"AAMQ;EACE,eAAA;AALV;AAQQ;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,4BAAA;EACA,wBAAA;EACA,kCAAA;AANV","sourcesContent":[":host {\n    h2 {\n        &.bold {\n          // commented out, so that it can be used again later\n          // font-weight: bold !important;\n        }\n        &.no-icon {\n          padding-left: 0;\n        }\n\n        .icon {\n          content: '';\n          display: block;\n          position: absolute;\n          width: 40px;\n          top: 0;\n          left: 0;\n          bottom: 0;\n          background-repeat: no-repeat;\n          background-size: contain;\n          background-position: center center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
