import {Injectable, Optional} from "@angular/core";
import {Config, Platform} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {BaseProvider} from "../base/base.provider";
import { HttpClient } from "@angular/common/http";
import {AuthService} from "../auth/auth.service";
import {UserService} from "../user/user.service";
import { DateAdapter } from '@angular/material/core';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;

export class Language {
    id: number;
    key: string;
    name: string;
    isocode: string;
}

const apiUrl: string = "/api/language/";

// this shall come from the backend? /api/language/list ...
//TODO: als Parameter in init
const de_id: number = 2;
const en_id: number = 1;
const de_key: string = "de";
const en_key: string = "en";

@Injectable()
export class LanguageService extends BaseProvider<Language> {

    defaultLanguage: string;
    language: string;
    languages: Language[];

    user: any;

    enabled: boolean = false;

    color: string = "#00ff00";

    constructor(
        public http: HttpClient,
        public platform: Platform,
        public translate: TranslateService,
        public config: Config,
        @Optional() public authProvider: AuthService,
        @Optional() public userProvider: UserService,
        @Optional() public adapter: DateAdapter<any>,
    ) {
        super(http, apiUrl);
        this.languages = [
            {id: en_id, isocode: en_key, key: en_key, name: en_key},
            {id: de_id, isocode: de_key, key: de_key, name: de_key},
        ];
        this.setDefaultLanguage(en_key);
    }

    private setDefaultLanguage(lang: string): void {
        this.defaultLanguage = lang;
    }

    private getDefaultLanguage(): string {
        return this.defaultLanguage;
    }

    private setLanguage(lang: string): Observable<any> {
        if(this.language === lang) {
            return;
        }
        if(this.hasLanguage(lang)) {
        //if(lang == de_key || lang == en_key) {
            this.language = lang;
            this.adapter && this.adapter.setLocale(lang); //set the formatting for angular material
            moment.locale(lang); //set momentjs locale
            let obs = this.translate.use(this.getLanguage());
            this.initConfig();
            console.log("%c set language to: " + lang, 'background: ' + this.color + ';');
            return obs;
        } else {
            console.error("language not supported:", lang, "reset to default language:", this.defaultLanguage);
            return this.setLanguage(this.getDefaultLanguage());
        }
    }

    public getLanguage(): string {
        if(this.language) {
            return this.language;
        } else {
            return this.defaultLanguage;
        }
    }

    public getLocale(): string {
        // this is a bit of a hack, as it
        // - supports only a subset of languages correctly (some special cases + the default, which will go wrong for some other languages)
        // - returns only a default locale for each language
        const lang = this.getLanguage();
        switch(lang) {
            case 'cs': return 'cs-CZ';
            case 'da': return 'da-DK';
            case 'en': return 'en-US';
            case 'el': return 'el-GR';
            case 'ja': return 'ja-JP';
            case 'ko': return 'ko-KR';
            case 'pt': return 'pt-BR';
            case 'sl': return 'sl-SI';
            case 'sv': return 'sv-SE';
            default: return (lang||'') + '-' + (lang||'').toUpperCase();
        }
    }

    /**
     * Call in app.component.ts
     * @param {string} defaultLanguage - set another default language than en
     * @param {Language[]} languages - set another set of initial languages than de + en to be available before the backend answers with the list of languages
     */
    public init(defaultLanguage: string = en_key, languages?: Language[]): void {
        if(defaultLanguage) {
            this.setDefaultLanguage(defaultLanguage);
        }
        if(languages) {
            this.languages = languages;
        }

        let _init = () => {
            this.enabled = true;

            let lang = this.getLanguage();
            this.translate.setDefaultLang(this.getDefaultLanguage());
            this.adapter && this.adapter.setLocale(lang); //set the formatting for angular material
            moment.locale(lang); //set momentjs locale
            this.translate.use(lang);
            this.initConfig();

            if(this.authProvider && this.authProvider.user && this.userProvider) {
                this.userProvider.get(this.authProvider.user.id).subscribe((result) => {

                    this.user = result;

                    if (this.user && this.user.language) {
                        console.log("%c now using user language.", 'background: ' + this.color + ';');
                        this.setLanguage(this.user.language.key);
                    } else {
                        this.useSystemLanguage();
                    }

                }, (error) => {
                    console.log(error);
                    this.useSystemLanguage();
                });
            } else {

                let lang = 0;
                try {
                    lang = parseInt(localStorage.getItem('taf2userLang')); // fallback for sessions that do not support user language (TCUC)
                } catch(e) {}
                if(lang > 0) {
                    this.setLanguage(this.idToKey(lang));
                } else {
                    this.useSystemLanguage();
                }
            }

            //subscribe to login / logout
            let stateSubscription = this.authProvider && this.authProvider.stateChanged().subscribe(isLoggedIn => {
                console.log('%c language provider: APPCOM: stateSubscription got changed state: isLoggedIn = '  + isLoggedIn, 'background: ' + this.color + ';');
                if(isLoggedIn) {
                    this.useUserLanguage(false);
                } else {
                    this.useSystemLanguage();
                }
            });

            //subscribe to user change (e.g. autologin on page reload)
            let userSubscription = this.authProvider && this.authProvider.userChanged().subscribe(user => {
                console.log('%c language provider: APPCOM: userSubscription got changed state: user.id = '  + (user ? user.id : '-/-'), 'background: ' + this.color + ';');
                if(user) {
                    this.useUserLanguage(!!this.userProvider); // only save if there is actually a userProvider to save to, so do not save (here) to the fallback (TCUC)
                } else {
                    this.useSystemLanguage();
                }
            });
        };

        this.translate.setDefaultLang(this.getDefaultLanguage()); //pre-init
        this.getList().subscribe(languages => {
            this.languages = languages;
            console.log('LanguageProvider: init (got languages)');
            _init();
        }, error => {
            console.error('LanguageProvider: Failed to load languages, defaulting to a set of de and en only',error);
            _init();
        });
    }

    private initConfig(): void {
        this.translate.get('BUTTON.BACK').subscribe(value => {
            if (this.platform.is('ios')) {
                // this.config.set('backButtonText', value);
            }
        });
    }

    public updateLanguage(lang: number): Observable<any> {
        let obs = this.setLanguage(this.idToKey(lang));
        this.updateUserLanguage(lang);
        return obs;
    }

    private updateUserLanguage(lang: number): void {
        if(this.authProvider && this.authProvider.user && this.userProvider) {
            let tmpUser: any = {};
            tmpUser.id = this.authProvider.user.id;
            tmpUser.language = {};
            tmpUser.language.id = lang;

            this.userProvider.update(tmpUser).subscribe((result) => {
                console.log("%c updated user language.", 'background: ' + this.color + ';');
                localStorage.setItem('taf2userLang',''+lang); // fallback s.u.
            }, (error) => {
                console.error("update user language failed:", error);
                localStorage.setItem('taf2userLang',''+lang); // fallback s.u.
            });
        } else {
            console.log("%c update user language failed: no user logged in.", 'background: ' + this.color + ';');
            localStorage.setItem('taf2userLang',''+lang); // fallback s.u.
        }
    }

    //Call at login
    private useUserLanguage(save: boolean = true): void {
        if(this.enabled) {
            if (this.authProvider && this.authProvider.user && this.userProvider) {
                this.userProvider.get(this.authProvider.user.id).subscribe((result) => {

                    this.user = result;

                    if (this.user && this.user.language) {
                        console.log("%c now using user language.", 'background: ' + this.color + ';');
                        this.setLanguage(this.user.language.key);
                    } else {
                        console.log("%c can not use user language. reason: user has not selected a language yet. now sending a POST to set language for first time.", 'background: ' + this.color + ';');
                        let lang = 0;
                        try {
                            lang = parseInt(localStorage.getItem('taf2userLang')); // fallback for sessions that do not support user language (TCUC)
                        } catch(e) {}
                        if(lang > 0) {
                            this.setLanguage(this.idToKey(lang));
                        }
                        if(save) {
                            this.updateUserLanguage(this.keyToId(this.getLanguage()));
                        }
                    }

                }, (error) => {
                    console.log(error);
                });
            } else {
                console.log("%c can not use user language. reason: not user logged in.", 'background: ' + this.color + ';');
                // fallback for getting last user language, even if (auto)login fails (e.g. network error, ...)
                let lang = 0;
                try {
                    lang = parseInt(localStorage.getItem('taf2userLang'));
                } catch(e) {}
                if(lang > 0) {
                    this.setLanguage(this.idToKey(lang));
                }
                if(save) {
                    this.updateUserLanguage(this.keyToId(this.getLanguage()));
                }
            }
        }
    }

    //Call at logout
    private useSystemLanguage(): void {
        if(this.enabled) {
            let lang = 0;
            if (this.translate.getBrowserLang() !== undefined) {
                console.log("%c now using system language.", 'background: ' + this.color + ';');
                //this.setLanguage(this.translate.getBrowserLang());
                lang = this.keyToId(this.translate.getBrowserLang());
            } else {
                console.log("%c can not use browser language. reason: browser language is undefined", 'background: ' + this.color + ';');
            }
            /*
            try {
                lang = parseInt(localStorage.getItem('taf2userLang')); // fallback for sessions that do not support user language (TCUC)
            } catch(e) {}
            */
            if(lang > 0) {
                this.setLanguage(this.idToKey(lang));
            }
            ///TODO: use cordova plugin for device language if necessary
        }
    }

    public hasLanguage(key: string): boolean {
        for(let i=0,il=this.languages.length;i<il;i++) {
            if(this.languages[i].key === key || this.languages[i].isocode === key || this.languages[i].name === key) {
                return true;
            }
        }
        return false;
    }

    public keyToModel(key: string): Language {
        for(let i=0,il=this.languages.length;i<il;i++) {
            if(this.languages[i].key === key || this.languages[i].isocode === key || this.languages[i].name === key) {
                return this.languages[i];
            }
        }
        return null;
    }

    public keyToId(key: string): number {
        for(let i=0,il=this.languages.length;i<il;i++) {
            if(this.languages[i].key === key || this.languages[i].isocode === key || this.languages[i].name === key) {
                return this.languages[i].id;
            }
        }
        console.error("unknown language key:", key);
        /*
        if (key == de_key) {
            return de_id;
        } else if (key == en_key) {
            return en_id;
        } else {
            console.error("unknown language key:", key);
        }
        */
    }

    public idToKey(id: number): string {
        for(let i=0,il=this.languages.length;i<il;i++) {
            if(this.languages[i].id == id) {
                return this.languages[i].key || this.languages[i].isocode || this.languages[i].name;
            }
        }
        console.error("unknown language id:", id);
        /*
        if (id == de_id) {
            return de_key;
        } else if (id == en_id) {
            return en_key;
        } else {
            console.error("unknown language id:", id);
        }
        */
    }


}
