import { HttpClient } from "@angular/common/http";
import { BaseList } from "../baselist/baselist";
import { BranchConfigService } from "../branch-config/branch-config";

export class Payable {
  needstobepaid?: boolean;
  hasbeenpaid?: boolean;
  price?: number;
  currency?: string;
}

export const PAYABLE_FIRSTLOAD_MAX = 6;

/**
 * Beitet default funktionen wie /api/<type>/overview, ../group, ../latest funktionen
 * Wird für die Themen seite gebraucht, in dem einige payable vorkommen
 * @deprecated use {@link ProductService} instead
 */
export class PayableService<P extends Payable> {

  constructor(
    public type: String,
    public http: HttpClient,
    public configProvider: BranchConfigService) {
  }

  getList(tippgroupId?: string, loadMore: boolean = false, maxItems: number = PAYABLE_FIRSTLOAD_MAX): Promise<BaseList<P>> {
    return this.configProvider.isReady.then(() => {
      return new Promise<BaseList<P>>((resolve, reject) => {
        const d = { params: { maxItems: "" + maxItems } };
        if (!maxItems || loadMore) {
          delete d.params.maxItems;
        }

        if (!tippgroupId) {
          this.http.get<BaseList<P>>('/api/' + this.type + '/overview', d).subscribe(data => {
            resolve(data);
          }, err => {
            console.error('ERROR: ', err);
          });
        } else {
          this.http.get<BaseList<P>>('/api/' + this.type + '/group/' + tippgroupId, d).subscribe(data => {
            resolve(data);
          }, err => {
            console.error('ERROR: ', err);
          });
        }
      });
    });
  }

  getLatest(maxAgeInDays: number): Promise<BaseList<P>> {
    return this.configProvider.isReady.then(() => {
      return new Promise<BaseList<P>>((resolve, reject) => {
        this.http.get<BaseList<P>>('/api/' + this.type + '/latest', { params: { maxAge: "" + maxAgeInDays } }).subscribe(data => {
          resolve(data);
        }, err => {
          console.error('ERROR: ', err);
        });
      });
    });
  }

  get(id: number): Promise<P> {
    return new Promise<P>((resolve, reject) => {
      this.http.get<P>('/api/' + this.type + '/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.error('ERROR: ', err);
      });
    });
  }
}