// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badgeStyle {
  display: block;
  font-size: 12px;
  width: auto;
  height: 18px;
  border-radius: 16px;
  background-color: #39f;
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 18px;
  margin-left: 4px;
  padding: 0 9px;
  margin-right: 2px;
  opacity: 1;
  transition: opacity 0.1s;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Shared/CometChat-badge-count/cometchat-badge-count/cometchat-badge-count.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".badgeStyle {\n  display: block;\n  font-size: 12px;\n  width: auto;\n  height: 18px;\n  border-radius: 16px;\n  background-color: #39f;\n  color: #fff;\n  text-align: center;\n  font-weight: 700;\n  line-height: 18px;\n  margin-left: 4px;\n  padding: 0 9px;\n  margin-right: 2px;\n  opacity: 1;\n  transition: opacity 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
