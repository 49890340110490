// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pager {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}
.pager .pager-less, .pager .pager-more {
  flex: 0 0 auto;
}
.pager .pager-less {
  margin-left: auto;
  margin-right: 15px;
}
.pager .pager-more {
  margin-right: auto;
  margin-left: 15px;
}
.pager .pager-info {
  display: block;
  flex: 0 0 auto;
  text-align: center;
  line-height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/pager/pager.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,cAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".pager {\n  width: 100%;\n  display: flex;\n  justify-items: center;\n  align-items: center;\n  .pager-less, .pager-more {\n    flex: 0 0 auto;\n  }\n  .pager-less {\n    margin-left: auto;\n    margin-right: 15px;\n  }\n  .pager-more {\n    margin-right: auto;\n    margin-left: 15px;\n  }\n  .pager-info {\n    display: block;\n    flex: 0 0 auto;\n    text-align: center;\n    line-height: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
