import { Component, Injector, OnInit } from '@angular/core';
import { AccountdataService } from 'services/accountdata/accountdata.service';
import { PagesService } from 'services/pages/pages.service';
import { RoutingService } from 'services/routing/routing.service';
import { DataGroup, DataPage, MetaPage } from 'src/app/metagroup';


@Component({
  selector: 'page-account',
  templateUrl: 'account.html',
  styleUrls: ['account.scss']
})
export class AccountPage implements OnInit {
  public readonly pageName: string = "AccountPage";

  accountdataMeta: MetaPage;
  accountdata: DataPage;

  //itemOpened: boolean = false;
  itemOpened: boolean = true;

  public pages: any[];

  constructor(
    public injector: Injector,
    public accountdataProvider: AccountdataService,
    public pagesProvider: PagesService,
    private routingService: RoutingService
  ) {
    /*
    this.pages = Pages.find(page => {
      return page.page == 'AccountPage';
    }).sub;
    */
    this.pages = pagesProvider.getPage('AccountPage').sub;
  }

  ngOnInit() {
    console.log('ngOnInit AccountPage');
    let addMissingDataGroups = () => {
      this.accountdataMeta.groups.forEach(group => {
        if (!this.accountdata[group.id]) {
          this.accountdata[group.id] = new DataGroup();
        }
      });
    };
    this.accountdataProvider.getMeta().then(data => {
      this.accountdataMeta = data;
      this.accountdataProvider.getSingleton().then(data => {
        this.accountdata = data;
        addMissingDataGroups();
      }, error => {
        console.error('cannot load accountdata', error);
      });
    }, error => {
      console.error('cannot load accountdata meta', error);
    });

    this.routingService.setRootPage();
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter AccountPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave AccountPage');
  }

  save() {
    this.accountdataProvider.update(this.accountdata.id, this.accountdata).then(data => {
      //this.itemOpened = false;
    }, error => {
      console.error('cannot save accountdata', error);
    });
  }

  cancel() {
    //this.itemOpened = false;
    this.ngOnInit();
  }

  open() {
    if (!this.itemOpened) {
      this.itemOpened = true;
    }
  }

}
