// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageContainerStyle {
  align-self: flex-end;
  margin-bottom: 16px;
  padding-left: 16px;

  max-width: 65%;
  clear: both;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  float: right;
}
.messageContainerStyle:hover > .tool {
  display: flex;
}
.tool {
  display: none;
}
.messageWrapperStyle {
  width: auto;
  flex: 1 1;
  align-self: flex-end;
  display: flex;
}

.messageImgWrapper {
  display: inline-block;
  align-self: flex-end;
  max-width: 128px;
  height: 128px;
  cursor: pointer;
  flex-shrink: 0;
}

.messageInfoWrapperStyle {
  display: flex;
  align-self: flex-end;
}

@media (min-width: 320px) and (max-width: 767px) {
  .messageImgWrapper {
    max-width: 128px;
    height: 128px;
    padding: 2px 2px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/Extensions/CometChat-sender-sticker-message-bubble/cometchat-sender-sticker-message-bubble/cometchat-sender-sticker-message-bubble.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;;EAElB,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,SAAS;EACT,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE;IACE,gBAAgB;IAChB,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":[".messageContainerStyle {\n  align-self: flex-end;\n  margin-bottom: 16px;\n  padding-left: 16px;\n\n  max-width: 65%;\n  clear: both;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  float: right;\n}\n.messageContainerStyle:hover > .tool {\n  display: flex;\n}\n.tool {\n  display: none;\n}\n.messageWrapperStyle {\n  width: auto;\n  flex: 1 1;\n  align-self: flex-end;\n  display: flex;\n}\n\n.messageImgWrapper {\n  display: inline-block;\n  align-self: flex-end;\n  max-width: 128px;\n  height: 128px;\n  cursor: pointer;\n  flex-shrink: 0;\n}\n\n.messageInfoWrapperStyle {\n  display: flex;\n  align-self: flex-end;\n}\n\n@media (min-width: 320px) and (max-width: 767px) {\n  .messageImgWrapper {\n    max-width: 128px;\n    height: 128px;\n    padding: 2px 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
