import { Component, Injector, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GoodType, PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { MetaFile } from 'src/app/metagroup';

@Component({
  selector: 'page-image-select-view-more-modal',
  templateUrl: 'image-select-view-more-modal.html',
  styleUrls: ['image-select-view-more-modal.scss']
})
export class ImageSelectViewMoreModalPage implements OnInit {
  public readonly pageName: string = "ImageSelectViewMoreModalPage";

  files: MetaFile[];
  viewIndex: number = null;
  fieldid: string;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    console.log('ngOnInit ImageSelectViewMoreModalPage');
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.files.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.files.length) {
      this.viewIndex = 0;
    }
  }

  getBuyUrl() {
    // this.files[this.viewIndex], this.fieldid)
    return this.paidGoodsProvider.buildPaymentUrl(GoodType.IMAGE, Number(this.fieldid));
  }

  cancel() {
    if (this.viewIndex || this.viewIndex === 0) {
      this.viewIndex = null;
    } else {
      this.dismiss();
    }
  }

  dismiss(file?: MetaFile) {
    this.modalController.dismiss(file);
  }

  imageSelectUrlFor(file: MetaFile | null): string {
    if (!file) {
      return;
    }
    if (file.url) {
      return file.url[0] === '.' ? file.url : ('/api' + file.url);
    } else if (file.value) {
      return 'data:' + file.filetype + ';base64,' + file.value;
    }
  }
  imageSelectUrlKindFor(file: MetaFile | null): string {
    if (!file) {
      return;
    }
    if (!file) return 'none';
    return file.url ? 'url' : (file.value ? 'value' : 'none');
  }

}
