import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pages } from 'src/app/pages';
import { BaseList } from '../baselist/baselist';
import { BranchConfigService } from '../branch-config/branch-config';
import { PagesService } from '../pages/pages.service';
import { Payable, PAYABLE_FIRSTLOAD_MAX } from '../payable/payable.service';

export class Tipp extends Payable {
  id: string | number;
  tippgroupId: string; //must match id of TippGroup
  title: string;
  //text: string;
  html: string;
}

export class TippGroup {
  id: string;
  title: string;
  more: string;
  list: Tipp[];
}

@Injectable({
  providedIn: 'root'
})
export class BewerbungstippsProvider {
  pages: any[];
  constructor(
    public http: HttpClient,
    public pagesProvider: PagesService,
    public configProvider: BranchConfigService
  ) {
    this.pages = Pages;
  }
  //if NOT tippgroupId => get an overview of the tippgroups containing max 5 tipps each
  //if tippgroupId => get a tippgroup (in a one-element array) containing all its tipps
  getList(tippgroupId?: string, loadMore: boolean = false, maxItems: number = PAYABLE_FIRSTLOAD_MAX): Promise<BaseList<TippGroup>> { // returns a structured list of Tipp's (TippGroup[])
    return this.configProvider.isReady.then(() => {
      return new Promise<BaseList<TippGroup>>((resolve, reject) => {
        const d = { params: { maxItems: "" + maxItems } };
        if (!maxItems || loadMore) {
          delete d.params.maxItems;
        }
        if (!tippgroupId) {
          this.http.get<BaseList<TippGroup>>('/api/bewerbungstipp/group/overview', d).subscribe(data => {
            resolve(data);
          }, err => {
            console.error('ERROR: ', err);
          });
        } else {
          this.http.get<BaseList<TippGroup>>('/api/bewerbungstipp/group/' + tippgroupId, d).subscribe(data => {
            resolve(data);
          }, err => {
            console.error('ERROR: ', err);
          });
        }
      });
    }, error => {
      return Promise.reject(error);
    });
  }

  get(tippgroupId: number, id: number): Promise<TippGroup> { // returns a TippGroup containing just the requested Tipp
    return this.configProvider.isReady.then(() => {
      return new Promise<TippGroup>((resolve, reject) => {
        this.http.get<TippGroup>('/api/bewerbungstipp/' + (Number.isNaN(tippgroupId) ? 'null' : tippgroupId) + '/' + id).subscribe(data => {
          resolve(data);
        }, err => {
          console.error('ERROR: ', err);
        });
      });
    }, error => {
      return Promise.reject(error);
    });
  }

  getLatest(maxAgeInDays: number): Promise<TippGroup> {
    return this.configProvider.isReady.then(() => {
      return new Promise<TippGroup>((resolve, reject) => {
        this.http.get<TippGroup>('/api/bewerbungstipp/latest', { params: { maxAge: "" + maxAgeInDays } }).subscribe(data => {
          resolve(data);
        }, err => {
          console.error('ERROR: ', err);
        });
      });
    }, error => {
      return Promise.reject(error);
    });
  }

  getNav(): Promise<TippGroup[]> {
    return this.configProvider.isReady.then(() => {
      return new Promise<TippGroup[]>((resolve, reject) => {
        this.http.get<TippGroup[]>('/api/bewerbungstipp/group/categories').subscribe(data => {
          resolve(data);
        }, err => {
          console.error('ERROR: ', err);
        });
      });
    });
  }

  updateSubpages(): Promise<TippGroup[]> {
    return this.getNav().then((tippgroups: TippGroup[]) => {
      /*
      let idx = this.pages.findIndex(page => {
        return page.page === 'BewerbungstippsPage';
      });
      if (idx !== -1) {
        let page = this.pages[idx];
      */
      let page = this.pagesProvider.getPage('BewerbungstippsPage');
      if (page) {
        page.sub.splice(0, page.sub.length);
        if (tippgroups) {
          tippgroups.forEach((tippgroup, index) => {
            if (tippgroup.id) { // otherwise it the list of most recent "blog entries" combined of all tippgroups
              let subpage = {
                title: tippgroup.title,
                page: 'BewerbungstippsPage',
                feature: 'bewerbungstipps',
                params: { id: tippgroup.id },
                active: false,
                hideSub: true,
                sub: [{
                  title: tippgroup.title,
                  page: 'BewerbungstippPage',
                  feature: 'bewerbungstipps',
                  params: { groupid: tippgroup.id, id: undefined, tipp: undefined, tippgroup: undefined },
                  active: false,
                  hideSub: true,
                  sub: null,
                }, {
                  title: tippgroup.title,
                  page: 'ChecklistPage',
                  feature: 'bewerbungstipps',
                  params: { groupid: tippgroup.id, id: undefined, checklist: undefined },
                  active: false,
                  hideSub: true,
                  sub: null,
                }],
              };
              page.sub.push(subpage);
            }
          });
        }
      }
      return tippgroups;
    });
  }
}
