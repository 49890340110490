import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BranchConfigService } from "services/branch-config/branch-config";
import { PayableService } from "services/payable/payable.service";
import { OnlineCourseProduct } from "./onlinecourse-product";
import { ProductService } from 'services/product/product.service';

/**
 * @deprecated use {@link ProductService} instead
 */
@Injectable({
  providedIn: 'root'
})
export class OnlineCourseProductService extends PayableService<OnlineCourseProduct> {

  constructor(
    public http: HttpClient,
    public configProvider: BranchConfigService,
  ) {
    super('onlinecourse', http, configProvider);
  }
}