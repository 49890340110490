import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends ApiService<File> {

  constructor(
    private sanitizer: DomSanitizer
  ) {
    super('file');
  }

  /**
   * GET a file as a blob.
   * @param key the resource key, leave this empty to access the default file API.
   * @param options HTTP request options, leave this empty for default behaviour.
   * @returns the requested file as a blob
   */
  public override get(key: string | number, options?: any): Promise<File> {
    return super.get(key, { ...options, ...{ responseType: 'blob' } });
  }

  /**
   * GET a file represented as a trusted url.
   * @param key the resource key, leave this empty to access the default file API.
   * @returns the requested file as a trusted url
   */
  public async getAsUrl(key: string | number): Promise<string> {
    const blob: Blob = await this.get(key);
    let objectURL = URL.createObjectURL(blob);
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(objectURL));
  }

  /**
   * GET a file represented as a trusted url, in the format 'url(<url>)' to use it in CSS directly.
   * @param key the resource key, leave this empty to access the default file API.
   * @returns the requested file as a trusted url
   */
  public async getAsUrlForCss(key: string | number): Promise<string> {
    return 'url(' + await this.getAsUrl(key) + ')';
  }

  /**
   * The update function is NOT YET IMPLEMENTED for files.
   */
  public override update(key: string | number, data: Object): Promise<File> {
    throw new Error("not implemented yet");
  }
}
