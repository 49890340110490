import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { isEmpty } from 'lodash';

/**
 * equalValidator
 * ValidatorFn for testing equality to a given other form element
 *
 * Can be used in a Reactive Form Validation Context as follows:
 * ... new FormControl(..., [ ..., Validators.required, ..., equalValidator('<name of other form field>'), ... ]) ...
 *
 * @param {string} validateEqual - name of form element to compare to, both form elements will be invalidated if unequal or validated if equal
 * @returns {ValidatorFn}
 */
export function equalValidator(validateEqual: string): ValidatorFn {
    return (currentControl: AbstractControl): {[key: string]: any} => {
        if(isEmpty(currentControl.value)) {
            return null;
        }
        // another control
        let validateControl: AbstractControl = currentControl.root.get(validateEqual);

        // value equal
        if(validateControl && currentControl.value === validateControl.value) {
            if(currentControl.hasError("validateEqual")) {
                delete currentControl.errors["validateEqual"];
                if(isEmpty(currentControl.errors)) {
                    currentControl.setErrors(null);
                }
            }
            if(validateControl.hasError("validateEqual")) {
                delete validateControl.errors["validateEqual"];
                if(isEmpty(validateControl.errors)) {
                    validateControl.setErrors(null);
                }
            }
            return null;
        }

        if(isEmpty(validateControl.value)) {
            return null;
        }

        // value not equal
        let err: any = { "validateEqual": true };

        if(!validateControl.hasError("validateEqual")) {
            if(!validateControl.errors) {
                validateControl.setErrors(err);
            } else {
                validateControl.errors["validateEqual"] = true;
            }
        }

        return err;
    };
}

/**
 * EqualValidatorDirective
 * Validator for testing equality to a given other form element
 *
 * Can be used in a Template Driven Form Validation Context as follows:
 * <input ... required ... validateEqual="<name of other form element" ... [(ngModel)]="..." ...>
 *
 */
@Directive({
    selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidatorDirective), multi: true }
    ]
})
export class EqualValidatorDirective implements Validator {
    constructor( @Attribute('validateEqual') public validateEqual: string) {}

    validate(c: AbstractControl): { [key: string]: any } {
        return equalValidator(this.validateEqual)(c);
    }
}