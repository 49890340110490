import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BranchConfigService } from "services/branch-config/branch-config";
import { JobboerseService } from 'services/jobboerse/jobboerse.service';
import { ConstantVariable } from "src/app/constant-variable";
import { DataGroup, DataPage, MetaFile, MetaId, MetaValue } from 'src/app/metagroup';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'page-jobboerse-detail',
  templateUrl: 'jobboerse-detail.html',
  styleUrls: ['jobboerse-detail.scss']
})
export class JobboerseDetailPage implements OnInit {
  public readonly pageName: string = "JobboerseDetailPage";

  job: DataPage;
  anredeById: Map<MetaId, MetaValue>;
  stelleArtById: Map<MetaId, MetaValue>;
  logoUrl: string;
  showAllFeatures: boolean;

  constructor(
    public injector: Injector,
    public jobboerseProvider: JobboerseService,
    public branch: BranchConfigService,
    private route: ActivatedRoute,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit JobboerseDetailPage');
    this.showAllFeatures = ConstantVariable.showAllFeatures;
    this.route.paramMap.subscribe(paramMap => {
      const id = paramMap.get('id');
      this.jobboerseProvider.get(id).then(data => {
        console.log('JOB', data);
        if (data) {
          this.job = data;
          this.logoUrl = this.logoImageUrl(this.job);
        } else {
          console.error('got null job with id ' + id);
        }
      }, error => {
        console.error('cannot get job with id ' + id, error);
      });
    })
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter JobboerseDetailPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave JobboerseDetailPage');
  }

  apply(job: DataPage) {
    this.navController.navigateForward('jobboerse-bewerbermappe/' + job.id);
  }

  back() {
    this.navController.pop();
  }

  logoImageUrl(job: DataPage): string {
    if (job.arbeitgeber && (<DataGroup>job.arbeitgeber).fieldData && (<DataGroup>job.arbeitgeber).fieldData.firmaLogo) {
      let file: MetaFile = <MetaFile>((<DataGroup>job.arbeitgeber).fieldData.firmaLogo);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  seitText(job: DataPage) {
    let dstr;
    if (!job) return;
    if (job.status) {
      dstr = (<DataGroup>job.status).fieldData.datum + ' ' + (<DataGroup>job.status).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

  abText(job: DataPage) {
    let dstr;
    if (!job) return;
    if (job.status) {
      dstr = (<DataGroup>job.stelle).fieldData.datum + ' ' + (<DataGroup>job.stelle).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

  /**
   * Versucht die höhe des Iframe zu bekommen, wenn externe Seite oder anderweitig nicht erlaubt wird defaultwert gesetzt
   * 
   * @param evt onload Event
   */
  iframeLoaded(evt: Event) {
    // console.log('IFRAME: ', evt, this);
    const t = <HTMLIFrameElement>evt.target;
    try {
      t.height = t.contentWindow.document.body.scrollHeight + 'px';
    } catch (e) {
      console.trace('iframe height not available, setting default', e);
      t.height = '1000px';
    }
  }
}
