import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BranchConfigService } from "services/branch-config/branch-config";
import { JobboerseApplyMdata, JobboerseService } from 'services/jobboerse/jobboerse.service';
import { MessageService } from 'services/message/message.service';
import { MetaInvalid, MetaValidityService } from 'services/meta-validity/meta-validity.service';
import { DataFields, DataGroup, DataPage, MetaFile, MetaId, MetaPage, MetaValue } from 'src/app/metagroup';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'page-jobboerse-bewerbermappe',
  templateUrl: 'jobboerse-bewerbermappe.html',
  styleUrls: ['jobboerse-bewerbermappe.scss']
})
export class JobboerseBewerbermappePage implements OnDestroy, OnInit {
  public readonly pageName: string = "JobboerseBewerbermappePage";

  job: DataPage;
  anredeById: Map<MetaId, MetaValue>;
  stelleArtById: Map<MetaId, MetaValue>;
  logoUrl: string;
  isLoadInProgress: boolean;

  public data: DataPage;
  public mdata: JobboerseApplyMdata;
  public meta: MetaPage;
  public metaInvalid: MetaInvalid[];
  public selectedTemplate: MetaId = 0;

  constructor(
    private router: Router,
    public injector: Injector,
    public metaValidity: MetaValidityService,
    public message: MessageService,
    public branch: BranchConfigService,
    public jobboerseProvider: JobboerseService,
    private route: ActivatedRoute,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit JobboerseBewerbermappePage');

    this.loaddata();
  }

  ionViewWillLeave() {
    //console.log('ionViewWillLeave JobboerseBewerbermappePage');
  }

  ngOnDestroy() {
  }

  loaddata() {
    this.route.paramMap.subscribe(paramMap => {
      const jobId = +paramMap.get('id');
      if (!this.job) {
        this.jobboerseProvider.get(jobId).then(data => {
          console.log('JOB', data);
          if (data) {
            this.job = data;
            this.logoUrl = this.logoImageUrl(this.job);
          } else {
            console.error('got null job with id ' + jobId);
          }
  
        }, error => {
          console.error('cannot get job with id ' + jobId, error);
        });
      } else {
        this.logoUrl = this.logoImageUrl(this.job);
      }
  
      this.jobboerseProvider.getApplyMeta(jobId).then(mdata => {
        this.mdata = mdata;
        this.meta = mdata.meta;
        this.jobboerseProvider.getApplyData(jobId).then(data => {
          this.data = data;
        });
      })
    });
  }

  allPagesAreValid(): boolean {
    if (!this.data) return true;

    this.metaInvalid = [];
    let thisPageValid: boolean = this.metaValidity.pageIsValid(this.data, this.meta, this.metaInvalid);
    //console.log('THIS datapage:'+this.groupData.id+' metapage:'+this.metapage.id+' has validity:'+thisPageValid,this.groupData,this.metapage);
    return thisPageValid && !this.isLoadInProgress;
  }

  doApply() {
    // console.debug('apply: ', this.data);
    this.isLoadInProgress = true;
    this.jobboerseProvider.postApplyData(this.data).then(success => {
      this.isLoadInProgress = false;
      this.navController.navigateRoot('/bewerbung/' + success.fieldData);
    }, error => {
      this.isLoadInProgress = false;
    });
  }

  onChangeData() {
    console.log("onChangeData", arguments);
  }

  onChangeTemplate() {
    console.log("onChangeTemplate", arguments);
    if (this.selectedTemplate && this.selectedTemplate != 0) {
      const idx = (<DataFields[]>this.mdata.templates).findIndex(e => e.id == this.selectedTemplate);
      if (idx < 0) return;

      const tmpl = this.mdata.templates[idx];
      (<DataGroup>this.data.email).fieldData.title = tmpl.subject;
      (<DataGroup>this.data.email).fieldData.msg = tmpl.message;

      setTimeout(() => this.selectedTemplate = 0, 50);
    }
  }

  logoImageUrl(job: DataPage): string {
    if (job.arbeitgeber && (<DataGroup>job.arbeitgeber).fieldData && (<DataGroup>job.arbeitgeber).fieldData.firmaLogo) {
      let file: MetaFile = <MetaFile>((<DataGroup>job.arbeitgeber).fieldData.firmaLogo);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  seitText(job: DataPage) {
    let dstr;
    if (!job) return;
    if (job.status) {
      dstr = (<DataGroup>job.status).fieldData.datum + ' ' + (<DataGroup>job.status).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }

  abText(job: DataPage) {
    let dstr;
    if (!job) return;
    if (job.status) {
      dstr = (<DataGroup>job.stelle).fieldData.datum + ' ' + (<DataGroup>job.stelle).fieldData.uhrzeit;
    }
    let d = moment(dstr, 'YYYY-MM-DD HH:mm');
    return d.fromNow();
  }
}
