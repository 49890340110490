import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GoodType, PaidGoodsService } from "services/paid-goods/paid-goods.service";
import { Podcast } from "services/podcasts/podcasts.service";

@Component({
  selector: 'page-podcast-view-more-modal',
  templateUrl: 'podcast-view-more-modal.html',
  styleUrls: ['podcast-view-more-modal.scss']
})
export class PodcastViewMoreModalPage implements OnInit {
  public readonly pageName: string = "PodcastViewMoreModalPage";

  @Input() podcasts: Podcast[];
  @Input() podcast: Podcast;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) {
  }
  
  ngOnInit() {
    console.log('ngOnInit PodcastViewMoreModalPage');
    this.viewIndex = this.podcasts.findIndex(podcast => podcast.id === this.podcast.id);
    if (this.viewIndex === -1) {
      console.error('PodcastViewMoreModalPage: podcast not in podcasts.');
      this.viewIndex = 0;
    }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.podcasts.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.podcasts.length) {
      this.viewIndex = 0;
    }
  }

  getBuyUrl() {
    return this.paidGoodsProvider.buildPaymentUrl(GoodType.PODCAST, this.podcasts[this.viewIndex].id);
  }

  cancel() {
    this.dismiss();
  }

  dismiss(podcast?: Podcast) {
    this.modalController.dismiss(podcast);
  }

}
