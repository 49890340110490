// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .onlinecourses {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
  margin-bottom: -5px;
}
:host .onlinecourses onlinecourse {
  flex: 1 1;
  flex-basis: calc(50% - 30px);
  min-width: 300px;
  margin-right: 30px;
  margin-bottom: 30px;
}
:host .right-links {
  padding-top: 10px;
  margin-bottom: 0;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/components/onlinecourse-list/onlinecourse-list.scss"],"names":[],"mappings":"AACE;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AAAN;AAEM;EACE,SAAA;EACA,4BAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;AAFJ","sourcesContent":[":host {\n  .onlinecourses {\n      display: flex;\n      flex-wrap: wrap;\n      margin-right: -30px;\n      margin-bottom: -5px;\n\n      onlinecourse {\n        flex: 1 1;\n        flex-basis: calc(50% - 30px);\n        min-width: 300px;\n        margin-right: 30px;\n        margin-bottom: 30px;\n      }\n  }\n\n  .right-links {\n    padding-top: 10px;\n    margin-bottom: 0;\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
