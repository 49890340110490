import { EventDetails } from './events.details';
import { Injectable } from '@angular/core';

import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventKey } from './events.keys';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    

    private eventsDispatcher = new Subject<EventDetails>();

    public publish(event: EventKey, ...payload: unknown[]): void {
        this.eventsDispatcher.next({ event, payload });
    }

    public subscribe(event: EventKey, handler: any): Subscription {
        return this.getEvent(event).subscribe(handler);
    }

    private getEvent(event: EventKey): Observable<unknown> {
        return this.eventsDispatcher.asObservable().pipe(
            filter((eventDetails) => eventDetails.event === event),
            map((eventDetails) => eventDetails.payload)
        );
    }
}