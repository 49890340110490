import { Injectable, Optional, Inject } from '@angular/core';
import { Mocker, MockService } from './mock.service';
import { HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthResponse } from '../auth/auth-response.interface';
import { ApiAuthOptions, AuthOptions } from '../auth/auth-config';

/*
    this can be registered with MockerProvider to mock a very basic authentication against AuthProvider
 */

@Injectable()
export class AuthMocker implements Mocker {

    user: AuthResponse = null;

    constructor(@Optional() public mockProvider: MockService, @Optional() @Inject(ApiAuthOptions) private authOptions: AuthOptions) {
        this.authOptions = AuthOptions.mergeAuthOptions(this.authOptions);
        if(this.mockProvider) {
            this.mockProvider.register(this);
        }
    }

    handle(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        let observable: Observable<HttpEvent<any>> = null;

        //TODO
        console.info('AuthMocker: ', url, method, request);
        if(url === '/api/'+this.authOptions.urls.sessionUser && method === 'GET') {
            console.log('AuthMocker: mocked session/user');
            this.user = this.user || JSON.parse(window.localStorage.getItem('taf2auth_mock_user'));
            observable = new Observable(resp => {
                resp.next(new HttpResponse({
                    status: this.user ? 200: 304,
                    body: this.user
                }));
                resp.complete();
            });
        } else if(url === '/api/'+this.authOptions.urls.login && method === 'POST') {
            console.log('AuthMocker: mocked login');
            this.user = { ... request.body,
                id: 1,
                authtoken: '1',
                password: undefined,
            };
            window.localStorage.setItem('taf2auth_mock_user', JSON.stringify(this.user));
            observable = new Observable(resp => {
                resp.next(new HttpResponse({
                    status: this.user ? 200: 304,
                    body: this.user
                }));
                resp.complete();
            });
        } else if(url === '/api/'+this.authOptions.urls.logout && method === 'POST') {
            console.log('AuthMocker: mocked logout');
            this.user = null;
            window.localStorage.removeItem('taf2auth_mock_user');
            observable = new Observable(resp => {
                resp.next(new HttpResponse({
                    status: 200
                }));
                resp.complete();
            });
        }
        //TODO: maybe mock other methods of AuthProvider

        return observable;
    }
}
