import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Checklist } from "services/checklist/checklist.service";
import { GoodType, PaidGoodsService } from "services/paid-goods/paid-goods.service";

@Component({
  selector: 'page-checklist-view-more-modal',
  templateUrl: 'checklist-view-more-modal.html',
  styleUrls: ['checklist-view-more-modal.scss']
})
export class ChecklistViewMoreModalPage implements OnInit {
  public readonly pageName: string = "ChecklistViewMoreModalPage";

  @Input() checklists: Checklist[];
  @Input() checklist: Checklist;
  viewIndex: number = null;

  constructor(
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    private modalController: ModalController
  ) {
  }
  
  ngOnInit() {
    console.log('ngOnInit ChecklistViewMoreModalPage');
    this.viewIndex = this.checklists.findIndex(checklist => checklist.id === this.checklist.id);
    if (this.viewIndex === -1) {
      console.error('ChecklistViewMoreModalPage: checklist not in checklists.');
      this.viewIndex = 0;
    }
  }

  view(index: number) {
    this.viewIndex = index;
  }
  prev() {
    this.viewIndex--;
    if (this.viewIndex < 0) {
      this.viewIndex = this.checklists.length - 1;
    }
  }
  next() {
    this.viewIndex++;
    if (this.viewIndex >= this.checklists.length) {
      this.viewIndex = 0;
    }
  }

  buynow() {
    return this.paidGoodsProvider.buildPaymentUrl(GoodType.CHECKLIST, this.checklists[this.viewIndex].id);
    // this.paidGoodsProvider.create(new PaidGood(this.checklists[this.viewIndex], GoodType.CHECKLIST)).then(good => {
    //   this.paidGoodsProvider.makePayment(good).then(good => {
    //     this.checklists[this.viewIndex].hasbeenpaid = !!good.paymenttime;
    //     if (!!good.paymenttime) {
    //       this.dismiss(this.checklists[this.viewIndex]);
    //     }
    //   }, error => {
    //     console.error('Failed to makePayment for a PaidGood', error);
    //   });
    // }, error => {
    //   console.error('Failed to create a PaidGood', error);
    // });
  }

  cancel() {
    this.dismiss();
  }

  dismiss(checklist?: Checklist) {
    this.modalController.dismiss(checklist);
  }

}
