// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .desc {
  margin-bottom: 20px;
}
:host .info {
  margin-bottom: 20px;
}

.register-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
.register-content .form-items {
  width: 100%;
}
.register-content .form-item,
.register-content ion-button {
  width: 100%;
  margin-bottom: 15px;
}
.register-content app-privacy {
  position: absolute;
  bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/register/register.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AAFF;AAIE;EACE,WAAA;AAFJ;AAKE;;EAEE,WAAA;EACA,mBAAA;AAHJ;AAME;EACE,kBAAA;EACA,YAAA;AAJJ","sourcesContent":[":host {\n  .desc {\n    margin-bottom: 20px;\n  }\n\n  .info {\n    margin-bottom: 20px;\n  }\n}\n\n.register-content {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  align-items: center;\n  width: 100%;\n\n  .form-items {\n    width: 100%;\n  }\n\n  .form-item,\n  ion-button {\n    width: 100%;\n    margin-bottom: 15px;\n  }\n\n  app-privacy {\n    position: absolute;\n    bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
