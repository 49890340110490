import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { BranchConfigService } from 'services/branch-config/branch-config';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'services/alert/alert.service';
import { EventKey } from 'services/events/events.keys';
import { EventsService } from 'services/events/events.service';
import { ToastService } from 'services/toast/toast.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { ApiAuthOptions, AuthOptions } from './auth-config';
import {
    LoginCredentials,
    PasswordChangeCredentials,
    PasswordResetChangeCredentials,
    PasswordResetCredentials,
    SignupCredentials,
} from './auth-credentials';
import { AuthError } from './auth-error';
import { AuthResponse } from './auth-response.interface';

/*
IMPORTANT NOTE:
It should be noted that Angular’s new HttpClient from @angular/common/http is being used here and not the Http class from @angular/http.
If we try to make requests with the traditional Http class, the interceptor won’t be hit, i.e. there will be no X-Auth-Token.
 */

@Injectable()
export class AuthService {
    private userSubject = new Subject<AuthResponse>();
    private stateSubject = new Subject<boolean>();
    private errorSubject = new Subject<AuthError>();
    private _user: AuthResponse;

    private loginCallbacks: Function[] = [];
    private logoutCallbacks: Function[] = [];

    public loginRedirect: string = '/';

    get user(): AuthResponse {
        return this._user || (this._user = JSON.parse(localStorage.getItem("AuthResponse") || 'null'));
    }
    set user(value: AuthResponse) {
        const oldState: boolean = !!this._user;
        this._user = value;
        // store:
        let authCookie: string = this.authOptions.preventCookie ? null : (encodeURIComponent(this.authOptions.tokenHeader) +
            "=" + encodeURIComponent(this.authOptions.tokenPrefix +
                (value && value[this.authOptions.tokenProperty]) || '') +
            ";path=/;domain=" + encodeURIComponent(this.domainOfUrl(this.configProvider?.config?.apiBaseUrl)));
        if (!value) {
            localStorage.removeItem("AuthResponse");
            if (!this.authOptions.preventCookie) {
                document.cookie = authCookie + ";max-age=0;expires=Fri, 31 Dec 1970 23:59:59 GMT";
                //console.error('unsetting cookie: ' + authCookie + ";max-age=0;expires=Fri, 31 Dec 1970 23:59:59 GMT");
            }
        } else {
            localStorage.setItem("AuthResponse", JSON.stringify(value));
            if (!this.authOptions.preventCookie) {
                document.cookie = authCookie;
                //console.error('setting cookie: ' + authCookie);
            }

            // Execute login callbacks after user is set
            this.executeLoginCallbacks();
        }
        // broadcast:
        this.userSubject.next(this._user);
        if (oldState !== !!this._user) {
            this.stateSubject.next(!!this._user);
        }
    }

    constructor(
        private http: HttpClient,
        @Optional() @Inject(ApiAuthOptions) public authOptions: AuthOptions,
        public events: EventsService,
        private userdataProvider: UserdataService,
        private router: Router,
        private configProvider: BranchConfigService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private alertService: AlertService,
    ) {
        this.authOptions = AuthOptions.mergeAuthOptions(this.authOptions);
        this.userChanged().subscribe(user => {
            userdataProvider.flush();
        });
    }

    domainOfUrl(url: string): string {
        if (!url) return;
        let u = new URL(url);
        return u && u.hostname;
    }

    // subscribe to this to get user object on each change
    // this is fired whenever the user is set, even when staying the same
    userChanged(): Observable<AuthResponse> {
        return this.userSubject.asObservable();
    }

    // subscribe to this to get change in login status as a simple boolean
    // this only fires on actual change of status, i.e. when the user was already logged in before the app started (localStorage), then it does *not* fire any value
    stateChanged(): Observable<boolean> {
        return this.stateSubject.asObservable();
    }

    // subscribe to this to get an error message every time any request fails
    // this fires whenever any request fails (all requests throughout the app) that is intercepted by AuthInterceptor
    // and contains boolean information if it is considered an auth failure and the HttpErrorResponse
    error(): Observable<AuthError> {
        return this.errorSubject.asObservable();
    }

    prepare(input: any): any {
        if (this.authOptions.encodeAs === 'application/json') {
            return input; // default, no conversion
        } else if (this.authOptions.encodeAs === 'application/x-www-form-urlencoded') {
            let str = '', arr = [];
            Object.getOwnPropertyNames(input).forEach(key => {
                arr.push(encodeURI(key) + '=' + encodeURI(input[key]));
            });
            str = arr.join('&');
            return str;
        } else {
            return input; // unknown, ignore
        }
    }

    refresh(): Promise<any> {
        if (!this.user) {
            return Promise.reject('Improper use: OAuth-like refresh attempted without having been logged in in the first place.');
        } else if (typeof this.authOptions.tokenRefreshProperty === 'string' && this.user) {
            let headers = new HttpHeaders();
            headers = headers.set('Content-Type', this.authOptions.encodeAs);
            let refresh = this.prepare({
                refresh_token: this.user[this.authOptions.tokenRefreshProperty],
                ...this.authOptions.tokenRefreshParameters
            });
            return new Promise<AuthResponse>((resolve, reject) => {
                this.http.post<AuthResponse>('/api/' + this.authOptions.urls.login, refresh, { headers: headers }).subscribe(data => {
                    this.user = data; //FIXME: user change is issued (and MUST be issued), but issuing of course reloads everything, since there is no way to identify that it is still the same user
                    resolve(data);
                }, err => {
                    this.user = null;
                    reject(err);
                });
            });
        } else {
            return Promise.reject('Improper use: OAuth-like refresh attempted without tokenRefreshProperty configured.');
        }
    }

    login(login: LoginCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        login.username = !!login && !!login.username ? login.username.trim() : null;
        login = this.prepare(login);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.login, login, { headers: headers }).subscribe(data => {
                this.user = data;
                this.events.publish(EventKey.USER_LOGGEDIN);
                this.router.navigate([this.loginRedirect]);
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    logout(logoutData?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const _logout: Function = () => {
                // Execute logout callbacks before user is cleared
                this.executeLogoutCallbacks();

                this.http.post('/api/' + this.authOptions.urls.logout, logoutData).subscribe(data => {
                    this.user = null;
                    this.router.navigate(['/']);
                    resolve(data);
                }, err => {
                    this.user = null;
                    reject(err);
                });
            };

            _logout();
        });
    }

    signup(signup: SignupCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        signup.username = !!signup && !!signup.username ? signup.username.trim() : null;
        signup = this.prepare(signup);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.signup, signup, { headers: headers }).subscribe(data => {
                this.user = data;
                resolve(data);
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    // logged-out, i.e. "forgot password"
    passwordReset(passwordReset: PasswordResetCredentials): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordReset.username = !!passwordReset && !!passwordReset.username ? passwordReset.username.trim() : null;
        passwordReset = this.prepare(passwordReset);
        return new Promise((resolve, reject) => {
            this.http.post('/api/' + this.authOptions.urls.passwordReset, passwordReset, { headers: headers }).subscribe(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    // logged-out, i.e. "change my password" after "forgot password"
    passwordResetChange(passwordResetChange: PasswordResetChangeCredentials): Promise<AuthResponse> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordResetChange = this.prepare(passwordResetChange);
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.post<AuthResponse>('/api/' + this.authOptions.urls.passwordResetChange, passwordResetChange, { headers: headers }).subscribe(data => {
                resolve(data);
            }, err => {
                reject(err);
            });
        });
    }

    // logged-in, i.e. "change my password"
    passwordChange(passwordChange: PasswordChangeCredentials): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', this.authOptions.encodeAs);
        passwordChange = this.prepare(passwordChange);
        return new Promise((resolve, reject) => {
            this.http.post('/api/' + this.authOptions.urls.passwordChange, passwordChange, { headers: headers }).subscribe(data => {
                this.toastService.handleSuccessTranslate('service.auth.changepw.success');
                resolve(data);
            }, err => {
                this.toastService.handleError(err);
                reject(err);
            });
        });
    }

    deleteAccount(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.alertService.presentAlert(
                this.translateService.instant('service.auth.deleteAccount.confirmation'),
                () => {
                    this.http.delete('/api/user/delete').subscribe(data => {
                        this.toastService.handleSuccessTranslate('service.auth.deleteAccount.success');
                        this.logout();
                        resolve(''); // Resolve
                    }, err => {
                        reject(err);
                    });
                },
                () => {
                    // Handle case where alert is dismissed or declined
                    reject('User canceled the account deletion.');
                });
        });
    }

    sessionUser(): Promise<AuthResponse> {
        /*let headers = new HttpHeaders();
        headers = headers.set('Content-Type',this.authOptions.encodeAs);*/
        return new Promise<AuthResponse>((resolve, reject) => {
            this.http.get<AuthResponse>('/api/' + this.authOptions.urls.sessionUser /*, {headers: headers}*/).subscribe(data => {
                if (data) {
                    this.user = data;
                    resolve(data);
                } else {
                    this.user = null;
                    reject(data);
                }
            }, err => {
                this.user = null;
                reject(err);
            });
        });
    }

    issueError(err: AuthError, revoke: boolean = false, revalidate: boolean = false): void {
        if (revoke && this.user) {
            if (revalidate) {
                this.sessionUser().then(res => {
                    console.log('not logged out, after succeeded to revalidate session');
                }, err => {
                    console.log('logged out, after failed to revalidate session');
                });
            } else {
                console.log('revoking session');
                this.user = null;
            }
        }
        this.errorSubject.next(err);
    }

    /**
     * Registers a callback function to be executed upon login.
     * 
     * @param callback - The function to be called when a login event occurs.
     *                    If the callback is already registered, it will not be added again.
     */
    public registerLoginCallback(callback: Function): void {
        if (callback && !this.loginCallbacks.includes(callback)) {
            this.loginCallbacks.push(callback);
        }
    }

    /**
     * Unregisters a previously registered login callback function.
     *
     * @param callback - The callback function to be unregistered.
     */
    public unregisterLoginCallback(callback: Function): void {
        const index = this.loginCallbacks.indexOf(callback);
        if (index > -1) {
            this.loginCallbacks.splice(index, 1);
        }
    }

    /**
     * Registers a callback function to be executed upon logout.
     * 
     * @param callback - The function to be called when a logout occurs.
     *                    If the callback is already registered, it will not be added again.
     */
    public registerLogoutCallback(callback: Function): void {
        if (callback && !this.logoutCallbacks.includes(callback)) {
            this.logoutCallbacks.push(callback);
        }
    }

    /**
     * Unregisters a previously registered logout callback function.
     *
     * @param callback - The callback function to be unregistered.
     */
    public unregisterLogoutCallback(callback: Function): void {
        const index = this.logoutCallbacks.indexOf(callback);
        if (index > -1) {
            this.logoutCallbacks.splice(index, 1);
        }
    }

    /**
     * Executes all registered login callbacks with the current user.
     * 
     * This method iterates over the `loginCallbacks` array and invokes each callback,
     * passing the current user as an argument. If any callback throws an error, it is caught
     * and logged to the console with an error message.
     * 
     * @private
     */
    private executeLoginCallbacks(): void {
        this.loginCallbacks.forEach(callback => {
            try {
                callback(this.user);
            } catch (error) {
                console.error('Error in login callback:', error);
            }
        });
    }

    /**
     * Executes all registered logout callbacks.
     * 
     * This method iterates over the `logoutCallbacks` array and invokes each callback
     * with the current user as an argument. If any callback throws an error, it catches
     * the error and logs it to the console.
     * 
     * @private
     */
    private executeLogoutCallbacks(): void {
        this.logoutCallbacks.forEach(callback => {
            try {
                callback(this.user);
            } catch (error) {
                console.error('Error in logout callback:', error);
            }
        });
    }
}
