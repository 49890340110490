import { Injectable } from '@angular/core';

import { MetaPage, MetaGroup, MetaField, MetaValue, MetaRelations, MetaRelation, MetaId, DataValue, DataGroup, DataFields, DataPage } from 'metagroup';

export class MetaInvalid {
  validator?: string;
  field?: string;
  group?: string;
  page?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaValidityService {

  constructor() {
  }

  pageIsValid(dataPage: DataPage, metaPage: MetaPage, invalid?: MetaInvalid[], ignorePageOrGroupInvalidity?: boolean) {
    if(!dataPage || !metaPage) return;
    let pageHasSomething = false;
    for(let i=0;i<metaPage.groups.length;i++) {
      let mg = metaPage.groups[i];
      if(mg.listtype === 'array' || mg.listtype === 'set') {
        let dgs = <DataGroup[]>dataPage[mg.id];
        for(let j=0;j<dgs.length;j++) {
          if(!this.groupIsValid(dgs[j],mg,metaPage,invalid)) return false;
        }
        if(metaPage.required && dgs.length > 0) pageHasSomething = true;
        if(!ignorePageOrGroupInvalidity && mg.required && dgs.length === 0) {
          this.pushInvalid('required', null, mg, metaPage, invalid);
          return false;
        }
      } else {
        if(!this.groupIsValid(<DataGroup>dataPage[mg.id],mg,metaPage,invalid)) return false;
        else pageHasSomething = true;
      }
    }
    if(!ignorePageOrGroupInvalidity && metaPage.required && !pageHasSomething) {
      this.pushInvalid('required', null, null, metaPage, invalid);
      return false;
    }
    return true;
  }

  groupIsValid(dataGroup: DataGroup, metaGroup: MetaGroup, metaPage?: MetaPage, invalid?: MetaInvalid[]) {
    if(!dataGroup || !metaGroup) return;
    for(let i=0;i<metaGroup.fields.length;i++) {
      let mf = metaGroup.fields[i];
      if(!this.fieldIsValid(dataGroup.fieldData[mf.id],mf,metaGroup,metaPage,invalid)) return false;
    }
    return true;
  }

  fieldIsValid(dataValue: DataValue, metaField: MetaField, metaGroup?: MetaGroup, metaPage?: MetaPage, invalid?: MetaInvalid[]) {
    if(!metaField) return;
    if(metaField.required && !dataValue) {
      this.pushInvalid('required',metaField,metaGroup,metaPage,invalid);
      return false;
    }
    if(metaField.maxValues && dataValue && (<MetaId[]>dataValue).length > metaField.maxValues) {
      this.pushInvalid('maxValues',metaField,metaGroup,metaPage,invalid);
      return false;
    }
    return true;
  }

  pushInvalid(validator: string, metaField: MetaField, metaGroup: MetaGroup, metaPage: MetaPage, invalid: MetaInvalid[]) {
    if(invalid) {
      invalid.push({
        validator: 'required',
        field: metaField && metaField.label,
        group: metaGroup && metaGroup.title,
        page: metaPage && metaPage.title,
      });
    }
  }

}
