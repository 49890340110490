import { Injectable } from '@angular/core';
import { ApiService } from 'services/api/api.service';
import { Order } from './order';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends ApiService<Order> {

  constructor() {
    super('order');
  }
}
