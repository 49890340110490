import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingContentPage } from './onboarding-content';
import { ComponentsModule } from 'components/components.module';
import { DirectivesModule } from 'directives/directives.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'pipes/pipes.module';

@NgModule({
  declarations: [
    OnboardingContentPage,
  ],
  imports: [
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class OnboardingContentPageModule {}
