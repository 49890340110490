import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ChecklistViewMoreModalPage } from 'pages/checklist-view-more-modal/checklist-view-more-modal';
import { BaseList } from 'services/baselist/baselist';
import { BewerbungstippsProvider, TippGroup } from 'services/bewerbungstipps/bewerbungstipps';
import { BranchConfigService } from "services/branch-config/branch-config";
import { Checklist, ChecklistService } from 'services/checklist/checklist.service';
import { CoachService } from 'services/coach/coach.service';
import { UserCoachService } from 'services/coach/user-coach.service';
import { ModalService } from 'services/modal/modal.service';
import { OnlineCourseProduct } from 'services/onlinecourse/onlinecourse-product';
import { OnlineCourseProductService } from 'services/onlinecourse/onlinecourse-product.service';
import { Podcast, PodcastsService } from 'services/podcasts/podcasts.service';
import { RoutingService } from 'services/routing/routing.service';
import { UserdataService } from 'services/userdata/userdata.service';
import { Video, VideoService } from 'services/video/video.service';
import { Webinar, WebinarService } from 'services/webinar/webinar.service';
import { ConstantVariable } from 'src/app/constant-variable';
import { DataPage, MetaGroup } from 'src/app/metagroup';

@Component({
  selector: 'page-bewerbungstipps',
  templateUrl: 'bewerbungstipps.html',
  styleUrls: ['bewerbungstipps.scss']
})
export class BewerbungstippsPage implements OnInit {
  public readonly pageName: string = "BewerbungstippsPage";

  showAllFeatures: boolean;
  openTippGroup: TippGroup;
  tippgroupId: string;
  leistungenMetaGroup: MetaGroup;
  myCoach: DataPage;

  bewerbungstippgroups: TippGroup[];
  bewerbungstippgroupsMore: boolean;
  podcasts: Podcast[];
  podcastsMore: boolean;
  checklists: Checklist[];
  checklistsMore: boolean;
  videos: Video[];
  videosMore: boolean;
  webinars: Webinar[];
  webinarsMore: boolean;
  onlinecourses: OnlineCourseProduct[];
  onlinecoursesMore: boolean;

  constructor(
    private route: ActivatedRoute,
    public injector: Injector,
    public bewerbungstippsProvider: BewerbungstippsProvider,
    public podcastsProvider: PodcastsService,
    public checklistsProvider: ChecklistService,
    public videosProvider: VideoService,
    public webinarsProvider: WebinarService,
    public branch: BranchConfigService,
    public coachesSettingsProvider: UserCoachService,
    public coachesProvider: CoachService,
    public userdataProvider: UserdataService,
    public onlineCourseProductProvider: OnlineCourseProductService,
    public modalService: ModalService,
    private navController: NavController,
    private routingService: RoutingService
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungstippsPage');

    this.route.paramMap.subscribe((params: ParamMap) =>  {
      this.tippgroupId = params.get('id');
    
      if (this.tippgroupId === 'overview') {
        this.tippgroupId = undefined;
      }

      this.branch.isReady.then(config => {
        console.log('branch.isReady BewerbungstippsPage');

        // Nicht implentiert
        // this.userdataProvider.reset('newtips').catch(error => console.error('failed to reset counter for newtips',error));

        // load tipps
        this.loadBewerbungstipps();

        // load podcasts
        this.loadPodcasts();

        // load checklist
        // TODO: genau wie REST-Anfragen von podcasts und videos aufbauen
        this.checklistsProvider.getList(this.tippgroupId).then(data => {
          this.checklists = data && data.slice(0, 6);
        });
        // load videos
        this.loadVideos();

        // load webinars
        this.loadWebinars();

        //load onlinecourse
        this.loadOnlinecourse();

        this.coachesProvider.getMeta().then(data => {
          this.leistungenMetaGroup = data.groups.find(group => {
            return group.id === 'leistungen';
          });
        })

        this.coachesSettingsProvider.getByTippGoupId(this.tippgroupId || "0").then(data => {
          console.log('COACH', data);
          if (data && data.id) {
            this.myCoach = data;
            //TODO => CoachComponent:   this.fotoUrl = this.fotoImageUrl(this.myCoach);
          } else {
            console.error('got no coach');
            //FIXME: the following should be done by the Backend on user registering for the App!!!
            //assign any one coach (dummy: first coach in list) 
            this.coachesProvider.getList({ i: 0, s: 1 }).then((coaches: BaseList<DataPage>) => {
              this.myCoach = coaches.list[0];
              this.coachesSettingsProvider.update(this.myCoach.id, this.myCoach).then(result => {
                console.warn('successfully assigned first coach in list to the user');
              }, error => {
                console.warn('failed to assign first coach in list to the user');
              });
            }, error => {
              console.error('cannot list coaches', error);
            });
          }
        }, error => {
          console.error('cannot get coach', error);
        });
      });// end branch ready
    });
  }

  loadOnlinecourse() {
    this.onlineCourseProductProvider.getList(this.tippgroupId, this.onlinecoursesMore).then(data => {
      this.onlinecourses = data && data.list;
      this.onlinecoursesMore = data && data.end;
    });
  }

  loadBewerbungstipps() {
    this.bewerbungstippsProvider.getList(this.tippgroupId, this.bewerbungstippgroupsMore).then(data => {
      this.bewerbungstippgroups = data.list;
      this.bewerbungstippgroupsMore = data && data.end;
    });
  }

  loadWebinars() {
    this.webinarsProvider.getList(this.tippgroupId, this.webinarsMore).then(data => {
      this.webinars = data && data.list;
      this.webinarsMore = data && data.end;
    });
  }

  loadPodcasts() {
    this.podcastsProvider.getList(this.tippgroupId, this.podcastsMore).then(data => {
      this.podcasts = data && data.list;
      this.podcastsMore = data && data.end;
    });
  }

  loadVideos() {
    this.videosProvider.getList(this.tippgroupId, this.videosMore).then(data => {
      this.videos = data && data.list;
      this.videosMore = data && data.end;
    });
  }

  openChecklistPage(checklist: Checklist) {
    let _open = (checklist: Checklist) => {
      this.navController.navigateForward('checklist/' + checklist.tippgroupId + '/' + checklist.id);
    };
    if (checklist.needstobepaid && !checklist.hasbeenpaid) {
      let checklists: Checklist[] = [];
      if (this.checklists) {
        this.checklists.forEach(checklist => {
          if (checklist.needstobepaid && !checklist.hasbeenpaid) {
            checklists.push(checklist);
          }
        });
      }
      this.modalService.showModal(
        {component: ChecklistViewMoreModalPage, componentProps: { checklists: checklists, checklist: checklist }, cssClass: 'modal-overlay' },
        (checklist: Checklist) => {
          if (checklist) { // could be another one of the videos
            _open(checklist);
          }
        }
      );
    } else {
      _open(checklist);
    }
  }

  openPage(page, params, event) {
    if (event) {
      event.preventDefault();
    }
    this.navController.navigateForward(this.routingService.getPathReplaceAny(page, params));
  }

}
