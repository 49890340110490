import { Component, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService } from "services/branch-config/branch-config";
import { Video } from "services/video/video.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from 'services/modal/modal.service';
import { VideoViewMoreModalPage } from 'pages/video-view-more-modal/video-view-more-modal';

@Component({
  selector: 'cdzvideo',
  templateUrl: 'video.html',
  styleUrls: ['video.scss']
})
export class CDZVideoComponent implements OnChanges {

  @Input() video: Video;
  @Input() otherVideos?: Video[];
  @Input() hideBuying?: boolean = false;
  @Input() isInList?: boolean = false;
  @ViewChild('videoElem', { static: true }) videoElement;
  playing: boolean;
  protected isYouTube: boolean = false;
  protected youTubeUrl: SafeResourceUrl;
  protected cycleSrc: boolean = false;

  constructor(
    public branch: BranchConfigService,
    private router: Router,
    private modalService: ModalService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('video')) {
      this.buildYoutubeUrl();
      // at least in Chrome, changing the video source attribute on an existing video element has no effect, so we have to force a new video element
      this.cycleSrc = true;
      setTimeout(() => {
        this.cycleSrc = false;
      });
    }
  }

  buy() {
    let videos: Video[] = [];
    if (this.otherVideos) {
      this.otherVideos.forEach(video => {
        if (video.needstobepaid && !video.hasbeenpaid) {
          videos.push(video);
        }
      });
    }
    this.modalService.showModal(
      {component: VideoViewMoreModalPage, componentProps: { videos: videos, video: this.video }, cssClass: 'modal-overlay'},
      (video: Video) => {
        if (video) { // could be another one of the videos
          //?
        }
      });
  }

  openVideo() {
    this.modalService.showModal({component: VideoViewMoreModalPage, componentProps: { videos: [this.video], video: this.video }, cssClass: 'modal-overlay'});
  }

  /**
   * This method finds and builds YouTube URLs with their embeded counterpart.
   * URLs like the following will be matched:
   * - https://youtu.be/YRLb_nEQEVY
   * - https://www.youtube.com/watch?v=YRLb_nEQEVY
   */
  buildYoutubeUrl() {
    const regex = /.*(?:(?:youtu\.be\/)|(?:youtube\.[a-z]+\/watch\?v=))(.+)/gi;
    this.isYouTube = !!(this.video) && !!(this.video.url) ? this.video.url.search(regex) == 0 : false;
    if (this.isYouTube) {
      this.youTubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.video.url.replace(regex, (fullMatch, videoKey) => `${videoKey}`));
    }
  }

}
