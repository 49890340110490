// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatWrapperStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
.chatWrapperStyle * {
  box-sizing: border-box;
}
.chatWrapperStyle *::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
.chatWrapperStyle *::-webkit-scrollbar-track {
  background: #ffffff00;
}
.chatWrapperStyle *::-webkit-scrollbar-thumb {
  background: #ccc;
}
.chatWrapperStyle *::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.chatsHeaderStyle {
  padding: 19px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
}

.chatsHeaderTitleStyle {
  margin: 0;
  display: inline-block;
  width: 66%;
  text-align: left;
  font-size: 20px;
}

.chatsMsgStyle {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
}

.chatsMsgTxtStyle {
  margin: 0;
  height: 30px;
  font-size: 24px !important;
  font-weight: 600;
  color: #cccccc;
}

.chatsListStyle {
  height: calc(100% - 65px);
  width: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Chats/CometChat-conversation-list/cometchat-conversation-list/cometchat-conversation-list.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,qBAAqB;AACvB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,SAAS;EACT,qBAAqB;EACrB,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".chatWrapperStyle {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  box-sizing: border-box;\n}\n.chatWrapperStyle * {\n  box-sizing: border-box;\n}\n.chatWrapperStyle *::-webkit-scrollbar {\n  width: 8px;\n  height: 4px;\n}\n.chatWrapperStyle *::-webkit-scrollbar-track {\n  background: #ffffff00;\n}\n.chatWrapperStyle *::-webkit-scrollbar-thumb {\n  background: #ccc;\n}\n.chatWrapperStyle *::-webkit-scrollbar-thumb:hover {\n  background: #aaa;\n}\n\n.chatsHeaderStyle {\n  padding: 19px 16px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #eaeaea;\n}\n\n.chatsHeaderTitleStyle {\n  margin: 0;\n  display: inline-block;\n  width: 66%;\n  text-align: left;\n  font-size: 20px;\n}\n\n.chatsMsgStyle {\n  overflow: hidden;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 50%;\n}\n\n.chatsMsgTxtStyle {\n  margin: 0;\n  height: 30px;\n  font-size: 24px !important;\n  font-weight: 600;\n  color: #cccccc;\n}\n\n.chatsListStyle {\n  height: calc(100% - 65px);\n  width: 100%;\n  overflow-y: auto;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
