import { Injectable } from "@angular/core";
import { ApiService } from 'services/api/api.service';
import { OnlineCourse } from "./onlinecourse";

@Injectable({
  providedIn: 'root'
})
export class OnlineCourseService extends ApiService<OnlineCourse> {

  constructor() {
    super('onlinecourse');
  }
}