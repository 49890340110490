import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { BewerbungstippViewMoreModalPage } from 'pages/bewerbungstipp-view-more-modal/bewerbungstipp-view-more-modal';
import { PodcastViewMoreModalPage } from 'pages/podcast-view-more-modal/podcast-view-more-modal';
import { VideoViewMoreModalPage } from 'pages/video-view-more-modal/video-view-more-modal';
import { WebinarViewMoreModalPage } from 'pages/webinar-view-more-modal/webinar-view-more-modal';
import { Subscription } from 'rxjs';
import { BaseList } from 'services/baselist/baselist';
import { Tipp } from 'services/bewerbungstipps/bewerbungstipps';
import { MessageService } from 'services/message/message.service';
import { ModalService } from 'services/modal/modal.service';
import { PagesService } from 'services/pages/pages.service';
import { GoodType, PaidGood, PaidGoodsService } from 'services/paid-goods/paid-goods.service';
import { Payable } from 'services/payable/payable.service';
import { Podcast, PodcastsService } from 'services/podcasts/podcasts.service';
import { Video } from 'services/video/video.service';
import { Webinar } from 'services/webinar/webinar.service';
import { DataFields } from 'src/app/metagroup';


const moment = _rollupMoment || _moment;

@Component({
  selector: 'page-paid-goods',
  templateUrl: 'paid-goods.html',
  styleUrls: ['paid-goods.scss']
})
export class PaidGoodsPage implements OnDestroy, OnInit {
  public readonly pageName: string = "PaidGoodsPage";

  paidGoods: BaseList<PaidGood<Payable>>;
  public podcasts: Podcast[];
  public subscription: Subscription;

  public pages: any[];

  constructor(
    public modalService: ModalService,
    public injector: Injector,
    public paidGoodsProvider: PaidGoodsService,
    public pagesProvider: PagesService,
    public podcastsProvider: PodcastsService,
    public message: MessageService,
    private navController: NavController
  ) {
    this.pages = pagesProvider.getPage('AccountPage').sub;
    this.subscription = this.message.messages().subscribe(msg => { //TODO: message that in the provider at create / buying process
      if (msg && msg.paidGood) {
        let idx = this.paidGoods.list.findIndex(pg => pg.id === msg.paidGood.id);
        if (idx === -1) {
          this.paidGoods.list.push(msg.paidGood);
          this.paidGoods.count++;
        } else {
          this.paidGoods.list[idx] = msg.paidGood;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit() {
    console.log('ngOnInit PaidGoodsPage');
    this.paidGoodsProvider.getList().then(paidGoods => {
      this.paidGoods = paidGoods;
    }, error => {
      console.error('cannot get paidGoods', error);
    });
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter PaidGoodsPage');
  }

  ionViewWillLeave() {
    console.log('ionViewWillLeave PaidGoodsPage');
  }

  title(pg: PaidGood<Payable>): string {
    return !!pg && !!pg.good ? (<string>(<PaidGood<DataFields>>pg).good.title) : "paidgood.null.title";
  }

  description(pg: PaidGood<Payable>): string {
    return !!pg && !!pg.good ? (<string>(<PaidGood<DataFields>>pg).good.description) : "paidgood.null.description";
  }

  view(pg: PaidGood<Payable>): void {
    let jump = () => {
      let modal = null;
      switch (pg.type) {
        case GoodType.COACHING:
          let bewerbungsservice = (<PaidGood<DataFields>>pg).good;
          //TODO: => where to go here???
          //
          //      A) there is *no* direct page for a Bewerbungsservice, just one for the coach listing all his/her offered Bewerbungsservices.
          //         the page receives the bewerbungsserviceId, but currently does nothing with it ...
          //      B) there is a NachrichtenPage which should by now contain a chat to the coach (even if only with an automatic response),
          //         but does it make sense to go to the chat to the coach without a specific connection to the Bewerbungsservicee?
          //
          //      => For a start I'm just directing to the page displaying the coach
          this.navController.navigateForward('bewerbungsservice/assigned');
          break;
          
          case GoodType.BEWERBUNGSTIPP:
            let tipp = (<PaidGood<Tipp>>pg).good;
            let _open = (tipp: Tipp) => {
              this.navController.navigateForward('bewerbungstipp/null/' + tipp.id);
          };
          if (tipp.needstobepaid && !tipp.hasbeenpaid) {
            this.modalService.showModal({component: BewerbungstippViewMoreModalPage, componentProps: { bewerbungstipps: [tipp], bewerbungstipp: tipp }, cssClass: 'modal-overlay' });
          } else {
            _open(tipp);
          }
          break;

        case GoodType.PODCAST:
          let podcast = (<PaidGood<Podcast>>pg).good;
          this.modalService.showModal({component: PodcastViewMoreModalPage, componentProps: { podcasts: [podcast], podcast: podcast }, cssClass: 'modal-overlay' });
          break;

        case GoodType.VIDEO:
          let video = (<PaidGood<Video>>pg).good;
          this.modalService.showModal({component: VideoViewMoreModalPage, componentProps: { videos: [video], video: video }, cssClass: 'modal-overlay' });
          break;

        case GoodType.WEBINAR:  
          let webinar = (<PaidGood<Webinar>>pg).good;
          this.modalService.showModal({component: WebinarViewMoreModalPage, componentProps: { webinars: [webinar], webinar: webinar }, cssClass: 'modal-overlay' });
          break;

        // case GoodType.TEMPLATE: this.navCtrl.push('BewerbungsmappePage', { metapage: 'template' });

        // case GoodType.CHECKLIST => checkliisten gibts nicht mehr wirklich
        //   let checklist = (<PaidGood<Checklist>>pg).good;
        // this.navCtrl.push('ChecklistPage', { id: checklist.id, groupid: checklist.tippgroupId });
        default:
          console.warn("good type invalid: " + pg.type);
          break;
      }
    }

    if (pg.paymenttime) {
      jump();
    } else {
      jump();
    }
  }

}
