import { RoutingService } from './../../services/routing/routing.service';
import { NavController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BranchConfigService, BranchConfig, BranchData } from 'services/branch-config/branch-config';
import { ConstantVariable } from "constant-variable";

@Component({
  selector: 'page-tabs',
  templateUrl: 'page-tabs.html',
  styleUrls: ['page-tabs.scss']
})
export class PageTabsComponent {
  @Input() pages: any[];
  @Input() percentage?: number;
  @Input() disabled?: boolean;
  showAllFeatures: boolean;

  //TODO: set value of progress indicator either by binding or from a service ...

  constructor(
    private router: Router,
    public navController: NavController,
    public configProvider: BranchConfigService,
    private routingService: RoutingService,
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  openPage(page) {
    if(this.disabled && !page.active) return;
    this.routingService.resetActive(page);
    this.navController.navigateRoot(this.routingService.getPathReplaceAny(page.page, page.params));
  }

  getRootPath(page) {
    return '/' + this.routingService.getPathReplaceAny(page.page, page.params);
  }

  activatePage(page) {
    this.routingService.resetActive(page);
  }
}
