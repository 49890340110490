import { NavController } from '@ionic/angular';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantVariable } from 'constant-variable';
import { DataGroup, DataPage, MetaFile } from 'metagroup';
import { BranchConfigService } from "services/branch-config/branch-config";
import { NachrichtenProvider } from 'services/nachrichten/nachrichten';

@Component({
  selector: 'coach',
  templateUrl: 'coach.html',
  styleUrls: ['coach.scss']
})
export class CoachComponent {
  @Input('coach') coach: DataPage;
  @Input('changeable') changeable?: boolean;
  @Input('hidedetail') hidedetail?: boolean;
  @Input('showdirectcontact') showdirectcontact?: boolean;
  showAllFeatures: boolean;

  constructor(
    public branch: BranchConfigService,
    private navController: NavController,
    public nachrichtenProvider: NachrichtenProvider,
  ) {
    this.showAllFeatures = ConstantVariable.showAllFeatures;
  }

  goToList() {
    if (this.branch.config.features.bewerbungsservice || this.showAllFeatures) {
      this.navController.navigateForward('bewerbungsservice');
    } else {
      console.log('not allowed to go to BewerbungsservicePage');
    }
  }
  
  goToDetail() {
    if (this.branch.config.features.bewerbungsservice || this.showAllFeatures) {
      this.navController.navigateForward('bewerbungsservice/' + (this.coach ? this.coach.id : null));
    } else {
      console.log('not allowed to go to BewerbungsserviceDetailPage');
    }
  }
  
  goToChat() {
    let gotochat = (data: any) => {
      this.navController.navigateForward('chat/' + data.userId);
    };
    if (this.branch.config.features.nachrichten || this.showAllFeatures) {
      this.nachrichtenProvider.initializeChat(this.coach).then(
        chatPageData => gotochat(chatPageData),
        error => console.error('Failed chat for coach: ' + this.coach.id + '/' + this.coach.userid, error));
    } else {
      console.log('not allowed to go to ChatPage');
    }
  }

  fotoUrl(): string {
    if (this.coach && this.coach.person && (<DataGroup>this.coach.person).fieldData && (<DataGroup>this.coach.person).fieldData.foto) {
      let file: MetaFile = <MetaFile>((<DataGroup>this.coach.person).fieldData.foto);
      return 'url(data:' + file.filetype + ';base64,' + file.value + ')';
    } else {
      return null;
    }
  }

  //TODO => CoachComponent:   this.fotoUrl = this.fotoImageUrl(this.myCoach);

}
