import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { MetaGroup, DataGroup, DataFields} from 'metagroup';

@Component({
  selector: 'leistung-list',
  templateUrl: 'leistung-list.html',
  styleUrls: ['leistung-list.scss']
})
export class LeistungListComponent  {
  @Input() group: MetaGroup;
  @Input() leistungen?: DataGroup|DataGroup[];

  constructor(private router: Router) {
  }

}
