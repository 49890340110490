import { DirectivesModule } from './../directives/directives.module';
import { OnlinecourseListComponent } from './onlinecourse-list/onlinecourse-list';
import { OnlincourseComponent } from './onlinecourse/onlinecourse';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { WarningComponent } from './warning/warning.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { WrapContentComponent } from './wrap-content/wrap-content';
import { WrapModalComponent } from './wrap-modal/wrap-modal';
import { PageTabsComponent } from './page-tabs/page-tabs';
import { FIconComponent } from './f-icon/f-icon';
import { MetaGroupComponent } from './meta-group/meta-group';
import { MetaFieldComponent } from './meta-field/meta-field';
import { PageStepComponent } from './page-step/page-step';
import { NewsComponent } from './news/news';
import { ProfilfragenComponent } from './profilfragen/profilfragen';
import { PodcastComponent } from './podcast/podcast';
import { PipesModule } from '../pipes/pipes.module';
import { MetaValidityComponent } from './meta-validity/meta-validity';
import { CDZVideoComponent } from './video/video';
import { CoachComponent } from './coach/coach';
import { WebinarComponent } from './webinar/webinar';
import { PremiumBarComponent } from './premium-bar/premium-bar';
import { PagePreviewComponent } from './page-preview/page-preview';
import { BewerbungstippListComponent } from './bewerbungstipp-list/bewerbungstipp-list';
import { PodcastListComponent } from './podcast-list/podcast-list';
import { BewerbungstippTitleComponent } from './bewerbungstipp-title/bewerbungstipp-title';
import { VideoListComponent } from './video-list/video-list';
import { WebinarListComponent } from './webinar-list/webinar-list';
import { LeistungComponent } from './leistung/leistung';
import { LeistungListComponent } from './leistung-list/leistung-list';
import { PagerComponent } from "./pager/pager";
import { PrivacyComponent } from './privacy/privacy.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    WrapContentComponent,
    WrapModalComponent,
    PageTabsComponent,
    MetaGroupComponent,
    MetaFieldComponent,
    PageStepComponent,
    NewsComponent,
    ProfilfragenComponent,
    PodcastComponent,
    MetaValidityComponent,
    CDZVideoComponent,
    CoachComponent,
    WebinarComponent,
    PremiumBarComponent,
    PagePreviewComponent,
    BewerbungstippListComponent,
    PodcastListComponent,
    BewerbungstippTitleComponent,
    VideoListComponent,
    WebinarListComponent,
    LeistungComponent,
    LeistungListComponent,
    PagerComponent,
    PrivacyComponent,
    WarningComponent,
    MenuComponent,
    HeaderComponent,
    OnlincourseComponent,
    OnlinecourseListComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    TranslateModule,
    PipesModule,
    RouterModule,
    FIconComponent
  ],
  exports: [
    WrapContentComponent,
    WrapModalComponent,
    PageTabsComponent,
    MetaGroupComponent,
    MetaFieldComponent,
    PageStepComponent,
    NewsComponent,
    ProfilfragenComponent,
    PodcastComponent,
    MetaValidityComponent,
    CDZVideoComponent,
    CoachComponent,
    WebinarComponent,
    PremiumBarComponent,
    PagePreviewComponent,
    BewerbungstippListComponent,
    PodcastListComponent,
    BewerbungstippTitleComponent,
    VideoListComponent,
    WebinarListComponent,
    LeistungComponent,
    LeistungListComponent,
    PagerComponent,
    PrivacyComponent,
    WarningComponent,
    MenuComponent,
    HeaderComponent,
    OnlincourseComponent,
    OnlinecourseListComponent,
  ]
})
export class ComponentsModule { }
