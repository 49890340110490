// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
  z-index: 1;
  min-height: 20vh;
  max-height: 60vh;
  overflow: hidden;
  padding: 20px;
}
:host::before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.05) 10%, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0.9) 60%, rgba(255, 255, 255, 0.95) 70%, rgb(255, 255, 255));
}
:host > div {
  position: relative;
  z-index: 2;
  width: 200%;
  transform: scale(0.5) translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/app/components/page-preview/page-preview.scss"],"names":[],"mappings":"AAAA;EAEE,cAAA;EACA,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;AAAF;AAEE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,4BAAA;EACA,sBAAA;EACA,kCAAA;EACA,+MAAA;AAAJ;AAGE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,2CAAA;AADJ","sourcesContent":[":host {\n\n  display: block;\n  position: relative;\n  z-index: 1;\n  min-height: 20vh;\n  max-height: 60vh;\n  overflow: hidden;\n  padding: 20px;\n\n  &::before {\n    content: '';\n    position: absolute;\n    z-index: 3;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center center;\n    background-image: linear-gradient(180deg, rgba(255,255,255,0.0), rgba(255,255,255,0.05) 10%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.9) 60%, rgba(255,255,255,0.95) 70%, rgba(255,255,255,1.0));\n  }\n\n  &>div {\n    position: relative;\n    z-index: 2;\n    width: 200%;\n    transform: scale(0.5) translate(-50%,-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
