import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { BewerbungstippsProvider, TippGroup } from 'services/bewerbungstipps/bewerbungstipps';

@Component({
  selector: 'page-bewerbungstipp',
  templateUrl: 'bewerbungstipp.html',
  styleUrls: ['bewerbungstipp.scss']
})
export class BewerbungstippPage implements OnInit {
  public readonly pageName: string = "BewerbungstippPage";

  tippgroup: TippGroup;

  constructor(
    public injector: Injector,
    public bewerbungstippsProvider: BewerbungstippsProvider,
    private route: ActivatedRoute,
    private ionRouterOutlet: IonRouterOutlet,
    private navController: NavController
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit BewerbungstippPage');
    this.route.paramMap.subscribe( paramMap => {
      this.bewerbungstippsProvider.get(+paramMap.get('groupid'), +paramMap.get('id')).then((tippgroup: TippGroup) => {
        this.tippgroup = tippgroup;
        console.log('TippGroup w/ one Tipp loaded: ', this.tippgroup);
      }, error => {
        console.error('failed to load tipp', error);
      });
    })
  }

  goBack() {
    if (this.ionRouterOutlet.canGoBack()) {
      this.navController.pop();
    } else {
      this.navController.navigateRoot('bewerbungstipps/' + this.tippgroup.id);
    }
  }

}
