import { Inject, Injectable, Optional } from '@angular/core';
import { ConstantVariable } from 'constant-variable';
import { DataPage } from 'metagroup';
import { ApiService } from 'services/api/api.service';
import { ApiAuthOptions, AuthOptions } from 'services/auth/auth-config';
import { SignupCredentials } from 'services/auth/auth-credentials';
import { AuthResponse } from 'services/auth/auth-response.interface';
import { AuthService } from 'services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateService extends ApiService {

  constructor(
    public authService: AuthService,
    @Optional() @Inject(ApiAuthOptions) public authOptions: AuthOptions
  ) {
    super('activate');
  }

  public getActivateMdata(postdata: { type: string, data: string }): Promise<DataPage> {
    return this.httpPost('mdata', '', postdata);
  }

  public signup(signup: SignupCredentials): Promise<AuthResponse> {
    signup.username = !!signup && !!signup.username ? signup.username.trim() : null;
    return this.httpPost('signup', '', signup).then(data => {
      const d = { username: signup.email, password: signup.password };
      const loginCredentials = { ...ConstantVariable.authParameters, ...d };
      return this.authService.login(loginCredentials);
    });
  }
}
