import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'services/routing/routing.service';

@Component({
  selector: 'app-dashboard-discover',
  templateUrl: './dashboard-discover.page.html',
  styleUrls: ['./dashboard-discover.page.scss'],
})
export class DashboardDiscoverPage implements OnInit {

  constructor(
    private routingService: RoutingService
  ) { }

  ngOnInit() {
    this.routingService.setRootPage();
  }

}
