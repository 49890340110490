import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ConstantVariable } from 'constant-variable';
import { filter } from 'rxjs/operators';
import { BewerbungsmappeService } from 'services/bewerbungsmappe/bewerbungsmappe.service';
import { BewerbungstippsProvider } from 'services/bewerbungstipps/bewerbungstipps';
import { BranchConfig, BranchConfigService } from 'services/branch-config/branch-config';
import { OnboardingContentService } from 'services/onboarding-content/onboarding-content.service';
import { RoutingService } from 'services/routing/routing.service';
import { StorageKey } from 'services/storage/storage.keys';
import { StorageService } from 'services/storage/storage.service';
import { Pages } from 'src/app/pages';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChildren("lazySubMenuItem") lazySubMenuItems: QueryList<ElementRef>;

  @Input() contentId: string;

  collapsedMenu: boolean = false;
  showAllFeatures: boolean = false;
  config: BranchConfig;
  pages: any;

  constructor(
    private configProvider: BranchConfigService,
    private navController: NavController,
    private routingService: RoutingService,
    private bewerbermappeProvider: BewerbungsmappeService,
    private onboardingContentProvider: OnboardingContentService,
    private bewerbungstippsProvider: BewerbungstippsProvider,
    private storageService: StorageService,
    private router: Router
  ) {
    this.pages = Pages;

    let firstCall: boolean = true;
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (firstCall) {
          firstCall = false;
          this.runAllUpdatePages(event?.urlAfterRedirects);
        }
      });
    this.runAllUpdatePages(null);
  }

  ngOnInit() {
    this.storageService.get(StorageKey.MENU_COLLAPSED).then(data => this.collapsedMenu = data);
    this.configProvider.isReady.then((config: BranchConfig) => {
      this.showAllFeatures = ConstantVariable.showAllFeatures;
      this.config = config;
    });
  }

  runAllUpdatePages(navigationUrl: string): void {
    this.configProvider.configChanged.subscribe((config: BranchConfig) => {
      this.config = config;
      if (config?.features && config?.features?.onboarding) {
        this.updateOnboardingContent(navigationUrl);
      }
      if (config?.features && config?.features?.bewerbungsmappe) {
        this.updateBewerbermappe(navigationUrl);
      }
      if (config?.features && config?.features?.bewerbungstipps) {
        this.updateBewerbungstipps(navigationUrl);
      }
    });
  }

  updateBewerbermappe(navigationUrl: string): void {
    this.bewerbermappeProvider.updateSubpages().then(() => {
      console.log('successfully loaded bewerbermappe subpages');
      this.config = this.configProvider.config;
      this.activatePageByNavigation(navigationUrl);
    })
      .catch(error => {
        console.error('could not load bewerbermappe subpages from server', error);
      });
  }

  updateOnboardingContent(navigationUrl: string): void {
    this.onboardingContentProvider.updateSubpages().then(() => {
      console.log('successfully loaded onboardingContentProvider subpages');
      this.config = this.configProvider.config;
      this.activatePageByNavigation(navigationUrl);
    })
      .catch(error => {
        console.error('could not load onboardingContentProvider subpages from server', error);
      });
  }

  updateBewerbungstipps(navigationUrl: string): void {
    this.bewerbungstippsProvider.updateSubpages().then(() => {
      console.log('successfully loaded bewerbungstippsProvider subpages');
      this.config = this.configProvider.config;
      this.activatePageByNavigation(navigationUrl);
    })
      .catch(error => {
        console.error('could not load bewerbungstippsProvider subpages from server', error);
      });
  }

  activatePageByNavigation(navigationUrl: string): void {
    if (!!navigationUrl) {
      this.pages.forEach(page => {
        // get url for page
        let url = this.getRootPath(page);
        // compare page url to current navigation url
        if (url == navigationUrl) {
          // activate page if urls fit
          this.activatePage(page);
          return;
        }
        // else if page has subpages, look for fitting subpages
        else if (!!page.sub) {
          page.sub.forEach(sub => {
            // get url for sub
            let subUrl = this.getRootPath(sub);
            // compare sub url to current navigation url
            if (subUrl == navigationUrl) {
              // activate sub if urls fit
              this.activatePage(sub);
              return;
            }
          });
        }
      });
    }
  }

  openPage(page, root = true) {
    this.routingService.resetActive(page);
    // navigate to the new page if it is not the current page
    if (root) {
      this.navController.navigateRoot(this.routingService.getPathReplaceAny(page.page, page.params));
    } else {
      this.navController.navigateForward(this.routingService.getPathReplaceAny(page.page, page.params));
    }
  }

  getRootPath(page) {
    return '/' + this.routingService.getPathReplaceAny(page.page, page.params);
  }

  activatePage(page) {
    this.routingService.resetActive(page);
  }

  toggleMenuCollapse(): void {
    this.collapsedMenu = !this.collapsedMenu;
    this.storageService.set(StorageKey.MENU_COLLAPSED, this.collapsedMenu);
  }
}
