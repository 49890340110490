// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-text {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  opacity: 0.4;
  background: var(--ion-color-warning);
  border: 1px solid var(--ion-color-warning-shade);
  color: var(--ion-color-warning-contrast);
}`, "",{"version":3,"sources":["webpack://./src/app/components/warning/warning.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACH,kBAAA;EACA,kBAAA;EACG,iBAAA;EACA,YAAA;EACA,oCAAA;EACH,gDAAA;EACG,wCAAA;AACJ","sourcesContent":[".warning-text {\n    display: inline-block;\n\tpadding: 10px 20px;\n\tborder-radius: 5px;\n    font-weight: bold;\n    opacity: 0.4;\n    background: var(--ion-color-warning);\n\tborder: 1px solid var(--ion-color-warning-shade);\n    color: var(--ion-color-warning-contrast);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
