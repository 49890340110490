// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm_dialog {
  width: calc(100% - 32px);
  height: auto;
  background-color: #fff;
  position: absolute;
  margin: 0 auto;
  padding: 16px;
  font-size: 13px;
  border-radius: 8px;
  border: 1px solid #eee;
  z-index: 4000;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.confirm_message {
  text-align: center;
}

.confirm_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0 0 0;
}
.confirm_buttons > button {
  padding: 5px 24px;
  margin: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #39f;
}

.yes {
  background-color: #39f;
  color: white;
}

.no {
  background-color: #f6f6f6;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Shared/CometChat-confirm-dialog/cometchat-confirm-dialog/cometchat-confirm-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,QAAQ;EACR,OAAO;EACP,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".confirm_dialog {\n  width: calc(100% - 32px);\n  height: auto;\n  background-color: #fff;\n  position: absolute;\n  margin: 0 auto;\n  padding: 16px;\n  font-size: 13px;\n  border-radius: 8px;\n  border: 1px solid #eee;\n  z-index: 4000;\n  top: 50%;\n  left: 0;\n  right: 0;\n  transform: translateY(-50%);\n}\n\n.confirm_message {\n  text-align: center;\n}\n\n.confirm_buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin: 24px 0 0 0;\n}\n.confirm_buttons > button {\n  padding: 5px 24px;\n  margin: 0 8px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 600;\n  border: 1px solid #39f;\n}\n\n.yes {\n  background-color: #39f;\n  color: white;\n}\n\n.no {\n  background-color: #f6f6f6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
