import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Environment } from './app/environment';
import { AppModule } from './app/app.module';

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {

  if ('serviceWorker' in navigator && Environment.serviceworker && typeof window["cordova"] === 'undefined') {
    navigator.serviceWorker.register('ngsw-worker.js')
      .then(() => console.log('service worker installed'))
      .catch(err => console.error('service worker installation error', err));
  }

}).catch(err => {
  console.error('app bootstrap failure', err);
});
