import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

import { StorageKey } from './storage.keys';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public set(key: StorageKey, value: any): Promise<any> {
    return Preferences.set({
      key: key.toString(),
      value: JSON.stringify(value)
    });
  }

  public async get(key: StorageKey): Promise<any> {
    const { value } = await Preferences.get({
      key: key.toString()
    });
    return JSON.parse(value);
  }

  public remove(key: StorageKey): Promise<any> {
    return Preferences.remove({
      key: key.toString()
    });
  }
}