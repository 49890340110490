
import {catchError, switchMap} from 'rxjs/operators';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



import { MessageService } from 'services/message/message.service';

/**
 * Purpose: enable AuthInterceptor to intercept downloal requests from the user by url that should get the X-Auth-Token ...
 *
 * usage e.g.:
 * <a [href]="document.url | download:document.mimeType | async" download>download file here</a>
 */

@Injectable()
@Pipe({
    name: 'download',
})
export class DownloadPipe implements PipeTransform {
    constructor(
        private http: HttpClient,
        private message: MessageService,
    ) {}

    transform(url: string, mimetype: string = 'application/octet-stream') {
        let thisA = this;
        thisA.message.subject.next({ // also tell potential listeners in the application that a download is now starting
            downloadPipe: {
                url: url,
                success: null
            }
        });
        //if(!url) return '';
        return this.http.get(url,{
            headers: new HttpHeaders({'Content-Type': mimetype, 'Accept': mimetype + ', */*'}),
            responseType: 'blob'
        }).pipe(
        switchMap((blob: Blob) => {
            // return new observable which emits a base64 string when blob is converted to base64
            return Observable.create(observer => {
                const  reader = new FileReader();
                reader.readAsDataURL(blob); // convert blob to base64
                reader.onloadend = function() {
                    observer.next(reader.result); // emit the base64 string result (to the actual displaying DOM or async pipe)
                    thisA.message.subject.next({ // also tell potential listeners in the application that it is now loaded (ready to be shown by the user agent)
                        downloadPipe: {
                            url: url,
                            success: true,
                            data: reader.result
                        }
                    });
                }
            });
        }),catchError((error, caught) => {
            thisA.message.subject.next({ // also tell potential listeners in the application that it failed to load
                downloadPipe: {
                    url: url,
                    success: false,
                    error: error,
                    caught: caught
                }
            });
            return Promise.resolve(url); // just return the original url (will not work, but breaks the deadloop)
        }),);
    }
}