// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatListStyle {
  z-index: 1;
  width: 100%;
  flex: 1 1 0;
  order: 2;
  position: relative;
  height: 100%;
}

.listWrapperStyle {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;

  width: 100%;
  z-index: 100;
  padding-top: 14px;
}

.actionMessageTxtStyle {
  padding: 8px 12px;
  margin-bottom: 16px;
  text-align: center;
}

.messageDateContainerStyle {
  text-align: center;
  margin-bottom: 16px;
}

.messageDateStyle {
  padding: 8px 12px;
  background-color: #f6f6f6;
  color: #141414;
  border-radius: 10px;
}
.decoratorMessageStyle {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
}

.decoratorMessageTxtStyle {
  margin: 0;
  height: 30px;
  color: #cccccc;
  font-size: 24px !important;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Messages/CometChat-message-list/cometchat-message-list/cometchat-message-list.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,WAAW;EACX,QAAQ;EACR,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;;EAEtB,kBAAkB;EAClB,MAAM;;EAEN,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,cAAc;EACd,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[".chatListStyle {\n  z-index: 1;\n  width: 100%;\n  flex: 1 1 0;\n  order: 2;\n  position: relative;\n  height: 100%;\n}\n\n.listWrapperStyle {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n\n  position: absolute;\n  top: 0;\n\n  width: 100%;\n  z-index: 100;\n  padding-top: 14px;\n}\n\n.actionMessageTxtStyle {\n  padding: 8px 12px;\n  margin-bottom: 16px;\n  text-align: center;\n}\n\n.messageDateContainerStyle {\n  text-align: center;\n  margin-bottom: 16px;\n}\n\n.messageDateStyle {\n  padding: 8px 12px;\n  background-color: #f6f6f6;\n  color: #141414;\n  border-radius: 10px;\n}\n.decoratorMessageStyle {\n  overflow: hidden;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 50%;\n}\n\n.decoratorMessageTxtStyle {\n  margin: 0;\n  height: 30px;\n  color: #cccccc;\n  font-size: 24px !important;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
