import { Component, Injector, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataPage } from 'metagroup';
import { ContractUploadedDocumentService } from 'services/contract-uploaded-document/contract-uploaded-document.service';

@Component({
  selector: 'page-onboarding-contract-remove',
  templateUrl: 'onboarding-contract-remove.html',
  styleUrls: ['onboarding-contract-remove.scss']
})
export class OnboardingContractRemoveModalPage implements OnInit {
  public readonly pageName: string = "TermsModalPage";

  @Input() dataPage: DataPage;

  constructor(
    public injector: Injector,
    public contractUploadedDocumentProvider: ContractUploadedDocumentService,
    private modalController: ModalController
  ) {
  }
  
  ngOnInit() {
    console.log('ngOnInit OnboardingContractRemovePage');
  }

  dismiss(remove: boolean) {
    if (remove) {
      this.contractUploadedDocumentProvider.delete(this.dataPage.id).then(data => {
        //?
      }, error => {
        console.error('cannot remove uploaded document');
      });
    }
    this.modalController.dismiss();
  }
}
